import React from "react";

import SecondaryTitle from "../../../../../components/SecondaryTitle/SecondaryTitle";
import DescriptionText from "../../../../../components/DescriptionText/DescriptionText";
import CurveRow from "./CurveRow";

import { PointKey } from "../../../../../../../../../store/services/models/installations/installationSettings";

import { calculateMinAndMaxOutdoorTempValues } from "../../../helpers/zoneSettingsHelpers";

import useCurveSection from "../../../hooks/curveSection/useCurveSection";
import useCurveSectionConditionsAndValuesForDisplay from "../../../hooks/curveSection/useCurveSectionConditionsAndValuesForDisplay";

import { ICureveSectionProps } from "../../../types";

export default function CurveSection({ isCooling }: ICureveSectionProps) {
  const {
    curve,
    isInstallationOffline,
    limitValuesForOtdoorTemp,
    updateTempInCurve,
  } = useCurveSection(isCooling);
  const {
    mainTitle,
    dependenciesDescriptionText,
    pointsTitle,
    flowTempInputLabel,
    outdoorTempInputLabel,
  } = useCurveSectionConditionsAndValuesForDisplay(isCooling);

  return (
    <>
      <SecondaryTitle title={mainTitle} variant="sm" />
      <DescriptionText text={dependenciesDescriptionText} />
      {curve &&
        Object.entries(curve).map(([key, value], index) => (
          <CurveRow
            key={key}
            pointValue={value}
            title={`${pointsTitle} ${index + 1}`}
            isInstallationOffline={isInstallationOffline}
            flowTempInputLabel={`${flowTempInputLabel} ${index + 1}`}
            outdorTempInputLabel={`${outdoorTempInputLabel} ${index + 1}`}
            limitValuesForOutdoorTemp={calculateMinAndMaxOutdoorTempValues(
              index,
              limitValuesForOtdoorTemp,
              0.5,
              isCooling
            )}
            onFlowTempChange={(newValue, tempKey) =>
              updateTempInCurve!(
                newValue,
                isCooling ? "coolingCurve" : "heatingCurve",
                key as PointKey,
                tempKey
              )
            }
          />
        ))}
    </>
  );
}
