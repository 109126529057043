import { Box, SxProps, Typography } from "@mui/material";
import React, { useMemo } from "react";
import useTheme from "../../theme/hooks/useTheme";
import {
  ChevronDown,
  ChevronUp,
  FilterLines,
  XClose,
} from "untitledui-js-base";
import { IDropdownButtonOpenerProps } from "./types";

export default function DropdownButtonOpener({
  buttonRef,
  selectedValuesForDropdown,
  buttonLabel,
  isDropdownOpen,
  handleButtonClick,
  handleClearListClick,
}: IDropdownButtonOpenerProps) {
  const theme = useTheme();
  const { colors, spacing, radius, typography } = theme;
  const dropdownOpenerStyles: Record<string, SxProps> = useMemo(
    () => ({
      buttonWrapper: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        backgroundColor:
          colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
        padding: `0 ${spacing.spacingLg}`,
        cursor: "pointer",
        minWidth: "155px",
        height: "40px",
        border: `1px solid ${
          selectedValuesForDropdown?.length
            ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder
            : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder
        }`,
        borderRadius: radius.radiusMd,
      },
      buttonText: {
        ...typography[".text-sm-semibold"],
        color: selectedValuesForDropdown?.length
          ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
          : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
        pointerEvents: "none",
      },
    }),
    [colors, spacing, radius, typography, selectedValuesForDropdown?.length]
  );
  const memoizedDropdownButtonOpener = useMemo(() => {
    return (
      <Box
        id="dd_button"
        ref={buttonRef}
        component={"button"}
        sx={dropdownOpenerStyles.buttonWrapper}
        onClick={handleButtonClick}
      >
        <FilterLines
          size="20"
          style={{
            color: selectedValuesForDropdown?.length
              ? theme.colors.colorsTextTextBrandTertiaryAlt
              : theme.colors.colorsTextTextSecondary,
            marginRight: spacing.spacingMd,
            pointerEvents: "none",
          }}
          strokeWidth={2}
        />
        <Typography sx={dropdownOpenerStyles.buttonText}>
          {buttonLabel}
        </Typography>
        <Box sx={{ display: "flex", ml: "auto" }}>
          {isDropdownOpen ? (
            <ChevronUp
              size="20"
              style={{
                color: selectedValuesForDropdown?.length
                  ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
                  : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
                marginLeft: spacing.spacingMd,
              }}
              strokeWidth={2}
            />
          ) : selectedValuesForDropdown?.length ? (
            <XClose
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClearListClick();
              }}
              size="20"
              style={{
                color:
                  colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
                marginLeft: spacing.spacingMd,
              }}
              strokeWidth={2}
            />
          ) : (
            <ChevronDown
              size="20"
              style={{
                color: selectedValuesForDropdown?.length
                  ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
                  : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
                marginLeft: spacing.spacingMd,
              }}
              strokeWidth={2}
            />
          )}
        </Box>
      </Box>
    );
  }, [isDropdownOpen, selectedValuesForDropdown, buttonLabel, theme]);
  return memoizedDropdownButtonOpener;
}
