import { Box, SxProps, Typography } from "@mui/material";
import React, { useMemo } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";

interface IDifferencesRowProps {
  label: string;
  value: React.JSX.Element | null;
}

export default function DifferencesRow({ label, value }: IDifferencesRowProps) {
  const theme = useTheme();
  const styles: Record<string, SxProps> = useMemo(
    () => ({
      wrapper: {
        display: "flex",
        width: "100%",
        mb: theme.spacing.spacingLg,
      },
      label: {
        ...theme.typography[".text-sm-medium"],
        color: theme.colors.colorsTextTextTertiary,
        width: "50%",
      },
      value: {
        ...theme.typography[".text-sm-medium"],
        color: theme.colors.colorsTextTextPrimary,
        width: "50%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        svg: {
          m: `0 ${theme.spacing.spacingSm}`,
        },
      },
    }),
    [theme]
  );
  return (
    value && (
      <Box sx={styles.wrapper}>
        <Typography sx={styles.label}>{label}</Typography>
        <Typography sx={styles.value}>{value}</Typography>
      </Box>
    )
  );
}
