import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Check, ChevronDown, Translate01 } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";
import { ButtonType, Button as AppButton } from "../Button/Button";
import { translations } from "../../translations";

type ChangeLanguageDialogProperties = {
  onSuccess?: () => void;
  hideTriggerBtn?: boolean;
};

export type ChangeLanguageDialogRef = {
  open: () => void;
  close: () => void;
};

type LngItemType = {
  title: string;
  key: string;
};

const ChangeLanguageDialog = forwardRef<ChangeLanguageDialogRef, ChangeLanguageDialogProperties>(
  ({ hideTriggerBtn = false, onSuccess }, ref) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation("cloud_ui", { keyPrefix: "changeLanguage" });
    const { t: tActions } = useTranslation("cloud_ui", { keyPrefix: "actions" });

    const [isOpened, setIsOpened] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.resolvedLanguage);

    const buildLanguageList = () => {
      const lngs = [];
      for (const lng in translations) {
        lngs.push({
          title: t(`label_${lng}` as any),
          key: lng,
        });
      }
      return lngs;
    };

    const items: Array<LngItemType> = buildLanguageList();

    const openModal = () => {
      setIsOpened(true);
    };
    const closeModal = () => {
      setIsOpened(false);
    };

    useImperativeHandle(ref, () => ({
      open: openModal,
      close: closeModal,
    }));

    const handleOnSubmit = () => {
      i18n.changeLanguage(currentLanguage);
      closeModal();
      if(onSuccess){
        onSuccess();
      }
    };

    const handleCancel = () => {
      setCurrentLanguage(i18n.resolvedLanguage);
      closeModal();
    };

    const handleLanguageChange = (event: any) => {
      setCurrentLanguage(event.target?.value || "");
    };

    return (
      <>
        {!hideTriggerBtn && (
          <AppBar
            position="absolute"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: theme.colors.colorsForegroundFgPrimary,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Container maxWidth="xl" sx={{ padding: "0 24px" }}>
              <Toolbar disableGutters sx={{ height: "64px", display: "flex" }}>
                <Box
                  component="a"
                  sx={{
                    mr: 2,
                  }}
                >
                  <img src="/name_logo_negative.svg" width="230px" alt="ES" />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    ml: "auto",
                    mr: theme.spacing.spacingLg,
                    textTransform: "initial",
                    borderRadius: theme.radius.radiusMd,
                    color: theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
                    backgroundColor:
                      theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,

                    "&:hover": {
                      backgroundColor: theme.colors.colorsBackgroundBgActive,
                    },
                    position: "relative",
                    bottom: "initial",
                    left: "initial",
                    transform: "initial",
                  }}
                  type="submit"
                  startIcon={
                    <Translate01
                      size="16"
                      color={theme.colors.colorsForegroundFgSecondary}
                      strokeWidth={2}
                    />
                  }
                  onClick={openModal}
                >
                  {t("btn_open_change_lng")}
                </Button>
              </Toolbar>
            </Container>
          </AppBar>
        )}
        <Dialog open={isOpened} fullWidth={true} sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}>
          <DialogTitle
            sx={{
              paddingTop: `${theme.spacing.spacing3xl}`,
              paddingBottom: `${theme.spacing.spacingMd}`,
            }}
          >
            <Typography
              sx={{
                ...theme.typography[".text-lg-semibold"],
                color: theme.colors.colorsTextTextPrimary,
              }}
            >
              {t("title")}
            </Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              padding: `0 ${theme.spacing.spacing3xl}`,
              mb: theme.spacing.spacing4xl,
            }}
          >
            <Box
              sx={{
                paddingTop: theme.spacing.spacingXl,
              }}
            >
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("language_label")}
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  displayEmpty
                  value={currentLanguage}
                  onChange={handleLanguageChange}
                  IconComponent={ChevronDown}
                  sx={{
                    "& .MuiSelect-select": { padding: "0 10px" },
                    "& .MuiSelect-icon": { top: "11px" },
                    borderRadius: theme.radius.radiusMd,
                    height: 44,
                    color: theme.colors.colorsTextTextPlaceholder,
                    fontSize: "16px",
                  }}
                  renderValue={(selected) => {
                    return items?.find((option) => option.key === selected)?.title;
                  }}
                >
                  {items?.length > 0 &&
                    items.map((option, index) => (
                      <MenuItem
                        key={`type-item-${index}`}
                        value={option.key}
                        sx={{
                          fontSize: "16px",
                          color: theme.colors.colorsTextTextPlaceholder,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {option.title}
                        {option.key === currentLanguage && (
                          <Check
                            color={theme.colors.colorsForegroundFgBrandPrimary}
                            strokeWidth={2}
                          />
                        )}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
              justifyContent: "space-between",
            }}
          >
            <AppButton
              label={`${tActions("cancel")}`}
              onClick={handleCancel}
              buttonType={ButtonType.SecondaryGray}
              sx={{
                width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
              }}
            />
            <AppButton
              label={`${tActions("save")}`}
              onClick={handleOnSubmit}
              buttonType={ButtonType.Primary}
              sx={{
                pointerEvents: false ? "none" : "initial",
                ml: "0 !important",
                width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
              }}
            />
          </DialogActions>
        </Dialog>
        {!hideTriggerBtn && (
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="text"
              endIcon={
                <ChevronDown
                  size="16"
                  color={theme.colors.componentColorsComponentsButtonsTertiaryButtonTertiaryFg}
                  strokeWidth={2}
                />
              }
              sx={{ m: 2 }}
              onClick={openModal}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-sm-semibold"],
                  color: theme.colors.componentColorsComponentsButtonsTertiaryButtonTertiaryFg,
                }}
              >
                {i18n.resolvedLanguage
                  ? t(`label_${i18n.resolvedLanguage}` as any)
                  : t("btn_open_change_lng")}
              </Typography>
            </Button>
          </Box>
        )}
      </>
    );
  },
);

export default ChangeLanguageDialog;
