import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../theme/hooks/useTheme";
import { Button, ButtonType } from "../../components/Button/Button";
import { ChevronDown, Mail01, Send01 } from "untitledui-js-base";
import { OrganizationTypes } from "../../api/OrganizationsAPI";
import { InviteOrganizationType } from "../../store/services/models/organizations/organizations";
import {
  useFindOrganizationMutation,
  useSendOrganizationInvitationMutation,
} from "../../store/services/organizations";
import { ServerErrorType } from "../../store/services/api";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";

type AddOrganizationDialogProperties = {
  organizationUuid: string;
  onSuccess?: () => void;
};

export type AddOrganizationDialogRef = {
  open: () => void;
  close: () => void;
};

type WizardStepType = "find" | "add_exist" | "add_new" | "success";
type OrgTypesListType = Array<{ key: InviteOrganizationType | ""; value: string }>;

const AddOrganizationDialog = forwardRef<AddOrganizationDialogRef, AddOrganizationDialogProperties>(
  ({ organizationUuid, onSuccess }, ref) => {
    const theme = useTheme();
    const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.company_organizations" });
    const { t: tActions } = useTranslation("cloud_ui", { keyPrefix: "actions" });
    const { t: tErrors } = useTranslation("cloud_ui", { keyPrefix: "errors" });
    const showSnackbar = useSnackbar();
    const orgTypesList: OrgTypesListType = [
      {
        key: "",
        value: t(`invite_new_organization.fields.placeholders.type`),
      },
      {
        key: OrganizationTypes.COMMERCIAL_CLIENT,
        value: t(`invite_new_organization.${OrganizationTypes.COMMERCIAL_CLIENT}`),
      },
      {
        key: OrganizationTypes.DISTRIBUTOR,
        value: t(`invite_new_organization.${OrganizationTypes.DISTRIBUTOR}`),
      },
      {
        key: OrganizationTypes.INSTALLER,
        value: t(`invite_new_organization.${OrganizationTypes.INSTALLER}`),
      },
    ];
    const [isOpened, setIsOpened] = useState(false);
    const [wizardStep, setWizardStep] = useState<WizardStepType>("find");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [type, setType] = useState<InviteOrganizationType | "">("");
    const [errors, setErrors] = useState({
      email: "",
    });

    const [findOrganization, { isLoading: isFindingOrg }] = useFindOrganizationMutation();
    const [sendOrganizationInvitation, { isLoading: isSendingInvite }] =
      useSendOrganizationInvitationMutation();

    const openModal = () => {
      setWizardStep("find");
      setIsOpened(true);
    };
    const closeModal = () => {
      setIsOpened(false);
    };

    useImperativeHandle(ref, () => ({
      open: openModal,
      close: closeModal,
    }));

    const validateEmail = (email: string) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (value: string) => {
      setEmail(value);
      if (validateEmail(value)) {
        setErrors({ ...errors, email: "" });
      }else{
        setErrors({ ...errors, email: t("find_organization.fields.errors.email.regex") });
      }
    };

    const handleEmailBlur = () => {
      if (!validateEmail(email) && email) {
        setErrors({ ...errors, email: t("find_organization.fields.errors.email.regex") });
      } else {
        setErrors({ ...errors, email: "" });
      }
    };

    const handleTypeChange = (event: any) => {
      setType(event.target?.value || "");
    };

    const handleOnSuccess = () => {
      closeModal();
      !!onSuccess && onSuccess();
    }

    const checkOrganization = () => {
      findOrganization({ uuid: organizationUuid, email: email })
        .unwrap()
        .then((response) => {
          setName(response.orgName || email);
          setType(response.orgType || "");
          setWizardStep("add_exist");
        })
        .catch((error: ServerErrorType) => {
          switch (error.status) {
            case 404:
              setWizardStep("add_new");
              break;
            case 400:
              setErrors({
                ...errors,
                email: t("find_organization.fields.errors.email.cooperation"),
              });
              break;
            default:
              onError();
              break;
          }
        });
    };

    const sendOrgInvite = (companyType: 'new' | 'exist') => {
      if (type) {
        const location = window.location;
        let userValidationUrl = `${location.protocol}//${location.host}/company-invitation-accept/${companyType}`;
        sendOrganizationInvitation({
          uuid: organizationUuid,
          userEmail: email,
          userOrgType: type,
          baseConfirmationUrl: userValidationUrl,
        })
          .unwrap()
          .then(() => {
            setWizardStep("success");
          })
          .catch(() => {
            onError();
          });
      }
    };

    const onError = () => {
      showSnackbar(tErrors("somethingWentWrong"), {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 3000,
        severity: "error",
      });
    };

    const renderFindOrgForm = () => (
      <>
        <DialogTitle
          sx={{
            paddingTop: `${theme.spacing.spacing3xl}`,
            paddingBottom: `${theme.spacing.spacingMd}`,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-lg-semibold"],
              color: theme.colors.colorsTextTextPrimary,
            }}
          >
            {t("find_organization.title")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: `0 ${theme.spacing.spacing3xl}`,
            mb: theme.spacing.spacing4xl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextTertiary,
              whiteSpace: "pre-line",
            }}
          >
            {t("find_organization.description")}
          </Typography>
          <Box
            sx={{
              paddingTop: theme.spacing.spacingXl,
            }}
          >
            <InputLabel
              shrink
              sx={{
                color: theme.colors.colorsTextTextSecondary,
                fontWeight: 500,
              }}
            >
              {t("find_organization.fields.labels.email")}
            </InputLabel>
            <TextField
              sx={{
                margin: 0,
                "& .MuiInputBase-root": {
                  height: 44,
                  padding: `0 ${theme.spacing.spacingLg}`,
                  borderRadius: theme.radius.radiusMd,
                  input: {
                    padding: 0,
                    ":placeholder-shown": {
                      color: theme.colors.colorsTextTextPlaceholder,
                    },
                  },
                },
              }}
              value={email}
              autoFocus
              margin="dense"
              id="email"
              placeholder={`${t("find_organization.fields.placeholders.email")}`}
              type="email"
              fullWidth
              onChange={(e) => handleEmailChange(e.target.value.trim())}
              onBlur={handleEmailBlur}
              InputProps={{
                startAdornment: (
                  <Mail01
                    size="20"
                    style={{
                      color: theme.colors.colorsForegroundFgQuaternary,
                      marginRight: theme.spacing.spacingMd,
                    }}
                    strokeWidth={2}
                  />
                ),
              }}
              helperText={errors.email}
              error={!!errors.email}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
          }}
        >
          <Button
            label={`${tActions("cancel")}`}
            onClick={closeModal}
            buttonType={ButtonType.SecondaryGray}
            sx={{
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
          <Button
            label={`${t("find_organization.submitButton")}`}
            disabled={!email || !validateEmail(email)}
            onClick={checkOrganization}
            loadingInProgress={isFindingOrg}
            sx={{
              pointerEvents: isFindingOrg ? "none" : "initial",
              ml: "0 !important",
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </>
    );

    const renderAddExistOrgForm = () => (
      <>
        <DialogTitle
          sx={{
            paddingTop: `${theme.spacing.spacing3xl}`,
            paddingBottom: `${theme.spacing.spacingMd}`,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-lg-semibold"],
              color: theme.colors.colorsTextTextPrimary,
            }}
          >
            {t("invite_exist_organization.title")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: `0 ${theme.spacing.spacing3xl}`,
            mb: theme.spacing.spacing4xl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextTertiary,
            }}
          >
            {t("invite_exist_organization.description")}
          </Typography>
          <Box
            sx={{
              paddingTop: theme.spacing.spacingXl,
              paddingBottom: theme.spacing.spacingMd,
            }}
          >
            <InputLabel
              shrink
              sx={{
                color: theme.colors.colorsTextTextSecondary,
                fontWeight: 500,
              }}
            >
              {t("invite_exist_organization.fields.labels.organization")}
            </InputLabel>
            <TextField
              sx={{
                margin: 0,
                "& .MuiInputBase-root": {
                  height: 44,
                  padding: `0 ${theme.spacing.spacingLg}`,
                  borderRadius: theme.radius.radiusMd,
                  input: {
                    padding: 0,
                    ":placeholder-shown": {
                      color: theme.colors.colorsTextTextPlaceholder,
                    },
                  },
                },
              }}
              value={name}
              margin="dense"
              id="name"
              placeholder={`${t("invite_exist_organization.fields.placeholders.organization")}`}
              type="text"
              fullWidth
              disabled
            />
          </Box>
          <Box>
            <InputLabel
              shrink
              sx={{
                color: theme.colors.colorsTextTextSecondary,
                fontWeight: 500,
              }}
            >
              {t("invite_exist_organization.fields.labels.type")}
            </InputLabel>
            <TextField
              sx={{
                margin: 0,
                "& .MuiInputBase-root": {
                  height: 44,
                  padding: `0 ${theme.spacing.spacingLg}`,
                  borderRadius: theme.radius.radiusMd,
                  input: {
                    padding: 0,
                    ":placeholder-shown": {
                      color: theme.colors.colorsTextTextPlaceholder,
                    },
                  },
                },
              }}
              value={orgTypesList.find((option) => option.key === type)?.value}
              margin="dense"
              id="type"
              placeholder={`${t("invite_exist_organization.fields.placeholders.type")}`}
              type="text"
              fullWidth
              disabled
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
          }}
        >
          <Button
            label={`${tActions("cancel")}`}
            onClick={closeModal}
            buttonType={ButtonType.SecondaryGray}
            sx={{
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
          <Button
            label={`${t("invite_exist_organization.submitButton")}`}
            disabled={!type || !name}
            onClick={() => sendOrgInvite("exist")}
            loadingInProgress={isSendingInvite}
            sx={{
              pointerEvents: isSendingInvite ? "none" : "initial",
              ml: "0 !important",
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </>
    );

    const renderAddNewOrgForm = () => (
      <>
        <DialogTitle
          sx={{
            paddingTop: `${theme.spacing.spacing3xl}`,
            paddingBottom: `${theme.spacing.spacingMd}`,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-lg-semibold"],
              color: theme.colors.colorsTextTextPrimary,
            }}
          >
            {t("invite_new_organization.title")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: `0 ${theme.spacing.spacing3xl}`,
            mb: theme.spacing.spacing4xl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextTertiary,
              whiteSpace: "pre-line",
            }}
          >
            {t("invite_new_organization.description")}
          </Typography>
          <Box
            sx={{
              paddingTop: theme.spacing.spacingXl,
            }}
          >
            <InputLabel
              shrink
              sx={{
                color: theme.colors.colorsTextTextSecondary,
                fontWeight: 500,
              }}
            >
              {t("invite_new_organization.fields.labels.type")}
            </InputLabel>
            <FormControl fullWidth variant="outlined">
              <Select
                displayEmpty
                value={type}
                onChange={handleTypeChange}
                IconComponent={ChevronDown}
                sx={{
                  "& .MuiSelect-select": { padding: "0 10px" },
                  "& .MuiSelect-icon": { top: "11px" },
                  borderRadius: theme.radius.radiusMd,
                  height: 44,
                  color: theme.colors.colorsTextTextPlaceholder,
                  fontSize: "16px",
                }}
                renderValue={(selected) => {
                  return orgTypesList.find((option) => option.key === selected)?.value;
                }}
              >
                {orgTypesList.map((option, index) => (
                  <MenuItem
                    key={`type-item-${index}`}
                    value={option.key}
                    sx={{
                      fontSize: "16px",
                      color: theme.colors.colorsTextTextPlaceholder,
                    }}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              paddingTop: theme.spacing.spacingXl,
            }}
          >
            <InputLabel
              shrink
              sx={{
                color: theme.colors.colorsTextTextSecondary,
                fontWeight: 500,
              }}
            >
              {t("find_organization.fields.labels.email")}
            </InputLabel>
            <TextField
              sx={{
                margin: 0,
                "& .MuiInputBase-root": {
                  height: 44,
                  padding: `0 ${theme.spacing.spacingLg}`,
                  borderRadius: theme.radius.radiusMd,
                  input: {
                    padding: 0,
                    ":placeholder-shown": {
                      color: theme.colors.colorsTextTextPlaceholder,
                    },
                  },
                },
              }}
              value={email}
              margin="dense"
              id="email"
              placeholder={`${t("invite_new_organization.fields.placeholders.email")}`}
              type="email"
              fullWidth
              disabled
              InputProps={{
                startAdornment: (
                  <Mail01
                    size="20"
                    style={{
                      color: theme.colors.colorsForegroundFgQuaternary,
                      marginRight: theme.spacing.spacingMd,
                    }}
                    strokeWidth={2}
                  />
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
          }}
        >
          <Button
            label={`${tActions("cancel")}`}
            onClick={closeModal}
            buttonType={ButtonType.SecondaryGray}
            sx={{
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
          <Button
            label={`${t("invite_new_organization.submitButton")}`}
            disabled={!type || !email}
            onClick={() => sendOrgInvite("new")}
            loadingInProgress={isSendingInvite}
            sx={{
              pointerEvents: isSendingInvite ? "none" : "initial",
              ml: "0 !important",
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </>
    );

    const renderSuccess = () => (
      <>
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            marginBottom: theme.spacing.spacing4xl,
            padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              backgroundColor: theme.colors.componentColorsUtilitySuccessUtilitySuccess100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacingXl,
            }}
          >
            <Send01
              size="24"
              style={{
                color: theme.colors.componentColorsUtilitySuccessUtilitySuccess600,
              }}
              strokeWidth={2}
            />
          </Box>
          <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
            {t("invitation_success.title")}
          </Typography>
          <Typography sx={{ ...theme.typography[".text-sm-regular"] }}>
            {t("invitation_success.description")}
          </Typography>
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            label={`${tActions("ok")}`}
            onClick={handleOnSuccess}
            sx={{
              display: "flex",
              ml: "0 !important",
              width: `calc(100% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </>
    );

    const renderForm = () => {
      switch (wizardStep) {
        case "find":
          return renderFindOrgForm();
        case "add_exist":
          return renderAddExistOrgForm();
        case "add_new":
          return renderAddNewOrgForm();
        case "success":
          return renderSuccess();
      }
    };

    return (
      <Dialog open={isOpened} fullWidth={true} sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}>
        {renderForm()}
      </Dialog>
    );
  },
);

export default AddOrganizationDialog;
