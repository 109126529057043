import { Box, SxProps, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TabsNavigation, {
  ITabData,
} from "../../../components/TabsNavigation/TabsNavigation";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import RealTimeData from "./RealTimeData/RealTimeData";
import useTheme from "../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { ArrowLeft } from "untitledui-js-base";
import SettingsPage from "./SettingsPage/SettingsPage";
import AnalyticsPage from "./AnalyticsPage";
import InfoDetailsPage from "./InfoDetailsPage/InfoDetailsPage";
import {
  useGetInstallationDetailsQuery,
  useGetInstallationStateQuery,
  useLazyGetInstallationAlertsHistoryQuery,
} from "../../../store/services/installation";
import {
  ActiveOrInactiveEnum,
  AlertType,
  OnlineStatus,
} from "../../../store/services/models/installations/installationsCommon";
import AlertsPage from "./AlertsPage/AlertsPage";
import { useAppDispatch, useTypedSelector } from "../../../store";
import { selectUserOrganizationInfo } from "../../../store/reducers/organizationSlice";
import { setSelectedInstallationDetails } from "../../../store/reducers/installationSlice";
import { formatDate } from "../../../util/DateUtil";
import SupportFormOpener from "../../../components/SupportForm/components/SupportFormOpener/SupportFormOpener";
import SupportFormModal from "../../../components/SupportForm/components/SupportFormModal/SupportFormModal";
import { AliveScope } from "react-activation";
import SystemMessageBox from "../../../components/SystemMessageBox";
import RestoreSettingsOpener from "../../../components/RestoreSettings/RestoreSettingsOpener/RestoreSettingsOpener";
import RestoreSettingsModal from "../../../components/RestoreSettings/RestoreSettingsModal/RestoreSettingsModal";

export interface IInstallationAlertsDataForCounter {
  totalCount: number;
  borderColor: string;
  backgroundColor: string;
  color: string;
}

export default function ResidentialInstallationDetailPage({
  isSuperAdminLevel = false,
}: {
  isSuperAdminLevel?: boolean;
}) {
  const theme = useTheme();
  const { typography, spacing, colors } = theme;
  const labelBaseStyles: SxProps = {
    ...typography[".text-xs-medium"],
    height: "22px",
    padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingSm}`,
    borderRadius: "10px",
    border: `1px solid ${colors.componentColorsUtilityGrayUtilityGray600}`,
    color: colors.componentColorsUtilityGrayUtilityGray600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const dotStyles: SxProps = {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: colors.componentColorsUtilityBrandUtilityBrand600,
    mr: spacing.spacingSm,
  };

  const { t } = useTranslation("cloud_ui");

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const currentUserOrganizationInfo = useTypedSelector(
    selectUserOrganizationInfo
  );

  const baseUrl = `/${
    isSuperAdminLevel ? "admin" : "portal"
  }/residential-installations/${id}`;
  const params = new URLSearchParams(location.search);
  const {
    data: installationDetails,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchDetails,
  } = useGetInstallationDetailsQuery(
    {
      installationUuid: id!,
      organizationUuid: currentUserOrganizationInfo?.uuid,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000,
    }
  );

  const { data: realTimeDataState } = useGetInstallationStateQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000,
    }
  );

  const [trigger] = useLazyGetInstallationAlertsHistoryQuery();

  const [installationInfo, setInstallationInfo] = useState<{
    name: string;
    location: string;
    status: OnlineStatus;
  }>();

  const [alertsData, setAlertsData] =
    useState<IInstallationAlertsDataForCounter>();

  const [isSupportDialogOpen, setIsSupportDialogOpen] =
    useState<boolean>(false);

  const [isRestoreSettingsDialogOpen, setIsRestoreSettingsDialogOpen] =
    useState<boolean>(false);

  const getTabs = () => {
    let tabs: Array<ITabData> = [];
    if (id) {
      tabs = tabs.concat([
        {
          href: `${baseUrl}/real-time-data${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.realTimeData"),
        },
        {
          href: `${baseUrl}/settings${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.settings"),
        },
        {
          href: `${baseUrl}/alerts${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.alerts"),
        },
        {
          href: `${baseUrl}/analytics${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.analytics"),
        },
        {
          href: `${baseUrl}/info-details${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.infoDetails"),
        },
      ]);
    }
    return tabs;
  };

  const getInstallationAlerts = useCallback(() => {
    trigger({
      installationUuid: id!,
      activeOnly: true,
      alertCode: [],
      pageNum: 0,
      pageSize: 300,
      startTimestamp: "",
      endTimestamp: "",
      type: [AlertType.ERROR, AlertType.WARNING],
    })
      .then(({ data }) => {
        if (data) {
          const isHasErrors = data.result.some(
            (alert) => alert.type === AlertType.ERROR
          );
          setAlertsData({
            totalCount: data.pagination.totalCount,
            borderColor: isHasErrors
              ? colors.componentColorsUtilityErrorUtilityError200
              : colors.componentColorsUtilityWarningUtilityWarning200,
            backgroundColor: isHasErrors
              ? colors.componentColorsUtilityErrorUtilityError50
              : colors.componentColorsUtilityWarningUtilityWarning50,
            color: isHasErrors
              ? colors.componentColorsUtilityErrorUtilityError700
              : colors.componentColorsUtilityWarningUtilityWarning700,
          });
        }
      })
      .catch((err) => {});
  }, [id, trigger]);

  useEffect(() => {
    if (
      location.pathname.includes("settings") ||
      location.pathname.includes("real-time-data")
    ) {
      refetchDetails();
    }
  }, [location.pathname, refetchDetails]);

  useEffect(() => {
    dispatch(setSelectedInstallationDetails(installationDetails));
  }, [installationDetails, installationDetails?.onlineStatus]);

  useEffect(() => {
    if (id) {
      getInstallationAlerts();
    }
  }, [getInstallationAlerts, id]);

  useEffect(() => {
    if (installationDetails) {
      setInstallationInfo({
        location: installationDetails.location,
        name: installationDetails.name,
        status: installationDetails.onlineStatus,
      });
    }
  }, [installationDetails]);

  const isRestoreSettingsDisabled =
    installationDetails?.onlineStatus === OnlineStatus.OFFLINE ||
    !!(
      installationDetails?.commissioningInfo?.commissionedDate &&
      new Date().getTime() -
        new Date(
          installationDetails.commissioningInfo.commissionedDate
        ).getTime() <
        24 * 60 * 60 * 1000
    );
  return (
    <Box
      sx={{
        display: "flex",
        padding: `${theme.spacing.spacing2xl} ${theme.spacing.spacing2xl} 0`,
        flexDirection: "column",
        backgroundColor: theme.colors.colorsBackgroundBgPrimary,
        height: "calc(100vh - 64px)",
        overflowY: "auto",
      }}
    >
      {realTimeDataState?.others.manualModeState ===
        ActiveOrInactiveEnum.ACTIVE && (
        <Box sx={{ mb: theme.spacing.spacingXl }}>
          <SystemMessageBox
            type="warning"
            title={t("pages.residentialInstallation.manualMode.title")}
            message={t("pages.residentialInstallation.manualMode.description")}
          />
        </Box>
      )}
      <Box sx={{ mb: theme.spacing.spacingXl, display: "flex" }}>
        <Box
          component={"button"}
          sx={{
            backgroundColor: "initial",
            height: "56px",
            width: "56px",
            border: `1px solid ${theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder}`,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: theme.colors.colorsBackgroundBgSecondaryHover,
            },
          }}
          onClick={() =>
            navigate(
              `/${
                isSuperAdminLevel ? "admin" : "portal"
              }/residential-installations`
            )
          }
        >
          <ArrowLeft
            style={{
              color:
                theme.colors
                  .componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            }}
            size="18"
            strokeWidth={2}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: theme.spacing.spacingLg,
          }}
        >
          <Box
            sx={{
              mb: theme.spacing.spacingXs,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                ...theme.typography[".display-xs-semibold"],
                color: theme.colors.colorsTextTextPrimary,
                mr: theme.spacing.spacingLg,
              }}
            >
              {installationInfo?.name ||
                installationDetails?.connectivityId ||
                ""}
            </Typography>
            {installationInfo?.status === OnlineStatus.ONLINE ? (
              <Box
                sx={{
                  ...labelBaseStyles,
                  color: colors.componentColorsUtilityBrandUtilityBrand600,
                  borderColor:
                    colors.componentColorsUtilityBrandUtilityBrand600,
                }}
              >
                <Box
                  sx={{
                    ...dotStyles,
                  }}
                ></Box>
                {t("online_statuses.online")}
              </Box>
            ) : (
              <Box sx={{ ...labelBaseStyles }}>
                {t("online_statuses.offline")}
              </Box>
            )}
          </Box>
          <Typography
            sx={{
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextTertiary,
            }}
          >
            {installationInfo?.location || ""}
          </Typography>
          {
            <Typography
              sx={{
                ...theme.typography[".text-md-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {`${t(
                "pages.residentialInstallation.details.lastComunicationLabel"
              )} ${
                installationDetails?.lastCommunicationDate
                  ? formatDate(installationDetails?.lastCommunicationDate)
                  : "--"
              }`}
            </Typography>
          }
        </Box>
        <Box sx={{ ml: "auto", display: "flex", gap: theme.spacing.spacingMd }}>
          {/* <RestoreSettingsOpener
            onClick={() => setIsRestoreSettingsDialogOpen(true)}
            disabled={isRestoreSettingsDisabled}
          /> */}
          <SupportFormOpener onClick={() => setIsSupportDialogOpen(true)} />
        </Box>
      </Box>
      <Box sx={{ mb: theme.spacing.spacingLg }}>
        <TabsNavigation tabs={getTabs()} alertsData={alertsData} />
      </Box>
      <SupportFormModal
        isOpen={isSupportDialogOpen}
        onClose={() => setIsSupportDialogOpen(false)}
      />
      {/* <RestoreSettingsModal
        isOpen={isRestoreSettingsDialogOpen}
        onClose={() => setIsRestoreSettingsDialogOpen(false)}
        createdAt={installationDetails?.commissioningInfo?.commissionedDate!}
      /> */}
      <AliveScope>
        <Routes>
          <Route
            path="/real-time-data"
            element={
              <PrivateRoute
                element={<RealTimeData />}
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          ></Route>
          <Route
            path="/settings/*"
            element={
              <PrivateRoute
                element={<SettingsPage baseUrl={`${baseUrl}/settings`} />}
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          ></Route>
          <Route
            path="/alerts"
            element={
              <PrivateRoute
                element={<AlertsPage />}
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          ></Route>
          <Route
            path="analytics/*"
            element={
              <PrivateRoute
                element={<AnalyticsPage baseUrl={`${baseUrl}/analytics`} />}
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          ></Route>
          <Route
            path="/info-details"
            element={
              <PrivateRoute
                element={<InfoDetailsPage />}
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          ></Route>
        </Routes>
      </AliveScope>
    </Box>
  );
}
