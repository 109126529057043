import React from "react";
import ConfirmationModal from "../../../../../../components/ConfirmationModal/confirmationModal";
import { AlertCircle } from "untitledui-js-base";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router";

export default function UnsavedChangesModal({
  condition,
  tabName,
  discardChanges,
  title,
  description,
}: {
  condition: boolean;
  tabName: string;
  discardChanges?: () => void;
  title?: string;
  description?: string;
}) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.confirmationModal",
  });
  const blocker = useBlocker(condition);

  React.useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (condition) {
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [condition]);

  return (
    <ConfirmationModal
      title={title ? title : `${t("title")}`}
      text={description ? description : `${t("description", { tabName })}`}
      icon={
        <AlertCircle
          size="24"
          style={{
            color: theme.colors.colorsBackgroundBgErrorSolid,
          }}
          strokeWidth={2}
        />
      }
      isOpen={blocker.state === "blocked"}
      onCancel={() => blocker.reset!()}
      onOk={() => {
        if (discardChanges) {
          discardChanges();
        }
        blocker.proceed!();
      }}
      confirmButtonStyles={{}}
      confirmButtonText={t("confirmButton")}
    />
  );
}
