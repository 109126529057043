import React, { useEffect, useState } from "react";
import TabWrapper from "../SettingsPage/TabWrapper";
import { Box } from "@mui/material";
import useTheme from "../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import InstallationDetailsColumn from "./components/InstallationDetailsColumn/InstallationDetailsColumn";
import { useParams } from "react-router";
import { useAppDispatch, useTypedSelector } from "../../../../store";
import {
  ISetInstallationDetails,
  useGetInstallationDetailsQuery,
  useGetInstallationStateQuery,
  useSetInstallationDetailsMutation,
} from "../../../../store/services/installation";
import { useLazyGetOrganizationDetailsQuery } from "../../../../store/services/organization";


import AboutColumn from "./components/AboutColumn/AboutColumn";
import UnsavedChangesModal from "../SettingsPage/components/UnsavedChangesModal/UnsavedChangesModal";
import { selectUserOrganizationInfo } from "../../../../store/reducers/organizationSlice";
import { selectSelectedInstallationDetails } from "../../../../store/reducers/installationSlice";

export interface IInstallationDetailsData {
  connectivityId: string;
  location: string;
  name: string;
}

export interface IInstallationCommmissioningInfo {
  commissioner: string | undefined;
  commissionedDate: string;
}

export default function InfoDetailsPage() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const currentUserOrganizationInfo = useTypedSelector(selectUserOrganizationInfo);

  const selectedInstallationDetails = useTypedSelector(selectSelectedInstallationDetails);

  const {
    data: installationDetails,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchDetails,
  } = useGetInstallationDetailsQuery(
    {
      installationUuid: id!,
      organizationUuid: currentUserOrganizationInfo?.uuid,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true, refetchOnFocus: true },
  );

  const {
    data: state,
    isFetching: isFetchingState,
    isLoading: isLoadingState,
    refetch,
    fulfilledTimeStamp: stateFulfilledTimeStamp,
  } = useGetInstallationStateQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    }
  );

  const [trigger, { data: organizationDetails, error, isLoading, isFetching }] =
    useLazyGetOrganizationDetailsQuery();

  const [setInstallationDetails] = useSetInstallationDetailsMutation();

  const [installationDetailsData, setInstallationDetailsData] =
    useState<IInstallationDetailsData>();
  const [initialInstallationDetailsData, setInitialInstallationDetailsData] =
    useState<IInstallationDetailsData>();

  const [commissioningInfo, setCommissioningInfo] = useState<IInstallationCommmissioningInfo>();

  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false);
  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);

  const [aboutSectionParts, setAboutSectionParts] = useState<Array<any>>([]);

  useEffect(() => {
    if (installationDetails) {
      let data: IInstallationDetailsData = {
        connectivityId: installationDetails.connectivityId,
        location: installationDetails.location,
        name: installationDetails.name,
      };
      setInstallationDetailsData({ ...data });
      setInitialInstallationDetailsData({ ...data });
    }

    if (installationDetails?.commissioningInfo?.commissionerUuid) {
      let formattedDate = "";
      const date = new Date(installationDetails?.commissioningInfo.commissionedDate);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;

      trigger({
        uuid: installationDetails.commissioningInfo.commissionerUuid,
      })
        .then(({ data }) => {
          setCommissioningInfo({
            commissioner: data?.name,
            commissionedDate: formattedDate,
          });
        })
        .catch((err) => {});
    }
  }, [installationDetails]);

  const flattenParts = (parts: any, parentIndex = null) => {
    let flatList: any = [];

    parts.forEach((part: any) => {
      const { parts: childParts, ...rest } = part;
      const flatPart = { ...rest, parentIndex };
      flatList.push(flatPart);

      if (childParts) {
        flatList = [...flatList, ...flattenParts(childParts, flatPart.index)];
      }
    });

    return flatList;
  };

  useEffect(() => {
    if (state?.parts) {
      setAboutSectionParts(flattenParts(state.parts));
    }
  }, [state]);

  const checkIsSomethingChanged = () => {
    if (
      installationDetailsData?.name !== initialInstallationDetailsData?.name ||
      installationDetailsData?.location !== initialInstallationDetailsData?.location
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkIsSomethingChanged();
  }, [installationDetailsData]);

  const updateInstallationDeltailsData = (fieldName: string, value: any) => {
    const updatedInstallationDetailsData = { ...installationDetailsData };
    (updatedInstallationDetailsData as any)[fieldName] = value;
    setInstallationDetailsData(updatedInstallationDetailsData as IInstallationDetailsData);
  };

  const onSaveChangesPress = () => {
    setIsSavingInProgress(true);
    const requestBody: ISetInstallationDetails = {
      name: installationDetailsData?.name || "",
      location: installationDetailsData?.location || "",
    };
    setInstallationDetails({
      ...(requestBody as ISetInstallationDetails),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        refetchDetails()
          .then((res) => {
            setIsSavingInProgress(false);
          })
          .catch((err) => {
            setIsSavingInProgress(false);
          });
      })
      .catch((error) => {
        setIsSavingInProgress(false);
        console.log(error);
      });
  };

  return (
    <Box
      sx={{
        height: "calc((var(--vh, 1vh) * 100) - 216px)",
        overflowY: "hidden",
        mr: `-${theme.spacing.spacing2xl}`,
      }}
    >
      <TabWrapper
        onDiscardPress={() => {
          setInstallationDetailsData(initialInstallationDetailsData);
        }}
        onSaveChangesPress={onSaveChangesPress}
        isDiscardDisabled={!isSomethingChanged || isSavingInProgress}
        isSaveDisabled={!isSomethingChanged || isSavingInProgress}
        disableScroll
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <InstallationDetailsColumn
            installationUuid={selectedInstallationDetails?.uuid}
            installationDetails={installationDetailsData}
            setInstallationDetails={updateInstallationDeltailsData}
            commissioningInfo={commissioningInfo}
            sharingInfo={installationDetails?.sharingInfo}
            refetchInstallationDetails={refetchDetails}
          />
          {!!aboutSectionParts?.length && <AboutColumn parts={aboutSectionParts} />}
        </Box>
        <UnsavedChangesModal
          tabName={t("pages.infoDetailsPage.installationDetails.mainTitle")}
          condition={isSomethingChanged}
          title={t("pages.infoDetailsPage.unsavedChangesTitle") || ""}
          description={t("pages.infoDetailsPage.unsavedChangesDescription") || ""}
        />
      </TabWrapper>
    </Box>
  );
}
