import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function PrivacyPolicy() {
  const defaultProductName = "Energy Save Digital Solutions";
  const { i18n, t } = useTranslation("cloud_ui", { keyPrefix: "policy" });
  const [searchParams] = useSearchParams({
    productName: defaultProductName,
    lang: "en",
  });

  useEffect(() => {
    i18n.changeLanguage(searchParams.get("lang")!);
  }, [i18n, searchParams]);

  return (
    <Box sx={{ overflow: "auto", height: "100vh" }}>
      <Container sx={{ padding: "20px" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: t("privacy_policy", { productName: searchParams.get("productName") })!,
          }}
        ></div>
      </Container>
    </Box>
  );
}
