import React from "react";
import useSeasonTab from "./hooks/useSeasonTab";
import TabWrapper from "../../../../TabWrapper";
import useSeasonTabConditionsAndValuesToDisplay from "./hooks/useSeasonTabConditionsAndValuesToDisplay";
import { IInstallationUserSettings } from "../../../../../../../../store/services/models/installations/installationSettings";
import useTheme, {
  ESThemeType,
} from "../../../../../../../../theme/hooks/useTheme";
import useTabsIcons from "../../../../hooks/useTabsIcons";
import { Typography } from "@mui/material";
import SettingsDivider from "../../../../components/SettingsDivider/SettingsDivider";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import SeasonDifferences from "./components/SeasonDifferences/SeasonDifferences";

interface ISeasonTabProps {
  isInstallationOffline?: boolean;
}

export default function SeasonTab({
  isInstallationOffline = false,
}: ISeasonTabProps) {
  const theme = useTheme();

  const {
    seasonSettingsFormData,
    isSomethingChanged,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    calculatedMaxValueForHeatingSeasonStartTemp,
    calculatedMinValueForCoolingSeasonStartTemp,
    seasonSettingsFormValueDifferencesWithInitialValue,
    onSeasonSettingsChange,
    onDiscardChanges,
    onSaveChanges,
    confirmFormChanges,
    setIsConfirmChangesModalOpen,
    setIsNotificationModalOpen,
  } = useSeasonTab();

  const {
    mainTitleText,
    seasonStartStopConditionsTitleText,
    coolingSeasonStartStopText,
    heatingSeasonStartStopText,
    coolingSeasonStartStopHintText,
    heatingSeasonStartStopHintText,
    differenceHintText,
    isInputsShown,
    tabNameLabelText,
    subTabNameLabelText,
    commonErrorForRequestText,
  } = useSeasonTabConditionsAndValuesToDisplay({
    seasonSettingsFormData: seasonSettingsFormData as IInstallationUserSettings,
  });

  const { userSettingsTabIcon } = useTabsIcons();

  const styles = getStyles(theme);

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isSaveDisabled={!isSomethingChanged}
      isDiscardDisabled={!isSomethingChanged}
    >
      <Typography sx={styles.mainTitle}>{mainTitleText}</Typography>
      <SettingsDivider sx={styles.divider} />
      <SecondaryTitle title={seasonStartStopConditionsTitleText} variant="md" />
      {isInputsShown && (
        <>
          <NumberInput
            label={`${heatingSeasonStartStopText}`}
            hint={`${heatingSeasonStartStopHintText}`}
            initialValue={seasonSettingsFormData?.heatingSeasonStartTemp?.value}
            decimalPlaces={1}
            min={seasonSettingsFormData?.heatingSeasonStartTemp?.min}
            max={calculatedMaxValueForHeatingSeasonStartTemp}
            step={0.5}
            changeValue={(val) => {
              onSeasonSettingsChange(val, "heatingSeasonStartTemp");
            }}
            disabled={isInstallationOffline}
          />
          <>
            <NumberInput
              label={`${coolingSeasonStartStopText}`}
              hint={`${coolingSeasonStartStopHintText}`}
              initialValue={
                seasonSettingsFormData?.coolingSeasonStartTemp?.value
              }
              decimalPlaces={1}
              min={calculatedMinValueForCoolingSeasonStartTemp}
              max={seasonSettingsFormData?.coolingSeasonStartTemp?.max}
              step={0.5}
              changeValue={(val) => {
                onSeasonSettingsChange(val, "coolingSeasonStartTemp");
              }}
              disabled={isInstallationOffline}
            />
            <Typography sx={styles.differenceHint}>
              {differenceHintText}
            </Typography>
          </>
        </>
      )}
      <UnsavedChangesModal
        tabName={tabNameLabelText}
        condition={isSomethingChanged}
        discardChanges={onDiscardChanges}
      />
      <ConfirmChangesModal
        isOpen={isConfirmChangesModalOpen}
        onCancel={() => setIsConfirmChangesModalOpen(false)}
        onOk={() => confirmFormChanges(commonErrorForRequestText)}
        page={subTabNameLabelText}
        block={tabNameLabelText}
        icon={userSettingsTabIcon}
      >
        <SeasonDifferences
          differences={seasonSettingsFormValueDifferencesWithInitialValue}
        />
      </ConfirmChangesModal>
      <NotificationModal
        isOpen={isNotificationModalOpen}
        onOkPress={() => setIsNotificationModalOpen(false)}
        icon={userSettingsTabIcon}
      />
    </TabWrapper>
  );
}

const getStyles = ({ colors, spacing, typography }: ESThemeType) => ({
  mainTitle: {
    ...typography[".text-lg-semibold"],
    color: colors.colorsTextTextPrimary,
  },
  divider: {
    margin: `${spacing.spacingXl} 0`,
  },
  differenceHint: {
    ...typography[".text-sm-regular"],
    color: colors.colorsTextTextTertiary,
  },
});
