import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  Controllers,
  IFlowTemperatureSettingsSectionProps,
} from "../../../types";

import { InstallationZoneOperationControllerEnum } from "../../../../../../../../../store/services/models/installations/installationSettings";

import RadioButtonWithLabel from "../../../../../components/RadioButtonWithLabel/RadioButtonWithLabel";
import SecondaryTitle from "../../../../../components/SecondaryTitle/SecondaryTitle";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import FlowTemperatureIndorSensorAndRoomTempSection from "../FlowTemperatureIndorSensorAndRoomTempSection/FlowTemperatureIndorSensorAndRoomTempSection";
import CurveSection from "../CurveSection/CurveSection";

import useFlowTempSettings from "../../../hooks/flowTempSettings/useFlowTempSettings";
import useFlowTemSettingsConditionsAndValuesForDisplay from "../../../hooks/flowTempSettings/useFlowTemSettingsConditionsAndValuesForDisplay";

export default function FlowTeperatureSettingsSection({
  type,
}: IFlowTemperatureSettingsSectionProps) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });

  const {
    isInstallationOffline,
    zoneFormData,
    zoneKeys,
    updateZoneController,
    updateZoneFormData,
  } = useFlowTempSettings();
  const {
    isHeatig,
    isCooling,
    isCurveControllerSelected,
    isFixedControllerSelected,
    isFixedFlowTempHasValue,

    //values to display
    mainTitle,
    fixedFlowTempData,
    setCurveControllerRadioLabel,
    setFixedControllerRadioLabel,
    fixedFlowSectionTitle,
  } = useFlowTemSettingsConditionsAndValuesForDisplay({
    type,
    zoneFormData,
    zoneNumber: zoneKeys?.zoneNumber || "",
  });

  return (
    <>
      <SecondaryTitle title={mainTitle} variant="sm" />
      <RadioButtonWithLabel
        label={setCurveControllerRadioLabel}
        onClick={() =>
          updateZoneController!(
            InstallationZoneOperationControllerEnum.CURVE,
            isHeatig ? Controllers.Heating : Controllers.Cooling
          )
        }
        isDisabled={isInstallationOffline}
        selected={isCurveControllerSelected}
      />

      {isCurveControllerSelected && (
        <Box pl={"34px"}>
          {isHeatig && <FlowTemperatureIndorSensorAndRoomTempSection />}
          <CurveSection isCooling={isCooling} />
        </Box>
      )}
      <RadioButtonWithLabel
        label={setFixedControllerRadioLabel}
        onClick={() =>
          updateZoneController!(
            InstallationZoneOperationControllerEnum.FIXED,
            isHeatig ? Controllers.Heating : Controllers.Cooling
          )
        }
        isDisabled={isInstallationOffline}
        selected={isFixedControllerSelected}
      />
      {isFixedControllerSelected && (
        <Box pl={"34px"}>
          <SecondaryTitle title={fixedFlowSectionTitle} variant="sm" />
          {isFixedFlowTempHasValue && (
            <NumberInput
              initialValue={fixedFlowTempData?.value}
              changeValue={(val) => {
                updateZoneFormData!(
                  val,
                  isHeatig
                    ? "preferredFixedFlowHeatingTemp"
                    : "preferredFixedFlowCoolingTemp"
                );
              }}
              disabled={isInstallationOffline}
              min={fixedFlowTempData?.min}
              max={fixedFlowTempData?.max}
              decimalPlaces={2}
              step={0.5}
              hint={`${t("fieldHints.flowTemperature", {
                from: fixedFlowTempData?.min,
                to: fixedFlowTempData?.max,
              })}`}
            />
          )}
        </Box>
      )}
    </>
  );
}
