import { useTranslation } from "react-i18next";
import { IInstallationUserSettingsQuietMode } from "../../../../../../../../../store/services/models/installations/installationSettings";

export interface IUserQuietModeTabConditionsAndValuesToDisplayParameters {
  quietModeSettingsFormData: IInstallationUserSettingsQuietMode | null;
  isQuietModeEnabledInitialy: boolean;
  wasEverEnabled: boolean;
  isLoadingSettings: boolean;
}

export default function useQuietModeTabConditionsAndValuesToDisplay({
  quietModeSettingsFormData,
  isQuietModeEnabledInitialy,
  wasEverEnabled,
  isLoadingSettings,
}: IUserQuietModeTabConditionsAndValuesToDisplayParameters) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.userSettings.quietMode",
  });

  const { t: baseT } = useTranslation("cloud_ui");

  const { t: tabNameT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.tabsNames",
  });

  const tabNameLabelText: string = tabNameT("userSettings");
  const subTabNameLabelText = tabNameT("quietMode");

  const mainTitleText = t("mainTitle");
  const mainDescriptionText = t("mainDescription");
  const commonErrorForRequestText: string = baseT("errors.somethingWentWrong");
  const stateSwitcherLabelText = t("stateSwitcherLabel");

  const isShowQuietModeSchedule: boolean =
    !!quietModeSettingsFormData &&
    (isQuietModeEnabledInitialy || wasEverEnabled);

  const isShowEmptyBlock: boolean =
    !quietModeSettingsFormData && !isLoadingSettings;

  return {
    mainTitleText,
    mainDescriptionText,
    stateSwitcherLabelText,
    tabNameLabelText,
    commonErrorForRequestText,
    subTabNameLabelText,
    isShowQuietModeSchedule,
    isShowEmptyBlock,
  };
}
