export enum ToastStatuses {
  Success = "Success",
  Error = "Error",
}

interface IStatusIconProps {
  status: ToastStatuses;
}

interface IIconInformation {
  icon: React.JSX.Element;
  color: string;
}

interface IGetSxForOutersParameters {
  opacity: string;
  size: string;
}

export type { IStatusIconProps, IIconInformation, IGetSxForOutersParameters };
