import { useContext } from "react";
import InstallationZonesContext from "../../context/InstallationZonesContext";

export default function useFlowTempSettings() {
  const {
    isInstallationOffline,
    staticValues,
    zoneFormData,
    zoneKeys,
    updateZoneController,
    updateZoneFormData,
  } = useContext(InstallationZonesContext);
  return {
    isInstallationOffline,
    staticValues,
    zoneFormData,
    zoneKeys,
    updateZoneController,
    updateZoneFormData,
  };
}
