import { Slide, toast } from "react-toastify";
import Toaster, { IToasterData } from "../components/Toaster/Toaster";
import useTheme from "../theme/hooks/useTheme";

export const useToast = () => {
  const theme = useTheme();
  const showToast = (data: IToasterData) => {
    toast((props) => <Toaster {...props} />, {
      closeButton: false,
      icon: false,
      hideProgressBar: true,
      transition: Slide,
      style: {
        border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
        borderRadius: theme.radius.radiusXl,
        padding: theme.spacing.spacingMd,
        maxWidth: "450px",
        minWidth: "400px",
        width: "100%",
      },
      data,
    });
  };

  return { showToast };
};
