import { useTranslation } from "react-i18next";
import useTheme, { ESThemeType } from "../../../../theme/hooks/useTheme";
import { Grid, SxProps } from "@mui/material";
import SimpleSideMenu, {
  ISideMenuItem,
} from "../../../../components/SimpleSideMenu/SimpleSideMenu";
import { BarChart08, LineChartUp01, WiFiOff } from "untitledui-js-base";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import Analytics from "./Analitics/Analytics";
import GraphReport from "./GraphReport/GraphReport";
import { useLayoutEffect } from "react";
import KeepAlive, {  } from "react-activation";
import { useTypedSelector } from "../../../../store";
import { selectSelectedInstallationDetails } from "../../../../store/reducers/installationSlice";
import { OnlineStatus } from "../../../../store/services/models/installations/installationsCommon";
import EmptyInstallationPage from "../../EmptyPage";

export default function AnalyticsPage({ baseUrl }: { baseUrl: string }) {
  const theme = useTheme();
  const styles = getStyles(theme);
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t: commonT } = useTranslation("cloud_ui");
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.analyticsPage" });

    const selectedInstallationDetails = useTypedSelector(selectSelectedInstallationDetails);

  useLayoutEffect(() => {
    if (location.pathname.endsWith("/analytics") || location.pathname.endsWith("/analytics/")) {
      navigate("./analytic-report");
    }
  }, [location.pathname, navigate]);

  const sideMenuItems: Array<ISideMenuItem> = [
    {
      href: `${baseUrl}/analytic-report`,
      label: t("sideMenu.analytics"),
      icon: <LineChartUp01 size="24" style={{ color: "inherit" }} strokeWidth={2} />,
    },
    {
      href: `${baseUrl}/graph-report`,
      label: t("sideMenu.graph"),
      icon: <BarChart08 size="24" style={{ color: "inherit" }} strokeWidth={2} />,
    },
  ];

    if (
      selectedInstallationDetails?.lastCommunicationDate === undefined &&
      selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
    ) {
      return (
        <EmptyInstallationPage
          icon={
            <WiFiOff
              size="20"
              style={{
                color:
                  theme.colors
                    .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
              }}
              strokeWidth={2}
            />
          }
          title={commonT("pages.installationBornOffline.titles.analytics")}
          description={commonT("pages.installationBornOffline.descriptions.analytics")}
        />
      );
    }

  return (
    <Grid container flexDirection={"row"} flexGrow={1} flexWrap={"nowrap"}>
      <Grid item sx={styles.sideMenu}>
        <SimpleSideMenu menuItems={sideMenuItems} />
      </Grid>
      <Grid item container sx={styles.container}>
        <Routes>
          <Route path="analytic-report" element={<Analytics />} />
          <Route
            path={"graph-report"}
            element={
              <KeepAlive
                id={id}
                name={`Graph_report_${id}`}
                saveScrollPosition="screen"
                autoFreeze={true}
              >
                <GraphReport installationId={id!} />
              </KeepAlive>
            }
          />
        </Routes>
      </Grid>
    </Grid>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    wrapper: {},
    sideMenu: {
      width: "252px",
      borderRight: `1px solid ${colors.colorsBorderBorderSecondary}`,
    } as SxProps,
    container: {
      flex: "1",
      width: "initial",
    } as SxProps,
  };
};
