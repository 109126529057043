import { useTranslation } from "react-i18next";
import {
  FlowTemperatureSettingsTypes,
  IUseFlowTemSettingsConditionsParameters,
} from "../../types";
import { InstallationZoneOperationControllerEnum } from "../../../../../../../../store/services/models/installations/installationSettings";
import { IInstallationBaseValueWithMeasurement } from "../../../../../../../../store/services/models/installations/installationsCommon";

export default function useFlowTemSettingsConditionsAndValuesForDisplay({
  type,
  zoneFormData,
  zoneNumber,
}: IUseFlowTemSettingsConditionsParameters) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });
  const isHeatig: boolean = type === FlowTemperatureSettingsTypes.Heating;
  const isCooling: boolean = type === FlowTemperatureSettingsTypes.Cooling;

  const fixedFlowTempData: IInstallationBaseValueWithMeasurement | undefined =
    isHeatig
      ? zoneFormData?.preferredFixedFlowHeatingTemp
      : zoneFormData?.preferredFixedFlowCoolingTemp;

  const isCurveControllerSelected: boolean =
    (isHeatig
      ? zoneFormData?.heatingController
      : zoneFormData?.coolingController) ===
    InstallationZoneOperationControllerEnum.CURVE;

  const isFixedControllerSelected: boolean =
    (isHeatig
      ? zoneFormData?.heatingController
      : zoneFormData?.coolingController) ===
    InstallationZoneOperationControllerEnum.FIXED;

  const isFixedFlowTempHasValue: boolean = isCooling
    ? zoneFormData?.preferredFixedFlowCoolingTemp?.value !== undefined
    : zoneFormData?.preferredFixedFlowHeatingTemp?.value !== undefined;

  const mainTitle: string = `${t("zoneTitle")} ${zoneNumber} - ${t(
    "flowTemperatureSetPoint"
  )}  - ${t(`${type}`)}`;

  const setCurveControllerRadioLabel: string = t(
    `buttons.${isCooling ? "useCoolingCurve" : "useHeatingCurve"}`
  );

  const setFixedControllerRadioLabel: string = t(
    `buttons.${
      isCooling
        ? "useFixedFlowTemperatureForCooling"
        : "useFixedFlowTemperatureForHeating"
    }`
  );

  const fixedFlowSectionTitle: string = t(
    `fieldsLabels.${
      isCooling
        ? "fixedFlowTemperatureForCooling"
        : "fixedFlowTemperatureForHeating"
    }`
  );

  return {
    isHeatig,
    isCooling,
    isCurveControllerSelected,
    isFixedControllerSelected,
    isFixedFlowTempHasValue,

    fixedFlowTempData,
    mainTitle,
    setCurveControllerRadioLabel,
    setFixedControllerRadioLabel,
    fixedFlowSectionTitle,
  };
}
