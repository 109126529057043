import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AlertCircle, XClose } from "untitledui-js-base";
import DatePickerInput from "../components/DatePickerInput/DatePickerInput";
import { Button, ButtonType } from "../../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router";
import { useLazyGetInstallationRuntimeHistoryQuery } from "../../../../../store/services/installation";
import { FleetManagementAPI } from "../../../../../api/FleetManagementAPI";
import { IInstalationByIdResponse } from "../../../../../api/responses/fleetManagementResponses/FleetManagementResponse";
import {
  toEndOfDay,
  toLocalISOString,
  toStartOfDay,
} from "../../../../../util/DateUtil";
import { enUS } from "date-fns/locale";
import { locales } from "../../../../../util/DateLocales";
import useParametersData from "../hooks/useParametersData";
import DropdownScrollableWithCheckboxesAndSections from "../../../../../components/DropdownScrollableWithCheckboxesAndSections/DropdownScrollableWithCheckboxesAndSections";

export default function Analytics() {
  const theme = useTheme();
  const navigate = useNavigate();
  const organizationsApi = new FleetManagementAPI(navigate);


  const { t, i18n } = useTranslation("cloud_ui", {
    keyPrefix: "pages.analyticsPage",
  });
  const { id } = useParams();

  const { availableParametersForDropdown } = useParametersData();



  const currentLocale = locales[i18n.language as keyof typeof locales] || enUS;

  const [currentInstallationInfo, setCurrentInstallationInfo] =
    useState<IInstalationByIdResponse>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedParameters, setSelectedParameters] = useState<Array<string>>(
    []
  );

  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);

  const [trigger, { error, isLoading, isFetching }] =
    useLazyGetInstallationRuntimeHistoryQuery();

  const handleFetchHistory = () => {
    let formattedParameters = [...selectedParameters];
    trigger({
      installationUuid: id!,
      startDateTime: toLocalISOString(toStartOfDay(new Date(startDate!))),
      endDateTime: toLocalISOString(toEndOfDay(new Date(endDate!))),
      param: formattedParameters,
    })
      .then(({ data }) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;

          const stDate = new Date(startDate!);
          const stMonth = (stDate.getMonth() + 1).toString().padStart(2, "0");
          const stDay = stDate.getDate().toString().padStart(2, "0");
          const stYear = stDate.getFullYear();
          const formattedStartDate = `${stDay}_${stMonth}_${stYear}`;

          const eDate = new Date(endDate!);
          const eMonth = (eDate.getMonth() + 1).toString().padStart(2, "0");
          const eDay = eDate.getDate().toString().padStart(2, "0");
          const eYear = eDate.getFullYear();
          const formattedEndDate = `${eDay}_${eMonth}_${eYear}`;

          a.download = `${currentInstallationInfo?.name || "runtime_history"}_${
            currentInstallationInfo?.connectivityId || "connectivityId"
          }_${formattedStartDate || ""}-${formattedEndDate || ""}.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (id) {
      organizationsApi
        .getInstallationById(id)
        .then((res) => {
          setCurrentInstallationInfo(res.data);
        })
        .catch((err) => {});
    }
  }, [id]);

  useEffect(() => {
    if (error) {
      setIsErrorVisible(true);
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 3000);
    }
  }, [error]);

  const maxStartDate = new Date();
  const minStartDate = new Date();
  minStartDate.setDate(maxStartDate.getDate() - 30);

  const minEndDate = startDate || minStartDate;
  const maxEndDate = new Date();

  const handleStartDateChange = (date: Date | null) => {
    setTimeout(() => {
      setStartDate(date);
      if (date && endDate && date > endDate) {
        setEndDate(null);
      }
    }, 300);
  };

  const handleEndDateChange = (date: Date | null) => {
    setTimeout(() => {
      setEndDate(date);
    }, 300);
  };

  return (
    <Box
      sx={{
        p: `0 ${theme.spacing.spacingXl}`,
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          m: `${theme.spacing.spacingXl} 0`,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-xl-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingXl,
          }}
        >
          {t("mainTitle")}
        </Typography>
        <Divider
          sx={{
            width: "100%",
            borderColor: theme.colors.colorsBorderBorderSecondary,
          }}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        {/* FILTERS START */}
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={currentLocale}
          >
            <DatePickerInput
              date={startDate}
              handleDateChange={handleStartDateChange}
              maxDate={maxStartDate}
              minDate={minStartDate}
              placeholder={t("selectStartDate")}
              sx={{ mr: theme.spacing.spacingXl }}
            />
            <DatePickerInput
              date={endDate}
              handleDateChange={handleEndDateChange}
              maxDate={maxEndDate}
              minDate={minEndDate}
              placeholder={t("selectEndDate")}
              sx={{ mr: theme.spacing.spacingXl }}
            />
          </LocalizationProvider>
          <DropdownScrollableWithCheckboxesAndSections
            buttonLabel={t("selectParameters")}
            items={availableParametersForDropdown}
            selectAllLabel={t("selectAll")}
            selectedValues={selectedParameters}
            setSelectedValues={(params) => setSelectedParameters(params)}
          />
        </Box>
        <Box sx={{ ml: "auto" }}>
          <Button
            disabled={
              (!startDate && !endDate && !selectedParameters.length) ||
              isLoading ||
              isFetching
            }
            buttonType={ButtonType.SecondaryGray}
            sx={{ mr: theme.spacing.spacingMd }}
            label={`${t("resetButton")}`}
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
              setSelectedParameters([]);
            }}
          />
          <Button
            disabled={
              !startDate ||
              !endDate ||
              !selectedParameters.length ||
              isLoading ||
              isFetching
            }
            label={`${t("downloadButton")}`}
            onClick={() => handleFetchHistory()}
            loadingInProgress={isLoading || isFetching}
          />
        </Box>
      </Box>
      {isErrorVisible && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: "76px",
            right: "10px",
            boxShadow:
              "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
            border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
            borderRadius: "12px",
            p: theme.spacing.spacingXl,
            maxWidth: "400px",
            backgroundColor: theme.colors.colorsForegroundFgWhite,
          }}
        >
          <Box
            sx={{
              height: "33px",
              width: "33px",
              minWidth: "33px",
              borderRadius: "50%",
              border: `2px solid #fbeae9`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: theme.spacing.spacingMd,
            }}
          >
            <Box
              sx={{
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                border: `2px solid #f4c0bd`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AlertCircle
                style={{ color: theme.colors.colorsForegroundFgErrorPrimary }}
                size="17"
                strokeWidth={2}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                ...theme.typography[".text-sm-semibold"],
                color: theme.colors.colorsForegroundFgPrimary,
              }}
            >
              {t("errors.titles.common")}
            </Typography>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsForegroundFgSecondary,
                maxHeight: "300px",
              }}
            >
              {t("errors.description.common")}
            </Typography>
          </Box>
          <XClose
            onClick={() => setIsErrorVisible(false)}
            style={{
              color: theme.colors.colorsForegroundFgQuinary,
              marginLeft: theme.spacing.spacingXl,
              cursor: "pointer",
            }}
            size="30"
            strokeWidth={2}
          />
        </Box>
      )}
    </Box>
  );
}
