import { SxProps, Typography } from "@mui/material";
import React, { useMemo } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export default function DescriptionText({ text }: { text: string }) {
  const theme = useTheme();
  const { colors, spacing, typography } = theme;
  const styles: Record<string, SxProps> = useMemo(
    () => ({
      text: {
        ...typography[".text-sm-regular"],
        color: colors.colorsTextTextTertiary,
        mb: spacing.spacingXl,
        maxWidth: "500px",
      },
    }),
    [typography, spacing, colors]
  );
  return <Typography sx={styles.text}>{text}</Typography>;
}
