import { useTranslation } from "react-i18next";
import SecondaryTitle from "../../../../../../components/SecondaryTitle/SecondaryTitle";
import { IInstallationUserSettingsQuietMode } from "../../../../../../../../../../store/services/models/installations/installationSettings";
import { IQuietModeDifferences } from "../../types/types";
import QuietModeDaysDifference from "./components/QuietModeDaysDifference/QuietModeDaysDifference";
import { IBaseDifferencesObject } from "../../../../../../types/commonTypes";
import {
  IInstallationBaseValueWithMeasurement,
  InstallationValueUnit,
} from "../../../../../../../../../../store/services/models/installations/installationsCommon";
import SettingsDivider from "../../../../../../components/SettingsDivider/SettingsDivider";

export interface IQuietModeDifferencesProps {
  differences: IQuietModeDifferences | null;
  initialValue: IInstallationUserSettingsQuietMode;
  formData: IInstallationUserSettingsQuietMode;
}

export default function QuietModeDifferences({
  differences,
  formData,
  initialValue,
}: IQuietModeDifferencesProps) {
  const { t } = useTranslation("cloud_ui");
  const { t: quietModeT } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.userSettings.quietMode.quietModeSchedule",
  });
  const mainTitle = quietModeT("mainTitle");

  const calculateChangedEndHour = (
    formDataStart: IInstallationBaseValueWithMeasurement,
    initialValueLength: IInstallationBaseValueWithMeasurement,
    initialValueStart: IInstallationBaseValueWithMeasurement,
    differences: IBaseDifferencesObject
  ): IBaseDifferencesObject | null => {
    if (!differences) return null;
    let defaultEndHour;
    let changedEndHour;
    if (initialValueLength.value === 0) {
      defaultEndHour = 0;
    } else {
      defaultEndHour =
        (initialValueLength.value + initialValueStart.value) % 24;
    }

    if (differences?.changedValue?.value === 0) {
      changedEndHour = 0;
    } else {
      changedEndHour =
        (differences.changedValue.value + formDataStart.value) % 24;
    }

    return {
      defaultValue: {
        value: defaultEndHour,
        unit: InstallationValueUnit.HOURS,
        min: 0,
        max: 24,
      },
      changedValue: {
        value: changedEndHour,
        unit: InstallationValueUnit.HOURS,
        min: 0,
        max: 24,
      },
    };
  };
  return (
    <>
      <SecondaryTitle title={mainTitle} variant="md" />
      {differences && (
        <>
          {(differences.startMonday || differences.lengthMonday) && (
            <>
              <SettingsDivider />
              <QuietModeDaysDifference
                dayTitle={t("daysOfWeekMultiple.mondays")}
                startHourChanges={differences.startMonday}
                startHourValue={formData.startMonday}
                endHourChanges={calculateChangedEndHour(
                  formData.startMonday,
                  initialValue.lengthMonday,
                  initialValue.startMonday,
                  differences.lengthMonday
                )}
                lengthChanges={differences.lengthMonday}
              />
            </>
          )}
          {(differences.startTuesday || differences.lengthTuesday) && (
            <>
              <SettingsDivider />
              <QuietModeDaysDifference
                dayTitle={t("daysOfWeekMultiple.tuesdays")}
                startHourChanges={differences.startTuesday}
                startHourValue={formData.startTuesday}
                endHourChanges={calculateChangedEndHour(
                  formData.startTuesday,
                  initialValue.lengthTuesday,
                  initialValue.startTuesday,
                  differences.lengthTuesday
                )}
                lengthChanges={differences.lengthTuesday}
              />
            </>
          )}
          {(differences.startWednesday || differences.lengthWednesday) && (
            <>
              <SettingsDivider />
              <QuietModeDaysDifference
                dayTitle={t("daysOfWeekMultiple.wednesdays")}
                startHourChanges={differences.startWednesday}
                startHourValue={formData.startWednesday}
                endHourChanges={calculateChangedEndHour(
                  formData.startWednesday,
                  initialValue.lengthWednesday,
                  initialValue.startWednesday,
                  differences.lengthWednesday
                )}
                lengthChanges={differences.lengthWednesday}
              />
            </>
          )}
          {(differences.startThursday || differences.lengthThursday) && (
            <>
              <SettingsDivider />
              <QuietModeDaysDifference
                dayTitle={t("daysOfWeekMultiple.thursdays")}
                startHourChanges={differences.startThursday}
                startHourValue={formData.startThursday}
                endHourChanges={calculateChangedEndHour(
                  formData.startThursday,
                  initialValue.lengthThursday,
                  initialValue.startThursday,
                  differences.lengthThursday
                )}
                lengthChanges={differences.lengthThursday}
              />
            </>
          )}
          {(differences.startFriday || differences.lengthFriday) && (
            <>
              <SettingsDivider />
              <QuietModeDaysDifference
                dayTitle={t("daysOfWeekMultiple.fridays")}
                startHourChanges={differences.startFriday}
                startHourValue={formData.startFriday}
                endHourChanges={calculateChangedEndHour(
                  formData.startFriday,
                  initialValue.lengthFriday,
                  initialValue.startFriday,
                  differences.lengthFriday
                )}
                lengthChanges={differences.lengthFriday}
              />
            </>
          )}
          {(differences.startSaturday || differences.lengthSaturday) && (
            <>
              <SettingsDivider />
              <QuietModeDaysDifference
                dayTitle={t("daysOfWeekMultiple.saturdays")}
                startHourChanges={differences.startSaturday}
                startHourValue={formData.startSaturday}
                endHourChanges={calculateChangedEndHour(
                  formData.startSaturday,
                  initialValue.lengthSaturday,
                  initialValue.startSaturday,
                  differences.lengthSaturday
                )}
                lengthChanges={differences.lengthSaturday}
              />
            </>
          )}
          {(differences.startSunday || differences.lengthSunday) && (
            <>
              <SettingsDivider />
              <QuietModeDaysDifference
                dayTitle={t("daysOfWeekMultiple.sundays")}
                startHourChanges={differences.startSunday}
                startHourValue={formData.startSunday}
                endHourChanges={calculateChangedEndHour(
                  formData.startSunday,
                  initialValue.lengthSunday,
                  initialValue.startSunday,
                  differences.lengthSunday
                )}
                lengthChanges={differences.lengthSunday}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
