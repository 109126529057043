import React, { useEffect, useState } from "react";
import { Box, Switch, Typography } from "@mui/material";
import useTheme from "../../../../../../theme/hooks/useTheme";

export interface ISettingsSwitcherProps {
  label?: string;
  state?: boolean;
  changeState: (val: boolean) => void;
  statusOn?: string;
  statusOff?: string;
  hint?: string;
  disabled?: boolean;
}

export default function SettingsSwitcher({
  label,
  state,
  changeState,
  statusOn,
  statusOff,
  hint,
  disabled,
}: ISettingsSwitcherProps) {
  const theme = useTheme();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    setIsActive(!!state);
  }, [state]);

  return (
    <Box sx={{ mb: theme.spacing.spacingXl }}>
      {label && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-medium"],
            color: theme.colors.colorsTextTextSecondary,
            mb: theme.spacing.spacingSm,
          }}
        >
          {label}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          opacity: disabled ? "0.5" : "1",
          pointerEvents: disabled ? "none" : "initial",
        }}
      >
        <Switch
          sx={{
            width: "44px",
            height: "24px",
            padding: 0,
            ".MuiSwitch-track": {
              height: "24px",
              borderRadius: "15px",
              backgroundColor: theme.colors.colorsBackgroundBgTertiary,
              opacity: 1,
            },
            ".MuiButtonBase-root": {
              padding: "2px",
              "&.Mui-checked": {
                ".MuiSwitch-thumb": {
                  width: "20px",
                  height: "20px",
                  color: theme.colors.colorsForegroundFgWhite,
                },
                "+.MuiSwitch-track": {
                  backgroundColor: theme.colors.colorsBackgroundBgBrandSolid,
                  opacity: 1,
                },
              },
              ".MuiSwitch-thumb": {
                width: "20px",
                height: "20px",
              },
            },
          }}
          checked={isActive}
          onChange={(event) => {
            if (disabled) {
              return;
            }
            changeState(event.target.checked);
          }}
        />
        {statusOn && statusOff && (
          <Typography
            sx={{
              ...theme.typography[".text-md-medium"],
              color: theme.colors.colorsTextTextSecondary,
              ml: theme.spacing.spacingLg,
            }}
          >
            {isActive ? statusOn : statusOff}
          </Typography>
        )}
      </Box>
      {hint && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
            mt: theme.spacing.spacingSm,
          }}
        >
          {hint}
        </Typography>
      )}
    </Box>
  );
}
