import { useTranslation } from "react-i18next";
import DifferencesSecondaryTitle from "../../../../../../../../components/Differences/components/DifferencesSecondaryTitle/DifferencesSecondaryTitle";
import useDifferencesHelpers from "../../../../../../../../components/Differences/hooks/useDifferencesHelpers";
import { IBaseDifferencesObject } from "../../../../../../../../types/commonTypes";
import DifferencesRow from "../../../../../../../../components/Differences/components/DifferencesRow/DifferencesRow";
import { ArrowRight } from "untitledui-js-base";
import useTheme from "../../../../../../../../../../../../theme/hooks/useTheme";
import { IInstallationBaseValueWithMeasurement } from "../../../../../../../../../../../../store/services/models/installations/installationsCommon";

export interface IQuietModeDaysDifferenceProps {
  dayTitle: string;
  startHourChanges: IBaseDifferencesObject | null;
  endHourChanges: IBaseDifferencesObject | null;
  lengthChanges: IBaseDifferencesObject | null;
  startHourValue: IInstallationBaseValueWithMeasurement | null;
}

export default function QuietModeDaysDifference({
  dayTitle,
  startHourChanges,
  startHourValue,
  endHourChanges,
  lengthChanges,
}: IQuietModeDaysDifferenceProps) {
  const theme = useTheme();

  const { t } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.userSettings.quietMode.quietModeSchedule",
  });

  const { buildBaseDifferenceValue } = useDifferencesHelpers();

  const isChangedToActive: boolean = lengthChanges?.defaultValue?.value === 0;
  const isChangedToInactive: boolean = lengthChanges?.changedValue?.value === 0;

  const buildStateDifferences = (isChangedToActive: boolean) => {
    return (
      <>
        {isChangedToActive ? t("inactive") : t("active")}
        <ArrowRight
          size="14"
          style={{
            color: theme.colors.colorsTextTextPrimary,
          }}
          strokeWidth={2}
        />
        {isChangedToActive ? t("active") : t("inactive")}
      </>
    );
  };

  return (
    <>
      <DifferencesSecondaryTitle title={dayTitle} />
      {isChangedToActive && (
        <>
          <DifferencesRow
            label={t("state")}
            value={buildStateDifferences(true)}
          />
          <DifferencesRow
            label={t("start")}
            value={buildBaseDifferenceValue({
              changedValue: startHourValue!,
            })}
          />
          <DifferencesRow
            label={t("stop")}
            value={buildBaseDifferenceValue({
              changedValue: endHourChanges?.changedValue!,
            })}
          />
        </>
      )}
      {isChangedToInactive && (
        <>
          <DifferencesRow
            label={t("state")}
            value={buildStateDifferences(false)}
          />
        </>
      )}
      {!isChangedToActive && !isChangedToInactive && (
        <>
          {startHourChanges && (
            <DifferencesRow
              label={t("start")}
              value={buildBaseDifferenceValue(startHourChanges)}
            />
          )}
          {endHourChanges &&
            endHourChanges.changedValue.value !==
              endHourChanges?.defaultValue?.value && (
              <DifferencesRow
                label={t("stop")}
                value={buildBaseDifferenceValue(endHourChanges)}
              />
            )}
        </>
      )}
    </>
  );
}
