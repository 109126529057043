import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import useTheme from "../../../../theme/hooks/useTheme";

export interface IRealTimeDataSection {
  icon: any;
  name: string;
  children?: ReactNode;
}

export default function RealTimeDataSection({
  icon,
  name,
  children,
}: IRealTimeDataSection) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        maxWidth: {lg: "80%", md: "90%"},
        p: `${theme.spacing.spacingMd} 0`,
        borderBottom: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "48px",
            width: "48px",
            border: `1px solid ${theme.colors.componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder}`,
            borderRadius: "10px",
            mr: theme.spacing.spacing2xl,
          }}
        >
          {icon}
        </Box>
        <Typography
          sx={{
            ...theme.typography[".text-xl-semibold"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box sx={{ ml: `calc(48px + ${theme.spacing.spacing2xl})` }}>
        {children}
      </Box>
    </Box>
  );
}
