import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../theme/hooks/useTheme";
import SortableTable, { Column } from "../../../../components/Grid/SortableTable";
import { Box, SxProps, TableCell, Typography } from "@mui/material";
import {
  AlertOctagon,
  AlertTriangle,
  Check,
  ChevronRight,
  InfoSquare,
  SearchLG,
  WiFiOff,
} from "untitledui-js-base";
import Filters, { IFilter } from "./components/Filters/Filters";
import DropdownWithCheckboxes, {
  IItems,
} from "./components/DropdownWithCheckboxes/DropdownWithCheckboxes";
import EmptyPage from "./EmptyPage/EmptyPage";
import { Button, ButtonType } from "../../../../components/Button/Button";
import {
  AlertType,
  OnlineStatus,
  OrganizationAlertItem,
} from "../../../../store/services/models/installations/installationsCommon";
import {
  useLazyGetInstallationAlertsHistoryCodesQuery,
  useLazyGetInstallationAlertsHistoryQuery,
} from "../../../../store/services/installation";
import { useParams } from "react-router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePickerInput from "../AnalyticsPage/components/DatePickerInput/DatePickerInput";
import AlertsInfoModal from "./components/AlertsInfoModal/AlertsInfoModal";
import EmptyInstallationPage from "../../EmptyPage";
import { useTypedSelector } from "../../../../store";
import { selectSelectedInstallationDetails } from "../../../../store/reducers/installationSlice";
import { formatDate, toEndOfDay, toLocalISOString, toStartOfDay } from "../../../../util/DateUtil";

// export const formatDate = (dateFromBE: string) => {
//   let formattedDate = "";
//   const date = new Date(dateFromBE);
//   const day = String(date.getDate()).padStart(2, "0");
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const year = date.getFullYear();
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;
//   return formattedDate;
// };

enum StateFilters {
  ALL_TIME = "ALL_TIME",
  ACTIVE = "ACTIVE",
}

enum TypeFilters {
  ALL_TYPES = "ALL_TYPES",
  ALARMS = "ERROR",
  WARNINGS = "WARNING",
  INFO = "INFO",
}

export default function AlertsPage() {
  const { t: commonT } = useTranslation("cloud_ui");
  const { t: alertsT } = useTranslation("cloud_ui", { keyPrefix: "alerts" });
  const { t: alertsPageT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.alertsPage",
  });
  const theme = useTheme();

  const { id } = useParams();

  const [trigger, { data: alerts, error, isLoading, isFetching }] =
    useLazyGetInstallationAlertsHistoryQuery();

  const selectedInstallationDetails = useTypedSelector(selectSelectedInstallationDetails);

  const [
    codesTrigger,
    { data: alertCodes, error: codesError, isLoading: codesIsLoading, isFetching: codesIsFetching },
  ] = useLazyGetInstallationAlertsHistoryCodesQuery();

  const baseTypography = useMemo(
    () => ({
      ...theme.typography[".text-sm-regular"],
      color: theme.colors.colorsTextTextQuaternary,
    }),
    [theme.typography, theme.colors.colorsTextTextQuaternary],
  );

  //FILTERS

  const defaultAlertStateFilters = useMemo(
    () => [
      {
        label: alertsPageT("table.filters.allTime"),
        value: StateFilters.ALL_TIME,
        selected: false,
        canNotBeCleared: true,
      },
      {
        label: alertsPageT("table.filters.active"),
        value: StateFilters.ACTIVE,
        selected: true,
      },
    ],
    [alertsPageT],
  );

  const defaultAlertTypeFilters = useMemo(
    () => [
      {
        label: alertsPageT("table.filters.allTypes"),
        value: TypeFilters.ALL_TYPES,
        selected: true,
        canNotBeCleared: true,
      },
      {
        label: alertsPageT("table.filters.alarms"),
        value: TypeFilters.ALARMS,
        selected: false,
        icon: (
          <AlertOctagon
            size="20"
            style={{
              color: "inherit",
            }}
            strokeWidth={2}
          />
        ),
      },
      {
        label: alertsPageT("table.filters.warnings"),
        value: TypeFilters.WARNINGS,
        selected: false,
        icon: (
          <AlertTriangle
            size="20"
            style={{
              color: "inherit",
            }}
            strokeWidth={2}
          />
        ),
      },
      {
        label: alertsPageT("table.filters.info"),
        value: TypeFilters.INFO,
        selected: false,
        icon: (
          <InfoSquare
            size="20"
            style={{
              color: "inherit",
            }}
            strokeWidth={2}
          />
        ),
      },
    ],
    [alertsPageT],
  );

  const [alertsStateFilters, setAlertsStateFilters] = useState<Array<IFilter>>([]);

  const [alertsTypeFilters, setAlertsTypeFilters] = useState<Array<IFilter>>([]);
  const [isNoAlerts, setIsNoAlerts] = useState<boolean>(false);

  useEffect(() => {
    setAlertsStateFilters(defaultAlertStateFilters);
  }, [defaultAlertStateFilters]);

  useEffect(() => {
    setAlertsTypeFilters(defaultAlertTypeFilters);
  }, [defaultAlertTypeFilters]);

  const [isActiveOnly, setIsActiveOnly] = useState<boolean>(true);
  const [selectedTypes, setSelectedTypes] = useState<Array<AlertType>>([]);

  const [codesForDropdown, setCodesForDropdown] = useState<Array<IItems>>();

  const [selectedCodesForDropdown, setSelectedCodesForDropdown] = useState<Array<string>>([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>();

  const [alertDetails, setAlertDetails] = useState<OrganizationAlertItem | null>(null);
  const [isAlertDetailModalOpen, setIsAlertDetailModalOpen] = useState<boolean>(false);

  const maxStartDate = new Date();
  const minStartDate = new Date();
  minStartDate.setDate(maxStartDate.getDate() - 30);

  const minEndDate = startDate || minStartDate;
  const maxEndDate = new Date();

  const handleStartDateChange = (date: Date | null) => {
    setTimeout(() => {
      setStartDate(date);
      if (date && endDate && date > endDate) {
        setEndDate(null);
      }
    }, 300);
  };

  const handleEndDateChange = (date: Date | null) => {
    setTimeout(() => {
      setEndDate(date);
    }, 300);
  };

  const getInstallationAlerts = (
    isActiveOnly: boolean,
    selectedTypes: Array<AlertType>,
    selectedCodesForDropdown: Array<string>,
    startDate: string,
    endDate: string,
  ) => {
    trigger({
      installationUuid: id!,
      activeOnly: isActiveOnly,
      alertCode: selectedCodesForDropdown,
      pageNum: page,
      pageSize: 7,
      startTimestamp: startDate,
      endTimestamp: endDate,
      type: selectedTypes,
    })
      .then(({ data }) => {
        if (data) {
          // renderTableRows(data.result);
          setTotalPages(data.pagination.totalPages);
          setTotalCount(data.pagination.totalCount);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    codesTrigger({
      installationUuid: id!,
    }).then(({ data }) => {
      if (data) {
        const codes: Array<IItems> = data.alerts.map((code) => {
          return {
            label: code.code,
            value: code.code,
            selected: false, //TODO !!! From query
          };
        });
        setCodesForDropdown(codes);
      }
    });

    trigger({
      installationUuid: id!,
      activeOnly: false,
      alertCode: [],
      pageNum: page,
      pageSize: 7,
      startTimestamp: "",
      endTimestamp: "",
      type: [],
    })
      .then(({ data }) => {
        setIsNoAlerts(!data?.result.length);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!isNoAlerts) {
      const formattedStartDate = startDate ? toLocalISOString(toStartOfDay(startDate)) : "";
      const formattedEndDate = endDate ? toLocalISOString(toEndOfDay(endDate)) : "";
      getInstallationAlerts(
        isActiveOnly,
        selectedTypes,
        selectedCodesForDropdown,
        formattedStartDate,
        formattedEndDate,
      );
    }
  }, [isNoAlerts, page, isActiveOnly, selectedTypes, selectedCodesForDropdown, startDate, endDate]);

  useEffect(() => {
    setPage(0);
  }, [isActiveOnly, selectedTypes, selectedCodesForDropdown, startDate, endDate]);

  useEffect(() => {
    let selectedTypes: Array<AlertType> = [];
    const isAllTypesSelected = alertsTypeFilters.find(
      (type) => type.value === TypeFilters.ALL_TYPES,
    )?.selected;
    if (isAllTypesSelected) {
      selectedTypes = [];
    } else {
      alertsTypeFilters.map((type) => {
        if (type.selected) {
          selectedTypes.push(type.value);
        }
      });
    }
    setSelectedTypes(selectedTypes);
  }, [alertsTypeFilters]);

  useEffect(() => {
    if (codesForDropdown) {
      const selected: Array<string> = [];
      codesForDropdown.map((code) => {
        if (code.selected) {
          selected.push(code.value);
        }
      });
      setSelectedCodesForDropdown(selected);
    }
  }, [codesForDropdown]);

  const setRowHighlightColor = useCallback(
    (alertType: AlertType) => {
      switch (alertType) {
        case AlertType.ERROR:
          return theme.colors.colorsForegroundFgErrorPrimary;
        case AlertType.WARNING:
          return theme.colors.colorsForegroundFgWarningSecondary;
        default:
          return theme.colors.componentColorsUtilityIndigoUtilityIndigo700;
      }
    },
    [
      theme.colors.colorsForegroundFgErrorPrimary,
      theme.colors.colorsForegroundFgWarningSecondary,
      theme.colors.componentColorsUtilityIndigoUtilityIndigo700,
    ],
  );

  const getableRows = useMemo(() => {
    const renderAlertTypeIcon = (alertType: AlertType) => {
      switch (alertType) {
        case AlertType.ERROR:
          return (
            <AlertOctagon
              size="20"
              style={{
                color: theme.colors.colorsForegroundFgErrorPrimary,
              }}
              strokeWidth={2}
            />
          );
        case AlertType.WARNING:
          return (
            <AlertTriangle
              size="20"
              style={{
                color: theme.colors.colorsForegroundFgWarningSecondary,
              }}
              strokeWidth={2}
            />
          );
        default:
          return (
            <InfoSquare
              size="20"
              style={{
                color: theme.colors.componentColorsUtilityIndigoUtilityIndigo700,
              }}
              strokeWidth={2}
            />
          );
      }
    };

    const alertsRowsForTable = alerts?.result?.map((alert, index) => {
      const isAlertActive = alert.active;
      const formattedCreatedAt = alert.alertStartTimestamp
        ? formatDate(alert.alertStartTimestamp)
        : "-";
      const formattedAcknowledgedAt = alert.alertAcknowledgedTimestamp
        ? formatDate(alert.alertAcknowledgedTimestamp)
        : "-";
      const formattedResolvedAt = alert.alertEndTimestamp
        ? formatDate(alert.alertEndTimestamp)
        : "-";

      return {
        // id: alert.id,
        alertData: alert,
        type: (
          <Box
            sx={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {renderAlertTypeIcon(alert.type)}
          </Box>
        ),
        code: (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextTertiary,
            }}
          >
            {alert.code}
          </Typography>
        ),
        alertName: (
          <Typography
            sx={{
              ...theme.typography[".text-sm-medium"],
              color: theme.colors.colorsTextTextPrimary,
            }}
          >
            {alertsT(`${alert.code.toLowerCase()}.name`)}
          </Typography>
        ),
        // origin: <Typography sx={{ ...baseTypography }}>{"OU"}</Typography>,
        createdAt: <Typography sx={{ ...baseTypography }}>{formattedCreatedAt}</Typography>,
        acknowledgedAt: (
          <Typography sx={{ ...baseTypography }}>{formattedAcknowledgedAt}</Typography>
        ),
        resolvedAt: <Typography sx={{ ...baseTypography }}>{formattedResolvedAt}</Typography>,
        action: (
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <ChevronRight
              size="20"
              style={{
                color: theme.colors.colorsForegroundFgBrandSecondary,
              }}
              strokeWidth={2}
            />
          </Box>
        ),
        isRowHighlited: true,
        highlightColor: isAlertActive
          ? setRowHighlightColor(alert.type)
          : theme.colors.colorsBorderBorderSecondary,
      };
    });
    return alertsRowsForTable || [];
  }, [
    alerts?.result,
    alertsT,
    baseTypography,
    setRowHighlightColor,
    theme.colors.colorsBorderBorderSecondary,
    theme.colors.colorsForegroundFgBrandSecondary,
    theme.colors.colorsForegroundFgErrorPrimary,
    theme.colors.colorsForegroundFgWarningSecondary,
    theme.colors.colorsTextTextPrimary,
    theme.colors.colorsTextTextTertiary,
    theme.colors.componentColorsUtilityIndigoUtilityIndigo700,
    theme.typography,
  ]);

  const tableColumns: Column[] = [
    // { id: "id", label: "", hidden: true },
    { id: "alertData", label: "", hidden: true },
    { id: "type", label: "", width: 70 },
    { id: "code", label: alertsPageT("table.headers.code"), width: 80 },
    {
      id: "alertName",
      label: alertsPageT("table.headers.alertName"),
      width: 300,
    },
    // { id: "origin", label: alertsPageT("table.headers.origin") },
    { id: "createdAt", label: alertsPageT("table.headers.ocurred") },
    { id: "acknowledgedAt", label: alertsPageT("table.headers.acknowledged") },
    { id: "resolvedAt", label: alertsPageT("table.headers.resolved") },
    { id: "action", label: "", width: 70 },
  ];

  const alertsFiltersChange = (
    filterVal: StateFilters | TypeFilters,
    filters: Array<IFilter>,
    allCase: any,
    setFilters: (filters: Array<IFilter>) => void,
  ) => {
    const updatedFilters = filters.map((filter) => {
      if (filterVal === allCase) {
        if (filterVal === filter.value) {
          return {
            ...filter,
            selected: true,
          };
        } else {
          return { ...filter, selected: false };
        }
      } else {
        if (filterVal === filter.value) {
          return {
            ...filter,
            selected: !filter.selected,
          };
        } else {
          if (filter.value === allCase) {
            return {
              ...filter,
              selected: false,
            };
          } else {
            return filter;
          }
        }
      }
    });
    setFilters(updatedFilters);
  };

  const clearFilters = () => {
    const updatedStateFilters = alertsStateFilters.map((filter) => {
      if (filter.value === StateFilters.ALL_TIME) {
        return { ...filter, selected: true };
      } else {
        return { ...filter, selected: false };
      }
    });

    const updatedTypeFilters = alertsTypeFilters.map((filter) => {
      if (filter.value === TypeFilters.ALL_TYPES) {
        return { ...filter, selected: true };
      } else {
        return { ...filter, selected: false };
      }
    });
    const updatedCodes = codesForDropdown?.map((code) => {
      return { ...code, selected: false };
    });

    setIsActiveOnly(false);
    setAlertsStateFilters(updatedStateFilters);
    setAlertsTypeFilters(updatedTypeFilters);
    setCodesForDropdown(updatedCodes);
    setStartDate(null);
    setEndDate(null);
  };

  const renderCodesForDropdown = useMemo(() => {
    return codesForDropdown?.map((code) => {
      return {
        ...code,
        label: `${code.value} - ${alertsT(`${code.value.toLowerCase()}.name` as any)}`,
      };
    });
  }, [alertsT, codesForDropdown]);

  const renderInfoTableHeadContent = () => {
    return (
      <TableCell colSpan={8} sx={{ position: "relative", pb: theme.spacing.spacingMd }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", mb: theme.spacing.spacingMd }}>
            <Box mr={theme.spacing.spacingMd}>
              <Filters
                filters={alertsStateFilters}
                onFilterClick={(val) => {
                  if (val === StateFilters.ALL_TIME) {
                    setIsActiveOnly(false);
                  } else {
                    setIsActiveOnly(!isActiveOnly);
                  }
                  alertsFiltersChange(
                    val,
                    alertsStateFilters,
                    StateFilters.ALL_TIME,
                    setAlertsStateFilters,
                  );
                }}
              />
            </Box>
            <Box>
              <Filters
                filters={alertsTypeFilters}
                onFilterClick={(val) => {
                  alertsFiltersChange(
                    val,
                    alertsTypeFilters,
                    TypeFilters.ALL_TYPES,
                    setAlertsTypeFilters,
                  );
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", mb: theme.spacing.spacingMd }}>
            <Box sx={{ display: "flex", ml: "auto" }}>
              <Box>
                <DropdownWithCheckboxes
                  buttonLabel={alertsPageT("table.alertCode")}
                  items={renderCodesForDropdown}
                  onCheckboxesApply={(updatedCodes) => {
                    setCodesForDropdown(updatedCodes);
                  }}
                  selectAllLabel={alertsPageT("table.selectAll")}
                  applyButtonLabel={alertsPageT("table.apply")}
                />
              </Box>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePickerInput
                date={startDate}
                handleDateChange={handleStartDateChange}
                maxDate={maxStartDate}
                minDate={minStartDate}
                placeholder={alertsPageT("table.filters.startDate")}
                width={"160"}
                showClearButton={!!startDate}
                onClearClick={() => {
                  setStartDate(null);
                }}
                sx={{
                  mr: theme.spacing.spacingMd,
                  ml: theme.spacing.spacingMd,
                }}
              />
              <DatePickerInput
                date={endDate}
                handleDateChange={handleEndDateChange}
                maxDate={maxEndDate}
                minDate={minEndDate}
                placeholder={alertsPageT("table.filters.endDate")}
                width={"160"}
                showClearButton={!!endDate}
                onClearClick={() => {
                  setEndDate(null);
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </TableCell>
    );
  };

  const renderNoResultsBlockForTable = () => {
    const isActiveOnlyFilterApplyed =
      isActiveOnly &&
      selectedCodesForDropdown.length === 0 &&
      selectedTypes.length === 0 &&
      !startDate &&
      !endDate;

    return (
      <Box sx={{ width: "100%", height: "268px", display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: "0 auto",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "48px",
              width: "48px",
              borderRadius: isActiveOnlyFilterApplyed
                ? theme.radius.radiusFull
                : theme.radius.radiusLg,
              color: theme.colors.colorsForegroundFgSecondary,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacingXl,
              border: isActiveOnlyFilterApplyed
                ? "none"
                : `1px solid ${theme.colors.componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder}`,
              backgroundColor: isActiveOnlyFilterApplyed
                ? theme.colors.componentColorsUtilityBrandUtilityBrand50
                : "initial",
            }}
          >
            {isActiveOnlyFilterApplyed ? (
              <Check
                size="20"
                style={{
                  color:
                    theme.colors
                      .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgBrand,
                }}
                strokeWidth={2}
              />
            ) : (
              <SearchLG
                size="20"
                style={{
                  color: "inherit",
                }}
                strokeWidth={2}
              />
            )}
          </Box>
          <Typography
            sx={{
              ...theme.typography[".text-md-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              mb: theme.spacing.spacingMd,
            }}
          >
            {alertsPageT(
              `emptyPage.${
                isActiveOnlyFilterApplyed ? "noResultsTitleActiveOnly" : "noResultsTitle"
              }`,
            )}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextTertiary,
              mb: theme.spacing.spacingXl,
            }}
          >
            {alertsPageT(
              `emptyPage.${
                isActiveOnlyFilterApplyed
                  ? "noResultsDescriptionActiveOnly"
                  : "noResultsDescription"
              }`,
            )}
          </Typography>
          {!isActiveOnlyFilterApplyed && (
            <Button
              onClick={() => clearFilters()}
              buttonType={ButtonType.SecondaryGray}
              label={`${alertsPageT("emptyPage.clearFiltersButton")}`}
            />
          )}
        </Box>
      </Box>
    );
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage - 1);
  };

  if (
    selectedInstallationDetails?.lastCommunicationDate === undefined &&
    selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
  ) {
    return (
      <EmptyInstallationPage
        icon={
          <WiFiOff
            size="20"
            style={{
              color:
                theme.colors
                  .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
            }}
            strokeWidth={2}
          />
        }
        title={commonT("pages.installationBornOffline.titles.alerts")}
        description={commonT("pages.installationBornOffline.descriptions.alerts")}
      />
    );
  }

  if (
    (!alerts?.result.length &&
      !startDate &&
      !endDate &&
      !isActiveOnly &&
      !selectedTypes.length &&
      !selectedCodesForDropdown.length &&
      !isFetching &&
      !isLoading) ||
    isNoAlerts
  ) {
    return <EmptyPage />;
  }

  return (
    <Box>
      <SortableTable
        columns={tableColumns}
        rows={getableRows}
        infoTableHeadContent={renderInfoTableHeadContent()}
        onRowClick={(row) => {
          setIsAlertDetailModalOpen(true);
          setAlertDetails(row.alertData);
        }}
        noResultsShown={
          !!(
            !alerts?.result.length &&
            !isLoading &&
            !isFetching &&
            (isActiveOnly ||
              selectedCodesForDropdown.length ||
              selectedTypes.length ||
              startDate ||
              endDate)
          )
        }
        noResultsBlock={renderNoResultsBlockForTable()}
        disableSort
        paginationData={{
          page: page + 1,
          totalCount: totalPages!,
          onPageChange: handleChangePage,
        }}
      />
      {isAlertDetailModalOpen && alertDetails && (
        <AlertsInfoModal
          isOpen={isAlertDetailModalOpen}
          closeModal={() => {
            setIsAlertDetailModalOpen(false);
            setAlertDetails(null);
          }}
          alertData={alertDetails}
        />
      )}
    </Box>
  );
}
