const deepClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

const findDifferences = ({
  prev,
  current,
}: {
  prev: any;
  current: any;
}): any => {
  if (prev === current) return null;

  const isObject = (obj: any) => typeof obj === "object" && obj !== null;

  if (!isObject(prev) || !isObject(current)) {
    return { defaultValue: prev, changedValue: current };
  }

  const isValueStructure = (obj: any) => isObject(obj) && "value" in obj;

  if (isValueStructure(prev) && isValueStructure(current)) {
    const diff: any = {
      defaultValue: { ...prev },
      changedValue: { ...prev },
    };

    let hasChanges = false;
    if (prev.value !== current.value) {
      diff.defaultValue.value = prev.value;
      diff.changedValue.value = current.value;
      hasChanges = true;
    }

    return hasChanges ? diff : null;
  }

  const diff: any = {};

  for (const key in current) {
    if (!(key in prev)) {
      diff[key] = current[key];
    } else {
      const nestedDiff = findDifferences({
        prev: prev[key],
        current: current[key],
      });
      if (nestedDiff !== null) {
        diff[key] = nestedDiff;
      }
    }
  }

  for (const key in prev) {
    if (!(key in current)) {
      diff[key] = null;
    }
  }

  return Object.keys(diff).length > 0 ? diff : null;
};

const isEmpty = (obj: object | undefined) => {
  if (obj) {
    return Object.keys(obj).length === 0;
  } else {
    return true;
  }
};

const transformDifferencesForRequest = (diff: any): any => {
  if (diff === null || typeof diff !== "object") return diff;

  if ("defaultValue" in diff && "changedValue" in diff) {
    return diff.changedValue?.value !== undefined
      ? diff.changedValue.value
      : diff.changedValue;
  }

  const transformed: any = {};
  for (const key in diff) {
    const transformedValue = transformDifferencesForRequest(diff[key]);
    if (transformedValue !== null && transformedValue !== undefined) {
      transformed[key] = transformedValue;
    }
  }

  return Object.keys(transformed).length > 0 ? transformed : null;
};

export { deepClone, findDifferences, isEmpty, transformDifferencesForRequest };
