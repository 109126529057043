import { Box, Grid, Skeleton } from "@mui/material";
import React, { memo } from "react";

const GraphSkeleton = memo(({ loadingText, height }: { loadingText: string; height: number }) => {
  return (
    <Box sx={{ width: "100%", textAlign: "center", mt: 2 }}>
      <Box sx={{ display: "flex", gap: 3, justifyContent: "flex-start", mb: 2 }}>
        {Array.from({ length: 8 }).map((_, index) => (
          <Skeleton key={`skeleton_l_${index}`} variant="rounded" width={120} height={25} />
        ))}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", mr: 1, gap: 4, flexDirection: "column" }}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton key={`skeleton_y_${index}`} variant="text" width={30} height={20} />
          ))}
        </Box>

        <Box sx={{ flex: 1, position: "relative" }}>
          <Skeleton variant="rounded" width="100%" height={height-100} />
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <strong>{loadingText}</strong>
          </Box>
        </Box>
      </Box>
      <Grid container justifyContent="space-between" sx={{ mt: 1 }}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Skeleton key={`skeleton_x_${index}`} variant="text" width={30} height={20} />
        ))}
      </Grid>
    </Box>
  );
});

export default GraphSkeleton;
