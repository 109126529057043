import { createContext } from "react";
import { IInstallationZonesContext } from "../types";

const InstallationZonesContext = createContext<IInstallationZonesContext>({
  isInstallerZones: false,
  installationSettingsResponse: null,
  installationStateResponse: null,
  isInstallationOffline: false,
  staticValues: null,
  zoneFormData: null,
  installerSettingsFormData: null,
  zoneKeys: null,
  updateZoneController: null,
  updateZoneFormData: null,
  updateInstallerZoneFormData: null,
  updateInstallerZoneMode: null,
  updateInstallerIndoorSensor: null,
  updateTempInCurve: null,
});

export default InstallationZonesContext;
