import React from "react";
import { ArrowRight } from "untitledui-js-base";
import useTheme from "../../../../../../../../theme/hooks/useTheme";

export default function ArrowIcon() {
  const theme = useTheme();
  return (
    <ArrowRight
      size="14"
      style={{
        color: theme.colors.colorsTextTextPrimary,
      }}
      strokeWidth={2}
    />
  );
}
