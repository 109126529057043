import { SxProps } from "@mui/material";
import { ESThemeType } from "../../../../../../theme/hooks/useTheme";

export const getStyles = ({
  colors,
  spacing,
  typography,
  radius,
}: ESThemeType): Record<string, SxProps> => {
  return {
    subMenu: {
      maxWidth: "300px",
      minWidth: "200px",
      height: "100%",
      borderRight: `1px solid ${colors.colorsBorderBorderSecondary}`,
    },
    listItem: {
      ...typography[".text-md-semibold"],
      maxHeight: "fit-content",
      padding: `0 ${spacing.spacingMd}`,
      color: colors.colorsTextTextQuaternary,
      borderRadius: radius.radiusMd,
      minHeight: "40px",
      mb: spacing.spacingXs,
    },
    listItemText: {
      ...typography[".text-md-semibold"],
      color: colors.colorsTextTextQuaternary,
      fontWeight: "600 !important",
    },
  };
};
