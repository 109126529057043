import React from "react";
import { IAdditionalInlineHeatingSourceDifferences } from "../../types/types";
import { useTranslation } from "react-i18next";
import SecondaryTitle from "../../../../../../components/SecondaryTitle/SecondaryTitle";
import DifferencesRow from "../../../../../../components/Differences/components/DifferencesRow/DifferencesRow";
import useDifferencesHelpers from "../../../../../../components/Differences/hooks/useDifferencesHelpers";
import SettingsDivider from "../../../../../../components/SettingsDivider/SettingsDivider";

interface IAdditionalHeatingSourceThresholdsDifferencesProps {
  differences: IAdditionalInlineHeatingSourceDifferences | null;
}

export default function AdditionalHeatingSourceThresholdsDifferences({
  differences,
}: IAdditionalHeatingSourceThresholdsDifferencesProps) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds",
  });

  const mainTitle = t("thresholds");

  const { buildBaseDifferenceValue } = useDifferencesHelpers();

  return (
    <>
      <SecondaryTitle title={mainTitle} variant="md" />
      {differences?.outdoorThresholdTemp && (
        <DifferencesRow
          label={t("fieldsLabels.outdorTempThreshold")}
          value={buildBaseDifferenceValue(differences.outdoorThresholdTemp)}
        />
      )}
      {differences?.outdoorThresholdTemp &&
        differences?.heatBalanceForHeating && (
          <SettingsDivider sx={{ m: "15px 0" }} />
        )}
      {differences?.heatBalanceForHeating && (
        <DifferencesRow
          label={t("fieldsLabels.heatBalanceThresholdForHeating")}
          value={buildBaseDifferenceValue(differences.heatBalanceForHeating)}
        />
      )}
    </>
  );
}
