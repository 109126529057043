import React, { useMemo } from "react";
import useTheme, { ESThemeType } from "../../../../../theme/hooks/useTheme";
import { Grid, SxProps } from "@mui/material";
import { IInstallationStateByIdResponse } from "../../../../../store/services/models/installations/installationState";
import { useTranslation } from "react-i18next";
import { OperationStatus } from "../../../../../store/services/models/installations/installationsCommon";
import SystemMessageBox from "../../../../../components/SystemMessageBox";

type FlowchartProps = {
  realTimeDataState: IInstallationStateByIdResponse | undefined;
};

export default function Flowchart({ realTimeDataState }: FlowchartProps) {
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.realTimeData.flowchart" });
  const theme = useTheme();
  const styles = getStyles(theme);

  const { colors, typography } = theme;
  const { space, refrigerantParameters, hydraulicParameters, others } = realTimeDataState || {};

  const mode = useMemo<"COOLING" | "HEATING" | "IDLE">(() => {
    if (!realTimeDataState?.refrigerantParameters) return "IDLE";

    const { defrostingActive, compressorSpeed } = realTimeDataState.refrigerantParameters;
    const { operationStatus } = realTimeDataState.systemOperation;

    if (defrostingActive) return "COOLING";
    if (!compressorSpeed?.value || compressorSpeed.value <= 0) return "IDLE";

    const isHeatingMode = [
      OperationStatus.HEATING,
      OperationStatus.DHW,
      OperationStatus.ANTI_LEGIONELLA,
    ].includes(operationStatus);

    return isHeatingMode ? "HEATING" : "COOLING";
  }, [realTimeDataState?.refrigerantParameters, realTimeDataState?.systemOperation]);

  const colorsSchema = {
    cooling: {
      fill: colors.componentColorsUtilityBlueDarkUtilityBlueDark400,
      gradient: { start: "#9DDCF9", end: "#7196F5" },
    },
    heating: {
      fill: colors.componentColorsUtilityWarningUtilityWarning500,
      gradient: { start: "#FFCD93", end: "#FC9E77" },
    },
    idle: {
      fill_heating: colors.componentColorsUtilityGrayUtilityGray700,
      fill_cooling: colors.componentColorsUtilityGrayUtilityGray500,
      gradient: {
        start: colors.componentColorsUtilityGrayUtilityGray400,
        end: colors.componentColorsUtilityGrayUtilityGray400,
      },
    },
  };

  return (
    <Grid container sx={styles.wrapper} justifyContent={{ xs: "flex-start", md: "center" }}>
      <Grid sx={styles.holder}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 893 483"
          fill="none"
          style={{ width: "100%", height: "auto" }}
        >
          <path // Pump feed
            d="M361.5 146.999V222.999"
            stroke={mode === "IDLE" ? colorsSchema.idle.fill_heating : colorsSchema.heating.fill}
            strokeWidth="7"
          />
          <rect
            x="115"
            y="228"
            width="16"
            height="22"
            rx="8"
            fill="url(#paint0_linear_8994_63268)"
          />
          <rect x="113" y="236" width="3" height="6" fill="#EAECF0" />
          <rect
            x="132.5"
            y="92.5"
            width="79"
            height="293"
            rx="6.5"
            fill="url(#paint1_linear_8994_63268)"
            stroke="url(#paint2_linear_8994_63268)"
            strokeWidth="7"
          />
          <path // fan blade top
            d="M110 233C99.4353 214.333 84.6446 177 110 177C135.355 177 120.565 214.333 110 233Z"
            fill={mode === "IDLE" ? colorsSchema.idle.fill_heating : colorsSchema.cooling.fill}
          />
          <path // fan blade bottom
            d="M110 245C120.565 263.667 135.355 301 110 301C84.6446 301 99.4353 263.667 110 245Z"
            fill={mode === "IDLE" ? colorsSchema.idle.fill_heating : colorsSchema.cooling.fill}
          />
          <rect x="107" y="233" width="6" height="12" rx="3" fill="#EAECF0" />
          <rect
            x="636.5"
            y="152.5"
            width="61"
            height="173"
            rx="6.5"
            fill="url(#paint3_linear_8994_63268)"
            stroke="url(#paint4_linear_8994_63268)"
            strokeWidth="7"
          />
          <path // Left circle to intercooler
            xmlns="http://www.w3.org/2000/svg"
            d="M489 417L181.469 416.031C175.958 416.014 171.5 411.542 171.5 406.031V366.5C171.5 360.977 167.023 356.5 161.5 356.5H153.5C147.977 356.5 143.5 352.023 143.5 346.5V346.09C143.5 340.532 148.032 336.04 153.589 336.09L189.456 336.41C194.988 336.46 199.5 331.988 199.5 326.455V326.455C199.5 320.957 195.043 316.5 189.545 316.5H153.5C147.977 316.5 143.5 312.023 143.5 306.5V306.5C143.5 300.977 147.977 296.5 153.5 296.5H189.5C195.023 296.5 199.5 292.023 199.5 286.5V286.5C199.5 280.977 195.023 276.5 189.5 276.5H153.5C147.977 276.5 143.5 272.023 143.5 266.5V266.5C143.5 260.977 147.977 256.5 153.5 256.5H189.5C195.023 256.5 199.5 252.023 199.5 246.5V246.5C199.5 240.977 195.023 236.5 189.5 236.5H153.5C147.977 236.5 143.5 232.023 143.5 226.5V226.5C143.5 220.977 147.977 216.5 153.5 216.5H189.5C195.023 216.5 199.5 212.023 199.5 206.5V206.5C199.5 200.977 195.023 196.5 189.5 196.5H153.5C147.977 196.5 143.5 192.023 143.5 186.5V186.5C143.5 180.977 147.977 176.5 153.5 176.5H189.5C195.023 176.5 199.5 172.023 199.5 166.5V166.5C199.5 160.977 195.023 156.5 189.5 156.5H153.5C147.977 156.5 143.5 152.023 143.5 146.5V146.5C143.5 140.977 147.977 136.5 153.5 136.5H189.5C195.023 136.5 199.5 132.023 199.5 126.5V126.5C199.5 120.977 195.023 116.5 189.5 116.5H181.5C175.977 116.5 171.5 112.023 171.5 106.5V73.5C171.5 67.9772 175.977 63.5 181.5 63.5H339.5C345.023 63.5 349.5 67.9772 349.5 73.5V109"
            stroke={
              mode === "HEATING"
                ? colorsSchema.cooling.fill
                : mode === "COOLING"
                ? colorsSchema.heating.fill
                : colorsSchema.idle.fill_cooling
            }
            strokeWidth="7"
          />
          <rect // Pump valve part
            x="340"
            y="124"
            width="43"
            height="8"
            rx="1.4"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          {mode === "COOLING" ? (
            <path // cold valve open
              xmlns="http://www.w3.org/2000/svg"
              d="M361.5 108V121.25C361.5 124.7018 364.298 127.5 367.75 127.5V127.5C371.202 127.5 374 124.7018 374 121.25V108"
              stroke={colorsSchema.cooling.fill}
              strokeWidth="7"
            />
          ) : (
            <path // cold valve closed
              xmlns="http://www.w3.org/2000/svg"
              d="M348.5 108V121.25C348.5 124.7018 351.298 127.5 354.75 127.5V127.5C358.202 127.5 361 124.7018 361 121.25V108"
              stroke={mode === "IDLE" ? colorsSchema.idle.fill_cooling : colorsSchema.cooling.fill}
              strokeWidth="7"
            />
          )}
          {mode === "COOLING" ? (
            <path // hot valve open
              xmlns="http://www.w3.org/2000/svg"
              d="M348.5 121C348.5 129.067 361.5 123.2 361.5 132"
              stroke={colorsSchema.heating.fill}
              strokeWidth="7"
            />
          ) : (
            <path // hot valve closed
              xmlns="http://www.w3.org/2000/svg"
              d="M361.5 132C361.5 123.933 374.5 129.8 374.5 121"
              stroke={mode === "IDLE" ? colorsSchema.idle.fill_heating : colorsSchema.heating.fill}
              strokeWidth="7"
            />
          )}
          <path // pump backward
            xmlns="http://www.w3.org/2000/svg"
            d="M362 108V67.5C362 61.9772 366.477 57.5 372 57.5H395.5C401.023 57.5 405.5 61.9772 405.5 67.5V272.5C405.5 278.023 401.023 282.5 395.5 282.5H356"
            stroke={mode === "IDLE" ? colorsSchema.idle.fill_cooling : colorsSchema.cooling.fill}
            strokeWidth="7"
          />
          <path // Right circle to radiators
            d="M374 112V40C374 34.4772 378.477 30 384 30H657.5C663.023 30 667.5 34.4772 667.5 40V168.5M667.5 168.5H658.5C652.977 168.5 648.5 172.977 648.5 178.5V178.5C648.5 184.023 652.977 188.5 658.5 188.5H676.5C682.023 188.5 686.5 192.977 686.5 198.5V198.5C686.5 204.023 682.023 208.5 676.5 208.5H658.5C652.977 208.5 648.5 212.977 648.5 218.5V218.5C648.5 224.023 652.977 228.5 658.5 228.5H676.5C682.023 228.5 686.5 232.977 686.5 238.5V238.5C686.5 244.023 682.023 248.5 676.5 248.5H658.5C652.977 248.5 648.5 252.977 648.5 258.5V258.5C648.5 264.023 652.977 268.5 658.5 268.5H676.5C682.023 268.5 686.5 272.977 686.5 278.5V278.5C686.5 284.023 682.023 288.5 676.5 288.5H658.5C652.977 288.5 648.5 292.977 648.5 298.5V298.5C648.5 304.023 652.977 308.5 658.5 308.5H667.5M667.5 168.5H810.5M667.5 308.5V407C667.5 412.523 663.023 417 657.5 417H507M667.5 308.5H810.5"
            stroke={
              mode === "COOLING"
                ? colorsSchema.cooling.fill
                : mode === "HEATING"
                ? colorsSchema.heating.fill
                : colorsSchema.idle.fill_heating
            }
            strokeWidth="7"
          />
          <mask
            xmlns="http://www.w3.org/2000/svg"
            id="mask0_8994_63268"
            maskUnits="userSpaceOnUse"
            x="370"
            y="26"
            width="441"
            height="395"
            {...{ "mask-type": "alpha" }}
          >
            <path
              d="M374 112V40C374 34.4772 378.477 30 384 30H657.5C663.023 30 667.5 34.4772 667.5 40V168.5M667.5 168.5H658.5C652.977 168.5 648.5 172.977 648.5 178.5V178.5C648.5 184.023 652.977 188.5 658.5 188.5H676.5C682.023 188.5 686.5 192.977 686.5 198.5V198.5C686.5 204.023 682.023 208.5 676.5 208.5H658.5C652.977 208.5 648.5 212.977 648.5 218.5V218.5C648.5 224.023 652.977 228.5 658.5 228.5H676.5C682.023 228.5 686.5 232.977 686.5 238.5V238.5C686.5 244.023 682.023 248.5 676.5 248.5H658.5C652.977 248.5 648.5 252.977 648.5 258.5V258.5C648.5 264.023 652.977 268.5 658.5 268.5H676.5C682.023 268.5 686.5 272.977 686.5 278.5V278.5C686.5 284.023 682.023 288.5 676.5 288.5H658.5C652.977 288.5 648.5 292.977 648.5 298.5V298.5C648.5 304.023 652.977 308.5 658.5 308.5H667.5M667.5 168.5H810.5M667.5 308.5V407C667.5 412.523 663.023 417 657.5 417H507M667.5 308.5H810.5"
              stroke="#F79009"
              strokeWidth="7"
            />
          </mask>
          <g xmlns="http://www.w3.org/2000/svg" mask="url(#mask0_8994_63268)">
            <rect x="642" y="165" width="175" height="147" fill="url(#paint5_linear_8994_63268)" />
          </g>
          <rect
            x="345"
            y="108"
            width="9"
            height="16"
            rx="1"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          <rect
            x="357.5"
            y="108"
            width="9"
            height="16"
            rx="1"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          <rect
            x="369.5"
            y="108"
            width="9"
            height="16"
            rx="1"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          <rect
            x="357"
            y="132"
            width="9"
            height="16"
            rx="1"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          <rect x="343" y="205" width="37" height="5" rx="0.5" fill="#98A2B3" />
          <rect x="339" y="209" width="45" height="5" rx="0.5" fill="#667085" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M380 214H343V218.416C341.234 219.187 340 220.949 340 223L338.5 223C338.224 223 338 223.223 338 223.5V230.5C338 230.776 338.224 231 338.5 231H340V313H335.7C335.313 313 335 313.313 335 313.7V318.3C335 318.686 335.313 319 335.7 319H387.3C387.687 319 388 318.686 388 318.3V313.7C388 313.313 387.687 313 387.3 313H383V223C383 220.949 381.766 219.187 380 218.416V214Z"
            fill="#98A2B3"
          />
          <circle cx="361.5" cy="292.5" r="15.5" fill="white" />
          <text
            x="361"
            y="293"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="8"
            fontWeight={700}
            fontFamily="Inter"
            fill={colors.colorsTextTextPrimary}
          >
            {mode === "IDLE" ? "0Hz" : `${refrigerantParameters?.compressorSpeed.value || "0"}Hz`}
          </text>
          <text
            x="363"
            y="336"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              ...typography[".text-md-medium"],
              fill: colors.colorsTextTextPrimary,
              textTransform: "none",
            }}
          >
            {`${others?.outdoorUnitCurrent.value || "0"} A`}
          </text>
          <mask id="path-26-inside-1_8994_63268" fill="white">
            <g>
              <path d="M375.837 292.5C376.48 292.5 377.005 293.021 376.956 293.661C376.691 297.198 375.218 300.551 372.765 303.146C370.045 306.024 366.327 307.752 362.374 307.975C358.421 308.198 354.532 306.9 351.505 304.347C348.479 301.794 346.544 298.179 346.099 294.245C345.653 290.31 346.73 286.355 349.108 283.189C351.486 280.023 354.986 277.888 358.889 277.221C362.792 276.554 366.802 277.406 370.097 279.602C373.068 281.583 375.254 284.521 376.305 287.909C376.495 288.522 376.1 289.148 375.474 289.291C374.848 289.435 374.229 289.042 374.031 288.431C373.119 285.622 371.284 283.188 368.807 281.537C366.007 279.67 362.598 278.946 359.281 279.513C355.963 280.08 352.988 281.895 350.967 284.586C348.945 287.276 348.03 290.639 348.409 293.983C348.788 297.327 350.432 300.4 353.005 302.57C355.577 304.74 358.883 305.843 362.243 305.654C365.603 305.464 368.763 303.995 371.075 301.549C373.12 299.386 374.364 296.603 374.624 293.661C374.68 293.021 375.195 292.5 375.837 292.5Z" />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 361.5 292.5"
                to="360 361.5 292.5"
                dur="2s"
                repeatCount="indefinite"
              />
            </g>
          </mask>
          {mode !== "IDLE" && refrigerantParameters?.compressorSpeed.value !== 0 && (
            <path
              d="M375.837 292.5C376.48 292.5 377.005 293.021 376.956 293.661C376.691 297.198 375.218 300.551 372.765 303.146C370.045 306.024 366.327 307.752 362.374 307.975C358.421 308.198 354.532 306.9 351.505 304.347C348.479 301.794 346.544 298.179 346.099 294.245C345.653 290.31 346.73 286.355 349.108 283.189C351.486 280.023 354.986 277.888 358.889 277.221C362.792 276.554 366.802 277.406 370.097 279.602C373.068 281.583 375.254 284.521 376.305 287.909C376.495 288.522 376.1 289.148 375.474 289.291C374.848 289.435 374.229 289.042 374.031 288.431C373.119 285.622 371.284 283.188 368.807 281.537C366.007 279.67 362.598 278.946 359.281 279.513C355.963 280.08 352.988 281.895 350.967 284.586C348.945 287.276 348.03 290.639 348.409 293.983C348.788 297.327 350.432 300.4 353.005 302.57C355.577 304.74 358.883 305.843 362.243 305.654C365.603 305.464 368.763 303.995 371.075 301.549C373.12 299.386 374.364 296.603 374.624 293.661C374.68 293.021 375.195 292.5 375.837 292.5Z"
              stroke="#779A19"
              strokeWidth="6"
              mask="url(#path-26-inside-1_8994_63268)"
            />
          )}
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M491.559 403.789L495.101 408.789C495.194 408.921 495.346 409 495.509 409H503.491C503.654 409 503.806 408.921 503.899 408.789L507.441 403.789C507.676 403.458 507.439 403 507.033 403H491.967C491.561 403 491.324 403.458 491.559 403.789Z"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />

          <rect
            x="500"
            y="420.5"
            width="7"
            height="12"
            rx="1"
            transform="rotate(-90 500 420.5)"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          <rect
            x="487"
            y="420.5"
            width="7"
            height="12"
            rx="1"
            transform="rotate(-90 487 420.5)"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          <rect
            x="495"
            y="421"
            width="9"
            height="25"
            rx="1"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray300
                : colors.componentColorsUtilityGrayUtilityGray400
            }
          />
          <rect
            x="495"
            y="409"
            width="9"
            height="16"
            rx="1"
            fill={
              mode === "IDLE"
                ? colors.componentColorsUtilityGrayUtilityGray400
                : colors.componentColorsUtilityGrayUtilityGray500
            }
          />
          <text
            x="33"
            y="48"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`TA = ${space?.outdoorTemp?.value || "0"} °C`}
          </text>
          <text
            x="33"
            y="410"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`${t("fanLabel")} 1 = ${refrigerantParameters?.fanSpeed1.value || "0"} rpm`}
          </text>
          <text
            x="33"
            y="438"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`${t("fanLabel")} 2 = ${refrigerantParameters?.fanSpeed2.value || "0"} rpm`}
          </text>
          <text
            x="227"
            y="359"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`TP = ${refrigerantParameters?.evaporatingTempTP.value || "0"} °C`}
          </text>
          <text
            x="459"
            y="391"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`EEV = ${refrigerantParameters?.eevOpening.value || "0"}`}
          </text>
          <text
            x="335"
            y="162"
            textAnchor="end"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`Pd = ${refrigerantParameters?.highPressurePd.value || "0"} bar`}
          </text>
          <text
            x="335"
            y="190"
            textAnchor="end"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`Td = ${refrigerantParameters?.compressorDischargeTempTD.value || "0"} °C`}
          </text>
          <text
            x="711"
            y="148"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`TUO = ${hydraulicParameters?.condenserOutletWaterTempTUO.value || "0"} °C`}
          </text>
          <text
            x="692"
            y="403"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`TUP = ${refrigerantParameters?.condensingTempTUP.value || "0"} °C`}
          </text>
          <text
            x="711"
            y="194"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`${t("flowLabel")} = ${hydraulicParameters?.waterFlowRate.value || "0"} l/min`}
          </text>
          <text
            x="711"
            y="293"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`TUI = ${hydraulicParameters?.condenserInletWaterTempTUI.value || "0"} °C`}
          </text>
          <text
            x="429"
            y="162"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`Ps = ${refrigerantParameters?.lowPressurePs.value || "0"} bar`}
          </text>
          <text
            x="429"
            y="190"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {`Ts = ${refrigerantParameters?.compressorSuctionTempTS.value || "0"} °C`}
          </text>
          <text
            x="429"
            y="306"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight={500}
            fill="#101828"
          >
            {/*TODO Stan: Have 'Step' translated is decide to keep it*/}
            {t("setPointLabel", { step: refrigerantParameters?.calculatedCompSpeed.value || "0" })}
          </text>
          <path
            d="M341.5 160.5C341.224 160.5 341 160.724 341 161C341 161.276 341.224 161.5 341.5 161.5V160.5ZM358.833 161C358.833 162.473 360.027 163.667 361.5 163.667C362.973 163.667 364.167 162.473 364.167 161C364.167 159.527 362.973 158.333 361.5 158.333C360.027 158.333 358.833 159.527 358.833 161ZM341.5 161.5H361.5V160.5H341.5V161.5Z"
            fill="black"
          />
          <path
            d="M665.333 401.5C665.333 402.973 666.527 404.167 668 404.167C669.473 404.167 670.667 402.973 670.667 401.5C670.667 400.027 669.473 398.833 668 398.833C666.527 398.833 665.333 400.027 665.333 401.5ZM688 402C688.276 402 688.5 401.776 688.5 401.5C688.5 401.224 688.276 401 688 401V402ZM668 402H688V401H668V402Z"
            fill="#101828"
          />
          <path
            d="M200.333 357.5C200.333 358.973 201.527 360.167 203 360.167C204.473 360.167 205.667 358.973 205.667 357.5C205.667 356.027 204.473 354.833 203 354.833C201.527 354.833 200.333 356.027 200.333 357.5ZM223 358C223.276 358 223.5 357.776 223.5 357.5C223.5 357.224 223.276 357 223 357V358ZM203 358H223V357H203V358Z"
            fill="#101828"
          />
          <path
            d="M402.333 161.5C402.333 162.973 403.527 164.167 405 164.167C406.473 164.167 407.667 162.973 407.667 161.5C407.667 160.027 406.473 158.833 405 158.833C403.527 158.833 402.333 160.027 402.333 161.5ZM425 162C425.276 162 425.5 161.776 425.5 161.5C425.5 161.224 425.276 161 425 161V162ZM405 162H425V161H405V162Z"
            fill="#101828"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M380.333 305C380.333 306.473 381.527 307.667 383 307.667C384.473 307.667 385.667 306.473 385.667 305C385.667 303.527 384.473 302.333 383 302.333C381.527 302.333 380.333 303.527 380.333 305ZM423 305.5C423.276 305.5 423.5 305.276 423.5 305C423.5 304.724 423.276 304.5 423 304.5V305.5ZM383 305.5H423V304.5H383V305.5Z"
            fill="#101828"
          />
          <defs>
            <linearGradient
              id="paint0_linear_8994_63268"
              x1="115"
              y1="238.313"
              x2="145"
              y2="238.312"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EAECF0" />
              <stop offset="1" stopColor="#ABABAB" />
            </linearGradient>
            <linearGradient // Intercooler gradient
              id="paint1_linear_8994_63268"
              x1="129"
              y1="239"
              x2="215"
              y2="239"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                stopColor={
                  mode === "HEATING"
                    ? colorsSchema.cooling.gradient.start
                    : mode === "COOLING"
                    ? colorsSchema.heating.gradient.start
                    : colorsSchema.idle.gradient.start
                }
              />
              <stop
                offset="1"
                stopColor={
                  mode === "HEATING"
                    ? colorsSchema.cooling.gradient.end
                    : mode === "COOLING"
                    ? colorsSchema.heating.gradient.end
                    : colorsSchema.idle.gradient.end
                }
              />
            </linearGradient>
            <linearGradient
              id="paint2_linear_8994_63268"
              x1="129"
              y1="236.188"
              x2="215.002"
              y2="236.735"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD1D3" />
              <stop offset="1" stopColor="#BFC1C4" />
            </linearGradient>
            <linearGradient
              xmlns="http://www.w3.org/2000/svg"
              id="paint3_linear_8994_63268"
              x1="633"
              y1="239"
              x2="701"
              y2="239"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                stopColor={
                  mode === "COOLING"
                    ? colorsSchema.cooling.gradient.start
                    : mode === "HEATING"
                    ? colorsSchema.heating.gradient.start
                    : colorsSchema.idle.gradient.start
                }
              />
              <stop
                offset="1"
                stopColor={
                  mode === "COOLING"
                    ? colorsSchema.cooling.gradient.end
                    : mode === "HEATING"
                    ? colorsSchema.heating.gradient.end
                    : colorsSchema.idle.gradient.end
                }
              />
            </linearGradient>
            <linearGradient
              id="paint4_linear_8994_63268"
              x1="633"
              y1="237.313"
              x2="701"
              y2="237.883"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD1D3" />
              <stop offset="1" stopColor="#BFC1C4" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_8994_63268"
              x1="667"
              y1="165"
              x2="667.392"
              y2="312"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset="0.45"
                stopColor={
                  mode === "COOLING"
                    ? colorsSchema.cooling.fill
                    : mode === "HEATING"
                    ? colorsSchema.heating.fill
                    : colorsSchema.idle.fill_heating
                }
              />
              <stop
                offset="0.55"
                stopColor={
                  mode === "HEATING"
                    ? colorsSchema.cooling.fill
                    : mode === "COOLING"
                    ? colorsSchema.heating.fill
                    : colorsSchema.idle.fill_heating
                }
              />
            </linearGradient>
          </defs>
        </svg>
      </Grid>
      {realTimeDataState?.refrigerantParameters?.defrostingActive && (
        <Grid item pb={2} px={2} alignItems="center">
          <SystemMessageBox type="info" message={t("defrostingMessage")} useBackground={false} />
        </Grid>
      )}
    </Grid>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    wrapper: {
      alignItems: "center",
      border: "solid 1px",
      overflowX: "auto",
      backgroundColor: colors.colorsBackgroundBgActive,
      borderColor: colors.componentColorsUtilityGrayUtilityGray200,
      borderRadius: radius.radiusXl,
      flexDirection: "column",
    } as SxProps,
    holder: {
      position: "relative",
      width: { xs: "100%", md: "893px" },
      minWidth: { xs: "600px", md: "auto" },
    } as SxProps,
  };
};
