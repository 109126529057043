import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import useUserAppPairingTab from "./hooks/useUserAppPairingTab";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import {
  Button,
  ButtonType,
} from "../../../../../../../../components/Button/Button";
import useTheme, {
  ESThemeType,
} from "../../../../../../../../theme/hooks/useTheme";
import TabWrapper from "../../../../TabWrapper";
import UnpairModal from "../../../../components/UnpairModal/UnpairModal";

export interface IUserAppPairingTabProps {
  isInstallationOffline?: boolean;
}

export default function UserAppPairingTab({
  isInstallationOffline,
}: IUserAppPairingTabProps) {
  const theme = useTheme();

  const {
    mainTitleText,
    userAppTitleText,
    unpairButtonText,
    unpairedText,
    isPaired,
    isUnpairModalOpen,
    setIsUnpairModalOpen,
    onUnpairInstallation,
  } = useUserAppPairingTab();

  const styles = getStyles(theme);
  return (
    <TabWrapper hideActions>
      <MainTitle title={mainTitleText} />
      <>
        <SecondaryTitle title={userAppTitleText} variant="md" />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isPaired ? (
            <Typography sx={styles.pairedText}>{unpairedText}</Typography>
          ) : (
            <Button
              buttonType={ButtonType.ErrorPrimary}
              label={`${unpairButtonText}`}
              onClick={() => setIsUnpairModalOpen(true)}
              disabled={isInstallationOffline}
            />
          )}
        </Box>
      </>
      {isUnpairModalOpen && (
        <UnpairModal
          isOpen={isUnpairModalOpen}
          onCancel={() => setIsUnpairModalOpen(false)}
          onOk={onUnpairInstallation}
        />
      )}
    </TabWrapper>
  );
}

const getStyles = ({ colors, typography, spacing }: ESThemeType) => ({
  pairedText: {
    ...typography[".text-sm-medium"],
    color: colors.colorsTextTextPrimary,
    mr: spacing.spacingLg,
  },
});
