import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export default function DisabledField({
  value,
  label,
}: {
  value?: string;
  label?: string;
}) {
  const theme = useTheme();

  return (
    value && (
      <Box sx={{ mb: theme.spacing.spacingXl }}>
        <Typography
          sx={{
            ...theme.typography[".text-sm-medium"],
            color: theme.colors.colorsTextTextSecondary,
            mb: theme.spacing.spacingSm,
          }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "40px",
            width: "fit-content",
            minWidth: "89px",
            border: `1px solid ${theme.colors.colorsBorderBorderDisabled}`,
            borderRadius: "8px",
            backgroundColor: theme.colors.colorsBackgroundBgDisabled,
            p: `0 ${theme.spacing.spacingLg}`,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextDisabled,
            }}
          >
            {value || ""}
          </Typography>
        </Box>
      </Box>
    )
  );
}
