import React, { useState } from "react";
import MainContainer from "../../../components/MainContainer/MainContainer";
import { Box, CssBaseline, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import AcceptNewOrganization from "./AcceptNewOrganization";
import AcceptExistOrganization from "./AcceptExistOrganization";
import { useTranslation } from "react-i18next";
import useTheme from "../../../theme/hooks/useTheme";
import { Button } from "../../../components/Button/Button";
import { CheckCircle } from "untitledui-js-base";
import ChangeLanguageDialog from "../../../components/ChangeLanguage/ChangeLanguageDialog";

export default function AcceptInvitation() {
  const { companyType, token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.company_organizations" });
  const { t: tActions } = useTranslation("cloud_ui", { keyPrefix: "actions" });
  const theme = useTheme();

  const [isSuccess, showSuccess] = useState(false);
  const [isRegularLogin, showLogin] = useState(false);

  const closeSuccess = () => {
    showSuccess(false);
    if (companyType === "new") {
      showLogin(true);
    } else {
      navigate("/portal/organizations");
    }
  };

  const onAcceptExistOrg = () => {
    if (isRegularLogin) {
      navigate("/portal/organizations");
    } else {
      showSuccess(true);
    }
  };

  return (
    <MainContainer
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          margin: { xs: "4em auto 0", md: "8em auto 0" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: isSuccess ? "360px" : "440px",
        }}
      >
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <img
            src="/logomark.svg"
            alt={"Energy Save logo"}
            style={{ maxWidth: "300px", marginLeft: "-12px" }}
          />
        </Box>
        {companyType === "new" && !isRegularLogin ? (
          <AcceptNewOrganization token={token} onSuccess={() => showSuccess(true)} />
        ) : (
          <AcceptExistOrganization
            token={token}
            onSuccess={onAcceptExistOrg}
            isRegularLogin={isRegularLogin}
          />
        )}
      </Box>
      <Dialog open={isSuccess} fullWidth={true} sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}>
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            marginBottom: theme.spacing.spacing4xl,
            padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              backgroundColor: theme.colors.componentColorsUtilitySuccessUtilitySuccess100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacingXl,
            }}
          >
            <CheckCircle
              size="20"
              style={{
                color: theme.colors.componentColorsUtilitySuccessUtilitySuccess600,
              }}
              strokeWidth={2}
            />
          </Box>
          <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
            {t("accept_success.title")}
          </Typography>
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            label={`${tActions("ok")}`}
            onClick={closeSuccess}
            sx={{
              display: "flex",
              ml: "0 !important",
              width: `calc(100% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </Dialog>
      <ChangeLanguageDialog />
    </MainContainer>
  );
}
