import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IPointsDifferencesProps } from "../../../types";

import DifferencesRow from "../../../../../components/Differences/components/DifferencesRow/DifferencesRow";
import DifferencesSecondaryTitle from "../../../../../components/Differences/components/DifferencesSecondaryTitle/DifferencesSecondaryTitle";

export default function PointsDifferences({
  curveDifferences,
  buildBaseDifferenceValue,
}: IPointsDifferencesProps) {
  const { t: diffT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones.zoneDifferences",
  });
  const pointTitle: string = useMemo(() => diffT("titles.point"), [diffT]);

  const flowTempLabel: string = useMemo(
    () => diffT("labels.flowTemperature"),
    [diffT]
  );

  const outdoorTempLabel: string = useMemo(
    () => diffT("labels.outdoorTemperature"),
    [diffT]
  );
  return (
    curveDifferences &&
    Object.entries(curveDifferences).map(([key, point], index) => (
      <Fragment key={key}>
        <DifferencesSecondaryTitle title={`${pointTitle} ${index + 1}`} />
        <DifferencesRow
          label={`${outdoorTempLabel} ${index + 1}`}
          value={buildBaseDifferenceValue(point.outdoorTemp)}
        />
        <DifferencesRow
          label={`${flowTempLabel} ${index + 1}`}
          value={buildBaseDifferenceValue(point.flowTemp)}
        />
      </Fragment>
    ))
  );
}
