import React from "react";

import useFlowTempLimits from "../../../hooks/flowTempLimits/useFlowTempLimits";

import SecondaryTitle from "../../../../../components/SecondaryTitle/SecondaryTitle";
import NumberInput from "../../../../../components/NumberInput/NumberInput";

import { IFlowTemperatureLimitsSectionsProps } from "../../../types";

export default function FlowTemperatureLimitsSection({
  type,
}: IFlowTemperatureLimitsSectionsProps) {
  const {
    isInstallationOffline,
    isHeating,
    mainTitle,
    maxFlowTemp,
    minFlowTemp,
    minFlowTempFieldLabel,
    maxFlowTempFieldLabel,
    updateInstallerZoneFormData,
  } = useFlowTempLimits(type);
  return (
    <>
      <SecondaryTitle title={mainTitle} variant="sm" />
      <NumberInput
        initialValue={minFlowTemp?.value}
        changeValue={(val) => {
          updateInstallerZoneFormData!(
            val,
            isHeating ? "minFlowHeatTemp" : "minFlowCoolTemp"
          );
        }}
        decimalPlaces={2}
        step={0.5}
        min={minFlowTemp?.min}
        max={minFlowTemp?.max}
        label={minFlowTempFieldLabel}
        disabled={isInstallationOffline}
      />
      <NumberInput
        initialValue={maxFlowTemp?.value}
        changeValue={(val) => {
          updateInstallerZoneFormData!(
            val,
            isHeating ? "maxFlowHeatTemp" : "maxFlowCoolTemp"
          );
        }}
        decimalPlaces={2}
        step={0.5}
        min={maxFlowTemp?.min}
        max={maxFlowTemp?.max}
        label={maxFlowTempFieldLabel}
        disabled={isInstallationOffline}
      />
    </>
  );
}
