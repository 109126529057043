import { ListItem, ListItemIcon, ListItemText, SxProps } from "@mui/material";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useTheme from "../../theme/hooks/useTheme";
import { ISideMenuItem } from "./SimpleSideMenu";

export default function SimpleSideMenuItem({
  menuItem,
}: {
  menuItem: ISideMenuItem;
}) {
  const theme = useTheme();
  const location = useLocation();

  const isNavItemActive =
    menuItem.href !== "/"
      ? location.pathname.startsWith(menuItem.href)
      : location.pathname === "/";

  const styles: Record<string, SxProps> = {
    wrapper: {
      ...theme.typography[".text-md-semibold"],
      padding: theme.spacing.spacingMd,
      color: theme.colors.colorsTextTextSecondary,
      borderRadius: "10px",
      minHeight: "40px",
      mb: theme.spacing.spacingXs,
      ".MuiListItemIcon-root": {
        color: isNavItemActive
          ? theme.colors.colorsTextTextBrandTertiaryAlt
          : "inherit",
      },
      ".MuiTypography-root": {
        color: isNavItemActive
          ? theme.colors.colorsTextTextBrandTertiaryAlt
          : "inherit",
      },
      backgroundColor: isNavItemActive
        ? theme.colors.colorsBackgroundBgActive
        : "inital",
    },
    icon: {
      minWidth: "fit-content",
      mr: theme.spacing.spacingLg,
    },
    text: {
      ...theme.typography[".text-md-semibold"],
      color: theme.colors.colorsTextTextSecondary,
      fontWeight: "600 !important",
    },
  };
  return (
    <ListItem
      button
      component={RouterLink}
      to={menuItem.href}
      sx={styles.wrapper}
    >
      <ListItemIcon sx={styles.icon}>{menuItem.icon}</ListItemIcon>
      <ListItemText
        sx={styles.text}
        primary={menuItem.label}
        primaryTypographyProps={{
          style: {
            fontWeight: 600,
          },
        }}
      />
    </ListItem>
  );
}
