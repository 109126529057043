import { useTranslation } from "react-i18next";
import { IInstallationUserSettings } from "../../../../../../../../../store/services/models/installations/installationSettings";

export interface ISeasonTabConditionsAndValuesToDisplayParameters {
  seasonSettingsFormData: IInstallationUserSettings | null;
}

export default function useSeasonTabConditionsAndValuesToDisplay({
  seasonSettingsFormData,
}: ISeasonTabConditionsAndValuesToDisplayParameters) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.userSettings.season",
  });

  const { t: tabNameT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.tabsNames",
  });

  const { t: baseT } = useTranslation("cloud_ui");

  const mainTitleText = t("mainTitle");
  const seasonStartStopConditionsTitleText = t(
    "seasonStartStopConditionsTitle"
  );
  const coolingSeasonStartStopText = t("fieldsLabels.coolingSeasonStartStop");
  const heatingSeasonStartStopText = t("fieldsLabels.heatingSeasonStartStop");
  const differenceHintText = t("fieldsHints.differenceHint");

  const coolingSeasonStartStopHintText = t(
    "fieldsHints.coolingSeasonStartStop",
    {
      value: seasonSettingsFormData?.coolingSeasonStartTemp.max,
    }
  );
  const heatingSeasonStartStopHintText = t(
    "fieldsHints.heatingSeasonStartStop",
    {
      value: seasonSettingsFormData?.heatingSeasonStartTemp.min,
    }
  );

  const tabNameLabelText = tabNameT("userSettings");
  const subTabNameLabelText = tabNameT("season");
  const commonErrorForRequestText = baseT("errors.somethingWentWrong");

  const isInputsShown =
    seasonSettingsFormData?.coolingSeasonStartTemp.value !== undefined &&
    seasonSettingsFormData?.heatingSeasonStartTemp.value !== undefined;

  return {
    mainTitleText,
    seasonStartStopConditionsTitleText,
    coolingSeasonStartStopText,
    heatingSeasonStartStopText,
    coolingSeasonStartStopHintText,
    heatingSeasonStartStopHintText,
    differenceHintText,
    tabNameLabelText,
    subTabNameLabelText,
    commonErrorForRequestText,
    isInputsShown,
  };
}
