import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../../store";
import { useToast } from "../../../../../../../../../hooks/useToast";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../../store/services/installation";
import { useEffect, useMemo, useState } from "react";
import {
  IAdditionalInlineHeatingSource,
  IInstallationInstallerSettings,
  IInstallationInstallerSettingsComissioning,
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningAdditionalInlineHeatingSource,
} from "../../../../../../../../../store/services/models/installations/installationSettings";
import {
  deepClone,
  findDifferences,
  transformDifferencesForRequest,
} from "../../../../../helpers/settingsHelpers";
import { IAdditionalInlineHeatingSourceDifferences } from "../types/types";
import { ToastStatuses } from "../../../../../../../../../components/Toaster/StatusIcon/Types";
import { useTranslation } from "react-i18next";

interface IUseAdditionalHeatingSourceThresholdsProps {
  isInstallationOffline?: boolean;
}

export default function useAdditionalHeatingSourceThresholds({
  isInstallationOffline,
}: IUseAdditionalHeatingSourceThresholdsProps) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds",
  });

  const { t: tabNameT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.tabsNames",
  });

  const { t: baseT } = useTranslation("cloud_ui");

  const { id: installationUuid } = useParams();
  const dispatch = useAppDispatch();

  const { showToast } = useToast();

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] =
    useState<boolean>(true);

  //form data states
  const [
    additionalHeatingSourceThresholdsFormData,
    setAdditionalHeatingSourceThresholdsFormData,
  ] = useState<Partial<IAdditionalInlineHeatingSource> | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false);

  //modals states
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const poolingInterval = isPoolingIntervalEnabled ? 60000 : 0;
  const {
    data: installationSettings,
    isLoading: isInstallationSettingsLoading,
  } = useGetInstallationSettingsQuery(
    { uuid: installationUuid! },
    { pollingInterval: poolingInterval }
  );

  const additionalHeatingSourceThresholdsData =
    installationSettings?.installerSettings?.commissioning
      ?.additionalInlineHeatingSource;

  const additionalHeatingSourceThresholdsFormValueDifferencesWithInitialValue: IAdditionalInlineHeatingSourceDifferences | null =
    useMemo(() => {
      if (
        !additionalHeatingSourceThresholdsFormData ||
        !additionalHeatingSourceThresholdsData
      )
        return null;
      return findDifferences({
        current: additionalHeatingSourceThresholdsFormData,
        prev: additionalHeatingSourceThresholdsData,
      });
    }, [
      additionalHeatingSourceThresholdsFormData,
      additionalHeatingSourceThresholdsData,
    ]);

  useEffect(() => {
    if (additionalHeatingSourceThresholdsData) {
      setAdditionalHeatingSourceThresholdsFormData(
        deepClone(additionalHeatingSourceThresholdsData)
      );
    }
  }, [additionalHeatingSourceThresholdsData]);

  useEffect(() => {
    const hasChanges = Boolean(
      additionalHeatingSourceThresholdsFormValueDifferencesWithInitialValue
    );
    setIsSomethingChanged(hasChanges);
    setIsPoolingIntervalEnabled(!hasChanges);
  }, [additionalHeatingSourceThresholdsFormValueDifferencesWithInitialValue]);

  const onAdditionalHeatingSourceThresholdsChange = (
    value: number,
    key: string
  ) => {
    setAdditionalHeatingSourceThresholdsFormData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [key]: {
          ...(prev[key as keyof IAdditionalInlineHeatingSource] as object),
          value,
        },
      };
    });
  };

  const onDiscardChanges = () => {
    setAdditionalHeatingSourceThresholdsFormData(
      deepClone(additionalHeatingSourceThresholdsData)
    );
  };

  const onSaveChanges = () => {
    setIsConfirmChangesModalOpen(true);
  };

  const confirmFormChanges = (commonErrorForRequestText: string) => {
    setIsConfirmChangesModalOpen(false);
    const additionalHeatingSourceThresholdsFormattedChanges: Partial<ISetInstallerSettignsComissioningAdditionalInlineHeatingSource> =
      transformDifferencesForRequest(
        additionalHeatingSourceThresholdsFormValueDifferencesWithInitialValue
      );
    let requestBody: Partial<ISetInstallationSettings> = {};
    let cacheData: IInstallationSettingsByIdResponse;
    if (additionalHeatingSourceThresholdsFormattedChanges) {
      requestBody = {
        installerSettings: {
          commissioning: {
            additionalInlineHeatingSource: {
              ...(additionalHeatingSourceThresholdsFormattedChanges as ISetInstallerSettignsComissioningAdditionalInlineHeatingSource),
            },
          },
        },
      };
    }
    cacheData = {
      ...(installationSettings as IInstallationSettingsByIdResponse),
      installerSettings: {
        ...(installationSettings!
          .installerSettings as IInstallationInstallerSettings),
        commissioning: {
          ...(installationSettings!.installerSettings!
            .commissioning as IInstallationInstallerSettingsComissioning),
          additionalInlineHeatingSource: {
            ...(installationSettings!.installerSettings!.commissioning!
              .additionalInlineHeatingSource as IAdditionalInlineHeatingSource),
            outdoorThresholdTemp:
              additionalHeatingSourceThresholdsFormData!.outdoorThresholdTemp!,
            heatBalanceForHeating:
              additionalHeatingSourceThresholdsFormData!.heatBalanceForHeating!,
          },
        },
      },
    };
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: installationUuid!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: installationUuid! },
            (draftSettings) => {
              Object.assign(draftSettings, cacheData);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((_error) => {
        showToast({
          title: commonErrorForRequestText,
          status: ToastStatuses.Error,
        });
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  const mainTitleText = t("mainTitle");
  const outdoorThresholdTempLabel = t("fieldsLabels.outdorTempThreshold");
  const heatBalanceForHeatingLabel = t(
    "fieldsLabels.heatBalanceThresholdForHeating"
  );

  const commonErrorForRequestText = baseT("commonErrorForRequestText");
  const tabNameLabelText = tabNameT(
    "installerAdditionalHeatingSourcePrioritiesAndThresholds"
  );

  const isOutdoorThresholdShown =
    additionalHeatingSourceThresholdsFormData?.outdoorThresholdTemp?.value !==
    undefined;

  const isHeatBalanceForHeatingShown =
    additionalHeatingSourceThresholdsFormData?.heatBalanceForHeating?.value !==
    undefined;

  return {
    additionalHeatingSourceThresholdsData,
    additionalHeatingSourceThresholdsFormData,
    isSomethingChanged,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    additionalHeatingSourceThresholdsFormValueDifferencesWithInitialValue,
    setIsSomethingChanged,
    setIsConfirmChangesModalOpen,
    setIsNotificationModalOpen,
    onAdditionalHeatingSourceThresholdsChange,
    onDiscardChanges,
    onSaveChanges,
    confirmFormChanges,
    mainTitleText,
    outdoorThresholdTempLabel,
    heatBalanceForHeatingLabel,
    isOutdoorThresholdShown,
    isHeatBalanceForHeatingShown,
    isInstallationSettingsLoading,
    tabNameLabelText,
    commonErrorForRequestText,
  };
}
