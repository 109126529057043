import { useContext } from "react";
import { IInstallationBaseValueWithMeasurement } from "../../../../../../../../store/services/models/installations/installationsCommon";
import { FlowTemperatureSettingsTypes } from "../../types";
import InstallationZonesContext from "../../context/InstallationZonesContext";
import { useTranslation } from "react-i18next";

export default function useFlowTempLimits(type: FlowTemperatureSettingsTypes) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });
  const {
    isInstallationOffline,
    zoneKeys,
    installerSettingsFormData,
    updateInstallerZoneFormData,
  } = useContext(InstallationZonesContext);
  const isHeating: boolean = type === FlowTemperatureSettingsTypes.Heating;
  const isCooling: boolean = type === FlowTemperatureSettingsTypes.Cooling;

  const maxFlowTemp: IInstallationBaseValueWithMeasurement | undefined =
    isHeating
      ? installerSettingsFormData?.maxFlowHeatTemp
      : installerSettingsFormData?.maxFlowCoolTemp;
  const minFlowTemp: IInstallationBaseValueWithMeasurement | undefined =
    isHeating
      ? installerSettingsFormData?.minFlowHeatTemp
      : installerSettingsFormData?.minFlowCoolTemp;

  const mainTitle: string = `${t("zoneTitle")} ${zoneKeys?.zoneNumber} - ${t(
    "flowTemperatureLimits"
  )} - ${t(isHeating ? "Heating" : "Cooling")}`;

  const minFlowTempFieldLabel: string = t(
    "fieldsLabels.flowTemperatureMinLimit"
  );

  const maxFlowTempFieldLabel: string = t(
    "fieldsLabels.flowTemperatureMaxLimit"
  );

  return {
    isInstallationOffline,
    isHeating,
    isCooling,
    maxFlowTemp,
    minFlowTemp,
    mainTitle,
    minFlowTempFieldLabel,
    maxFlowTempFieldLabel,
    updateInstallerZoneFormData,
  };
}
