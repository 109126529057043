import en from './resources/en.json'
import sv from './resources/sv.json'
import de from './resources/de.json';
import da from './resources/da.json';
import sl from './resources/sl.json';
import bg from './resources/bg.json';
import cs from './resources/cs.json';
import es from './resources/es.json';
import et from './resources/et.json';
import fi from './resources/fi.json';
import fr from './resources/fr.json';
import hu from './resources/hu.json';
import it from './resources/it.json';
import lt from './resources/lt.json';
import lv from './resources/lv.json';
import nl from './resources/nl.json';
import pl from './resources/pl.json';
import ro from './resources/ro.json';
import no from './resources/no.json';
import uk from './resources/uk.json';

import alertEn from './resources/alerts/en.json'
import alertDe from './resources/alerts/de.json';
import alertDa from './resources/alerts/da.json';
import alertSv from './resources/alerts/sv.json';
import alertSl from './resources/alerts/sl.json';
import alertBg from './resources/alerts/bg.json';
import alertCs from './resources/alerts/cs.json';
import alertEs from './resources/alerts/es.json';
import alertEt from './resources/alerts/et.json';
import alertFi from './resources/alerts/fi.json';
import alertFr from './resources/alerts/fr.json';
import alertHu from './resources/alerts/hu.json';
import alertIt from './resources/alerts/it.json';
import alertLt from './resources/alerts/lt.json';
import alertLv from './resources/alerts/lv.json';
import alertNl from './resources/alerts/nl.json';
import alertPl from './resources/alerts/pl.json';
import alertRo from './resources/alerts/ro.json';
import alertNo from './resources/alerts/no.json';
import alertUk from './resources/alerts/uk.json';

import { policyEN } from './resources/privacyPolicy/en'

import { termsEN } from './resources/terms&conditions/en';
import { termsSV } from './resources/terms&conditions/sv';

const en_res = { cloud_ui: { alerts: alertEn, policy: policyEN, terms: termsEN, ...en }};
const sv_res = { cloud_ui: { alerts: alertSv, policy: null, terms: termsSV, ...sv }};
const de_res = { cloud_ui: { alerts: alertDe, policy: null, terms: null, ...de }};
const da_res = { cloud_ui: { alerts: alertDa, policy: null, terms: null, ...da } };
const sl_res = { cloud_ui: { alerts: alertSl, policy: null, terms: null, ...sl } };
const bg_res = { cloud_ui: { alerts: alertBg, policy: null, terms: null, ...bg } };
const cs_res = { cloud_ui: { alerts: alertCs, policy: null, terms: null, ...cs } };
const es_res = { cloud_ui: { alerts: alertEs, policy: null, terms: null, ...es } };
const et_res = { cloud_ui: { alerts: alertEt, policy: null, terms: null, ...et } };
const fi_res = { cloud_ui: { alerts: alertFi, policy: null, terms: null, ...fi } };
const fr_res = { cloud_ui: { alerts: alertFr, policy: null, terms: null, ...fr } };
const hu_res = { cloud_ui: { alerts: alertHu, policy: null, terms: null, ...hu } };
const it_res = { cloud_ui: { alerts: alertIt, policy: null, terms: null, ...it } };
const lt_res = { cloud_ui: { alerts: alertLt, policy: null, terms: null, ...lt } };
const lv_res = { cloud_ui: { alerts: alertLv, policy: null, terms: null, ...lv } };
const nl_res = { cloud_ui: { alerts: alertNl, policy: null, terms: null, ...nl } };
const pl_res = { cloud_ui: { alerts: alertPl, policy: null, terms: null, ...pl } };
const ro_res = { cloud_ui: { alerts: alertRo, policy: null, terms: null, ...ro } };
const no_res = { cloud_ui: { alerts: alertNo, policy: null, terms: null, ...no } };
const uk_res = { cloud_ui: { alerts: alertUk, policy: null, terms: null, ...uk } };

export type Translations = typeof en_res;

export const translations = {
    en: en_res,
    sv: sv_res,
    de: de_res,
    da: da_res,
    sl: sl_res,
    bg: bg_res,
    cs: cs_res,
    es: es_res,
    et: et_res,
    fi: fi_res,
    fr: fr_res,
    hu: hu_res,
    it: it_res,
    lt: lt_res,
    lv: lv_res,
    nl: nl_res,
    pl: pl_res,
    ro: ro_res,
    no: no_res,
    uk: uk_res,
};