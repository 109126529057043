import { useEffect, useMemo, useState } from "react";
import {
  InstallationIndoorSensorStateEnum,
  InstallationZoneOperationModeEnum,
  IZoneComissioningValues,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import {
  IInstallationInstallerZonesDifferences,
  IUseInstallationInstallerZone,
} from "../../types";
import { IInstallationBaseValueWithMeasurement } from "../../../../../../../../store/services/models/installations/installationsCommon";
import {
  deepClone,
  findDifferences,
} from "../../../../helpers/settingsHelpers";
import { IBaseDifferencesObject } from "../../../../types/commonTypes";

export default function useInstallationInstallerZone({
  isInstallerZones,
  installationSettingsResponse,
  zoneOperationInstallerSettingsData,
}: IUseInstallationInstallerZone) {
  const [installerSettingsFormData, setInstallerSettingsFromData] =
    useState<IZoneComissioningValues | null>(null);
  const [zonesCountData, setZonesCountData] = useState<number>(0);
  const [
    zonesCountDataDifferencesWithInitialZonesCount,
    setZonesCountDataDifferencesWithInitialZonesCount,
  ] = useState<IBaseDifferencesObject | null>(null);

  useEffect(() => {
    if (isInstallerZones && zoneOperationInstallerSettingsData) {
      setInstallerSettingsFromData(
        deepClone(zoneOperationInstallerSettingsData)
      );
    }
  }, [zoneOperationInstallerSettingsData, isInstallerZones]);

  useEffect(() => {
    setZonesCountData(
      installationSettingsResponse?.installerSettings?.commissioning?.zonesCount
        ?.value || 0
    );
  }, [
    installationSettingsResponse?.installerSettings?.commissioning?.zonesCount
      ?.value,
  ]);

  useEffect(() => {
    const initialZonesCount =
      installationSettingsResponse?.installerSettings?.commissioning?.zonesCount
        ?.value;
    setZonesCountDataDifferencesWithInitialZonesCount(
      initialZonesCount !== zonesCountData
        ? {
            changedValue: {
              value: zonesCountData,
            } as IInstallationBaseValueWithMeasurement,
            defaultValue: {
              value: initialZonesCount,
            } as IInstallationBaseValueWithMeasurement,
          }
        : null
    );
  }, [
    zonesCountData,
    installationSettingsResponse?.installerSettings?.commissioning?.zonesCount
      ?.value,
  ]);

  const installerZoneFormValueDifferencesWithInitialValue: IInstallationInstallerZonesDifferences | null =
    useMemo(() => {
      if (!installerSettingsFormData || !zoneOperationInstallerSettingsData)
        return null;
      return findDifferences({
        current: installerSettingsFormData,
        prev: zoneOperationInstallerSettingsData,
      });
    }, [installerSettingsFormData, zoneOperationInstallerSettingsData]);

  const updateZonesCount = (valueFromSwitcher: boolean) => {
    setZonesCountData(valueFromSwitcher ? 2 : 1);
  };

  const updateInstallerZoneMode = (
    newValue: InstallationZoneOperationModeEnum
  ) => {
    setInstallerSettingsFromData(
      (prev) => ({ ...prev, mode: newValue } as any)
    );
  };

  const updateInstallerIndoorSensor = (
    newValue: InstallationIndoorSensorStateEnum
  ) => {
    setInstallerSettingsFromData(
      (prev) =>
        ({
          ...prev,
          indoorSensor: newValue,
        } as any)
    );
  };

  const updateInstallerZoneFormData = (
    newValue: number,
    propertyName: string
  ) => {
    setInstallerSettingsFromData(
      (prev) =>
        ({
          ...prev,
          [propertyName]: {
            ...(prev as any)[propertyName],
            value: newValue,
          },
        } as any)
    );
  };

  const discardZoneCountChanges = () => {
    setZonesCountData(
      installationSettingsResponse?.installerSettings?.commissioning?.zonesCount
        ?.value || 0
    );
  };

  const discardInstallerZoneSettingsFormChanges = () => {
    setInstallerSettingsFromData(deepClone(zoneOperationInstallerSettingsData));
  };

  return {
    installerSettingsFormData,
    zonesCountData,
    zonesCountDataDifferencesWithInitialZonesCount,
    installerZoneFormValueDifferencesWithInitialValue,
    updateZonesCount,
    updateInstallerZoneMode,
    updateInstallerIndoorSensor,
    updateInstallerZoneFormData,
    discardZoneCountChanges,
    discardInstallerZoneSettingsFormChanges,
  };
}
