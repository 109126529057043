import { createContext } from "react";
import { IDropdownWithExtendedOptionsItem } from "../../DropdownWithExtendedOptions/types";
import {
  ISupportFormData,
  ISupportFormErrors,
  Priorities,
} from "../hooks/types";

type IChangePriority = ((nePpriority: Priorities) => void) | null;
type IChangeDescription = ((newDescription: string) => void) | null;
type IChangeImages = ((images: IPreview[]) => void) | null;
type IHandleSendClick = (() => void) | null;
type IHandleCancelClick = ((onClose: () => void) => void) | null;

interface ISupportFormContext {
  priorities: IDropdownWithExtendedOptionsItem[];
  supportFormData: ISupportFormData | null;
  supportFormErrors: ISupportFormErrors | null;
  hints: string[];
  changePriority: IChangePriority;
  changeDescription: IChangeDescription;
  changeImages: IChangeImages;
  handleSendClick: IHandleSendClick;
  handleCancelClick: IHandleCancelClick;
}

const SupportFormContext = createContext<ISupportFormContext>({
  priorities: [],
  supportFormData: null,
  supportFormErrors: null,
  hints: [],
  changePriority: null,
  changeDescription: null,
  changeImages: null,
  handleSendClick: null,
  handleCancelClick: null,
});

export default SupportFormContext;
