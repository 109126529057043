import { createContext } from "react";
import { IInstallationUserSettingsQuietMode } from "../../../../../../../../../store/services/models/installations/installationSettings";
const QuietModeContext = createContext<IQuietModeContext>({
  isInstallationOffline: false,
  quietModeSettingsData: undefined,
  quietModeSettingsFormData: null,
  quietModeSettingsHelperData: null,
  hoursDropdownOptions: [],
  onQuietModeScheduleChange: () => {},
  onQuietModeHelperDataChange: () => {},
  onApplyForAllDays: () => {},
});

export default QuietModeContext;

export interface IQuietModeContext {
  isInstallationOffline: boolean;
  quietModeSettingsData: IInstallationUserSettingsQuietMode | undefined;
  quietModeSettingsFormData: IInstallationUserSettingsQuietMode | null;
  quietModeSettingsHelperData: IInstallationUserSettingsQuietMode | null;
  hoursDropdownOptions: { label: string; value: number }[];
  onQuietModeScheduleChange: (value: number, key: string) => void;
  onQuietModeHelperDataChange: (value: number, key: string) => void;
  onApplyForAllDays: (start: number, length: number) => void;
}
