import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import useTheme from "../../../../theme/hooks/useTheme";
import { Button, ButtonType } from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";

export interface ITabWrapper {
  onDiscardPress?: () => void;
  onSaveChangesPress?: () => void;
  children: ReactNode;
  isDiscardDisabled?: boolean;
  isSaveDisabled?: boolean;
  topHeight?: string;
  disableScroll?: boolean;
  hideActions?: boolean;
}

export default function TabWrapper({
  onDiscardPress,
  onSaveChangesPress,
  children,
  isDiscardDisabled,
  isSaveDisabled,
  topHeight = "77",
  disableScroll,
  hideActions,
}: ITabWrapper) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: `calc(100% - ${hideActions ? 0 : topHeight}px)`,
          overflow: disableScroll ? "hidden" : "auto",
          mr: `-${theme.spacing.spacing2xl}`,
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
            backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(0,0,0,0.2)",
            minHeight: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.colors.colorsTextTextQuaternary,
          },
        }}
      >
        <Box
          sx={{
            p: `${theme.spacing.spacingMd} ${theme.spacing.spacing4xl} 0`,
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
      {!hideActions && (
        <Box
          sx={{
            height: "77px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            borderTop: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
            padding: `0 ${theme.spacing.spacing4xl}`,
          }}
        >
          <Button
            buttonType={ButtonType.SecondaryGray}
            label={`${t("pages.installationSettings.commonButtons.discard")}`}
            sx={{ mr: theme.spacing.spacingXl }}
            onClick={onDiscardPress}
            disabled={isDiscardDisabled}
          />
          <Button
            label={`${t(
              "pages.installationSettings.commonButtons.saveChanges"
            )}`}
            onClick={onSaveChangesPress}
            disabled={isSaveDisabled}
          />
        </Box>
      )}
    </Box>
  );
}
