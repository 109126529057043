import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import CheckboxWithLabel from "../CheckboxWithLabel/CheckboxWithLabel";
import { IDropdownContentProps } from "./types";
import useDropdownContentStyles from "./hooks/styles/useDropdownContentStyles";

export default function DropdownContent({
  dropdownRef,
  distanceToBottom,
  itemsForDropdown,
  selectedValues,
  dropdownCoords,
  buttonCoords,
  selectAllCheckedCondition,
  onSelectAllChange,
  selectAllLabel,
  onCheckboxChange,
  limitForSelect = 0,
}: IDropdownContentProps) {
  const { dropdownContentStyles } = useDropdownContentStyles({
    buttonCoords,
    dropdownCoords,
    distanceToBottom,
  });

  const memoizedDropdownContent = useMemo(() => {
    return (
      <Box ref={dropdownRef} sx={dropdownContentStyles.wrapper}>
        {!limitForSelect && (
          <CheckboxWithLabel
            checkedCondition={selectAllCheckedCondition}
            onChange={onSelectAllChange}
            label={selectAllLabel}
          />
        )}
        {itemsForDropdown?.map((section, sectionIndex) => {
          return (
            !section.sectionHidden && (
              <Box key={sectionIndex}>
                {section.sectionTitle && (
                  <Typography sx={dropdownContentStyles.sectionTitle}>
                    {section.sectionTitle}
                  </Typography>
                )}
                {section.itemsList.map(
                  (item, itemIndex) =>
                    !item.hidden && (
                      <CheckboxWithLabel
                        key={itemIndex}
                        checkedCondition={
                          !!selectedValues?.includes(item.value)
                        }
                        onChange={() => onCheckboxChange(item)}
                        label={item.label}
                        disabled={
                          limitForSelect > 0 &&
                          selectedValues?.length === limitForSelect &&
                          !selectedValues.includes(item.value)
                        }
                      />
                    )
                )}
              </Box>
            )
          );
        })}
      </Box>
    );
  }, [
    dropdownRef,
    selectAllCheckedCondition,
    onSelectAllChange,
    selectAllLabel,
    itemsForDropdown,
    selectedValues,
    limitForSelect,
    onCheckboxChange,
    dropdownContentStyles.sectionTitle,
    dropdownContentStyles.wrapper,
  ]);
  return memoizedDropdownContent;
}
