import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import DisabledField from "../../../../../components/DisabledField/DisabledField";
import SimpleDropdown from "../../../../../components/SimpleDropdown/SimpleDropdown";
import NumberInput from "../../../../../components/NumberInput/NumberInput";

import InstallationZonesContext from "../../../context/InstallationZonesContext";

import { InstallationIndoorSensorStateEnum } from "../../../../../../../../../store/services/models/installations/installationSettings";

export default function FlowTemperatureIndorSensorAndRoomTempSection() {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });
  const { t: untisT } = useTranslation("cloud_ui", { keyPrefix: "units" });
  const {
    isInstallerZones,
    staticValues,
    zoneFormData,
    installerSettingsFormData,
    isInstallationOffline,
    updateInstallerIndoorSensor,
    updateZoneFormData,
  } = useContext(InstallationZonesContext);

  const isIndorSensorConfigured: boolean =
    (isInstallerZones
      ? installerSettingsFormData?.indoorSensor
      : staticValues?.indoorSensor) !==
      InstallationIndoorSensorStateEnum.NONE &&
    (isInstallerZones
      ? installerSettingsFormData?.indoorSensor
      : staticValues?.indoorSensor) !==
      InstallationIndoorSensorStateEnum.UNDEFINED;

  const installerIndoorSensorDropdownValues = [
    {
      label: t(`indoorSensorState.${InstallationIndoorSensorStateEnum.NONE}`),
      value: InstallationIndoorSensorStateEnum.NONE,
    },
    {
      label: t(`indoorSensorState.${InstallationIndoorSensorStateEnum.WIRED}`),
      value: InstallationIndoorSensorStateEnum.WIRED,
    },
    {
      label: t(
        `indoorSensorState.${InstallationIndoorSensorStateEnum.WIRELESS_1}`
      ),
      value: InstallationIndoorSensorStateEnum.WIRELESS_1,
    },
    {
      label: t(
        `indoorSensorState.${InstallationIndoorSensorStateEnum.WIRELESS_2}`
      ),
      value: InstallationIndoorSensorStateEnum.WIRELESS_2,
    },
  ];

  return (
    <>
      {staticValues?.indoorSensor && !isInstallerZones && (
        <DisabledField
          label={`${t("fieldsLabels.sensor")}`}
          value={`${t(`indoorSensorState.${staticValues.indoorSensor}`)}`}
        />
      )}
      {isInstallerZones && installerSettingsFormData?.indoorSensor && (
        <SimpleDropdown
          disabled={isInstallationOffline}
          label={t("fieldsLabels.sensor")}
          currentValue={installerSettingsFormData?.indoorSensor}
          dropdownOptions={installerIndoorSensorDropdownValues}
          onChange={(val) => updateInstallerIndoorSensor!(val)}
        />
      )}

      {staticValues?.currentRoomTemp?.value !== undefined &&
        isIndorSensorConfigured && (
          <DisabledField
            label={`${t("fieldsLabels.roomTemperature")}`}
            value={`${staticValues.currentRoomTemp.value} ${untisT(
              staticValues.currentRoomTemp.unit
            )}`}
          />
        )}

      {zoneFormData?.preferredRoomTemp?.value !== undefined &&
        isIndorSensorConfigured && (
          <NumberInput
            initialValue={zoneFormData?.preferredRoomTemp?.value}
            changeValue={(val) => {
              updateZoneFormData!(val, "preferredRoomTemp");
            }}
            disabled={isInstallationOffline}
            min={zoneFormData?.preferredRoomTemp?.min || 10}
            max={zoneFormData?.preferredRoomTemp?.max || 30}
            decimalPlaces={1}
            step={0.5}
            label={`${t("fieldsLabels.desiredRoomTemperature")}`}
            hint={`${t("fieldHints.desiredRoomTemperature", {
              from: zoneFormData?.preferredRoomTemp?.min || "10",
              to: zoneFormData?.preferredRoomTemp?.max || "30",
            })}`}
          />
        )}
    </>
  );
}
