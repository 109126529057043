import { bg, cs, da, de, enUS, es, et, fi, fr, hu, lt, lv, nb, nl, pl, ro, sl, sv, uk, it } from "date-fns/locale";

export const locales = {
    en: enUS,
    sv: sv,
    de: de,
    da: da,
    sl: sl,
    bg: bg,
    cs: cs,
    es: es,
    et: et,
    fi: fi,
    fr: fr,
    hu: hu,
    it: it,
    lt: lt,
    lv: lv,
    nl: nl,
    pl: pl,
    ro: ro,
    no: nb,
    uk: uk,
}