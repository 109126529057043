import {
  IInstallationBaseValueWithMeasurement,
  ZoneOperationsKey,
} from "../../../../../../store/services/models/installations/installationsCommon";
import {
  IInstallationSettingsByIdResponse,
  IInstallationZoneOperation,
  InstallationComissioningZoneCircuitTypeEnum,
  InstallationIndoorSensorStateEnum,
  InstallationZoneOperationControllerEnum,
  InstallationZoneOperationModeEnum,
  IPointValues,
  IZoneComissioningValues,
  PointKey,
} from "../../../../../../store/services/models/installations/installationSettings";
import { IInstallationStateByIdResponse } from "../../../../../../store/services/models/installations/installationState";
import { IBaseDifferencesObject } from "../../types/commonTypes";

export enum FlowTemperatureSettingsTypes {
  Cooling = "Cooling",
  Heating = "Heating",
}

export enum Controllers {
  Cooling = "coolingController",
  Heating = "heatingController",
}

//props

export interface IInstallationZonesProps {
  isInstallationOffline?: boolean;
  baseUrl: string;
  isInstallerZones?: boolean;
}

export interface IInstallationZoneProps {
  isInstallerZones?: boolean;
  installationSettingsResponse:
    | IInstallationSettingsByIdResponse
    | undefined
    | null;
  installationStateResponse: IInstallationStateByIdResponse | undefined | null;
  isInstallationOffline: boolean;
  isLoadingSettings: boolean;
  isLoadingState: boolean;
  setIsPoolingIntervalEnabled: (value: boolean) => void;
}

export interface IFlowTemperatureSettingsSectionProps {
  type: FlowTemperatureSettingsTypes;
}

export interface IFlowTemperatureLimitsSectionsProps
  extends IFlowTemperatureSettingsSectionProps {}

export interface ICurveRowProps {
  title: string;
  outdorTempInputLabel: string;
  flowTempInputLabel: string;
  isInstallationOffline?: boolean;
  pointValue: IPointValues;
  limitValuesForOutdoorTemp: ILimitValuesForOutdoorTemp;
  onFlowTempChange: (newValue: number, tempKey: ZoneCurveTempKeys) => void;
}

export interface ICureveSectionProps {
  isCooling: boolean;
}

export interface IDefaultZoneDifferencesProps {
  differences: IInstallationZonesDifferences | null;
}

export interface IInstallerZoneDifferencesProps {
  zoneCountDifferences: IBaseDifferencesObject | null;
  installerDifferences: IInstallationInstallerZonesDifferences | null;
}

export interface IPointsDifferencesProps {
  curveDifferences: Record<PointKey, IPointsDifferencesObject> | undefined;
  buildBaseDifferenceValue: (
    difference: IBaseDifferencesObject | undefined
  ) => React.JSX.Element | null;
}

export interface IZoneDifferencesProps {
  differences: IInstallationZonesDifferences | null;
  zoneCountDifferences: IBaseDifferencesObject | null;
  installerDifferences: IInstallationInstallerZonesDifferences | null;
}

//props end

//hooks parameters
export interface IUseFlowTemSettingsConditionsParameters {
  type: FlowTemperatureSettingsTypes;
  zoneFormData: IInstallationZoneOperation | null;
  zoneNumber: string;
}

export interface IUseInstallationInstallerZone {
  isInstallerZones?: boolean;
  installationSettingsResponse:
    | IInstallationSettingsByIdResponse
    | undefined
    | null;
  zoneOperationInstallerSettingsData: IZoneComissioningValues | undefined;
}

export interface IUseInstallationInstallerZoneConditionsAndValuesFromEnumsParameters {
  zoneKeys: IZoneKeys;
  isInstallerZones?: boolean;
  zonesCountData: number;
}

export interface IUseInstallationZoneParameters {
  installationSettingsResponse:
    | IInstallationSettingsByIdResponse
    | undefined
    | null;
  installationStateResponse: IInstallationStateByIdResponse | undefined | null;
  isInstallationOffline: boolean;
}

export interface IUseInstallationZoneConditionsAndValuesFromEnumsParams {
  staticValues: IInstallationZoneStaticValues;
  isLoadingSettings: boolean;
  isLoadingState: boolean;
  zoneFormData: IInstallationZoneOperation | null;
  zoneKeys: IZoneKeys;
  isInstallerZones?: boolean;
  installerSettingsFormData: IZoneComissioningValues | null;
}

export interface IUseZoneAndInstallerZoneCommonParameters {
  isInstallerZones?: boolean;
  zoneFormValueDifferencesWithInitialValue: IInstallationZonesDifferences | null;
  zonesCountDataDifferencesWithInitialZonesCount: IBaseDifferencesObject | null;
  installerZoneFormValueDifferencesWithInitialValue: IInstallationInstallerZonesDifferences | null;
  installationSettingsResponse:
    | IInstallationSettingsByIdResponse
    | null
    | undefined;
  zoneFormData: IInstallationZoneOperation | null;
  zonesCountData: number;
  installerSettingsFormData: IZoneComissioningValues | null;
  setIsPoolingIntervalEnabled: (value: boolean) => void;
  discardFormChanges: () => void;
  discardZoneCountChanges: () => void;
  discardInstallerZoneSettingsFormChanges: () => void;
}

export interface IUseInstallationZonesParameters {
  baseUrl: string;
  isInstallerZones: boolean;
}
//hooks parameters end

export interface IZoneKeys {
  zoneOperationKey: ZoneOperationsKey;
  zoneNumber: string | undefined;
}

export interface IInstallationZoneStaticValues {
  mode: InstallationZoneOperationModeEnum | undefined;
  indoorSensor: InstallationIndoorSensorStateEnum | undefined;
  currentRoomTemp: IInstallationBaseValueWithMeasurement | undefined;
  circuitType: InstallationComissioningZoneCircuitTypeEnum | undefined;
}

export interface ILimitValuesForOutdoorTemp {
  min: number;
  max: number;
}

//differences types
export interface IControllerDifferencesObject {
  defaultValue: InstallationZoneOperationControllerEnum;
  changedValue: InstallationZoneOperationControllerEnum;
}

export interface IModeDifferencesObject {
  defaultValue: InstallationZoneOperationModeEnum;
  changedValue: InstallationZoneOperationModeEnum;
}

export interface IIndoorSensorDifferencesObject {
  defaultValue: InstallationIndoorSensorStateEnum;
  changedValue: InstallationIndoorSensorStateEnum;
}

export interface IPointsDifferencesObject {
  outdoorTemp?: IBaseDifferencesObject;
  flowTemp?: IBaseDifferencesObject;
}

export interface IInstallationZonesDifferences {
  heatingController?: IControllerDifferencesObject;
  coolingController?: IControllerDifferencesObject;
  preferredRoomTemp?: IBaseDifferencesObject;
  preferredFixedFlowHeatingTemp?: IBaseDifferencesObject;
  preferredFixedFlowCoolingTemp?: IBaseDifferencesObject;
  heatingCurve?: Record<PointKey, IPointsDifferencesObject>;
  coolingCurve?: Record<PointKey, IPointsDifferencesObject>;
}

export interface IInstallationInstallerZonesDifferences {
  indoorSensor?: IIndoorSensorDifferencesObject;
  maxFlowCoolTemp?: IBaseDifferencesObject;
  maxFlowHeatTemp?: IBaseDifferencesObject;
  minFlowCoolTemp?: IBaseDifferencesObject;
  minFlowHeatTemp?: IBaseDifferencesObject;
  mixingValveTurningTime?: IBaseDifferencesObject;
  mode?: IModeDifferencesObject;
}

//strings
export type ZoneCurveKeys = "heatingCurve" | "coolingCurve";

export type ZoneCurveTempKeys = "flowTemp" | "outdoorTemp";

//Context Types
export type IUpdateZoneController =
  | ((
      newValue: InstallationZoneOperationControllerEnum,
      propertyName: Controllers
    ) => void)
  | null;

export type IUpdateZoneFormData =
  | ((newValue: number, propertyName: string) => void)
  | null;

export type IUpdateTempInCurve =
  | ((
      newValue: number,
      curveType: ZoneCurveKeys,
      pointKey: PointKey,
      tempKey: ZoneCurveTempKeys
    ) => void)
  | null;
export type IUpdateInstallationMode =
  | ((newValue: InstallationZoneOperationModeEnum) => void)
  | null;

export type IUpdateInstallerIndoorSensor =
  | ((newValue: InstallationIndoorSensorStateEnum) => void)
  | null;

export interface IInstallationZonesContext {
  isInstallerZones?: boolean;
  installationSettingsResponse:
    | IInstallationSettingsByIdResponse
    | undefined
    | null;
  installationStateResponse: IInstallationStateByIdResponse | undefined | null;
  isInstallationOffline: boolean;
  staticValues: IInstallationZoneStaticValues | null;
  zoneFormData: IInstallationZoneOperation | null;
  installerSettingsFormData: IZoneComissioningValues | null;
  zoneKeys: IZoneKeys | null;
  updateZoneController: IUpdateZoneController;
  updateZoneFormData: IUpdateZoneFormData;
  updateInstallerZoneFormData: IUpdateZoneFormData;
  updateInstallerIndoorSensor: IUpdateInstallerIndoorSensor;
  updateInstallerZoneMode: IUpdateInstallationMode;
  updateTempInCurve: IUpdateTempInCurve;
}
