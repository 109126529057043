import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import useSimpleButtonStyles from "./useSimpleButtonStyles";

export default function SimpleButton({
  text,
  type,
  onClick,
  isDisabled,
  isLoading,
}: ISimpleButtonProps) {
  const { styles } = useSimpleButtonStyles(type);
  return (
    <Box
      sx={styles.wrapper}
      component={"button"}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <CircularProgress size={"20px"} />
      ) : (
        <Typography sx={styles.text}>{text}</Typography>
      )}
    </Box>
  );
}
