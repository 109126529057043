import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISupportFormData, ISupportFormErrors, Priorities } from "./types";
import { IDropdownWithExtendedOptionsItem } from "../../DropdownWithExtendedOptions/types";
import { useCreateSupportRequestForInstallationMutation } from "../../../store/services/installation";
import { useToast } from "../../../hooks/useToast";
import { ToastStatuses } from "../../Toaster/StatusIcon/Types";
import { useParams } from "react-router";

export default function useSupportData(onClose: () => void) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.support_form.modal",
  });

  const { id } = useParams();
  const { showToast } = useToast();

  const [createSupportRequest] =
    useCreateSupportRequestForInstallationMutation();

  const priorities: IDropdownWithExtendedOptionsItem[] = useMemo(
    () =>
      Object.keys(Priorities).map((priority) => ({
        value: Priorities[priority as keyof typeof Priorities],
        label: t(`dropdown.lables.${priority}`),
        description: t(`dropdown.descriptions.${priority}`),
      })),
    [t]
  );

  const hints: string[] = useMemo(
    () => [
      t("textarea.hints_list.list_item_1"),
      t("textarea.hints_list.list_item_2"),
      t("textarea.hints_list.list_item_3"),
    ],
    [t]
  );

  const initialSupportFormData: ISupportFormData = {
    priority: Priorities.NORMAL,
    description: "",
    images: [],
  };

  const initalSupportFormErrors: ISupportFormErrors = {
    description: "",
  };

  const [supportFormData, setSupportFormData] = useState<ISupportFormData>(
    initialSupportFormData
  );

  const [supportFormErrors, setSupportFormErrors] =
    useState<ISupportFormErrors>(initalSupportFormErrors);

  const [isRequestSendingInPropgress, setIsRequestSendingInProgress] =
    useState<boolean>(false);

  const resetForm = () => {
    setSupportFormData(initialSupportFormData);
    setSupportFormErrors(initalSupportFormErrors);
  };

  const changePriority = (value: Priorities) => {
    setSupportFormData({ ...supportFormData, priority: value });
  };

  const changeDescription = (value: string) => {
    if (supportFormErrors.description) {
      setSupportFormErrors({ ...supportFormErrors, description: "" });
    }
    setSupportFormData({ ...supportFormData, description: value });
  };

  const changeImages = (images: IPreview[]) => {
    setSupportFormData({ ...supportFormData, images: [...images] });
  };

  const handleSendClick = () => {
    setIsRequestSendingInProgress(true);
    if (supportFormData.description.length < 5) {
      setSupportFormErrors({
        ...supportFormErrors,
        description: t("textarea.errors.min_characters"),
      });
      setIsRequestSendingInProgress(false);
      return;
    }

    createSupportRequest({
      installationId: id!,
      request: {
        priority: supportFormData.priority,
        description: supportFormData.description,
      },
      files: supportFormData.images.map((image) => image.fileData),
    })
      .unwrap()
      .then(() => {
        showToast({
          status: ToastStatuses.Success,
          title: t("toaster.success.title"),
          text: t("toaster.success.text"),
        });
        onClose();
        resetForm();
      })
      .catch(() => {
        showToast({
          status: ToastStatuses.Error,
          title: t("toaster.error.title"),
          text: t("toaster.error.text"),
        });
      })
      .finally(() => {
        setIsRequestSendingInProgress(false);
      });
  };

  const handleCancelClick = () => {
    resetForm();
    onClose();
  };

  return {
    priorities,
    hints,
    supportFormData,
    supportFormErrors,
    isRequestSendingInPropgress,
    changePriority,
    changeDescription,
    changeImages,
    handleSendClick,
    handleCancelClick,
  };
}
