import { Box, SxProps } from "@mui/material";
import React from "react";
import PreviewCard from "../PreviewCard/PreviewCard";

export default function PreviewsList({
  previews,
  removePreview,
}: IPreviewList) {
  const styles: Record<string, SxProps> = {
    listWrapper: {
      display: "flex",
      flexWrap: "wrap",
      gap: "16px",
      mt: "16px",
    },
  };

  return (
    <Box sx={styles.listWrapper}>
      {previews.map((file, index) => (
        <PreviewCard
          key={index}
          file={file}
          removePreview={(file) => removePreview(file, index)}
        />
      ))}
    </Box>
  );
}
