import { useMemo } from "react";
import useTheme from "../../../../theme/hooks/useTheme";
import { SxProps } from "@mui/material";
import { getScrollStyles } from "../../../../styles/stylesHelpers/scrollStyles";

export interface IUseDropdownContentStylesParameters {
  buttonCoords: DOMRect | null;
  dropdownCoords: DOMRect | null;
  distanceToBottom: string | null;
}

export default function useDropdownContentStyles({
  buttonCoords,
  dropdownCoords,
  distanceToBottom,
}: IUseDropdownContentStylesParameters) {
  const theme = useTheme();
  const { colors, radius, spacing, typography } = theme;
  const dropdownContentStyles: Record<"wrapper" | "sectionTitle", SxProps> =
    useMemo(
      () => ({
        wrapper: {
          ...getScrollStyles({ theme }),
          position: "fixed",
          border: `1px solid ${colors.colorsBorderBorderSecondary}`,
          borderRadius: radius.radiusMd,
          p: spacing.spacingSm,
          backgroundColor:
            colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
          boxShadow:
            "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
          minHeight: "60px",
          height: distanceToBottom,
          maxHeight: "400px",
          left: `calc(${buttonCoords?.left}px - ${dropdownCoords?.width}px + ${buttonCoords?.width}px)`,
          top: `calc(${buttonCoords?.top}px + 45px)`,
          zIndex: 999,
        },
        sectionTitle: {
          ...typography[".text-sm-semibold"],
          color: colors.colorsTextTextPrimary,
          ml: spacing.spacingXl,
          height: "40px",
          display: "flex",
          alignItems: "center",
        },
      }),
      [
        theme,
        colors,
        radius,
        spacing,
        typography,
        buttonCoords,
        dropdownCoords,
        distanceToBottom,
      ]
    );
  return { dropdownContentStyles };
}
