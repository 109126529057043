import { Box, Typography } from "@mui/material";
import React from "react";
import useRadioButtonWithLabelStyles from "./useRadioButtonWithLabelStyles";

export default function RadioButtonWithLabel({
  isDisabled = false,
  selected,
  label,
  onClick,
}: IRadioButtonWithLabelProps) {
  const { baseRadioIconStyles, selectedRadioIconStyles, styles } =
    useRadioButtonWithLabelStyles(isDisabled);

  return (
    <Box component={"button"} onClick={onClick} sx={styles.wrapper}>
      <Box
        sx={
          selected ? { ...selectedRadioIconStyles } : { ...baseRadioIconStyles }
        }
      />
      <Typography sx={styles.label}>{label}</Typography>
    </Box>
  );
}
