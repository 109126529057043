import { Box, SxProps, Typography } from "@mui/material";
import React, { useContext } from "react";
import DropdownWithExtendedOptions from "../../../DropdownWithExtendedOptions/DropdownWithExtendedOptions";
import TextArea from "../../../TextArea/TextArea";
import useTheme from "../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import SupportFormContext from "../../context/SupportFormContext";
import DescriptionTextAreaHintsList from "./DescriptionTextAreaHintsList";
import DropZone from "../../../DropZone/DropZone";
import typography from "../../../../styles/typography/textStyle";

export default function SupportFormContent() {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.support_form.modal",
  });
  const { colors, spacing } = useTheme();
  const styles: Record<string, SxProps> = {
    dropdownWrapper: {
      mb: spacing.spacing2xl,
    },
    dropZoneInnerLabelPt1: {
      ...typography[".text-sm-semibold"],
      mr: spacing.spacingSm,
      transition: "color .3s ease",
      color:
        colors.componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
      "&:hover": {
        color: colors.componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
      },
    },
  };

  const {
    priorities,
    supportFormData,
    supportFormErrors,
    changePriority,
    changeDescription,
    changeImages,
  } = useContext(SupportFormContext);

  const renderInnerLabelForDropzone = () => {
    return (
      <React.Fragment>
        <Typography sx={styles.dropZoneInnerLabelPt1}>
          {t("drop_zone.inner_label_pt1")}
        </Typography>
        {t("drop_zone.inner_label_pt2")}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Box sx={styles.dropdownWrapper}>
        <DropdownWithExtendedOptions
          items={priorities}
          defaultValue={supportFormData!.priority}
          onChange={changePriority!}
          label={t("dropdown.main_label")}
        />
      </Box>
      <TextArea
        defaultValue={supportFormData!.description}
        label={t("textarea.main_label")}
        placeholder={t("textarea.placeholder")}
        onChange={changeDescription!}
        isRequired
        error={supportFormErrors!.description}
      />
      <DescriptionTextAreaHintsList />
      <DropZone
        label={t("drop_zone.main_label")}
        innerLabel={renderInnerLabelForDropzone()}
        innerDescription={t("drop_zone.inner_description")}
        maxSizeMB={100}
        onUpload={changeImages!}
      />
    </React.Fragment>
  );
}
