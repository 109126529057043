import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import useTheme from '../../../../../../theme/hooks/useTheme'
import { Box, Checkbox, FormControlLabel, SxProps, Typography } from '@mui/material'
import { Check, CheckSquare, ChevronDown, ChevronUp, Square, XClose } from 'untitledui-js-base'
import useResize from '../../../../../../hooks/useResize'
import { Button } from '../../../../../../components/Button/Button'
import { getTextWidth } from '../../../../../../util/TextWidth'

export interface IItems {
  label: string
  value: string
  selected: boolean
}

export interface IDropdownWithCheckboxesProps {
  buttonLabel: string
  items: Array<IItems> | undefined
  onCheckboxesApply: (newValue: any) => void
  selectAllLabel: string
  applyButtonLabel: string
}

export default function DropdownWithCheckboxes({
  buttonLabel,
  items,
  onCheckboxesApply,
  selectAllLabel,
  applyButtonLabel,
}: IDropdownWithCheckboxesProps) {
  const { width, height } = useResize()
  const theme = useTheme()

  const baseCheckboxStyles: SxProps = {
    height: '16px',
    width: '16px',
    borderRadius: '5px',
    border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
  }

  const selectedCheckboxStyles: SxProps = {
    ...baseCheckboxStyles,
    border: `1px solid ${theme.colors.colorsBackgroundBgBrandSolid}`,
    backgroundColor: theme.colors.colorsBackgroundBgBrandSolid,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { colors, typography, spacing, radius } = theme

  const [buttonCoords, setButtonCoords] = useState<any>()
  const [dropdownCoords, setDropdownCoords] = useState<any>()

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const [itemsForDropdown, setItemsForDropdown] = useState<Array<IItems>>()

  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        if (((event as MouseEvent).target as any)?.id === 'dd_button') {
          return
        }
        setIsDropdownOpen(false)
        setItemsForDropdown(items)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [items])

  useEffect(() => {
    setItemsForDropdown(items)
  }, [items])

  useLayoutEffect(() => {
    if (buttonRef.current) {
      const coords = buttonRef.current.getBoundingClientRect()
      setButtonCoords(coords)
    }
  }, [width, isDropdownOpen])

  useLayoutEffect(() => {
    if (dropdownRef.current && isDropdownOpen) {
      const coords = dropdownRef.current!.getBoundingClientRect()

      setDropdownCoords(coords)
    }
  }, [isDropdownOpen])

  useEffect(() => {
    if (JSON.stringify(itemsForDropdown) !== JSON.stringify(items)) {
      setIsSomethingChanged(true)
    } else {
      setIsSomethingChanged(false)
    }
  }, [itemsForDropdown, items])

  return (
    <>
      <Box
        id="dd_button"
        ref={buttonRef}
        component={"button"}
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          backgroundColor: colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
          padding: `0 ${spacing.spacingLg}`,
          cursor: "pointer",
          width: `${Math.max(155, getTextWidth(buttonLabel, "14px Inter")+ (12*2)+ (20+8)*2 + 5)}px`,
          height: "40px",
          border: `1px solid ${
            items?.some((item) => item.selected)
              ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder
              : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder
          }`,
          borderRadius: radius.radiusMd,
        }}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
          if (isDropdownOpen) {
            setItemsForDropdown(items);
          }
        }}
      >
        {items?.some((item) => item.selected) ? (
          <CheckSquare
            size="20"
            style={{
              color: colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
              marginRight: spacing.spacingMd,
              pointerEvents: "none",
            }}
            strokeWidth={2}
          />
        ) : (
          <Square
            size="20"
            style={{
              color: colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
              marginRight: spacing.spacingMd,
              pointerEvents: "none",
            }}
            strokeWidth={2}
          />
        )}

        <Typography
          sx={{
            ...typography[".text-sm-semibold"],
            color: items?.some((item) => item.selected)
              ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
              : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            pointerEvents: "none",
          }}
        >
          {buttonLabel}
        </Typography>
        {isDropdownOpen ? (
          <ChevronUp
            size="20"
            style={{
              color: items?.some((item) => item.selected)
                ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
                : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
              marginLeft: spacing.spacingMd,
              pointerEvents: "none",
            }}
            strokeWidth={2}
          />
        ) : items?.some((item) => item.selected) ? (
          <XClose
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const updatedItems = itemsForDropdown?.map((item) => {
                return {
                  ...item,
                  selected: false,
                };
              });
              setItemsForDropdown(updatedItems);
              onCheckboxesApply(updatedItems);
            }}
            size="20"
            style={{
              color: colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
              marginLeft: spacing.spacingMd,
            }}
            strokeWidth={2}
          />
        ) : (
          <ChevronDown
            size="20"
            style={{
              color: items?.some((item) => item.selected)
                ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
                : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
              marginLeft: spacing.spacingMd,
              pointerEvents: "none",
            }}
            strokeWidth={2}
          />
        )}
      </Box>
      {isDropdownOpen && (
        <Box
          ref={dropdownRef}
          sx={{
            position: "fixed",
            border: `1px solid ${colors.colorsBorderBorderSecondary}`,
            borderRadius: radius.radiusMd,
            backgroundColor: colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
            boxShadow:
              "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
            height: "400px",
            //   minHeight: "118px",
            width: "400px",
            //   maxWidth: "466px",
            overflow: "hidden",
            left: `calc(${buttonCoords?.left}px - ${
              buttonCoords?.left > window.innerWidth / 2 ? dropdownCoords?.width : 0
            }px + ${buttonCoords?.left > window.innerWidth / 2 ? buttonCoords?.width : 0}px)`,
            top: `calc(${buttonCoords?.top}px + 45px)`,
            zIndex: 1,
          }}
        >
          {/* <Box sx={{ height: `calc(${dropdownCoords?.height}px - 72px)`, overflow: "auto" }}> */}
          <Box
            sx={{
              height: `calc(400px - 72px)`,
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
                backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: "rgba(0,0,0,0.2)",
                minHeight: "24px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.colors.colorsTextTextQuaternary,
              },
            }}
          >
            <Box
              key={"selectAll"}
              sx={{
                display: "flex",
                alignItems: "center",
                p: `${theme.spacing.spacingSm} ${theme.spacing.spacingXl}`,
                //   maxWidth: "350px",
                ".MuiTypography-root": {
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextSecondary,
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    //   checked={arraysAreEqual(allAvailableParameters, selected)}
                    checked={itemsForDropdown?.every((item) => item.selected)}
                    onChange={() => {
                      const updatedItems = itemsForDropdown?.map((item) => {
                        return {
                          ...item,
                          selected: itemsForDropdown?.every((item) => item.selected) ? false : true,
                        };
                      });
                      setItemsForDropdown(updatedItems);
                    }}
                    icon={<Box sx={baseCheckboxStyles} />}
                    checkedIcon={
                      <Box sx={selectedCheckboxStyles}>
                        <Check
                          style={{
                            color: theme.colors.colorsForegroundFgWhite,
                          }}
                          size="12"
                          strokeWidth={2}
                        />
                      </Box>
                    }
                  />
                }
                label={selectAllLabel}
              />
            </Box>
            {itemsForDropdown?.map((item) => {
              return (
                <Box
                  key={item.value}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: `${theme.spacing.spacingSm} ${theme.spacing.spacingXl}`,
                    //   maxWidth: "350px",
                    ".MuiTypography-root": {
                      ...theme.typography[".text-sm-medium"],
                      color: theme.colors.colorsTextTextSecondary,
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.selected}
                        onChange={() => {
                          const updatedItems = itemsForDropdown.map((currItem) => {
                            if (currItem.value === item.value) {
                              return {
                                ...currItem,
                                selected: !currItem.selected,
                              };
                            } else {
                              return currItem;
                            }
                          });
                          setItemsForDropdown(updatedItems);
                        }}
                        icon={<Box sx={baseCheckboxStyles} />}
                        checkedIcon={
                          <Box sx={selectedCheckboxStyles}>
                            <Check
                              style={{
                                color: theme.colors.colorsForegroundFgWhite,
                              }}
                              size="12"
                              strokeWidth={2}
                            />
                          </Box>
                        }
                      />
                    }
                    label={item.label}
                  />
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              padding: spacing.spacingXl,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              label={applyButtonLabel}
              disabled={!isSomethingChanged}
              onClick={() => {
                onCheckboxesApply(itemsForDropdown);
                setIsDropdownOpen(false);
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
