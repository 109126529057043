import { Box, SxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../theme/hooks/useTheme";
import { AlertCircle, CheckCircle } from "untitledui-js-base";
import {
  IGetSxForOutersParameters,
  IIconInformation,
  IStatusIconProps,
  ToastStatuses,
} from "./Types";

export default function StatusIcon({ status }: IStatusIconProps) {
  const getSxForOuters = ({
    opacity,
    size,
  }: IGetSxForOutersParameters): SxProps => {
    return {
      position: "relative",
      height: size,
      width: size,
      minWidth: size,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "50%",
        border: `2px solid ${iconInformation?.color}`,
        opacity: opacity,
      },
    };
  };

  const [iconInformation, setIconInformation] = useState<IIconInformation>();
  const theme = useTheme();

  useEffect(() => {
    if (status) {
      const baseProps = {
        size: "17",
        strokeWidth: 2,
      };
      switch (status) {
        case ToastStatuses.Success:
          setIconInformation({
            icon: (
              <CheckCircle
                {...baseProps}
                style={{ color: theme.colors.colorsForegroundFgBrandPrimary }}
              />
            ),
            color: theme.colors.colorsForegroundFgBrandPrimary,
          });
          break;
        case ToastStatuses.Error:
          setIconInformation({
            icon: (
              <AlertCircle
                {...baseProps}
                style={{ color: theme.colors.colorsForegroundFgErrorPrimary }}
              />
            ),
            color: theme.colors.colorsForegroundFgErrorPrimary,
          });
          break;
        default:
          break;
      }
    }
  }, [status]);

  return (
    <Box
      sx={{
        ...getSxForOuters({ opacity: ".3", size: "33px" }),
        mr: theme.spacing.spacingMd,
      }}
    >
      <Box
        sx={{
          ...getSxForOuters({ opacity: ".7", size: "25px" }),
        }}
      >
        {iconInformation?.icon && iconInformation.icon}
      </Box>
    </Box>
  );
}
