import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import commonSlice from "./reducers/commonSlice";
import authSlice from "./reducers/authSlice";
import installationSlice from "./reducers/installationSlice";
import { api } from "./services/api";
import { persistReducer, persistStore } from "redux-persist";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import storage from "redux-persist/lib/storage";
import organizationSlice from "./reducers/organizationSlice";
import parametersSlice  from "./reducers/parametersSlice";

const persistParametersConfig = {
  key: "parameters",
  version: 1,
  storage: storage,
};

const persistedParametersReducer = persistReducer(persistParametersConfig, parametersSlice);

const middlewareList: any = [api.middleware];

export const store = configureStore({
  reducer: {
    commonSlice,
    authSlice,
    installationSlice,
    organizationSlice,
    parametersSlice: persistedParametersReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewareList),
  devTools: process.env.NODE_ENV !== "production",
  // enhancers: getDefaultEnhancers => getDefaultEnhancers().concat([reactotron.createEnhancer!()]),
});

// export const store = createStore();
export const persistor = persistStore(store);
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
