import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Typography } from "@mui/material";

export default function SecondaryTitle({
  title,
  variant,
  color,
}: {
  title: string;
  variant: "sm" | "md";
  color?: string;
}) {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        ...theme.typography[`.text-${variant}-semibold`],
        color: color || theme.colors.colorsTextTextPrimary,
        m: `${theme.spacing.spacingXl} 0`,
      }}
    >
      {title}
    </Typography>
  );
}
