import { Box, CardMedia, SxProps, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import useTheme from "../../../../theme/hooks/useTheme";
import { File04, Trash04 } from "untitledui-js-base";

export default function PreviewCard({ file, removePreview }: IPreviewCard) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { colors, radius, spacing, typography } = useTheme();
  const styles: Record<string, SxProps> = useMemo(() => {
    return {
      wrapper: { position: "relative" },
      image: {
        width: "135px",
        height: "135px",
        objectFit: "cover",
        borderRadius: radius.radiusSm,
        border: `1px solid ${colors.colorsBorderBorderSecondary}`,
      },
      documentBox: {
        width: "135px",
        height: "135px",
        p: `0 ${spacing.spacingMd}`,
        borderRadius: radius.radiusSm,
        border: `1px solid ${colors.colorsBorderBorderSecondary}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        svg: {
          color: colors.colorsForegroundFgQuaternary,
          mb: spacing.spacingLg,
        },
      },
      documentNameText: {
        ...typography[".text-xs-regular"],
        color: colors.colorsForegroundFgQuaternary,
        whiteSpace: "nowrap",
        maxWidth: "120px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      mask: {
        position: "absolute",
        borderRadius: radius.radiusSm,
        transition: "top .5s ease, background-color .5s ease, opacity .5s ease",
        top: isHovered ? "0px" : "72px",
        backgroundColor: isHovered ? "rgba(0, 0, 0, .6)" : "transparent",
        opacity: isHovered ? 1 : 0,
        right: 0,
        bottom: 0,
        left: 0,
        visibility: isHovered ? "visible" : "none",
        p: spacing.spacingMd,
      },
      fileNameInMask: {
        ...typography[".text-xs-semibold"],
        color: colors.colorsTextTextWhite,
        maxHeight: "150px",
        overflow: "hidden",
        wordBreak: "break-all",
      },
      removeButton: {
        position: "absolute",
        border: "none",
        bgcolor: "initial",
        right: "5px",
        bottom: "5px",
        cursor: "pointer",
        transition: "opacity .8s ease",
        opacity: isHovered ? 1 : 0,
      },
    };
  }, [isHovered]);

  const isImageFile: boolean = file.fileData.type.startsWith("image");

  return (
    <Box
      sx={styles.wrapper}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isImageFile ? (
        <CardMedia
          component={"img"}
          src={file.previewSrc}
          alt={`preview-${file.fileData.name}`}
          sx={styles.image}
        />
      ) : (
        <Box sx={styles.documentBox}>
          <File04 size="40" strokeWidth={2} />
          <Typography sx={styles.documentNameText}>
            {file.fileData.name}
          </Typography>
        </Box>
      )}
      <Box sx={styles.mask}>
        <Typography sx={styles.fileNameInMask}>{file.fileData.name}</Typography>
      </Box>
      <Box
        component="button"
        sx={styles.removeButton}
        onClick={() => removePreview(file.fileData)}
      >
        <Trash04 size="20" strokeWidth={2} color={colors.colorsTextTextWhite} />
      </Box>
    </Box>
  );
}
