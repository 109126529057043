import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  IInstallationSettingsByIdResponse,
  IPriorities,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningPriorities,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import { Box, Divider, Typography } from "@mui/material";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import {
  baseFindDifferences,
  cleanValuesForRequest,
  isEmpty,
} from "../../../../helpers/findDifferences";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import NoParametersBlock from "../../../../NoParametersBlock";

export default function ShiftingPriorities({
  isInstallationOffline,
}: {
  isInstallationOffline?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] = useState<boolean>(true);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    },
  );

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(!!isInstallationOffline);
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [shiftingPrioritiesSettingsData, setShiftingPrioritiesSettingsData] =
    useState<IPriorities>();
  const [initialShiftingPrioritiesSettingsData, setInitialShiftingPrioritiesSettingsData] =
    useState<IPriorities>();

  const [initialSettings, setInitialSettings] = useState<IInstallationSettingsByIdResponse | null>(
    null,
  );

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] = useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsFieldsDisabled(!!isInstallationOffline);
  }, [isInstallationOffline]);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setShiftingPrioritiesSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings?.commissioning?.shiftingPriorities || {},
          ),
        ),
      );
      setInitialShiftingPrioritiesSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings?.commissioning?.shiftingPriorities || {},
          ),
        ),
      );
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(shiftingPrioritiesSettingsData) !==
      JSON.stringify(initialShiftingPrioritiesSettingsData)
    ) {
      setIsSomethingChanged(true);
      setIsPoolingIntervalEnabled(false);
    } else {
      setIsSomethingChanged(false);
      setIsPoolingIntervalEnabled(true);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [shiftingPrioritiesSettingsData]);

  const updateSettings = (field: string, value: any) => {
    const updatedSettings = { ...shiftingPrioritiesSettingsData };
    (updatedSettings as any)[field] = value;
    setShiftingPrioritiesSettingsData(updatedSettings as IPriorities);
  };

  const onDiscardChanges = () => {
    setShiftingPrioritiesSettingsData(
      JSON.parse(JSON.stringify(initialShiftingPrioritiesSettingsData)),
    );
    setIsPoolingIntervalEnabled(true);
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialShiftingPrioritiesSettingsData!,
      shiftingPrioritiesSettingsData!,
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newSettings: Partial<ISetInstallerSettignsComissioningPriorities> =
      cleanValuesForRequest(differencesToShow);

    return {
      installerSettings: {
        commissioning: {
          shiftingPriorities: newSettings,
        },
      },
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      commissioning: {
        ...(updatedSettings.installerSettings?.commissioning as any),
        shiftingPriorities: shiftingPrioritiesSettingsData,
      },
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            },
          ),
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  if (
    !settings ||
    isEmpty(settings?.installerSettings?.commissioning?.shiftingPriorities) ||
    isEmpty(shiftingPrioritiesSettingsData)
  ) {
    return <NoParametersBlock />;
  }
  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t("pages.installationSettings.installerSettings.shiftingPriorities.mainTitle")}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />
      {(shiftingPrioritiesSettingsData?.heating?.value ||
        shiftingPrioritiesSettingsData?.dhw?.value) && (
        <SecondaryTitle
          title={`${t("pages.installationSettings.installerSettings.shiftingPriorities.heating")}`}
          variant="md"
        />
      )}
      <NumberInput
        initialValue={shiftingPrioritiesSettingsData?.heating.value}
        changeValue={(val) =>
          updateSettings("heating", {
            ...shiftingPrioritiesSettingsData?.heating,
            value: val,
          })
        }
        decimalPlaces={0}
        unit={shiftingPrioritiesSettingsData?.heating?.unit}
        step={1}
        label={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.heatingMin",
        )}`}
        min={shiftingPrioritiesSettingsData?.heating.min}
        max={shiftingPrioritiesSettingsData?.heating.max}
        disabled={isFieldsDisabled}
      />
      <NumberInput
        initialValue={shiftingPrioritiesSettingsData?.dhw.value}
        changeValue={(val) =>
          updateSettings("dhw", {
            ...shiftingPrioritiesSettingsData?.dhw,
            value: val,
          })
        }
        decimalPlaces={0}
        unit={shiftingPrioritiesSettingsData?.dhw?.unit}
        step={1}
        label={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin",
        )}`}
        min={shiftingPrioritiesSettingsData?.dhw?.min}
        max={shiftingPrioritiesSettingsData?.dhw?.max}
        disabled={isFieldsDisabled}
      />
      {/* {(shiftingPrioritiesSettingsData?.cooling?.value ||
        shiftingPrioritiesSettingsData?.coolingDhw?.value) && (
        <Divider
          sx={{
            width: "100%",
            borderColor: theme.colors.colorsBorderBorderSecondary,
          }}
        />
      )}
      {(shiftingPrioritiesSettingsData?.cooling?.value ||
        shiftingPrioritiesSettingsData?.coolingDhw?.value) && (
        <SecondaryTitle
          title={`${t(
            "pages.installationSettings.installerSettings.shiftingPriorities.cooling"
          )}`}
          variant="md"
        />
      )}
      {shiftingPrioritiesSettingsData?.cooling?.value && (
        <NumberInput
          initialValue={shiftingPrioritiesSettingsData?.cooling?.value}
          changeValue={(val) =>
            updateSettings("coolingMinTemp", {
              ...shiftingPrioritiesSettingsData?.cooling,
              value: val,
            })
          }
          decimalPlaces={1}
          unit={""}
          step={0.5}
          label={`${t(
            "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.coolingMin"
          )}`}
          min={shiftingPrioritiesSettingsData?.cooling?.min}
          max={shiftingPrioritiesSettingsData?.cooling?.max}
        />
      )}
      {shiftingPrioritiesSettingsData?.coolingDhw?.value && (
        <NumberInput
          initialValue={shiftingPrioritiesSettingsData?.coolingDhw?.value}
          changeValue={(val) =>
            updateSettings("coolingDhwMinTemp", {
              ...shiftingPrioritiesSettingsData?.coolingDhw,
              value: val,
            })
          }
          decimalPlaces={1}
          unit={""}
          step={0.5}
          label={`${t(
            "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin"
          )}`}
          min={shiftingPrioritiesSettingsData?.coolingDhw?.min}
          max={shiftingPrioritiesSettingsData?.coolingDhw?.max}
        />
      )} */}
      <UnsavedChangesModal
        tabName={t("pages.installationSettings.tabsNames.installerShiftingPriorities")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t("pages.installationSettings.tabsNames.installerShiftingPriorities")}`}
          block={`${t("pages.installationSettings.tabsNames.installerSettings")}`}
          // showHeatPumpNote={differencesToShow.state}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <>
            {(differencesToShow.dhw || differencesToShow.heating) && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                }}
              >
                {t("pages.installationSettings.installerSettings.shiftingPriorities.heating")}
              </Typography>
            )}
            {differencesToShow?.heating && (
              <DefaultChange
                changedValue={differencesToShow?.heating.value.newValue}
                previousValue={differencesToShow?.heating.value.previousValue}
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.heatingMin",
                )}`}
              />
            )}
            {differencesToShow?.dhw && (
              <DefaultChange
                changedValue={differencesToShow?.dhw.value.newValue}
                previousValue={differencesToShow?.dhw.value.previousValue}
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin",
                )}`}
              />
            )}
            {(differencesToShow.dhw || differencesToShow.heating) && (
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
            )}

            {(differencesToShow.coolingMinTemp || differencesToShow.coolingDhwMinTemp) && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                  mt: theme.spacing.spacingXl,
                }}
              >
                {t("pages.installationSettings.installerSettings.shiftingPriorities.cooling")}
              </Typography>
            )}
            {differencesToShow?.coolingMinTemp && (
              <DefaultChange
                changedValue={differencesToShow?.coolingMinTemp?.value?.newValue}
                previousValue={differencesToShow?.coolingMinTemp?.value?.previousValue}
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.coolingMin",
                )}`}
              />
            )}
            {differencesToShow?.coolingDhwMinTemp && (
              <DefaultChange
                changedValue={differencesToShow?.coolingDhwMinTemp?.value?.newValue}
                previousValue={differencesToShow?.coolingDhwMinTemp?.value?.previousValue}
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin",
                )}`}
              />
            )}
          </>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
