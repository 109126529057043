import React from "react";
import TabWrapper from "../../../../TabWrapper";
import useAdditionalHeatingSourceThresholds from "./hooks/useAdditionalHeatingSourceThresholds";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import NoParametersBlock from "../../../../NoParametersBlock";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import useTabsIcons from "../../../../hooks/useTabsIcons";
import AdditionalHeatingSourceThresholdsDifferences from "./components/AdditionalHeatingSourceThresholdsDiff/AdditionalHeatingSourceThresholdsDiff";

interface IAdditionalHeatingSourceThresholdsProps {
  isInstallationOffline?: boolean;
}

export default function AdditionalHeatingSourceThresholds({
  isInstallationOffline,
}: IAdditionalHeatingSourceThresholdsProps) {
  const {
    additionalHeatingSourceThresholdsFormValueDifferencesWithInitialValue,
    isSomethingChanged,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    additionalHeatingSourceThresholdsFormData,
    confirmFormChanges,
    onAdditionalHeatingSourceThresholdsChange,
    onDiscardChanges,
    onSaveChanges,
    setIsNotificationModalOpen,
    setIsConfirmChangesModalOpen,
    mainTitleText,
    outdoorThresholdTempLabel,
    heatBalanceForHeatingLabel,
    isOutdoorThresholdShown,
    isHeatBalanceForHeatingShown,
    isInstallationSettingsLoading,
    tabNameLabelText,
    commonErrorForRequestText,
  } = useAdditionalHeatingSourceThresholds({ isInstallationOffline });

  const { additionalHeatingSourceThresholdsIcon } = useTabsIcons();

  if (
    !isInstallationSettingsLoading &&
    !additionalHeatingSourceThresholdsFormData
  ) {
    return <NoParametersBlock />;
  }

  return (
    <TabWrapper
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
    >
      <MainTitle title={mainTitleText} sx={{ mb: "15px" }} />
      {isOutdoorThresholdShown && (
        <NumberInput
          initialValue={
            additionalHeatingSourceThresholdsFormData?.outdoorThresholdTemp
              ?.value
          }
          changeValue={(val) =>
            onAdditionalHeatingSourceThresholdsChange(
              val,
              "outdoorThresholdTemp"
            )
          }
          decimalPlaces={1}
          step={0.5}
          label={outdoorThresholdTempLabel}
          min={
            additionalHeatingSourceThresholdsFormData?.outdoorThresholdTemp?.min
          }
          max={
            additionalHeatingSourceThresholdsFormData?.outdoorThresholdTemp?.max
          }
          disabled={isInstallationOffline}
        />
      )}
      {isHeatBalanceForHeatingShown && (
        <NumberInput
          initialValue={
            additionalHeatingSourceThresholdsFormData?.heatBalanceForHeating
              ?.value
          }
          changeValue={(val) =>
            onAdditionalHeatingSourceThresholdsChange(
              val,
              "heatBalanceForHeating"
            )
          }
          unit="°min"
          decimalPlaces={1}
          step={0.5}
          label={heatBalanceForHeatingLabel}
          min={
            additionalHeatingSourceThresholdsFormData?.heatBalanceForHeating
              ?.min
          }
          max={
            additionalHeatingSourceThresholdsFormData?.heatBalanceForHeating
              ?.max
          }
          disabled={isInstallationOffline}
        />
      )}
      <UnsavedChangesModal
        tabName={tabNameLabelText}
        condition={isSomethingChanged}
        discardChanges={onDiscardChanges}
      />
      <ConfirmChangesModal
        isOpen={isConfirmChangesModalOpen}
        onCancel={() => setIsConfirmChangesModalOpen(false)}
        onOk={() => confirmFormChanges(commonErrorForRequestText)}
        page={tabNameLabelText}
        block={tabNameLabelText}
        icon={additionalHeatingSourceThresholdsIcon}
      >
        <AdditionalHeatingSourceThresholdsDifferences
          differences={
            additionalHeatingSourceThresholdsFormValueDifferencesWithInitialValue
          }
        />
      </ConfirmChangesModal>
      <NotificationModal
        isOpen={isNotificationModalOpen}
        onOkPress={() => setIsNotificationModalOpen(false)}
        icon={additionalHeatingSourceThresholdsIcon}
      />
    </TabWrapper>
  );
}
