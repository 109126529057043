import React from "react";
import Switch from "@mui/material/Switch";
import styled from "@emotion/styled";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: "50px",
  height: "30px",
  padding: 0,
  ".MuiSwitch-track": {
    height: "30px",
    borderRadius: "15px",
    backgroundColor: "#e0e1e6",
    opacity: 1,
  },
  ".MuiButtonBase-root": {
    padding: "2px",
    "&.Mui-checked": {
      ".MuiSwitch-thumb": {
        width: "26px",
        height: "26px",
        color: "#fff",
      },
      "+.MuiSwitch-track": {
        backgroundColor: "#95c11f",
        opacity: 1,
      },
    },
    ".MuiSwitch-thumb": {
      width: "26px",
      height: "26px",
    },
  },
}));

export interface ISwitchProps {
  checked: boolean;
  setChecked: (val: boolean) => void;
}

const SwitchStyled = ({ checked, setChecked }: ISwitchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return <CustomSwitch checked={checked} onChange={handleChange} />;
};

export default SwitchStyled;
