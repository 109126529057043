import {
  installationApi,
  useGetInstallationSettingsQuery,
} from "../../../../../../../../../store/services/installation";

import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../../../../../store";
import { useToast } from "../../../../../../../../../hooks/useToast";
import { useSetInstallationSettingsMutation } from "../../../../../../../../../store/services/installation";
import { useEffect, useMemo, useState } from "react";
import {
  IInstallationSettingsByIdResponse,
  IInstallationUserSettings,
  ISetInstallationSettings,
  ISetUserSettings,
} from "../../../../../../../../../store/services/models/installations/installationSettings";
import {
  deepClone,
  findDifferences,
  transformDifferencesForRequest,
} from "../../../../../helpers/settingsHelpers";
import { ISeasonSettingsDifferences } from "../types/types";
import { ToastStatuses } from "../../../../../../../../../components/Toaster/StatusIcon/Types";

export default function useSeasonTab() {
  const { id: installationUuid } = useParams();
  const dispatch = useAppDispatch();

  const { showToast } = useToast();

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] =
    useState<boolean>(true);

  //form data states
  const [seasonSettingsFormData, setSeasonSettingsFormData] =
    useState<Partial<IInstallationUserSettings> | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false);

  //modals states
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const poolingInterval = isPoolingIntervalEnabled ? 60000 : 0;
  const { data: installationSettings } = useGetInstallationSettingsQuery(
    { uuid: installationUuid! },
    { pollingInterval: poolingInterval }
  );

  const seasonSettingsData = installationSettings?.userSettings;

  const calculatedMaxValueForHeatingSeasonStartTemp =
    (seasonSettingsFormData?.coolingSeasonStartTemp?.value || 0) - 3 <=
    (seasonSettingsFormData?.heatingSeasonStartTemp?.max || 0)
      ? (seasonSettingsFormData?.coolingSeasonStartTemp?.value || 0) - 3
      : seasonSettingsFormData?.heatingSeasonStartTemp?.max || 0;

  const calculatedMinValueForCoolingSeasonStartTemp =
    (seasonSettingsFormData?.heatingSeasonStartTemp?.value || 0) + 3 >=
    (seasonSettingsFormData?.coolingSeasonStartTemp?.min || 0)
      ? (seasonSettingsFormData?.heatingSeasonStartTemp?.value || 0) + 3
      : seasonSettingsFormData?.coolingSeasonStartTemp?.min || 0;

  const seasonSettingsFormValueDifferencesWithInitialValue: ISeasonSettingsDifferences | null =
    useMemo(() => {
      if (!seasonSettingsFormData || !seasonSettingsData) return null;
      return findDifferences({
        current: seasonSettingsFormData,
        prev: seasonSettingsData,
      });
    }, [seasonSettingsFormData, seasonSettingsData]);

  useEffect(() => {
    if (seasonSettingsData) {
      setSeasonSettingsFormData(deepClone(seasonSettingsData));
    }
  }, [seasonSettingsData]);

  useEffect(() => {
    const hasChanges = Boolean(
      seasonSettingsFormValueDifferencesWithInitialValue
    );
    setIsSomethingChanged(hasChanges);
    setIsPoolingIntervalEnabled(!hasChanges);
  }, [seasonSettingsFormValueDifferencesWithInitialValue]);

  const onSeasonSettingsChange = (value: number, key: string) => {
    setSeasonSettingsFormData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [key]: {
          ...prev[key as keyof IInstallationUserSettings],
          value,
        },
      };
    });
  };

  const onDiscardChanges = () => {
    setSeasonSettingsFormData(deepClone(seasonSettingsData));
  };

  const onSaveChanges = () => {
    setIsConfirmChangesModalOpen(true);
  };

  const confirmFormChanges = (commonErrorForRequestText: string) => {
    setIsConfirmChangesModalOpen(false);
    const seasonSettingsFormattedChanges: Partial<ISetUserSettings> =
      transformDifferencesForRequest(
        seasonSettingsFormValueDifferencesWithInitialValue
      );
    let requestBody: Partial<ISetInstallationSettings> = {};
    let cacheData: IInstallationSettingsByIdResponse;
    if (seasonSettingsFormattedChanges) {
      requestBody = {
        userSettings: {
          ...seasonSettingsFormattedChanges,
        },
      };
    }
    cacheData = {
      ...(installationSettings as IInstallationSettingsByIdResponse),
      userSettings: {
        ...installationSettings!.userSettings,
        coolingSeasonStartTemp: seasonSettingsFormData!.coolingSeasonStartTemp!,
        heatingSeasonStartTemp: seasonSettingsFormData!.heatingSeasonStartTemp!,
      },
    };
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: installationUuid!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: installationUuid! },
            (draftSettings) => {
              Object.assign(draftSettings, cacheData);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((_error) => {
        showToast({
          title: commonErrorForRequestText,
          status: ToastStatuses.Error,
        });
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  return {
    seasonSettingsFormData,
    isSomethingChanged,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    calculatedMaxValueForHeatingSeasonStartTemp,
    calculatedMinValueForCoolingSeasonStartTemp,
    seasonSettingsFormValueDifferencesWithInitialValue,
    onSeasonSettingsChange,
    onDiscardChanges,
    onSaveChanges,
    confirmFormChanges,
    setIsConfirmChangesModalOpen,
    setIsNotificationModalOpen,
  };
}
