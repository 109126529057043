import React from "react";

import {
  FlowTemperatureSettingsTypes,
  IInstallationZoneProps,
} from "../../types";

import InstallationZonesContext from "../../context/InstallationZonesContext";

import TabWrapper from "../../../../TabWrapper";
import SettingsDivider from "../../../../components/SettingsDivider/SettingsDivider";
import FlowTeperatureSettingsSection from "./FlowTemperatureSettingsSection/FlowTeperatureSettingsSection";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import ZoneDifferences from "../ZoneDifferences/ZoneDifferences";
import NoParametersBlock from "../../../../NoParametersBlock";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";

import MainSection from "./MainSection/MainSection";

import useInstallationZone from "../../hooks/zone/useInstallationZone";
import useInstallationInstallerZone from "../../hooks/installerZone/useInstallationInstallerZone";
import useInstallationZoneConditionsAndValuesFromEnums from "../../hooks/zone/useInstallationZoneConditionsAndValuesFromEnums";
import useInstallationInstallerZoneConditionsAndValuesFromEnums from "../../hooks/installerZone/useInstallationInstallerZoneConditionsAndValuesFromEnums";
import useZoneAndInstallerZoneCommon from "../../hooks/zoneAndInstallerZoneCommon/useZoneAndInstallerZoneCommon";
import useTabsIcons from "../../../../hooks/useTabsIcons";

export default function InstallationZone({
  isInstallerZones,
  installationSettingsResponse,
  installationStateResponse,
  isInstallationOffline,
  isLoadingSettings,
  isLoadingState,
  setIsPoolingIntervalEnabled,
}: IInstallationZoneProps) {
  //zone hook
  const {
    zoneKeys,
    staticValues,
    zoneFormData,
    zoneOperationInstallerSettingsData,
    zoneFormValueDifferencesWithInitialValue,
    updateZoneController,
    updateZoneFormData,
    updateTempInCurve,
    discardFormChanges,
  } = useInstallationZone({
    installationSettingsResponse,
    installationStateResponse,
    isInstallationOffline,
  });

  //installer zone hook
  const {
    installerSettingsFormData,
    zonesCountData,
    zonesCountDataDifferencesWithInitialZonesCount,
    installerZoneFormValueDifferencesWithInitialValue,
    updateZonesCount,
    updateInstallerZoneFormData,
    updateInstallerZoneMode,
    updateInstallerIndoorSensor,
    discardZoneCountChanges,
    discardInstallerZoneSettingsFormChanges,
  } = useInstallationInstallerZone({
    installationSettingsResponse,
    isInstallerZones,
    zoneOperationInstallerSettingsData,
  });

  //zone conditions hook
  const {
    modeForZoneIsNotNone,
    modeForZoneIsCooling,
    modeForZoneIsCoolingAndHeating,
    modeForZoneIsHeating,
    commonErrorForRequest,
    tabNameLabel,
    zoneSwitcherLabel,
    showEmptyBlock,
  } = useInstallationZoneConditionsAndValuesFromEnums({
    isInstallerZones,
    staticValues,
    isLoadingSettings,
    isLoadingState,
    installerSettingsFormData,
    zoneFormData,
    zoneKeys,
  });

  //installer zone conditions hook
  const { showZoneActiveSwitcher, showZoneContent } =
    useInstallationInstallerZoneConditionsAndValuesFromEnums({
      zoneKeys,
      isInstallerZones,
      zonesCountData,
    });

  //zone and installer zone common hook
  const {
    isSomethingChanged,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    saveFormChanges,
    confirmFormChanges,
    handleDiscardClick,
    setIsConfirmChangesModalOpen,
    setIsNotificationModalOpen,
  } = useZoneAndInstallerZoneCommon({
    isInstallerZones,
    zoneFormValueDifferencesWithInitialValue,
    zonesCountDataDifferencesWithInitialZonesCount,
    installerZoneFormValueDifferencesWithInitialValue,
    installationSettingsResponse,
    zoneFormData,
    zonesCountData,
    installerSettingsFormData,
    setIsPoolingIntervalEnabled,
    discardFormChanges,
    discardZoneCountChanges,
    discardInstallerZoneSettingsFormChanges,
  });

  const { zonesTabIcon } = useTabsIcons();

  if (showEmptyBlock) {
    return <NoParametersBlock />;
  }
  return (
    <>
      <TabWrapper
        onDiscardPress={handleDiscardClick}
        onSaveChangesPress={saveFormChanges}
        isDiscardDisabled={!isSomethingChanged}
        isSaveDisabled={!isSomethingChanged}
        topHeight={"0"}
      >
        {showZoneActiveSwitcher && (
          <SettingsSwitcher
            disabled={isInstallationOffline}
            label={zoneSwitcherLabel}
            state={zonesCountData > 1 ? true : false}
            changeState={updateZonesCount}
          />
        )}

        <InstallationZonesContext.Provider
          value={{
            isInstallerZones,
            installationSettingsResponse,
            installationStateResponse,
            isInstallationOffline,
            staticValues,
            zoneFormData,
            installerSettingsFormData,
            zoneKeys,
            updateZoneController,
            updateZoneFormData,
            updateInstallerZoneFormData,
            updateInstallerZoneMode,
            updateInstallerIndoorSensor,
            updateTempInCurve,
          }}
        >
          {showZoneContent && (
            <>
              <MainSection />
              {modeForZoneIsNotNone && <SettingsDivider />}
              {(modeForZoneIsHeating || modeForZoneIsCoolingAndHeating) && (
                <FlowTeperatureSettingsSection
                  type={FlowTemperatureSettingsTypes.Heating}
                />
              )}
              {modeForZoneIsCoolingAndHeating && <SettingsDivider />}
              {(modeForZoneIsCooling || modeForZoneIsCoolingAndHeating) && (
                <FlowTeperatureSettingsSection
                  type={FlowTemperatureSettingsTypes.Cooling}
                />
              )}
            </>
          )}
          <UnsavedChangesModal
            tabName={tabNameLabel}
            condition={isSomethingChanged}
            discardChanges={handleDiscardClick}
          />
          <ConfirmChangesModal
            isOpen={isConfirmChangesModalOpen}
            onCancel={() => setIsConfirmChangesModalOpen(false)}
            onOk={() => confirmFormChanges(commonErrorForRequest)}
            page={tabNameLabel}
            block={tabNameLabel}
            icon={zonesTabIcon}
            showHeatPumpNote={!!zonesCountDataDifferencesWithInitialZonesCount}
          >
            <ZoneDifferences
              differences={zoneFormValueDifferencesWithInitialValue}
              zoneCountDifferences={
                zonesCountDataDifferencesWithInitialZonesCount
              }
              installerDifferences={
                installerZoneFormValueDifferencesWithInitialValue
              }
            />
          </ConfirmChangesModal>

          <NotificationModal
            isOpen={isNotificationModalOpen}
            onOkPress={() => setIsNotificationModalOpen(false)}
            icon={zonesTabIcon}
          />
        </InstallationZonesContext.Provider>
      </TabWrapper>
    </>
  );
}
