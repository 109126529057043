import React, { useState } from "react";
import { IUpdatedAddressDataParameters } from "../EditMyOrganizationForm";
import { useEditOrganizationMutation } from "../../../store/services/organizations";
import { useToast } from "../../../hooks/useToast";
import {
  IUpdateOrganizationRequestBody,
  OrganizationItemResponse,
} from "../../../store/services/models/organizations/organizations";
import { ToastStatuses } from "../../../components/Toaster/StatusIcon/Types";
import { useTranslation } from "react-i18next";

export default function useEditOrganization() {
  const { showToast } = useToast();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.company_organizations",
  });
  const [isEditMyOrganizationModalOpen, setIsEditMyOrganizationModalOpen] =
    useState<boolean>(false);

  const [
    myOrganizationUpdatedAddressData,
    setMyOrganizationUpdatedAddressData,
  ] = useState<IUpdatedAddressDataParameters>();

  const [myOrganizationUpdatedPhoneData, setMyOrganizationUpdatedPhoneData] =
    useState<string>();

  const getIsSaveChangesInEditModalButtonDisabled = (
    currentOrg: OrganizationItemResponse
  ) => {
    return !(
      myOrganizationUpdatedPhoneData !== currentOrg?.phone ||
      (myOrganizationUpdatedAddressData?.address !== currentOrg?.address &&
        myOrganizationUpdatedAddressData?.isValidAddress)
    );
  };

  const [
    editOrganization,
    { isLoading: isEditMyOrganizationAPICallInProgress },
  ] = useEditOrganizationMutation();

  const onSaveEditOrganization = (currentOrg: OrganizationItemResponse) => {
    const isAddressChanged =
      myOrganizationUpdatedAddressData?.address !== currentOrg?.address;
    const isPhoneChanged = myOrganizationUpdatedPhoneData !== currentOrg?.phone;
    let requestBody: IUpdateOrganizationRequestBody = {
      address: myOrganizationUpdatedAddressData?.address,
      phone: myOrganizationUpdatedPhoneData,
      country: currentOrg?.country,
      name: currentOrg?.name,
    };

    editOrganization({ orgUuid: currentOrg!.uuid, requestBody })
      .unwrap()
      .then(() => {
        showToast({
          title: t("edit_modal.success_toast.title"),
          text: `${t("edit_modal.success_toast.text_pt_1", {
            orgName: currentOrg?.name,
          })}${t(
            `edit_modal.success_toast.text_updated${
              isAddressChanged && isPhoneChanged
                ? "_phone_address"
                : isAddressChanged
                ? "_address"
                : "_phone"
            }`
          )}`,
          status: ToastStatuses.Success,
        });
      })
      .catch(() => {
        showToast({
          title: t("edit_modal.error_toast.title"),
          text: t("edit_modal.error_toast.text"),
          status: ToastStatuses.Success,
        });
      })
      .finally(() => {
        setIsEditMyOrganizationModalOpen(false);
      });
  };
  return {
    isEditMyOrganizationModalOpen,
    setIsEditMyOrganizationModalOpen,
    isEditMyOrganizationAPICallInProgress,
    setMyOrganizationUpdatedAddressData,
    setMyOrganizationUpdatedPhoneData,
    getIsSaveChangesInEditModalButtonDisabled,
    onSaveEditOrganization,
  };
}
