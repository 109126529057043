import React, { useState } from "react";
import { IDropdownScrollableWithCheckboxesAndSectionsProps } from "./types";
import DropdownButtonOpener from "./DropdownButtonOpener";
import DropdownContent from "./DropdownContent";
import useDropdownScrollableWithCheckboxesAndSectionsPosition from "./hooks/useDropdownScrollableWithCheckboxesAndSectionsPosition";
import useDropdownScrollableWithCheckboxesAndSectionsState from "./hooks/useDropdownScrollableWithCheckboxesAndSectionsState";

export default function DropdownScrollableWithCheckboxesAndSections({
  buttonLabel,
  items,
  selectedValues,
  setSelectedValues,
  selectAllLabel,
  applyOnClose,
  limit,
}: IDropdownScrollableWithCheckboxesAndSectionsProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const {
    buttonRef,
    dropdownRef,
    buttonCoords,
    dropdownCoords,
    distanceToBottom,
  } = useDropdownScrollableWithCheckboxesAndSectionsPosition(isDropdownOpen);

  const {
    allItemsCount,
    selectedValuesDependsOnMode,
    onDropdownOpenerClick,
    onClearSelectionClick,
    handleSelectAllCheckboxChange,
    handleParameterCheckboxChange,
  } = useDropdownScrollableWithCheckboxesAndSectionsState({
    isDropdownOpen,
    setIsDropdownOpen,
    items,
    selectedValues,
    setSelectedValues,
    applyOnClose,
    dropdownRef,
  });

  return (
    <>
      <DropdownButtonOpener
        buttonLabel={buttonLabel}
        buttonRef={buttonRef}
        isDropdownOpen={isDropdownOpen}
        selectedValuesForDropdown={selectedValuesDependsOnMode}
        handleButtonClick={onDropdownOpenerClick}
        handleClearListClick={onClearSelectionClick}
      />
      {isDropdownOpen && (
        <DropdownContent
          dropdownRef={dropdownRef}
          distanceToBottom={distanceToBottom}
          itemsForDropdown={items}
          selectedValues={selectedValuesDependsOnMode}
          buttonCoords={buttonCoords}
          dropdownCoords={dropdownCoords}
          selectAllCheckedCondition={
            allItemsCount === selectedValuesDependsOnMode?.length
          }
          onSelectAllChange={handleSelectAllCheckboxChange}
          selectAllLabel={selectAllLabel}
          onCheckboxChange={handleParameterCheckboxChange}
          limitForSelect={limit}
        />
      )}
    </>
  );
}
