import React, { JSX } from "react";
import useTheme, { ESThemeType } from "../../theme/hooks/useTheme";
import { Box, SxProps, Typography } from "@mui/material";

type FilterButtonProps = {
  onClick: () => void;
  isFirst?: boolean;
  isLast?: boolean;
  label: string;
  isSelected: boolean;
  icon?: JSX.Element;
};

export default function FilterButton({
  onClick,
  isFirst = true,
  isLast = true,
  label,
  isSelected,
  icon,
}: FilterButtonProps) {
  const theme = useTheme();
  const { typography, colors } = theme;
  const styles = getStyles(theme);

  return (
    <Box
      key={label}
      component={"button"}
      sx={{
        ...styles.buttonStyle,
        borderRadius:
          isFirst && isLast ? "8px" : isFirst ? "8px 0 0 8px" : isLast ? "0 8px 8px 0" : "initial",
        borderRight: isLast ? `1px solid ${colors.colorsBorderBorderPrimary}` : "none",
        border: `1px solid ${colors.colorsBorderBorderPrimary}`,
        backgroundColor: isSelected ? colors.colorsBackgroundBgActive : "initial",
      }}
      onClick={onClick}
    >
      {icon && (
        <Box
          sx={{
            ...styles.iconStyle,
            color: isSelected ? colors.colorsTextTextBrandTertiary : colors.colorsTextTextSecondary,
          }}
        >
          {icon}
        </Box>
      )}
      <Typography
        sx={{
          ...typography[".text-sm-semibold"],
          color: isSelected ? colors.colorsTextTextBrandTertiary : colors.colorsTextTextSecondary,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    buttonStyle: {
      ...typography[".text-sm-semibold"],
      minHeight: "40px",
      display: "flex",
      alignItems: "center",
      border: "none",
      padding: `${spacing.spacingMd} ${spacing.spacingXl}`,
      cursor: "pointer",
      borderRadius: "8px",
      backgroundColor: "initial",
      "&:hover": {
        backgroundColor: colors.colorsBackgroundBgActive,
      },
    } as SxProps,
    iconStyle: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      mr: spacing.spacingSm,
    } as SxProps,
  };
};
