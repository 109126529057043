import { useContext, useEffect, useState } from "react";
import QuietModeContext from "../../../../../context/QuietModeContext";
import { IInstallationUserSettingsQuietMode } from "../../../../../../../../../../../../../store/services/models/installations/installationSettings";

export interface IUserQuietModeScheduleRowParameters {
  timeLengthFormData: number | undefined;
  startHourFormData: number | undefined;
  startKey: string;
  lengthKey: string;
}

export default function useQuietModeScheduleRow({
  timeLengthFormData = 0,
  startHourFormData = 0,
  startKey,
  lengthKey,
}: IUserQuietModeScheduleRowParameters) {
  const [calculatedEndHour, setCalculatedEndHour] = useState<number>(0);

  const {
    hoursDropdownOptions,
    isInstallationOffline,
    quietModeSettingsHelperData,
    onQuietModeScheduleChange,
    onQuietModeHelperDataChange,
    onApplyForAllDays,
  } = useContext(QuietModeContext);

  const isDayActive: boolean = timeLengthFormData > 0;

  useEffect(() => {
    const endHour =
      (startHourFormData + (timeLengthFormData > 0 ? timeLengthFormData : 6)) %
      24;
    setCalculatedEndHour(endHour);
  }, [
    timeLengthFormData,
    startHourFormData,
    quietModeSettingsHelperData,
    lengthKey,
  ]);

  const handleCheckboxChange = (value: boolean) => {
    const start =
      quietModeSettingsHelperData![
        startKey as keyof IInstallationUserSettingsQuietMode
      ].value;
    onQuietModeScheduleChange(start !== undefined ? start : 0, startKey);
    if (value) {
      onQuietModeScheduleChange(
        quietModeSettingsHelperData![
          lengthKey as keyof IInstallationUserSettingsQuietMode
        ].value || 6,
        lengthKey
      );
    } else {
      onQuietModeScheduleChange(0, lengthKey);
    }
  };

  const calculateLength = (endHour: number, startHour: number) => {
    let length;
    if (endHour === startHour) {
      length = 24;
    } else if (endHour >= startHour) {
      length = endHour - startHour;
    } else {
      length = 24 - startHour + endHour;
    }
    return length;
  };

  const handleStartHourChange = (value: number) => {
    const length = calculateLength(calculatedEndHour, value);
    onQuietModeScheduleChange(value, startKey);
    onQuietModeHelperDataChange(value, startKey);
    onQuietModeScheduleChange(length, lengthKey);
    onQuietModeHelperDataChange(length, lengthKey);
  };

  const handleStopHourChange = (value: number) => {
    const length = calculateLength(value, startHourFormData);
    onQuietModeScheduleChange(length, lengthKey);
    onQuietModeHelperDataChange(length, lengthKey);
  };

  return {
    calculatedEndHour,
    isDayActive,
    isInstallationOffline,
    hoursDropdownOptions,
    handleCheckboxChange,
    handleStartHourChange,
    handleStopHourChange,
    onApplyForAllDays,
  };
}
