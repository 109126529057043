import {
  Button,
  Container,
  Grid,
  MenuItem,
  TableCell,
  Typography,
} from "@mui/material";
import React, {
  CSSProperties,
  LegacyRef,
  useEffect,
  useRef,
  useState,
} from "react";
import useTheme from "../../theme/hooks/useTheme";
import SortableTable, { Column } from "../../components/Grid/SortableTable";
import { useTranslation } from "react-i18next";
import {
  useGetOrganizationQuery,
  useGetPartnerShipsQuery,
} from "../../store/services/organizations";
import { useNavigate } from "react-router";
import { UsersAPI } from "../../api/UsersAPI";
import { jwtDecode } from "jwt-decode";
import {
  OrganizationItemResponse,
  RelatedCompanyResponse,
} from "../../store/services/models/organizations/organizations";
import { Edit01, Plus, Trash03 } from "untitledui-js-base";
import AddOrganizationDialog, {
  AddOrganizationDialogRef,
} from "./AddOrganizationDialog";
import { OrganizationTypes } from "../../api/OrganizationsAPI";
import { MoreMenu } from "../../components/MoreMenu/MoreMenu";
import AuthenticationAPI from "../../api/AuthenticationAPI";
import SmallDialogWrapper from "../../components/SmallDialogWrapper/SmallDialogWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import EditMyOrganizationForm from "./EditMyOrganizationForm";

import useDeleteOrganisation from "./organizationHooks/useDeleteOrganisation";
import useEditOrganization from "./organizationHooks/useEditOrganization";

enum MenuActions {
  Edit = 0,
  Delete,
}

export default function OrganizationsListPage() {
  const theme = useTheme();
  const pendingUserStyle: CSSProperties = {
    borderRadius: "15px",
    border: `1px solid ${theme.colors.componentColorsUtilityWarningUtilityWarning200}`,
    backgroundColor: theme.colors.componentColorsUtilityWarningUtilityWarning50,
    color: theme.colors.componentColorsUtilityWarningUtilityWarning700,
    padding: "2px 8px",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    display: "flex",
    whiteSpace: "nowrap",
    maxHeight: "20px",
    alignItems: "center",
    maxWidth: "max-content",
  };

  const activeUserStyle: CSSProperties = {
    ...pendingUserStyle,
    border: `1px solid ${theme.colors.componentColorsUtilitySuccessUtilitySuccess200}`,
    backgroundColor: theme.colors.componentColorsUtilitySuccessUtilitySuccess50,
    color: theme.colors.componentColorsUtilitySuccessUtilitySuccess700,
  };

  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.company_organizations",
  });
  const { t: tOrgTypes } = useTranslation("cloud_ui", {
    keyPrefix: "pages.organizations_types",
  });
  const { t: tStatuses } = useTranslation("cloud_ui", {
    keyPrefix: "statuses",
  });
  const navigate = useNavigate();

  const {
    isDeleteOrganizationAPIInProgress,
    isDeleteOrganizationModalOpen,
    handleDeleteOrganization,
    setIsDeleteOrganizationModalOpen,
  } = useDeleteOrganisation();

  const {
    isEditMyOrganizationModalOpen,
    setIsEditMyOrganizationModalOpen,
    isEditMyOrganizationAPICallInProgress,
    setMyOrganizationUpdatedAddressData,
    setMyOrganizationUpdatedPhoneData,
    getIsSaveChangesInEditModalButtonDisabled,
    onSaveEditOrganization,
  } = useEditOrganization();

  const usersApi = new UsersAPI(navigate);
  const authApi = new AuthenticationAPI(navigate);

  const addOrganizationDialogRef = useRef<AddOrganizationDialogRef>(null);

  const [organizationForAction, setOrganizationForAction] = useState<
    RelatedCompanyResponse | OrganizationItemResponse
  >();

  const [canDeleteOrganizationPermission, setCanDeleteOrganizationPermission] =
    useState<boolean>(false);
  const [canEditOrganizationPermission, setCanEditOrganizationPermission] =
    useState<boolean>(false);

  const getOrgUuid = () => {
    const token = usersApi.getAuthTokenForUsersInfo();
    const decodedToken: any = jwtDecode(token as string);
    return decodedToken.current_membership_uuid;
  };

  const orgUuid = getOrgUuid();

  const { data, refetch } = useGetPartnerShipsQuery(
    { uuid: orgUuid },
    { refetchOnMountOrArgChange: true }
  );
  const { data: currentOrg } = useGetOrganizationQuery({ uuid: orgUuid });

  useEffect(() => {
    authApi
      .hasRoles(["ORGANIZATION_COOPERATION", "ADMIN"])
      .then((response) => {
        setCanDeleteOrganizationPermission(response.data.value);
      })
      .catch(() => {});

    authApi
      .hasRoles(["ORGANIZATION_WRITE", "ADMIN"])
      .then((response) => {
        setCanEditOrganizationPermission(response.data.value);
      })
      .catch(() => {});
  }, []);

  const renderUserState = (status: RelatedCompanyResponse["status"]) => {
    switch (status) {
      case "ACTIVE":
        return <span style={activeUserStyle}>{tStatuses("active")}</span>;
      case "PENDING":
        return <span style={pendingUserStyle}>{tStatuses("pending")}</span>;
      default:
        return <></>;
    }
  };

  const renderOrgType = (type: RelatedCompanyResponse["type"]) => {
    switch (type) {
      case OrganizationTypes.COMMERCIAL_CLIENT:
        return tOrgTypes("client");
      case OrganizationTypes.INSTALLER:
        return tOrgTypes("installer");
      case OrganizationTypes.DISTRIBUTOR:
        return tOrgTypes("distributor");
      default:
        return type;
    }
  };

  const renderMenuItems = (handleClose: () => void, menuId: any) => {
    let isMyOrganizationMenu;
    let currentOrganization = data?.children.find((org) => org.uuid === menuId);
    if (currentOrganization) {
      isMyOrganizationMenu = false;
    } else {
      isMyOrganizationMenu = true;
      currentOrganization = currentOrg;
    }

    const onMenuItemClick = (action: MenuActions) => {
      setOrganizationForAction(currentOrganization);
      switch (action) {
        case MenuActions.Delete:
          setIsDeleteOrganizationModalOpen(true);
          break;
        case MenuActions.Edit:
          setIsEditMyOrganizationModalOpen(true);
          break;
        default:
          break;
      }
      handleClose();
    };
    const iconProps = {
      size: "16",
      style: { marginRight: "5px" },
      strokeWidth: 2,
    };
    const menuItems: Array<any> = [
      isMyOrganizationMenu &&
        canEditOrganizationPermission && {
          key: `${menuId}_edit`,
          label: t("table.actions.edit"),
          action: MenuActions.Edit,
          icon: <Edit01 {...iconProps} />,
        },
      !isMyOrganizationMenu &&
        currentOrganization?.status === "PENDING" &&
        canDeleteOrganizationPermission && {
          key: `${menuId}_delete`,
          label: t("table.actions.delete"),
          action: MenuActions.Delete,
          icon: <Trash03 {...iconProps} />,
        },
    ].filter((el) => !!el);

    return menuItems.map((item) => {
      return (
        <MenuItem
          sx={
            item.key === `${menuId}_delete`
              ? {
                  color: theme.colors.colorsTextTextErrorPrimary,
                }
              : {}
          }
          key={item.key}
          onClick={() => onMenuItemClick(item.action)}
        >
          {item.icon}
          {item.label}
        </MenuItem>
      );
    });
  };

  const getParentsRows = () => {
    return (
      data?.parents.map((item) => {
        return {
          ...item,
          isRowHighlited: item.status === "PENDING",
          name: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextPrimary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {`${item.name}`}
            </Typography>
          ),
          type: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextSecondary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {renderOrgType(item.type)}
            </Typography>
          ),
          status: renderUserState(item.status || "ACTIVE"),
        };
      }) || []
    );
  };

  const getChildRows = () => {
    const hasParents = Boolean(data?.parents?.length);
    return (
      data?.children.map((item) => {
        return {
          ...item,
          isRowHighlited: item.status === "PENDING",
          name: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextPrimary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
                paddingLeft: hasParents ? "40px" : theme.spacing.spacing3xl,
              }}
            >
              {"↳ "}
              {`${item.name}`}
            </Typography>
          ),
          type: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextSecondary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {renderOrgType(item.type)}
            </Typography>
          ),
          status: renderUserState(item.status || "ACTIVE"),
          actions: item.status === "PENDING" && (
            <MoreMenu renderMenuItems={renderMenuItems} menuId={item.uuid} />
          ),
        };
      }) || []
    );
  };

  const getMyRow = () => {
    const hasParents = Boolean(data?.parents?.length);
    return {
      ...currentOrg,
      name: (
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            color: theme.colors.colorsTextTextBrandSecondary,
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            paddingLeft: hasParents ? theme.spacing.spacing3xl : 0,
          }}
        >
          {hasParents && "↳ "}
          {`${currentOrg?.name} (${t("label_my")})`}
        </Typography>
      ),
      type: currentOrg?.type && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextSecondary,
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {renderOrgType(currentOrg.type)}
        </Typography>
      ),
      status: renderUserState("ACTIVE"),
      actions: (
        <MoreMenu renderMenuItems={renderMenuItems} menuId={currentOrg?.uuid} />
      ),
    };
  };

  const tableColumns: Column[] = [
    { id: "uuid", label: "", hidden: true },
    {
      id: "name",
      label: t("table.titles.name"),
      disableSorting: true,
    },
    {
      id: "type",
      label: t("table.titles.type"),
      disableSorting: true,
    },
    {
      id: "address",
      label: t("table.titles.address"),
      disableSorting: true,
    },
    {
      id: "phone",
      label: t("table.titles.phone"),
      disableSorting: true,
    },
    {
      id: "status",
      label: t("table.titles.status"),
      disableSorting: true,
    },
    { id: "actions", label: "", disableSorting: true, width: 50 },
  ];

  const renderInfoTableHeadContent = () => {
    const pendingCount = [
      ...(data?.children || []),
      ...(data?.parents || []),
    ]?.filter((el) => el.status === "PENDING").length;
    return (
      <TableCell colSpan={6}>
        <Grid container alignItems={"center"}>
          <Grid container xs={6} alignItems={"center"}>
            <Typography
              sx={{
                ...theme.typography[".text-xl-semibold"],
                paddingRight: theme.spacing.spacingSm,
              }}
            >
              {t("title")}
            </Typography>
            {pendingCount > 0 && (
              <Typography
                sx={{
                  ...pendingUserStyle,
                  ...theme.typography[".text-xs-medium"],
                }}
              >
                {t("title_status", { count: pendingCount })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "initial",
                borderRadius: theme.radius.radiusMd,
                backgroundColor:
                  theme.colors
                    .componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
              }}
              type="submit"
              startIcon={
                <Plus
                  size="16"
                  color={
                    theme.colors
                      .componentColorsComponentsButtonsPrimaryButtonPrimaryFg
                  }
                  strokeWidth={2}
                />
              }
              onClick={() => addOrganizationDialogRef.current?.open()}
            >
              {t("btn_add_organization")}
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  return (
    <Container style={{ marginTop: theme.spacing.spacing3xl }}>
      <Typography
        sx={{
          ...theme.typography[".display-sm-semibold"],
          paddingBottom: theme.spacing.spacing2xl,
        }}
      >
        {t("page_title")}
      </Typography>
      <SortableTable
        columns={tableColumns}
        rows={[...getParentsRows(), getMyRow(), ...getChildRows()]}
        infoTableHeadContent={renderInfoTableHeadContent()}
      />
      <AddOrganizationDialog
        organizationUuid={orgUuid}
        ref={addOrganizationDialogRef as LegacyRef<AddOrganizationDialogRef>}
        onSuccess={refetch}
      />
      <DeleteDialog
        title={t("delete_modal.title")}
        description={t("delete_modal.description")}
        dialogOpen={isDeleteOrganizationModalOpen}
        setDialogOpen={setIsDeleteOrganizationModalOpen}
        deleteAPICallInProgress={isDeleteOrganizationAPIInProgress}
        onDelete={() => {
          handleDeleteOrganization({
            parentOrgUuid: currentOrg!.uuid,
            email: organizationForAction!.name,
          });
        }}
      />
      <SmallDialogWrapper
        dialogOpen={isEditMyOrganizationModalOpen}
        onClose={() => setIsEditMyOrganizationModalOpen(false)}
        isSaveInProgress={isEditMyOrganizationAPICallInProgress}
        onSave={() => onSaveEditOrganization(currentOrg!)}
        title={t("edit_modal.title")}
        description={t("edit_modal.description")}
        isSaveButtonDisabled={getIsSaveChangesInEditModalButtonDisabled(
          currentOrg!
        )}
      >
        <EditMyOrganizationForm
          organizationToEdit={organizationForAction as OrganizationItemResponse}
          setMyOrganizationUpdatedAddressData={
            setMyOrganizationUpdatedAddressData
          }
          setMyOrganizationUpdatedPhoneData={setMyOrganizationUpdatedPhoneData}
        />
      </SmallDialogWrapper>
    </Container>
  );
}
