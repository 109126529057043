import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useToast } from "../../../hooks/useToast";
import { useCancelCooperationInvitationMutation } from "../../../store/services/organizations";
import { IOrganizationCancelCooperationInvitationRequestParams } from "../../../store/services/models/organizations/organizations";
import { ToastStatuses } from "../../../components/Toaster/StatusIcon/Types";

export default function useDeleteOrganisation() {
  const { showToast } = useToast();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.company_organizations",
  });
  const [deleteOrganization, { isLoading: isDeleteOrganizationAPIInProgress }] =
    useCancelCooperationInvitationMutation();

  const [isDeleteOrganizationModalOpen, setIsDeleteOrganizationModalOpen] =
    useState<boolean>(false);

  const handleDeleteOrganization = ({
    parentOrgUuid,
    email,
  }: IOrganizationCancelCooperationInvitationRequestParams) => {
    deleteOrganization({
      parentOrgUuid,
      email,
    })
      .unwrap()
      .then(() => {
        showToast({
          status: ToastStatuses.Success,
          title: t("delete_modal.success_toast.title"),
          text: t("delete_modal.success_toast.text"),
        });
      })
      .catch(() => {
        showToast({
          status: ToastStatuses.Error,
          title: t("delete_modal.error_toast.title"),
          text: t("delete_modal.error_toast.text"),
        });
      })
      .finally(() => {
        setIsDeleteOrganizationModalOpen(false);
      });
  };
  return {
    isDeleteOrganizationAPIInProgress,
    isDeleteOrganizationModalOpen,
    setIsDeleteOrganizationModalOpen,
    handleDeleteOrganization,
  };
}
