import { AvailableParametersEnum } from "../types/types";

const parameterColors: Record<AvailableParametersEnum, string> = {
  [AvailableParametersEnum.operationStatus]: "#ff3333",
  [AvailableParametersEnum.currentRoomTemperatureZ1]: "#a50000",
  [AvailableParametersEnum.currentRoomTemperatureZ2]: "#ff6699",
  [AvailableParametersEnum.outdoorTemperature]: "#0030be",
  [AvailableParametersEnum.waterTempTHC]: "#ff4500",
  [AvailableParametersEnum.dhwTankTemperatureTWD]: "#ff9900",
  [AvailableParametersEnum.tv1Temperature]: "#11c0fb",
  [AvailableParametersEnum.tv2Temperature]: "#66ccff",
  [AvailableParametersEnum.condenserOutletWaterTemperatureTUO]: "#ff6d2e",
  [AvailableParametersEnum.currentSeason]: "#7dd229",
  [AvailableParametersEnum.condenserInletWaterTemperatureTUI]: "#3a68f2",
  [AvailableParametersEnum.compressorSpeed]: "#004c1a",
  [AvailableParametersEnum.requestedCompressorSpeed]: "#006622",
  [AvailableParametersEnum.defrostMode]: "#3c3f41",
  [AvailableParametersEnum.waterPumpP0Status]: "#009966",
  [AvailableParametersEnum.waterFlowRate]: "#33cccc",
  [AvailableParametersEnum.waterPumpP1Status]: "#009933",
  [AvailableParametersEnum.waterPumpP2Status]: "#82c893",
  [AvailableParametersEnum.dhwAntiLegionellaState]: "#00d300",
  [AvailableParametersEnum.quietMode]: "#2b2e30",
  [AvailableParametersEnum.highPressurePd]: "#3e0066",
  [AvailableParametersEnum.compressorDischargeTemperatureTD]: "#ff9966",
  [AvailableParametersEnum.lowPressurePs]: "#883cdc",
  [AvailableParametersEnum.compressorSuctionTemperatureTS]: "#206060",
  [AvailableParametersEnum.condensingTemperatureTUP]: "#107070",
  [AvailableParametersEnum.evaporatingTemperatureTP]: "#145a73",
  [AvailableParametersEnum.eevOpeningP]: "#3e4520",
  [AvailableParametersEnum.fanSpeed1]: "#226622",
  [AvailableParametersEnum.fanSpeed2]: "#2e8b57",
  [AvailableParametersEnum.hpState]: "#333333",
  [AvailableParametersEnum.additionalInlineHeatingSource]: "#cc0033",
  [AvailableParametersEnum.additionalInlineHeatingSourceStage1]: "#ff4444",
  [AvailableParametersEnum.additionalInlineHeatingSourceStage2]: "#00cccc",
  [AvailableParametersEnum.additionalInlineHeatingSourceStage3]: "#ffff33",
  [AvailableParametersEnum.additionalDhwHeatingSourceStatus]: "#ff0066",
  [AvailableParametersEnum.additionalBufferTankHeatingSourceStatus]: "#ff3399",
  [AvailableParametersEnum.outdoorUnitCurrent]: "#33aaff",
  [AvailableParametersEnum.outdoorUnitVoltage]: "#0099ff",
  [AvailableParametersEnum.preferredRoomTempZ1]: "#cccccc",
  [AvailableParametersEnum.preferredRoomTempZ2]: "#0011ff",
  [AvailableParametersEnum.sgReadyStatus]: "#990033",
  [AvailableParametersEnum.setPointTHC]: "#cc0003",
  [AvailableParametersEnum.tv1SetPointTemp]: "#ff6699",
  [AvailableParametersEnum.tv2SetPointTemp]: "#99ff33",
  [AvailableParametersEnum.vacationModeState]: "#a8410d",
  [AvailableParametersEnum.reducedModeState]: "#ffcc00",
  [AvailableParametersEnum.dhwPreferredTemp]: "#e3e300",
  [AvailableParametersEnum.electricGridProtectionStatus]: "#D500D1",
  [AvailableParametersEnum.heatBalance]: "#343297",
  [AvailableParametersEnum.dhwValvePosition]: "#22d230",
};

const parameterUnits: Record<AvailableParametersEnum, string> = {
  [AvailableParametersEnum.currentRoomTemperatureZ1]: "°C",
  [AvailableParametersEnum.currentRoomTemperatureZ2]: "°C",
  [AvailableParametersEnum.outdoorTemperature]: "°C",
  [AvailableParametersEnum.dhwTankTemperatureTWD]: "°C",
  [AvailableParametersEnum.tv1Temperature]: "°C",
  [AvailableParametersEnum.tv2Temperature]: "°C",
  [AvailableParametersEnum.condenserOutletWaterTemperatureTUO]: "°C",
  [AvailableParametersEnum.condenserInletWaterTemperatureTUI]: "°C",
  [AvailableParametersEnum.compressorSpeed]: "Hz",
  [AvailableParametersEnum.requestedCompressorSpeed]: "step",
  [AvailableParametersEnum.highPressurePd]: "bar",
  [AvailableParametersEnum.lowPressurePs]: "bar",
  [AvailableParametersEnum.compressorDischargeTemperatureTD]: "°C",
  [AvailableParametersEnum.compressorSuctionTemperatureTS]: "°C",
  [AvailableParametersEnum.condensingTemperatureTUP]: "°C",
  [AvailableParametersEnum.evaporatingTemperatureTP]: "°C",
  [AvailableParametersEnum.eevOpeningP]: "p",
  [AvailableParametersEnum.fanSpeed1]: "rpm",
  [AvailableParametersEnum.fanSpeed2]: "rpm",
  [AvailableParametersEnum.waterFlowRate]: "l/min",
  [AvailableParametersEnum.waterPumpP0Status]: "%",
  [AvailableParametersEnum.outdoorUnitCurrent]: "A",
  [AvailableParametersEnum.outdoorUnitVoltage]: "V",
  [AvailableParametersEnum.preferredRoomTempZ1]: "°C",
  [AvailableParametersEnum.preferredRoomTempZ2]: "°C",
  [AvailableParametersEnum.setPointTHC]: "°C",
  [AvailableParametersEnum.dhwPreferredTemp]: "°C",
  [AvailableParametersEnum.waterTempTHC]: "°C",
  [AvailableParametersEnum.additionalBufferTankHeatingSourceStatus]: "",
  [AvailableParametersEnum.additionalDhwHeatingSourceStatus]: "",
  [AvailableParametersEnum.additionalInlineHeatingSource]: "",
  [AvailableParametersEnum.additionalInlineHeatingSourceStage1]: "",
  [AvailableParametersEnum.additionalInlineHeatingSourceStage2]: "",
  [AvailableParametersEnum.additionalInlineHeatingSourceStage3]: "",
  [AvailableParametersEnum.currentSeason]: "",
  [AvailableParametersEnum.defrostMode]: "",
  [AvailableParametersEnum.dhwAntiLegionellaState]: "",
  [AvailableParametersEnum.electricGridProtectionStatus]: "",
  [AvailableParametersEnum.hpState]: "",
  [AvailableParametersEnum.operationStatus]: "",
  [AvailableParametersEnum.quietMode]: "",
  [AvailableParametersEnum.reducedModeState]: "",
  [AvailableParametersEnum.sgReadyStatus]: "",
  [AvailableParametersEnum.tv1SetPointTemp]: "",
  [AvailableParametersEnum.tv2SetPointTemp]: "",
  [AvailableParametersEnum.vacationModeState]: "",
  [AvailableParametersEnum.waterPumpP1Status]: "",
  [AvailableParametersEnum.waterPumpP2Status]: "",
  [AvailableParametersEnum.heatBalance]: "",
  [AvailableParametersEnum.dhwValvePosition]: "",
};

export const getParameterUnit = (
  parameter: AvailableParametersEnum
): string => {
  return parameterUnits[parameter] || "";
};

export const getParameterColor = (
  parameter: AvailableParametersEnum
): string | undefined => {
  return parameterColors[parameter] || undefined;
};
