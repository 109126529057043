import { Box, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import RealTimeDataSection from "./RealTimeDataSection";
import { ReactComponent as SeasonSectionIcon } from "./SectionIcons/SeasonSectionIcon.svg";
import { ReactComponent as SystemOperationIcon } from "./SectionIcons/SystemOperationIcon.svg";
import { ReactComponent as Zone1OperationsIcon } from "./SectionIcons/Zone1OperationsIcon.svg";
import { ReactComponent as Zone2OperationsIcon } from "./SectionIcons/Zone2OperationsIcon.svg";
import { ReactComponent as DHWIcon } from "./SectionIcons/DHWIcon.svg";
import { ReactComponent as SGReadyIcon } from "./SectionIcons/SGReadyIcon.svg";
import { ReactComponent as OutdoorTemperatureIcon } from "./SectionIcons/OutdoorTemperatureIcon.svg";
import { ReactComponent as HydraulicParametersIcon } from "./SectionIcons/HydraulicParametersIcon.svg";
import { ReactComponent as RefrigerantParametersIcon } from "./SectionIcons/RefrigerantParametersIcon.svg";
import { ReactComponent as AdditionalHeatingSourcesIcon } from "./SectionIcons/AdditionalHeatingSourcesIcon.svg";
import { ReactComponent as AccumulateOperationTimeIcon } from "./SectionIcons/AccumulateOperationTimeIcon.svg";
import { ReactComponent as OtherIcon } from "./SectionIcons/OtherIcon.svg";
import { ReactComponent as ModesIcon } from "./SectionIcons/ModesIcon.svg";

import { useTranslation } from "react-i18next";
import RealTimeDataRow from "./RealTimeDataRow";
import useTheme from "../../../../theme/hooks/useTheme";
import {
  useGetInstallationSettingsForRealTimeDataQuery,
  useGetInstallationStateQuery,
} from "../../../../store/services/installation";
import {
  ActiveOrInactiveEnum,
  InstallationValueUnit,
  OnlineStatus,
  OperationStatus,
  Season,
  SgReadyStatus,
} from "../../../../store/services/models/installations/installationsCommon";
import { IStateZoneOperation } from "../../../../store/services/models/installations/installationState";
import {
  IInstallationZoneOperation,
  InstallationComissioningZoneCircuitTypeEnum,
  InstallationIndoorSensorStateEnum,
  IZoneComissioningValues,
} from "../../../../store/services/models/installations/installationSettings";
import { useTypedSelector } from "../../../../store";
import { selectSelectedInstallationDetails } from "../../../../store/reducers/installationSlice";
import EmptyInstallationPage from "../../EmptyPage";
import { WiFiOff } from "untitledui-js-base";
import Flowchart from "./Flowchart/Flowchart";

export default function RealTimeData() {
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const theme = useTheme();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const selectedInstallationDetails = useTypedSelector(
    selectSelectedInstallationDetails
  );

  const { data: realTimeDataState } = useGetInstallationStateQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000,
    }
  );

  const { data: realTimeDataSettings } =
    useGetInstallationSettingsForRealTimeDataQuery(
      {
        uuid: id!,
        isRealData: params.get("dummyData") === "false",
      },
      {
        refetchOnMountOrArgChange: true,
        pollingInterval: 60000,
      }
    );

  function formatValue(
    value: number | undefined,
    decimalPlaces: number,
    unit: InstallationValueUnit | undefined
  ) {
    let unitString: string;

    switch (unit) {
      case InstallationValueUnit.CELSIUS:
        unitString = "°C";
        break;
      case InstallationValueUnit.LITRE_PER_MINUTE:
        unitString = "l/min";
        break;
      case InstallationValueUnit.PERCENT:
        unitString = "%";
        break;
      case InstallationValueUnit.HERTZ:
        unitString = "Hz";
        break;
      case InstallationValueUnit.BAR:
        unitString = "bar";
        break;
      case InstallationValueUnit.REVOLUTION_PER_MINUTE:
        unitString = "rpm";
        break;
      case InstallationValueUnit.STEP:
        unitString = "p";
        break;
      case InstallationValueUnit.AMPERE:
        unitString = "A";
        break;
      case InstallationValueUnit.VOLT:
        unitString = "V";
        break;
      case InstallationValueUnit.KILOWATT:
        unitString = "kw";
        break;
      case InstallationValueUnit.WATT:
        unitString = "W";
        break;
      default:
        unitString = "";
        break;
    }
    if (value || value === 0) {
      switch (decimalPlaces) {
        case 0:
          return `${value?.toFixed(0)} ${unitString}`;
        case 1:
          return `${value?.toFixed(1)} ${unitString}`;
        case 2:
          return `${value?.toFixed(2)} ${unitString}`;
        default:
          throw new Error("decimalPlaces should be 0, 1, or 2");
      }
    } else {
      return "--";
    }
  }

  const calculateAdditionalInlineHeatingSourceStage = () => {
    const stage =
      realTimeDataState?.additionalHeatingSourcesParameters
        ?.additionalInlineHeatingSourceStage;
    return stage === 0
      ? t("pages.realTimeData.sections.inactive")
      : `${stage}/3`;
  };

  const seasonFormatting = (season: Season | undefined) => {
    switch (season) {
      case Season.HEATING:
        return t("pages.realTimeData.sections.season.heating");
      case Season.COOLING:
        return t("pages.realTimeData.sections.season.cooling");
      case Season.NEUTRAL:
        return t("pages.realTimeData.sections.season.neutral");
      default:
        return "";
    }
  };

  const systemOperationFormatting = (
    operationStatus: OperationStatus | undefined
  ) => {
    switch (operationStatus) {
      case OperationStatus.COOLING:
        return t("pages.realTimeData.sections.systemOperation.cooling");
      case OperationStatus.DHW:
        return t("pages.realTimeData.sections.systemOperation.DHW");
      case OperationStatus.HEATING:
        return t("pages.realTimeData.sections.systemOperation.heating");
      case OperationStatus.IDLE:
        return t("pages.realTimeData.sections.systemOperation.IDLE");
      case OperationStatus.ANTI_LEGIONELLA:
        return t("pages.realTimeData.sections.systemOperation.antiLegionella");
      default:
        return "";
    }
  };

  const activeOrInactiveStateFormatting = (
    state: ActiveOrInactiveEnum | undefined
  ) => {
    switch (state) {
      case ActiveOrInactiveEnum.ACTIVE:
        return t("pages.realTimeData.sections.active");
      case ActiveOrInactiveEnum.INACTIVE:
        return t("pages.realTimeData.sections.inactive");
      default:
        return "";
    }
  };

  const SGReadyStatusFormatting = (status: SgReadyStatus | undefined) => {
    switch (status) {
      case SgReadyStatus.BLOCKED:
        return t("pages.realTimeData.sections.SGReady.blocked");
      case SgReadyStatus.ENCOURAGED:
        return t("pages.realTimeData.sections.SGReady.encouraged");
      case SgReadyStatus.NORMAL:
        return t("pages.realTimeData.sections.SGReady.normal");
      case SgReadyStatus.ORDERED:
        return t("pages.realTimeData.sections.SGReady.ordered");
      default:
        return "";
    }
  };

  const extractHoursAndMinutes = (duration: string | undefined) => {
    if (duration) {
      const regex = /PT(\d+H)?(\d+M)?/;
      const matches = duration?.match(regex);

      if (!matches) {
        throw new Error("Invalid duration format");
      }

      const hours = matches[1] ? matches[1].slice(0, -1) : "0";
      const minutes = matches[2] ? matches[2].slice(0, -1) : "0";

      return `${hours}h ${minutes}m`;
    } else {
      return "";
    }
  };

  const renderZonesOperationsSections = (
    zone: 1 | 2,
    data: IStateZoneOperation | undefined,
    settingsData: IInstallationZoneOperation | undefined,
    settingsComissioningData: IZoneComissioningValues | undefined
  ) => {
    return (
      <RealTimeDataSection
        icon={zone === 1 ? <Zone1OperationsIcon /> : <Zone2OperationsIcon />}
        name={
          zone === 1
            ? t("pages.realTimeData.sections.zoneOperations.title1")
            : t("pages.realTimeData.sections.zoneOperations.title2")
        }
      >
        {/* UPDATE!!!: TO HIDE IT RTD */}
        {settingsComissioningData?.indoorSensor !==
          InstallationIndoorSensorStateEnum.NONE &&
          settingsComissioningData?.indoorSensor !==
            InstallationIndoorSensorStateEnum.UNDEFINED && (
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.zoneOperations.desiredRoomTemperature"
              )}
              value={formatValue(
                settingsData?.preferredRoomTemp?.value,
                1,
                settingsData?.preferredRoomTemp?.unit
              )}
            />
          )}
        {settingsComissioningData?.indoorSensor !==
          InstallationIndoorSensorStateEnum.NONE &&
          settingsComissioningData?.indoorSensor !==
            InstallationIndoorSensorStateEnum.UNDEFINED && (
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.zoneOperations.currentRoomTemperature",
                {
                  zoneNumber: zone,
                }
              )}
              value={formatValue(
                data?.currentRoomTemp?.value,
                1,
                data?.currentRoomTemp?.unit
              )}
            />
          )}

        {data?.circuitType ===
          InstallationComissioningZoneCircuitTypeEnum.MIXING && (
          <>
            <RealTimeDataRow
              label={t(
                `pages.realTimeData.sections.zoneOperations.TV${zone}Temperature`
              )}
              value={formatValue(
                data?.currentFlowTemp?.value,
                1,
                data?.currentFlowTemp?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                `pages.realTimeData.sections.zoneOperations.TV${zone}SetPoint`
              )}
              value={formatValue(
                data?.flowSetPoint?.value,
                1,
                data?.flowSetPoint?.unit
              )}
            />
          </>
        )}
        <RealTimeDataRow
          label={
            zone === 1
              ? t(
                  "pages.realTimeData.sections.zoneOperations.waterPumpP1Status"
                )
              : t(
                  "pages.realTimeData.sections.zoneOperations.waterPumpP2Status"
                )
          }
          value={data?.waterPumpStatus as string}
        />
      </RealTimeDataSection>
    );
  };

  if (
    selectedInstallationDetails?.lastCommunicationDate === undefined &&
    selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
  ) {
    return (
      <EmptyInstallationPage
        icon={
          <WiFiOff
            size="20"
            style={{
              color:
                theme.colors
                  .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
            }}
            strokeWidth={2}
          />
        }
        title={t("pages.installationBornOffline.titles.realTimeData")}
        description={t(
          "pages.installationBornOffline.descriptions.realTimeData"
        )}
      />
    );
  }

  return (
    <Box>
      {selectedInstallationDetails?.onlineStatus === OnlineStatus.ONLINE && realTimeDataState?.others.manualModeState === ActiveOrInactiveEnum.INACTIVE && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "52px",
            p: `0 ${theme.spacing.spacingLg}`,
            border: `1px solid ${theme.colors.colorsBorderBorderBrandSolid}`,
            borderRadius: theme.radius.radiusMd,
            m: "10px 0",
            backgroundColor:
              theme.colors.componentColorsUtilityBrandUtilityBrand100,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-sm-medium"],
              color: theme.colors.colorsTextTextPrimary,
            }}
          >
            {t("pages.realTimeData.infoMessageText")}
          </Typography>
        </Box>
      )}
      <Flowchart realTimeDataState={realTimeDataState} />
      {/* data container */}
      <Box
        sx={{
          display: "flex",
          pt: theme.spacing.spacingLg,
          flexDirection: { sm: "column", md: "row" },
        }}
      >
        {/* column 1 container */}
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {/* system operations section */}
          <RealTimeDataSection
            icon={<SystemOperationIcon />}
            name={t("pages.realTimeData.sections.systemOperation.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.space.heatpumpOnOff")}
              value={realTimeDataSettings?.operation?.hpState}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.systemOperation.operationStatus"
              )}
              value={systemOperationFormatting(
                realTimeDataState?.systemOperation?.operationStatus
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.space.outdoorTemperature")}
              value={formatValue(
                realTimeDataState?.space?.outdoorTemp?.value,
                1,
                realTimeDataState?.space?.outdoorTemp?.unit
              )}
            />
          </RealTimeDataSection>
          {/* system operations section section end*/}
          {/* season section */}
          <RealTimeDataSection
            icon={<SeasonSectionIcon />}
            name={t("pages.realTimeData.sections.season.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.season.currentSeasonLabel")}
              value={seasonFormatting(realTimeDataState?.season?.currentSeason)}
            />
          </RealTimeDataSection>
          {/* season section end*/}
          {/* zone 1 operations*/}
          {renderZonesOperationsSections(
            1,
            realTimeDataState?.zoneOperations?.zone1Operations,
            realTimeDataSettings?.zoneOperations?.zone1Operations,
            realTimeDataSettings?.installerSettings?.commissioning
              ?.zoneOperations?.zone1Operations
          )}
          {/* zone 1 operations end*/}

          {/* zone 2 operations*/}
          {realTimeDataSettings &&
            realTimeDataSettings?.installerSettings?.commissioning?.zonesCount
              ?.value > 1 &&
            renderZonesOperationsSections(
              2,
              realTimeDataState?.zoneOperations?.zone2Operations,
              realTimeDataSettings?.zoneOperations?.zone2Operations,
              realTimeDataSettings?.installerSettings?.commissioning
                ?.zoneOperations?.zone2Operations
            )}
          {/* zone 2 operations end*/}

          {/* DHW section*/}
          {realTimeDataSettings?.installerSettings?.commissioning?.dhw
            ?.active === ActiveOrInactiveEnum.ACTIVE && (
            <RealTimeDataSection
              icon={<DHWIcon />}
              name={t("pages.realTimeData.sections.DHW.title")}
            >
              <RealTimeDataRow
                label={t("pages.realTimeData.sections.DHW.setDHWTemperature")}
                value={formatValue(
                  realTimeDataSettings?.dhw?.preferredTemp?.value,
                  1,
                  realTimeDataSettings?.dhw?.preferredTemp?.unit
                )}
              />
              <RealTimeDataRow
                label={t(
                  "pages.realTimeData.sections.DHW.DHWTankTemperatureTWD"
                )}
                value={formatValue(
                  realTimeDataState?.dhw?.currentTemp?.value,
                  1,
                  realTimeDataState?.dhw?.currentTemp?.unit
                )}
              />
              {realTimeDataState?.dhw?.antiLegionella?.state ===
                ActiveOrInactiveEnum.ACTIVE && (
                <RealTimeDataRow
                  label={t(
                    "pages.realTimeData.sections.DHW.AntiLegionellaCurrentState"
                  )}
                  value={activeOrInactiveStateFormatting(
                    realTimeDataState?.dhw?.antiLegionella?.state
                  )}
                />
              )}
            </RealTimeDataSection>
          )}
          {/* DHW section end*/}

          {/* Modes section */}
          <RealTimeDataSection
            icon={<ModesIcon />}
            name={t("pages.realTimeData.sections.modes.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.modes.defrostingMode")}
              value={t(
                `statuses.${
                  realTimeDataState?.refrigerantParameters?.defrostingActive
                    ? "on"
                    : "off"
                }`
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.modes.quietMode")}
              value={t(
                `statuses.${
                  realTimeDataState?.quietMode?.state ===
                  ActiveOrInactiveEnum.ACTIVE
                    ? "on"
                    : "off"
                }`
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.modes.vacationMode")}
              value={t(
                `statuses.${
                  realTimeDataState?.vacationMode?.state ===
                  ActiveOrInactiveEnum.ACTIVE
                    ? "on"
                    : "off"
                }`
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.modes.reducedMode")}
              value={t(
                `statuses.${
                  realTimeDataState?.reducedMode?.state ===
                  ActiveOrInactiveEnum.ACTIVE
                    ? "on"
                    : "off"
                }`
              )}
            />
          </RealTimeDataSection>

          {/* Modes section end */}

          {/* outdoor temperature section */}
          <RealTimeDataSection
            icon={<OutdoorTemperatureIcon />}
            name={t("pages.realTimeData.sections.outdoorTemperature.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.outdoorTemperature"
              )}
              value={formatValue(
                realTimeDataState?.space?.outdoorTemp?.value,
                1,
                realTimeDataState?.space?.outdoorTemp?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.averageOutdoorTemperature1h"
              )}
              value={formatValue(
                realTimeDataState?.outdoorTemp?.average1h?.value,
                1,
                realTimeDataState?.outdoorTemp?.average1h?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.averageOutdoorTemperature3h"
              )}
              value={formatValue(
                realTimeDataState?.outdoorTemp?.average3h?.value,
                1,
                realTimeDataState?.outdoorTemp?.average3h?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.averageOutdoorTemperature24h"
              )}
              value={formatValue(
                realTimeDataState?.outdoorTemp?.average24h?.value,
                1,
                realTimeDataState?.outdoorTemp?.average24h?.unit
              )}
            />
          </RealTimeDataSection>
          {/* outdoor temperature section end */}
          <Box sx={{ height: theme.spacing.spacing2xl }} />
        </Box>
        {/* column 1 container end*/}

        {/* column 2 container*/}
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {/* hydraulic parameters section */}
          <RealTimeDataSection
            icon={<HydraulicParametersIcon />}
            name={t("pages.realTimeData.sections.hydraulicParameters.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.heatBalance"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters?.heatBalance?.value || 0,
                0,
                realTimeDataState?.hydraulicParameters?.heatBalance?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.DHW.coolingHeatingWaterTemperatureTHC"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters?.waterTemperatureTHC
                  ?.value,
                1,
                realTimeDataState?.hydraulicParameters?.waterTemperatureTHC
                  ?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.setPointTHC"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters?.setPointTHC?.value,
                1,
                realTimeDataState?.hydraulicParameters?.setPointTHC?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.condenserOutletWaterTemperatureTUO"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters
                  ?.condenserOutletWaterTempTUO?.value,
                1,
                realTimeDataState?.hydraulicParameters
                  ?.condenserOutletWaterTempTUO?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.condenserInletWaterTemperatureTUI"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters
                  ?.condenserInletWaterTempTUI?.value,
                1,
                realTimeDataState?.hydraulicParameters
                  ?.condenserInletWaterTempTUI?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.waterPumpP0Status"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters?.waterPumpP0Status
                  ?.value,
                0,
                realTimeDataState?.hydraulicParameters?.waterPumpP0Status?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.waterFlowRate"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters?.waterFlowRate?.value,
                1,
                realTimeDataState?.hydraulicParameters?.waterFlowRate?.unit
              )}
            />
          </RealTimeDataSection>
          {/* hydraulic parameters section end*/}
          {/* refrigerant parameters section */}
          <RealTimeDataSection
            icon={<RefrigerantParametersIcon />}
            name={t("pages.realTimeData.sections.refrigerantParameters.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.compressorSpeed"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.compressorSpeed
                  ?.value,
                0,
                realTimeDataState?.refrigerantParameters?.compressorSpeed?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.highPressurePd"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.highPressurePd?.value,
                1,
                realTimeDataState?.refrigerantParameters?.highPressurePd?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.compressorDischargeTemperatureTD"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters
                  ?.compressorDischargeTempTD?.value,
                1,
                realTimeDataState?.refrigerantParameters
                  ?.compressorDischargeTempTD?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.lowPressurePs"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.lowPressurePs?.value,
                1,
                realTimeDataState?.refrigerantParameters?.lowPressurePs?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.compressorSuctionTemperatureTS"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters
                  ?.compressorSuctionTempTS?.value,
                1,
                realTimeDataState?.refrigerantParameters
                  ?.compressorSuctionTempTS?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.condensingTemperatureTUP"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.condensingTempTUP
                  ?.value,
                1,
                realTimeDataState?.refrigerantParameters?.condensingTempTUP
                  ?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.evaporatingTemperatureTP"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.evaporatingTempTP
                  ?.value,
                1,
                realTimeDataState?.refrigerantParameters?.evaporatingTempTP
                  ?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.EEVOpening"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.eevOpening?.value,
                0,
                realTimeDataState?.refrigerantParameters?.eevOpening?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.fanSpeed1"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.fanSpeed1?.value,
                0,
                realTimeDataState?.refrigerantParameters?.fanSpeed1?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.fanSpeed2"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters?.fanSpeed2?.value,
                0,
                realTimeDataState?.refrigerantParameters?.fanSpeed2?.unit
              )}
            />
          </RealTimeDataSection>
          {/* refrigerant parameters section end*/}

          {/* SG ready section */}
          {realTimeDataState?.sgReady?.status && (
            <RealTimeDataSection
              icon={<SGReadyIcon />}
              name={t("pages.realTimeData.sections.SGReady.title")}
            >
              <RealTimeDataRow
                label={t("pages.realTimeData.sections.SGReady.SGReadyStage")}
                value={SGReadyStatusFormatting(
                  realTimeDataState?.sgReady.status
                )}
              />
            </RealTimeDataSection>
          )}
          {/* SG ready section end */}

          {/* Additional heating sources section */}
          {((realTimeDataSettings?.installerSettings?.commissioning?.dhw
            ?.active === ActiveOrInactiveEnum.ACTIVE &&
            realTimeDataState?.additionalHeatingSourcesParameters?.dhw
              ?.state === ActiveOrInactiveEnum.ACTIVE) ||
            (realTimeDataSettings?.installerSettings?.commissioning?.bufferTank
              ?.state === ActiveOrInactiveEnum.ACTIVE &&
              realTimeDataState?.additionalHeatingSourcesParameters?.bufferTank
                ?.state === ActiveOrInactiveEnum.ACTIVE) ||
            realTimeDataSettings?.installerSettings?.commissioning
              ?.additionalInlineHeatingSource?.state ===
              ActiveOrInactiveEnum.ACTIVE ||
            realTimeDataState?.additionalHeatingSourcesParameters
              ?.additionalInlineHeatingSourceStage1 ===
              ActiveOrInactiveEnum.ACTIVE ||
            realTimeDataState?.additionalHeatingSourcesParameters
              ?.additionalInlineHeatingSourceStage2 ===
              ActiveOrInactiveEnum.ACTIVE ||
            realTimeDataState?.additionalHeatingSourcesParameters
              ?.additionalInlineHeatingSourceStage3 ===
              ActiveOrInactiveEnum.ACTIVE) && (
            <RealTimeDataSection
              icon={<AdditionalHeatingSourcesIcon />}
              name={t(
                "pages.realTimeData.sections.additionalHeatingSources.title"
              )}
            >
              {realTimeDataState?.additionalHeatingSourcesParameters
                .additionalInlineHeatingSourceStage !== undefined && (
                <RealTimeDataRow
                  label={t(
                    "pages.realTimeData.sections.additionalHeatingSources.additionalInlineHeatingSource"
                  )}
                  value={calculateAdditionalInlineHeatingSourceStage()}
                />
              )}

              {realTimeDataSettings?.installerSettings?.commissioning
                ?.additionalDhwHeatingSource?.state ===
                ActiveOrInactiveEnum.ACTIVE &&
                realTimeDataState?.additionalHeatingSourcesParameters?.dhw
                  ?.state && (
                  <RealTimeDataRow
                    label={t(
                      "pages.realTimeData.sections.additionalHeatingSources.additionalDHWTankHeatingSource"
                    )}
                    value={activeOrInactiveStateFormatting(
                      realTimeDataState?.additionalHeatingSourcesParameters?.dhw
                        ?.state
                    )}
                  />
                )}
              {realTimeDataSettings?.installerSettings?.commissioning
                ?.bufferTank?.inlineHeaterState ===
                ActiveOrInactiveEnum.ACTIVE &&
                realTimeDataState?.additionalHeatingSourcesParameters
                  ?.bufferTank?.state && (
                  <RealTimeDataRow
                    label={t(
                      "pages.realTimeData.sections.additionalHeatingSources.additionalBufferTankHeatingSource"
                    )}
                    value={activeOrInactiveStateFormatting(
                      realTimeDataState?.additionalHeatingSourcesParameters
                        ?.bufferTank?.state
                    )}
                  />
                )}
            </RealTimeDataSection>
          )}
          {/* Additional heating sources section end*/}

          {/* Accumulate Operation time section */}
          <RealTimeDataSection
            icon={<AccumulateOperationTimeIcon />}
            name={t(
              "pages.realTimeData.sections.accumulatedOperationTime.title"
            )}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.accumulatedOperationTime.heatPump"
              )}
              value={extractHoursAndMinutes(
                realTimeDataState?.accumulatedOperationTime.heatPump
              )}
            />
            {realTimeDataSettings?.installerSettings?.commissioning
              ?.additionalInlineHeatingSource?.state ===
              ActiveOrInactiveEnum.ACTIVE && (
              <RealTimeDataRow
                label={t(
                  "pages.realTimeData.sections.accumulatedOperationTime.additionalInlineHeatingSourceStage1"
                )}
                value={extractHoursAndMinutes(
                  realTimeDataState?.accumulatedOperationTime
                    ?.additionalInlineHeatingSource1
                )}
              />
            )}
            {realTimeDataSettings?.installerSettings?.commissioning
              ?.additionalInlineHeatingSource?.state ===
              ActiveOrInactiveEnum.ACTIVE && (
              <RealTimeDataRow
                label={t(
                  "pages.realTimeData.sections.accumulatedOperationTime.additionalInlineHeatingSourceStage2"
                )}
                value={extractHoursAndMinutes(
                  realTimeDataState?.accumulatedOperationTime
                    ?.additionalInlineHeatingSource2
                )}
              />
            )}
            {realTimeDataSettings?.installerSettings?.commissioning
              ?.additionalInlineHeatingSource?.state ===
              ActiveOrInactiveEnum.ACTIVE && (
              <RealTimeDataRow
                label={t(
                  "pages.realTimeData.sections.accumulatedOperationTime.additionalInlineHeatingSourceStage3"
                )}
                value={extractHoursAndMinutes(
                  realTimeDataState?.accumulatedOperationTime
                    ?.additionalInlineHeatingSource3
                )}
              />
            )}
            {realTimeDataSettings?.installerSettings?.commissioning?.dhw
              ?.active === ActiveOrInactiveEnum.ACTIVE &&
              realTimeDataState?.additionalHeatingSourcesParameters?.dhw
                ?.state === ActiveOrInactiveEnum.ACTIVE && (
                <RealTimeDataRow
                  label={t(
                    "pages.realTimeData.sections.accumulatedOperationTime.additionalDHWTankHeatingSource"
                  )}
                  value={extractHoursAndMinutes(
                    realTimeDataState?.accumulatedOperationTime
                      ?.additionalDHWHeatingSource
                  )}
                />
              )}
            {realTimeDataSettings?.installerSettings?.commissioning?.bufferTank
              ?.state === ActiveOrInactiveEnum.ACTIVE &&
              realTimeDataState?.additionalHeatingSourcesParameters?.bufferTank
                ?.state === ActiveOrInactiveEnum.ACTIVE && (
                <RealTimeDataRow
                  label={t(
                    "pages.realTimeData.sections.accumulatedOperationTime.additionalBufferTankHeatingSource"
                  )}
                  value={extractHoursAndMinutes(
                    realTimeDataState?.accumulatedOperationTime
                      ?.additionalBufferTankHeatingSource
                  )}
                />
              )}
          </RealTimeDataSection>
          {/* Accumulate Operation time section end*/}

          {/* Other section */}
          <RealTimeDataSection
            icon={<OtherIcon />}
            name={t("pages.realTimeData.sections.other.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.other.outdoorUnitCurrent")}
              value={formatValue(
                realTimeDataState?.others?.outdoorUnitCurrent?.value,
                0,
                realTimeDataState?.others?.outdoorUnitCurrent?.unit
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.other.outdoorUnitVoltage")}
              value={formatValue(
                realTimeDataState?.others?.outdoorUnitVoltage?.value,
                0,
                realTimeDataState?.others?.outdoorUnitVoltage?.unit
              )}
            />
          </RealTimeDataSection>
          {/* Other section */}
        </Box>
        {/* column 2 container end*/}
      </Box>
      {/* data container end*/}
    </Box>
  );
}
