import { useEffect, useState } from "react";
import {
  IUseZoneAndInstallerZoneCommonParameters,
  IZoneKeys,
} from "../../types";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import { useToast } from "../../../../../../../../hooks/useToast";
import {
  installationApi,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningZoneOperation,
  ISetOperationZonesParams,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import { ZoneOperationsKey } from "../../../../../../../../store/services/models/installations/installationsCommon";
import { ToastStatuses } from "../../../../../../../../components/Toaster/StatusIcon/Types";
import { transformDifferencesForRequest } from "../../../../helpers/settingsHelpers";

export default function useZoneAndInstallerZoneCommon({
  isInstallerZones = false,
  zoneFormValueDifferencesWithInitialValue,
  zonesCountDataDifferencesWithInitialZonesCount,
  installerZoneFormValueDifferencesWithInitialValue,
  installationSettingsResponse,
  zoneFormData,
  zonesCountData,
  installerSettingsFormData,
  setIsPoolingIntervalEnabled,
  discardFormChanges,
  discardZoneCountChanges,
  discardInstallerZoneSettingsFormChanges,
}: IUseZoneAndInstallerZoneCommonParameters) {
  const { zoneNumber, id } = useParams();

  const dispatch = useAppDispatch();

  const { showToast } = useToast();

  const [setInstallationSettings] = useSetInstallationSettingsMutation();

  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const zoneKeys: IZoneKeys = {
    zoneOperationKey: `zone${zoneNumber?.slice(
      -1
    )}Operations` as ZoneOperationsKey,
    zoneNumber: zoneNumber?.slice(-1),
  };

  useEffect(() => {
    const hasChanges = Boolean(
      zoneFormValueDifferencesWithInitialValue ||
        zonesCountDataDifferencesWithInitialZonesCount ||
        installerZoneFormValueDifferencesWithInitialValue
    );

    setIsPoolingIntervalEnabled(!hasChanges);
    setIsSomethingChanged(hasChanges);
  }, [
    zoneFormValueDifferencesWithInitialValue,
    zonesCountDataDifferencesWithInitialZonesCount,
    installerZoneFormValueDifferencesWithInitialValue,
    setIsPoolingIntervalEnabled,
  ]);

  const saveFormChanges = () => {
    setIsConfirmChangesModalOpen(true);
  };

  const confirmFormChanges = (errorMessage: string) => {
    setIsConfirmChangesModalOpen(false);
    const zoneFormattedChanges: ISetOperationZonesParams =
      transformDifferencesForRequest(zoneFormValueDifferencesWithInitialValue);
    const installerZoneFormattedChanges: ISetInstallerSettignsComissioningZoneOperation =
      transformDifferencesForRequest(
        installerZoneFormValueDifferencesWithInitialValue
      );
    let requestBody: Partial<ISetInstallationSettings> = {};
    let cacheData: IInstallationSettingsByIdResponse;
    if (!isInstallerZones) {
      requestBody = {
        zoneOperations: {
          [zoneKeys.zoneOperationKey]: zoneFormattedChanges,
        },
      };
    } else {
      if (zoneFormattedChanges) {
        requestBody = {
          zoneOperations: {
            [zoneKeys.zoneOperationKey]: zoneFormattedChanges,
          },
        };
      }

      if (
        zonesCountDataDifferencesWithInitialZonesCount ||
        installerZoneFormValueDifferencesWithInitialValue
      ) {
        if (zonesCountDataDifferencesWithInitialZonesCount) {
          requestBody = {
            ...requestBody,
            installerSettings: {
              ...requestBody.installerSettings,
              commissioning: {
                ...requestBody.installerSettings?.commissioning,
                zonesCount: zonesCountData,
              },
            },
          };
        }

        if (installerZoneFormValueDifferencesWithInitialValue) {
          requestBody = {
            ...requestBody,
            installerSettings: {
              ...requestBody.installerSettings,
              commissioning: {
                ...requestBody.installerSettings?.commissioning,
                zoneOperations: {
                  ...requestBody.installerSettings?.commissioning
                    ?.zoneOperations,
                  [zoneKeys.zoneOperationKey]: installerZoneFormattedChanges,
                },
              },
            },
          };
        }
      }
    }

    cacheData = {
      ...(installationSettingsResponse as IInstallationSettingsByIdResponse),
      zoneOperations: {
        ...installationSettingsResponse!.zoneOperations,
        [zoneKeys.zoneOperationKey]: zoneFormData,
      },
      installerSettings: {
        ...installationSettingsResponse!.installerSettings,
        commissioning: {
          ...installationSettingsResponse!.installerSettings!.commissioning,
          zonesCount: {
            ...installationSettingsResponse!.installerSettings!.commissioning!
              .zonesCount,
            value: zonesCountData,
          },
          [zoneKeys.zoneOperationKey]: installerSettingsFormData,
        },
      },
    };
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id! },
            (draftSettings) => {
              Object.assign(draftSettings, cacheData);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((_error) => {
        showToast({
          title: errorMessage,
          status: ToastStatuses.Error,
        });
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  const handleDiscardClick = () => {
    discardFormChanges();
    if (isInstallerZones) {
      discardZoneCountChanges();
      discardInstallerZoneSettingsFormChanges();
    }
  };
  return {
    isSomethingChanged,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    setIsNotificationModalOpen,
    setIsConfirmChangesModalOpen,
    saveFormChanges,
    confirmFormChanges,
    handleDiscardClick,
  };
}
