import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, SxProps, Typography } from "@mui/material";
import useTheme, { ESThemeType } from "../../../../../theme/hooks/useTheme";
import { Button, ButtonSize, ButtonType } from "../../../../../components/Button/Button";
import { use, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { locales } from "../../../../../util/DateLocales";
import { enUS } from "date-fns/locale";
import DatePickerInput from "../components/DatePickerInput/DatePickerInput";
import DropdownScrollableWithCheckboxesAndSections from "../../../../../components/DropdownScrollableWithCheckboxesAndSections/DropdownScrollableWithCheckboxesAndSections";
import FilterButton from "../../../../../components/Button/FilterButton";
import { useLazyGetInstallationRuntimeHistoryForGraphQuery } from "../../../../../store/services/installation";
import { toEndOfDay, toLocalISOString, toStartOfDay } from "../../../../../util/DateUtil";
import Graph, { GraphRef } from "../components/Graph/Graph";
import { SensorData } from "../../../../../store/services/models/analytics/analytics";
import { Download02 } from "untitledui-js-base";
import { useTypedSelector } from "../../../../../store";
import { selectSelectedInstallationDetails } from "../../../../../store/reducers/installationSlice";
import useParametersData from "../hooks/useParametersData";

type TypeTimeFrameMode = "24" | "48" | "custom";

function splitByWeek(startDateTime: string, endDateTime: string): { start: string; end: string }[] {
  const result = [];
  let currentStart = new Date(startDateTime);
  let end = new Date(endDateTime);

  while (currentStart < end) {
    let currentEnd = new Date(currentStart);
    currentEnd.setDate(currentEnd.getDate() + 6);

    if (currentEnd > end) currentEnd = new Date(end);

    result.push({
      start: toLocalISOString(currentStart, true),
      end: toLocalISOString(currentEnd, true),
    });

    currentStart.setDate(currentStart.getDate() + 7);
  }

  return result;
}

export default function GraphReport({ installationId }: { installationId: string }) {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { t, i18n } = useTranslation("cloud_ui", { keyPrefix: "pages.analyticsPage" });
  const currentLocale = locales[i18n.language as keyof typeof locales] || enUS;
  const selectedInstallationDetails = useTypedSelector(selectSelectedInstallationDetails);
  const { availableParametersForDropdown } = useParametersData();

  const [loadGraph, { isLoading, isFetching, isError }] =
    useLazyGetInstallationRuntimeHistoryForGraphQuery();
  const [currentData, setCurrentData] = useState<Array<SensorData>>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedParameters, setSelectedParameters] = useState<Array<string>>([]);
  const [timeFrameMode, setTimeFrameMode] = useState<TypeTimeFrameMode>();

  const graphRef = useRef<GraphRef>(null);

  const todayDate = useMemo(() => new Date(), []);
  const maxStartDate = new Date(todayDate);
  const minStartDate = useMemo(() => {
    const date = new Date(todayDate);
    date.setDate(todayDate.getDate() - 30);
    return date;
  }, [todayDate]);
  const maxEndDate = new Date(todayDate);
  const minEndDate = useMemo(() => {
    return startDate || minStartDate;
  }, [minStartDate, startDate]);

  const handleStartDateChange = (date: Date | null) => {
    setTimeout(() => {
      setTimeFrameMode("custom");
      setStartDate(date);
      if (date && endDate && date > endDate) {
        setEndDate(null);
      }
    }, 300);
  };

  const handleEndDateChange = (date: Date | null) => {
    setTimeout(() => {
      setTimeFrameMode("custom");
      setEndDate(date);
    }, 300);
  };

  const getFreshedTimeFrame = () => {
      const freshDate = new Date();
      switch (timeFrameMode) {
        case "48":
          const date48 = new Date(freshDate);
          date48.setTime(freshDate.getTime() - 48 * 60 * 60 * 1000);
          return {start: date48, end: freshDate};
        default:
        case "24":
          const date24 = new Date(freshDate);
          date24.setTime(freshDate.getTime() - 24 * 60 * 60 * 1000);
          return {start: date24, end: freshDate};
      }
  };

  const graphGenerate = async () => {
    const { start: timeStart, end: timeEnd } = timeFrameMode && timeFrameMode !== "custom" 
      ? getFreshedTimeFrame()
      : { start: startDate, end: endDate };
    
    const startDateTime = toLocalISOString(new Date(timeStart!), true);
    const endDateTime = toLocalISOString(toEndOfDay(new Date(timeEnd!)), true);
    setCurrentData([]);
    const weeks = splitByWeek(startDateTime, endDateTime);

    const results = await Promise.all(
      weeks.map(async (week) => {
        const { history } = await loadGraph({
          installationUuid: installationId!,
          startDateTime: encodeURIComponent(week.start),
          endDateTime: encodeURIComponent(week.end),
          param: selectedParameters,
        }).unwrap();
        return history || [];
      }),
    );

    setCurrentData(results.flat());
  };
  useEffect(() => {
    setTimeFrameMode("24");
  }, []);

  return (
    <Grid container flexDirection={"column"} sx={styles.wrapper}>
      <Grid item>
        <Box sx={styles.headerContainer}>
          <Typography sx={styles.title}>{t("graphPage.title")}</Typography>
          <Button
            label={t("graphPage.createGraphBtn")}
            disabled={(timeFrameMode === "custom" && (!startDate || !endDate)) || !selectedParameters.length}
            onClick={graphGenerate}
          />
        </Box>
        <Divider sx={styles.hDivider} />
      </Grid>
      <Grid item>
        <Grid
          container
          flexDirection={"row"}
          paddingY={theme.spacing.spacingXl}
          rowSpacing={theme.spacing.spacingXl}
        >
          <Grid item flex={1} pb={theme.spacing.spacingXl}>
            <Grid container flexDirection={"row"} gap={theme.spacing.spacingXl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLocale}>
                <DatePickerInput
                  date={timeFrameMode === "custom" ? startDate : null}
                  handleDateChange={handleStartDateChange}
                  maxDate={maxStartDate}
                  minDate={minStartDate}
                  placeholder={t("selectStartDate")}
                />
                <DatePickerInput
                  date={timeFrameMode === "custom" ? endDate : null}
                  handleDateChange={handleEndDateChange}
                  maxDate={maxEndDate}
                  minDate={minEndDate}
                  placeholder={t("selectEndDate")}
                />
              </LocalizationProvider>
              <Grid item flexDirection={"row"} display={"flex"}>
                <FilterButton
                  label={t("graphPage.selectDatePeriodBtn", { number: 24 })}
                  isSelected={timeFrameMode === "24"}
                  isFirst={true}
                  isLast={false}
                  onClick={() => setTimeFrameMode("24")}
                />
                <FilterButton
                  label={t("graphPage.selectDatePeriodBtn", { number: 48 })}
                  isSelected={timeFrameMode === "48"}
                  isFirst={false}
                  isLast={true}
                  onClick={() => setTimeFrameMode("48")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              flexDirection={"row"}
              justifyContent={"flex-end"}
              gap={theme.spacing.spacingXl}
            >
              <DropdownScrollableWithCheckboxesAndSections
                buttonLabel={t("selectParameters")}
                items={availableParametersForDropdown}
                selectAllLabel={t("selectAll")}
                selectedValues={selectedParameters}
                setSelectedValues={setSelectedParameters}
                limit={10}
              />
              <Button
                Icon={Download02}
                buttonSize={ButtonSize.sm}
                buttonType={ButtonType.SecondaryGray}
                label={t("graphPage.downloadImageBtn")}
                disabled={!currentData}
                onClick={async () => {
                  const imgUrl = await graphRef.current?.getImageUrl();
                  const locale = i18n.language;
                  if (imgUrl) {
                    const link = document.createElement("a");
                    link.href = imgUrl;
                    link.download = `GraphReport_${
                      selectedInstallationDetails?.name ||
                      selectedInstallationDetails?.connectivityId
                    }_${startDate?.toLocaleDateString(locale)}-${endDate?.toLocaleDateString(
                      locale,
                    )}.jpeg`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Graph
          ref={graphRef}
          data={currentData || []}
          isLoading={isLoading || isFetching}
          isError={isError}
        />
      </Grid>
    </Grid>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    wrapper: {
      padding: spacing.spacingXl,
      flexGrow: 1,
      width: "100%",
    } as SxProps,
    title: {
      ...typography[".text-xl-semibold"],
      color: colors.colorsTextTextPrimary,
    } as SxProps,
    hDivider: {
      width: "100%",
      borderColor: colors.colorsBorderBorderSecondary,
    } as SxProps,
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      pb: spacing.spacingLg,
    } as SxProps,
  };
};
