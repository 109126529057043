import { Box, Checkbox, FormControlLabel, SxProps } from "@mui/material";
import React from "react";
import { Check } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";

export interface ICheckboxWithLabelProps {
  checkedCondition: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  sx?: SxProps;
}

export default function CheckboxWithLabel({
  checkedCondition,
  onChange,
  label,
  disabled,
  sx,
}: ICheckboxWithLabelProps) {
  const theme = useTheme();
  const baseCheckboxStyles: SxProps = {
    height: "16px",
    width: "16px",
    borderRadius: "5px",
    border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
  };

  const selectedCheckboxStyles: SxProps = {
    ...baseCheckboxStyles,
    border: `1px solid ${theme.colors.colorsBackgroundBgBrandSolid}`,
    backgroundColor: theme.colors.colorsBackgroundBgBrandSolid,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box
      sx={{
        ...sx,  
        display: "flex",
        alignItems: "center",
        p: `${theme.spacing.spacingSm} ${theme.spacing.spacingXl}`,
        maxWidth: "350px",
        ".MuiTypography-root": {
          ...theme.typography[".text-sm-medium"],
          color: theme.colors.colorsTextTextSecondary,
        },
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={checkedCondition}
            onChange={(event, checked) => onChange(checked)}
            icon={<Box sx={baseCheckboxStyles} />}
            checkedIcon={
              <Box sx={selectedCheckboxStyles}>
                <Check
                  style={{
                    color: theme.colors.colorsForegroundFgWhite,
                  }}
                  size="12"
                  strokeWidth={2}
                />
              </Box>
            }
          />
        }
        label={label || ""}
      />
    </Box>
  );
}
