import React, { ReactElement, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { Box, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";

export interface IInfoTooltipProps {
  title?: string;
  description?: string | ReactElement;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
};

const InfoTooltip = ({ title, description }: IInfoTooltipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <IconButton aria-label="info" onClick={() => setIsOpen(true)}>
        <InfoIcon
          sx={{
            color: "#bdbdbd",
          }}
        />
      </IconButton>
      <Modal
        keepMounted
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="info-modal"
        aria-describedby="info-modal-description"
      >
        <Box>
          <Paper sx={{ ...style, width: 550, maxWidth: "calc(100vw - 5%)" }}>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <IconButton onClick={() => setIsOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Typography
              component="h4"
              variant="h4"
              noWrap
              sx={{
                color: "#212121",
                flexGrow: 1,
                pt: "20px",
                fontSize: "24px",
                whiteSpace: "normal",
              }}
            >
              {title}
            </Typography>
            <Typography
              component="h5"
              variant="h5"
              sx={{
                color: "#666",
                flexGrow: 1,
                pt: "20px",
                fontSize: "16px",
                mb: "20px",
              }}
            >
              {description}
            </Typography>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

export default InfoTooltip;
