import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../theme/hooks/useTheme";
import useSupportData from "../../hooks/useSupportData";
import SimpleButton from "../../../SimpleButton/SimpleButton";
import SupportFormContent from "../SupportFormContent/SupportFormContent";
import SupportFormContext from "../../context/SupportFormContext";
import radius from "../../../../styles/spacing/radius";
import { getScrollStyles } from "../../../../styles/stylesHelpers/scrollStyles";

export default function SupportFormModal({
  isOpen,
  onClose,
}: ISupportFormModalProps) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.support_form.modal",
  });
  const theme = useTheme();
  const { colors, spacing, typography } = theme;
  const {
    priorities,
    hints,
    supportFormData,
    supportFormErrors,
    isRequestSendingInPropgress,
    changePriority,
    changeDescription,
    changeImages,
    handleSendClick,
    handleCancelClick,
  } = useSupportData(onClose);

  const styles: Record<string, SxProps> = {
    wrapper: {
      color: colors.colorsTextTextPrimary,
      ".MuiPaper-root": {
        width: "640px",
        borderRadius: radius.radiusXl,
        maxWidth: "initial",
      },
    },
    title: {
      ...typography[".text-lg-semibold"],
      p: `${spacing.spacing3xl} ${spacing.spacing3xl} 0`,
      mb: spacing.spacing3xl,
    },
    content: {
      ...getScrollStyles({ theme }),
      p: `0 ${spacing.spacing3xl}`,
      maxHeight: "500px",
      overflowY: "auto",
    },

    actions: {
      p: `0 ${spacing.spacing3xl} ${spacing.spacing3xl}`,
      mt: spacing.spacing4xl,
    },
  };

  return (
    <Dialog open={isOpen} fullWidth sx={styles.wrapper}>
      <DialogTitle sx={styles.title}>{t("title")}</DialogTitle>
      <SupportFormContext.Provider
        value={{
          priorities,
          supportFormData,
          supportFormErrors,
          hints,
          changePriority,
          changeDescription,
          changeImages,
          handleSendClick,
          handleCancelClick,
        }}
      >
        <DialogContent sx={styles.content}>
          <SupportFormContent />
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <SimpleButton
            text={t("actions.cancel")}
            type="Secondary"
            isLoading={isRequestSendingInPropgress}
            onClick={() => {
              handleCancelClick();
            }}
          />
          <SimpleButton
            text={t("actions.send")}
            type="Primary"
            isLoading={isRequestSendingInPropgress}
            onClick={() => {
              handleSendClick();
            }}
          />
        </DialogActions>
      </SupportFormContext.Provider>
    </Dialog>
  );
}
