import React from "react";

import DisabledField from "../../../../../components/DisabledField/DisabledField";
import SimpleDropdown from "../../../../../components/SimpleDropdown/SimpleDropdown";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import SettingsDivider from "../../../../../components/SettingsDivider/SettingsDivider";

import FlowTemperatureLimitsSection from "../FlowTemperatureLimitsSection/FlowTemperatureLimitsSection";

import useMainSection from "../../../hooks/mainSection/useMainSection";

import { FlowTemperatureSettingsTypes } from "../../../types";

export default function MainSection() {
  const {
    isInstallationOffline,
    isInstallerZones,
    showModeReadOnlyField,
    showMixingValveTurningTimeField,
    showModeDropdown,
    showFlowTempCoolingLimits,
    showFlowTempHeatingLimits,

    formattedModeValue,
    formattedCircuitTypeValue,
    modeDropdownOptions,
    modeReadOnlyFieldLabel,
    circuitTypeReadOnlyFieldLabel,
    mixingValveTurningTimeFieldLabel,
    modeDropdownLabel,

    installerSettingsFormData,

    updateInstallerZoneFormData,
    updateInstallerZoneMode,
  } = useMainSection();

  return (
    <>
      {showModeReadOnlyField && (
        <DisabledField
          label={modeReadOnlyFieldLabel}
          value={formattedModeValue}
        />
      )}
      {isInstallerZones && (
        <>
          <DisabledField
            label={circuitTypeReadOnlyFieldLabel}
            value={formattedCircuitTypeValue}
          />
          {showMixingValveTurningTimeField && (
            <NumberInput
              initialValue={
                installerSettingsFormData?.mixingValveTurningTime.value
              }
              unit={installerSettingsFormData?.mixingValveTurningTime.unit}
              changeValue={(val) => {
                updateInstallerZoneFormData!(val, "mixingValveTurningTime");
              }}
              label={mixingValveTurningTimeFieldLabel}
              disabled={isInstallationOffline}
              min={installerSettingsFormData?.mixingValveTurningTime.min}
              max={installerSettingsFormData?.mixingValveTurningTime.max}
              decimalPlaces={0}
              step={1}
            />
          )}
          {showModeDropdown && (
            <SimpleDropdown
              disabled={isInstallationOffline}
              label={modeDropdownLabel}
              currentValue={installerSettingsFormData?.mode}
              dropdownOptions={modeDropdownOptions}
              onChange={(val) => updateInstallerZoneMode!(val)}
            />
          )}
          <SettingsDivider />
          {showFlowTempHeatingLimits && (
            <FlowTemperatureLimitsSection
              type={FlowTemperatureSettingsTypes.Heating}
            />
          )}
          {showFlowTempHeatingLimits && showFlowTempCoolingLimits && (
            <SettingsDivider />
          )}
          {showFlowTempCoolingLimits && (
            <FlowTemperatureLimitsSection
              type={FlowTemperatureSettingsTypes.Cooling}
            />
          )}
        </>
      )}
    </>
  );
}
