import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { TableContainerWithCustomScroll } from "../EnergySavingCalculatorPage";
import { useTranslation } from "react-i18next";
import { defineSourceAndUnit } from "../helpers/defineSourceAndUnit";
import { IAlternativeSource } from "../DownloadReportInterface";

export interface IInputEnergyComparisionTableProps {
  showEnergyPricing: boolean;
  currency: string | null | undefined;
  totalEnergyEfficiency: string | undefined;
  totalInputEnergy: string | undefined;
  totalCosts: string | undefined;
  currentEnergySource: string | undefined;
  currentEnergyPrice: string | undefined;
  electricityPrice: string | undefined;
  totalEnergyDemand: string;
  currentEnergyEfficiency: string;
}

export const InputEnergyComparisionTable = forwardRef(
  (
    {
      showEnergyPricing,
      currency,
      totalEnergyEfficiency,
      totalInputEnergy,
      totalCosts,
      currentEnergySource,
      currentEnergyPrice,
      electricityPrice,
      totalEnergyDemand,
      currentEnergyEfficiency,
    }: IInputEnergyComparisionTableProps,
    ref
  ) => {
    const SavingTableRow = styled(TableRow)(({ theme }) => ({
      [`th, td`]: {
        padding: `6px 16px`,
        fontSize: "1rem",
      },
      "&:last-child td, &:last-child th": { border: 0 },
    }));
    const { t } = useTranslation("cloud_ui");
    const [
      electricHeatingInputEnergyNeeded,
      setElectricHeatingInputEnergyNeeded,
    ] = useState<string>();
    const [
      districtHeatingInputEnergyNeeded,
      setDistrictHeatingInputEnergyNeeded,
    ] = useState<string>();
    const [oilInputEnergyNeeded, setOilInputEnergyNeeded] = useState<string>();
    const [naturalGasInputEnergyNeeded, setNaturalGasInputEnergyNeeded] =
      useState<string>();
    const [pelletsInputEnergyNeeded, setPelletsInputEnergyNeeded] =
      useState<string>();
    const [woodLogsInputEnergyNeeded, setWoodLogsInputEnergyNeeded] =
      useState<string>();
    const [stonedWoodInputEnergyNeeded, setStonedWoodInputEnergyNeeded] =
      useState<string>();
    const [pilletWoodInputEnergyNeeded, setPilledWoodInputEnergyNeeded] =
      useState<string>();
    const [coalInputEnergyNeeded, setCoalInputEnergyNeeded] =
      useState<string>();
    const [biogasInputEnergyNeeded, setBiogasInputEnergyNeeded] =
      useState<string>();

    const [electricHeatingEnergyCosts, setElectricHeatingEnergyCosts] =
      useState<string>();

    const [
      currentEnergySourceInputEnergyCosts,
      setCurrentEnergySourceInputEnergyCosts,
    ] = useState<string>();

    useImperativeHandle(ref, () => ({
      getData: () => {
        const alternativeSourcesForReport: Array<IAlternativeSource> = [
          {
            currentSource: false,
            name: t(
              "pages.savingCalculator.inputEnergyComparison.ESInstallationRowTitle"
            ),
            efficiency: totalEnergyEfficiency?.replace(/[^\d]/g, "") || "",
            inputEnergy: totalInputEnergy?.replace(/[^\d]/g, "") || "",
            inputEnergyCost: totalCosts?.replace(/[^\d]/g, "") || "",
          },
          {
            currentSource: currentEnergySource === "2",
            amountInOriginalUOM:
              currentEnergySource === "2"
                ? calculateAmountInOriginalUOM(electricHeatingInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.electricHeatingRowTitle"
            ),
            efficiency:
              currentEnergySource === "2" ? currentEnergyEfficiency : "95",
            inputEnergy:
              electricHeatingInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              electricHeatingEnergyCosts?.replace(/[^\d]/g, "") || undefined,
          },
          {
            currentSource: currentEnergySource === "3",
            amountInOriginalUOM:
              currentEnergySource === "3"
                ? calculateAmountInOriginalUOM(districtHeatingInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.districtHeatingRowTitle"
            ),
            efficiency:
              currentEnergySource === "3" ? currentEnergyEfficiency : "95",
            inputEnergy:
              districtHeatingInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "3"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "0",
            amountInOriginalUOM:
              currentEnergySource === "0"
                ? calculateAmountInOriginalUOM(oilInputEnergyNeeded)
                : "",
            name: t("pages.savingCalculator.inputEnergyComparison.oilRowTitle"),
            efficiency:
              currentEnergySource === "0" ? currentEnergyEfficiency : "80",
            inputEnergy: oilInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "0"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "1",
            amountInOriginalUOM:
              currentEnergySource === "1"
                ? calculateAmountInOriginalUOM(naturalGasInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.naturalGasRowTitle"
            ),
            efficiency:
              currentEnergySource === "1" ? currentEnergyEfficiency : "65",
            inputEnergy:
              naturalGasInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "1"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "5",
            amountInOriginalUOM:
              currentEnergySource === "5"
                ? calculateAmountInOriginalUOM(pelletsInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.pelletsRowTitle"
            ),
            efficiency:
              currentEnergySource === "5" ? currentEnergyEfficiency : "80",
            inputEnergy: pelletsInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "5"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "6",
            amountInOriginalUOM:
              currentEnergySource === "6"
                ? calculateAmountInOriginalUOM(woodLogsInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.woodLogsRowTitle"
            ),
            efficiency:
              currentEnergySource === "6" ? currentEnergyEfficiency : "80",
            inputEnergy: woodLogsInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "6"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "7",
            amountInOriginalUOM:
              currentEnergySource === "7"
                ? calculateAmountInOriginalUOM(stonedWoodInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.stonedWoodRowTitle"
            ),
            efficiency:
              currentEnergySource === "7" ? currentEnergyEfficiency : "80",
            inputEnergy:
              stonedWoodInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "7"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "8",
            amountInOriginalUOM:
              currentEnergySource === "8"
                ? calculateAmountInOriginalUOM(pilletWoodInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.piledWoodRowTitle"
            ),
            efficiency:
              currentEnergySource === "8" ? currentEnergyEfficiency : "80",
            inputEnergy:
              pilletWoodInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "8"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            //TODO: ASK STAN ABOUT COAL
            currentSource: currentEnergySource === "9",
            amountInOriginalUOM:
              currentEnergySource === "9"
                ? calculateAmountInOriginalUOM(coalInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.coalRowTitle"
            ),
            efficiency:
              currentEnergySource === "9" ? currentEnergyEfficiency : "80",
            inputEnergy: coalInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "9"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            //TODO: ASK STAN ABOUT COAL
            currentSource: currentEnergySource === "10",
            amountInOriginalUOM:
              currentEnergySource === "10"
                ? calculateAmountInOriginalUOM(biogasInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.biogasRowTitle"
            ),
            efficiency:
              currentEnergySource === "10" ? currentEnergyEfficiency : "80",
            inputEnergy: biogasInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "10"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
        ];
        return alternativeSourcesForReport;
      },
    }));

    useEffect(() => {
      if (totalEnergyDemand) {
        const totalEnergyDemandNumber = Number(
          totalEnergyDemand.replace(/[^\d]/g, "")
        );
        const formattedCurrentEnergyEfficiency = +currentEnergyEfficiency / 100;
        const calculateInputEnergyNeeded = (
          energySourceNumber: string,
          defaultCoeff: number
        ) => {
          return Math.round(
            totalEnergyDemandNumber /
              (currentEnergySource === energySourceNumber
                ? formattedCurrentEnergyEfficiency
                : defaultCoeff)
          );
        };
        const EH = calculateInputEnergyNeeded("2", 0.95);
        setElectricHeatingInputEnergyNeeded(
          `${EH.toLocaleString("sv-SE")} kWh`
        );
        const DH = calculateInputEnergyNeeded("3", 0.95);
        setDistrictHeatingInputEnergyNeeded(
          `${DH.toLocaleString("sv-SE")} kWh`
        );
        const oil = calculateInputEnergyNeeded("0", 0.8);
        setOilInputEnergyNeeded(`${oil.toLocaleString("sv-SE")} kWh`);
        const naturalGas = calculateInputEnergyNeeded("1", 0.65);
        setNaturalGasInputEnergyNeeded(
          `${naturalGas.toLocaleString("sv-SE")} kWh`
        );
        const pellets = calculateInputEnergyNeeded("5", 0.8);
        setPelletsInputEnergyNeeded(`${pellets.toLocaleString("sv-SE")} kWh`);
        const woodLogs = calculateInputEnergyNeeded("6", 0.8);
        setWoodLogsInputEnergyNeeded(`${woodLogs.toLocaleString("sv-SE")} kWh`);
        const stonedWood = calculateInputEnergyNeeded("7", 0.8);
        setStonedWoodInputEnergyNeeded(
          `${stonedWood.toLocaleString("sv-SE")} kWh`
        );
        const pilledWood = calculateInputEnergyNeeded("8", 0.8);
        setPilledWoodInputEnergyNeeded(
          `${pilledWood.toLocaleString("sv-SE")} kWh`
        );
        const coal = calculateInputEnergyNeeded("9", 0.8);
        setCoalInputEnergyNeeded(`${coal.toLocaleString("sv-SE")} kWh`);
        const biogas = calculateInputEnergyNeeded("10", 0.8);
        setBiogasInputEnergyNeeded(`${biogas.toLocaleString("sv-SE")} kWh`);
        const currPriceNumber = Number(
          currentEnergyPrice?.replace(/[^\d.,]/g, "")
        );
        let inputEnergyNeeded: number;
        switch (currentEnergySource) {
          case "0":
            inputEnergyNeeded = oil;
            break;
          case "1":
            inputEnergyNeeded = naturalGas;
            break;
          case "3":
            inputEnergyNeeded = DH;
            break;
          case "5":
            inputEnergyNeeded = pellets;
            break;
          case "6":
            inputEnergyNeeded = woodLogs;
            break;
          case "7":
            inputEnergyNeeded = stonedWood;
            break;
          case "8":
            inputEnergyNeeded = pilledWood;
            break;
          case "9":
            inputEnergyNeeded = coal;
            break;
          case "10":
            inputEnergyNeeded = biogas;
            break;
          default:
            break;
        }
        const { approximatelyEquals } =
          defineSourceAndUnit(currentEnergySource);
        const approximatelyEqualsNumber = Number(
          approximatelyEquals?.replace(/[^\d]/g, "")
        );
        const value = inputEnergyNeeded! / (approximatelyEqualsNumber || 1);
        const rounded = Math.ceil(value * 10) / 10;
        const source = rounded % 1 === 0 ? Math.floor(rounded) : rounded;
        const formattedValue = parseInt((source * currPriceNumber).toString());
        setCurrentEnergySourceInputEnergyCosts(
          `${formattedValue?.toLocaleString("sv-SE")} ${currency}`
        );
      }
    }, [
      totalEnergyDemand,
      currentEnergySource,
      currentEnergyPrice,
      currentEnergyEfficiency,
    ]);

    useEffect(() => {
      if (electricHeatingInputEnergyNeeded && electricityPrice) {
        const priceNumber = Number(electricityPrice?.replace(/[^\d.,]/g, ""));
        const EHNumber = Number(
          electricHeatingInputEnergyNeeded?.replace(/[^\d]/g, "")
        );
        const price = Math.round(priceNumber * EHNumber);
        setElectricHeatingEnergyCosts(
          `${price.toLocaleString("sv-SE")} ${currency}`
        );
      }
    }, [electricityPrice, electricHeatingInputEnergyNeeded]);

    const currEnergySourceLabel = (inputEnergyNeeded: string | undefined) => {
      const { unit, approximatelyEquals } =
        defineSourceAndUnit(currentEnergySource);
      const inputEnergyNeededNumber = Number(
        inputEnergyNeeded?.replace(/[^\d]/g, "")
      );
      const approximatelyEqualsNumber = Number(
        approximatelyEquals?.replace(/[^\d]/g, "")
      );
      const value = inputEnergyNeededNumber / (approximatelyEqualsNumber || 1);
      const rounded = Math.ceil(value * 10) / 10;
      const calculatedValue = rounded % 1 === 0 ? Math.floor(rounded) : rounded;
      const formatted = calculatedValue.toLocaleString("sv-SE");
      const prefix = `${formatted} ${unit}`;
      return (
        <Typography ml={"5px"} fontSize={14} fontStyle={"italic"}>
          ({prefix}){" "}
          {t(
            "pages.savingCalculator.inputEnergyComparison.currentEnergySourseLabel"
          )}
        </Typography>
      );
    };

    const calculateAmountInOriginalUOM = (
      inputEnergyNeeded: string | undefined
    ) => {
      const { approximatelyEquals } = defineSourceAndUnit(currentEnergySource);
      const inputEnergyNeededNumber = Number(
        inputEnergyNeeded?.replace(/[^\d]/g, "")
      );
      const approximatelyEqualsNumber = Number(
        approximatelyEquals?.replace(/[^\d]/g, "")
      );
      const value = inputEnergyNeededNumber / (approximatelyEqualsNumber || 1);
      const rounded = Math.ceil(value * 10) / 10;
      const prefix = `${rounded % 1 === 0 ? Math.floor(rounded) : rounded}`;
      return prefix;
    };
    const memoizedTable = useMemo(() => {
      return (
        <Box mb={"35px"}>
          <TableContainerWithCustomScroll>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
              <TableHead sx={{ borderBottom: "2px solid #95C11F" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={500} fontSize={14}>
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.efficiencyColTitle"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={500} fontSize={14}>
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.inputEnergyNeededColTitle"
                      )}{" "}
                      {` ⁽⁴`}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontWeight={500} fontSize={14}>
                        {t(
                          "pages.savingCalculator.inputEnergyComparison.inputEnergyCostsColTitle"
                        )}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor: "#f7fbf2",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography fontWeight={700} fontSize={14}>
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.ESInstallationRowTitle"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14} fontWeight={700}>
                      {totalEnergyEfficiency}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>{totalInputEnergy}</Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>{totalCosts}</Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "2" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.electricHeatingRowTitle"
                      )}
                      {currentEnergySource === "2" &&
                        currEnergySourceLabel(electricHeatingInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "2"
                        ? currentEnergyEfficiency + " %"
                        : "95 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {electricHeatingInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {electricHeatingEnergyCosts}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "3" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.districtHeatingRowTitle"
                      )}
                      {currentEnergySource === "3" &&
                        currEnergySourceLabel(districtHeatingInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "3"
                        ? currentEnergyEfficiency + " %"
                        : "95 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {districtHeatingInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "3"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "0" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.oilRowTitle"
                      )}
                      {currentEnergySource === "0" &&
                        currEnergySourceLabel(oilInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "0"
                        ? currentEnergyEfficiency + " %"
                        : "80 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {oilInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "0"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "1" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.naturalGasRowTitle"
                      )}
                      {currentEnergySource === "1" &&
                        currEnergySourceLabel(naturalGasInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "1"
                        ? currentEnergyEfficiency + " %"
                        : "65 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {naturalGasInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "1"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "5" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.pelletsRowTitle"
                      )}
                      {currentEnergySource === "5" &&
                        currEnergySourceLabel(pelletsInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "5"
                        ? currentEnergyEfficiency + " %"
                        : "80 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {pelletsInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "5"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>

                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "6" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.woodLogsRowTitle"
                      )}
                      {currentEnergySource === "6" &&
                        currEnergySourceLabel(woodLogsInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "6"
                        ? currentEnergyEfficiency + " %"
                        : "80 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {woodLogsInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "6"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>

                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "7" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.stonedWoodRowTitle"
                      )}
                      {currentEnergySource === "7" &&
                        currEnergySourceLabel(stonedWoodInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "7"
                        ? currentEnergyEfficiency + " %"
                        : "80 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {stonedWoodInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "7"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>

                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "8" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.piledWoodRowTitle"
                      )}
                      {currentEnergySource === "8" &&
                        currEnergySourceLabel(pilletWoodInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "8"
                        ? currentEnergyEfficiency + " %"
                        : "80 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {pilletWoodInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "8"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>

                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "9" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.coalRowTitle"
                      )}
                      {currentEnergySource === "9" &&
                        currEnergySourceLabel(coalInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "9"
                        ? currentEnergyEfficiency + " %"
                        : "80 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {coalInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      {/* TODO: ASK STAN ABOUT COAL */}
                      <Typography fontSize={14}>
                        {currentEnergySource === "9"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "10" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.biogasRowTitle"
                      )}
                      {currentEnergySource === "10" &&
                        currEnergySourceLabel(biogasInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {currentEnergySource === "10"
                        ? currentEnergyEfficiency + " %"
                        : "80 %"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {biogasInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "10"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
              </TableBody>
            </Table>
          </TableContainerWithCustomScroll>
          <Box
            mt={"16px"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <Typography fontSize={12} fontStyle={"italic"} mr={"20px"}>
              {t("pages.savingCalculator.inputEnergyComparison.tableHint4", {
                totalEnergyDemand,
              })}
            </Typography>
            {showEnergyPricing && (
              <Typography fontSize={12} fontStyle={"italic"}>
                {t("pages.savingCalculator.inputEnergyComparison.tableHint5")}
              </Typography>
            )}
          </Box>
        </Box>
      );
    }, [
      showEnergyPricing,
      currency,
      totalEnergyEfficiency,
      totalInputEnergy,
      totalCosts,
      currentEnergySource,
      currentEnergyPrice,
      electricityPrice,
      totalEnergyDemand,
      currentEnergyEfficiency,
    ]);
    return memoizedTable;
  }
);
