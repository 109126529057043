import { Box, InputLabel, SxProps, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import useTheme from "../../theme/hooks/useTheme";
import { UploadCloud01 } from "untitledui-js-base";
import PreviewsList from "./components/PreviewsList/PreviewsList";
import { useToast } from "../../hooks/useToast";
import { ToastStatuses } from "../Toaster/StatusIcon/Types";
import { useTranslation } from "react-i18next";
import placeholderImage from "./assets/images/placeholder.svg";

export default function DropZone({
  label,
  innerLabel,
  innerDescription,
  maxSizeMB,
  onUpload,
}: IDropzoneProps) {
  const { t: errorsT } = useTranslation("cloud_ui", {
    keyPrefix: "errors.drop_zone",
  });
  const { colors, radius, spacing, typography } = useTheme();
  const { showToast } = useToast();

  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const [previews, setPreviews] = useState<IPreview[]>([]);

  const styles: Record<string, SxProps> = useMemo(
    () => ({
      mainLabel: {
        ...typography[".text-sm-medium"],
        color: colors.colorsTextTextSecondary,
      },
      mainWrapper: {
        m: `${spacing.spacingSm} 0`,
        p: `${spacing.spacingXl} ${spacing.spacing3xl}`,
        border: `1px solid ${
          isDragActive
            ? colors.colorsBorderBorderBrand
            : colors.colorsBorderBorderPrimary
        }`,
        borderRadius: radius.radiusMd,
        boxShadow: isDragActive
          ? "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(149, 193, 31, 0.24)"
          : "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        cursor: "pointer",
        "&:hover": {
          borderColor: colors.colorsBorderBorderBrand,
        },
      },
      innerContentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      iconWrapper: {
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: ".625rem",
        border: `1px solid ${colors.componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder}`,
        borderRadius: radius.radiusMd,
        mb: spacing.spacingLg,
        svg: {
          color: colors.colorsForegroundFgSecondary,
        },
      },
      innerLabel: {
        ...typography[".text-sm-regular"],
        color: colors.colorsTextTextTertiary,
        display: "flex",
        mb: spacing.spacingXs,
      },
      innerDescription: {
        ...typography[".text-xs-regular"],
        color: colors.colorsTextTextTertiary,
      },
    }),
    [isDragActive]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        const title = errorsT(
          `${rejectedFiles.length > 1 ? "multiple" : "single"}_size_error_title`
        );
        const text = errorsT(
          `${rejectedFiles.length > 1 ? "multiple" : "single"}_size_error_text`,
          {
            filesNames: rejectedFiles.map((el) => el.file.name).join(", "),
            maxSize: maxSizeMB,
          }
        );
        showToast({
          status: ToastStatuses.Error,
          title,
          text,
        });
      }

      const previewUrls: string[] = [];

      acceptedFiles.forEach((file) => {
        const previewSrc = URL.createObjectURL(file);
        previewUrls.push(previewSrc);

        const img = new Image();
        img.src = previewSrc;

        img.onload = () => {
          setPreviews((prev) => {
            const updatedPreviews = [...prev, { fileData: file, previewSrc }];
            onUpload(updatedPreviews);
            return updatedPreviews;
          });
        };

        img.onerror = () => {
          setPreviews((prev) => {
            const updatedPreviews = [
              ...prev,
              { fileData: file, previewSrc: placeholderImage },
            ];
            onUpload(updatedPreviews);
            return updatedPreviews;
          });
        };
      });

      return () => {
        previewUrls.forEach((url) => URL.revokeObjectURL(url));
      };
    },
    [onUpload]
  );

  const removePreview = (file: File, index: number) => {
    const updatedPreviews = previews.filter(
      (preview, currIndex) =>
        preview.fileData.name !== file.name && currIndex !== index
    );
    onUpload(updatedPreviews);
    setPreviews(updatedPreviews);
  };

  return (
    <React.Fragment>
      <InputLabel sx={styles.mainLabel}>{label}</InputLabel>
      <Dropzone
        onDrop={(acceptedFiles, rejectedFiles) =>
          onDrop(acceptedFiles, rejectedFiles)
        }
        multiple
        maxSize={maxSizeMB * 1024 * 1024}
        onDragEnter={() => setIsDragActive(true)}
        onDragLeave={() => setIsDragActive(false)}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <Box {...getRootProps()} sx={styles.mainWrapper}>
              <input {...getInputProps()} />
              <Box sx={styles.innerContentWrapper}>
                <Box sx={styles.iconWrapper}>
                  <UploadCloud01 size="20" strokeWidth={2} />
                </Box>
                <Box sx={styles.innerLabel}>{innerLabel}</Box>
                <Typography sx={styles.innerDescription}>
                  {innerDescription}
                </Typography>
              </Box>
            </Box>
          );
        }}
      </Dropzone>
      <PreviewsList previews={previews} removePreview={removePreview} />
    </React.Fragment>
  );
}
