import { SxProps } from "@mui/material";
import useTheme from "../../../../../../../theme/hooks/useTheme";

export default function useInstallationZonesStyles(
  zonesCount: number,
  isInstallerZones: boolean
) {
  const theme = useTheme();
  const { colors, spacing, typography } = theme;
  const styles: Record<string, SxProps> = {
    mainWrapper: {
      height: "100%",
    },
    mainTitle: {
      ...typography[".text-lg-semibold"],
      color: colors.colorsTextTextPrimary,
      mb: spacing.spacingXl,
      p: `${spacing.spacingMd} ${spacing.spacing4xl} 0`,
    },
    zonesNavigationWrapper: {
      p: `0 ${spacing.spacing4xl}`,
    },
    zoneContentWrapper: {
      height: `calc(100% - ${
        zonesCount > 1 || isInstallerZones ? "175px" : "128px"
      })`,
    },
  };

  return { styles };
}
