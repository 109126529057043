import { useTranslation } from "react-i18next";
import {
  InstallationComissioningZoneCircuitTypeEnum,
  InstallationZoneOperationModeEnum,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import { useContext } from "react";
import InstallationZonesContext from "../../context/InstallationZonesContext";

export default function useMainSection() {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });

  const {
    isInstallationOffline,
    isInstallerZones,
    staticValues,
    installerSettingsFormData,
    updateInstallerZoneFormData,
    updateInstallerZoneMode,
  } = useContext(InstallationZonesContext);

  const modeValueToDisplay = {
    [InstallationZoneOperationModeEnum.HEATING]: t("onlyHeating"),
    [InstallationZoneOperationModeEnum.COOLING]: t("onlyCooling"),
    [InstallationZoneOperationModeEnum.BOTH]: t("HeatingAndCooling"),
    [InstallationZoneOperationModeEnum.NONE]: t("none"),
  };

  const circuitTypeToDisplay = {
    [InstallationComissioningZoneCircuitTypeEnum.DIRECT]: t(
      "circuitTypes.direct"
    ),
    [InstallationComissioningZoneCircuitTypeEnum.MIXING]: t(
      "circuitTypes.mixing"
    ),
  };

  const modeDropdownOptions = [
    {
      label: t("onlyHeating"),
      value: InstallationZoneOperationModeEnum.HEATING,
    },
    {
      label: t("onlyCooling"),
      value: InstallationZoneOperationModeEnum.COOLING,
    },
    {
      label: t("HeatingAndCooling"),
      value: InstallationZoneOperationModeEnum.BOTH,
    },
    {
      label: t("none"),
      value: InstallationZoneOperationModeEnum.NONE,
    },
  ];

  const showModeReadOnlyField: boolean = Boolean(
    !isInstallerZones && staticValues?.mode
  );

  const showMixingValveTurningTimeField: boolean = Boolean(
    installerSettingsFormData?.mixingValveTurningTime?.value !== undefined &&
      staticValues?.circuitType ===
        InstallationComissioningZoneCircuitTypeEnum.MIXING
  );

  const showModeDropdown: boolean = Boolean(
    installerSettingsFormData?.mode !== undefined
  );

  const showFlowTempHeatingLimits: boolean = Boolean(
    installerSettingsFormData?.mode ===
      InstallationZoneOperationModeEnum.BOTH ||
      installerSettingsFormData?.mode ===
        InstallationZoneOperationModeEnum.HEATING
  );

  const showFlowTempCoolingLimits: boolean = Boolean(
    installerSettingsFormData?.mode ===
      InstallationZoneOperationModeEnum.BOTH ||
      installerSettingsFormData?.mode ===
        InstallationZoneOperationModeEnum.COOLING
  );

  const formattedModeValue: string = staticValues?.mode
    ? modeValueToDisplay[staticValues.mode]
    : "";

  const formattedCircuitTypeValue: string = staticValues?.circuitType
    ? circuitTypeToDisplay[staticValues.circuitType]
    : "";

  const modeReadOnlyFieldLabel: string = t("fieldsLabels.zoneHeatingCooling");

  const circuitTypeReadOnlyFieldLabel: string = t("fieldsLabels.circuitType");

  const mixingValveTurningTimeFieldLabel: string = t(
    "fieldsLabels.mixingValveTurningTime"
  );

  const modeDropdownLabel: string = t("fieldsLabels.zoneHeatingCooling");

  return {
    isInstallationOffline,
    isInstallerZones,
    showModeReadOnlyField,
    showMixingValveTurningTimeField,
    showModeDropdown,
    showFlowTempHeatingLimits,
    showFlowTempCoolingLimits,
    formattedModeValue,
    formattedCircuitTypeValue,
    modeReadOnlyFieldLabel,
    circuitTypeReadOnlyFieldLabel,
    mixingValveTurningTimeFieldLabel,
    modeDropdownLabel,
    installerSettingsFormData,
    modeDropdownOptions,
    updateInstallerZoneFormData,
    updateInstallerZoneMode,
  };
}
