import { useContext } from "react";
import { useTranslation } from "react-i18next";
import QuietModeContext from "../../../context/QuietModeContext";

export default function useQuietModeSchedule() {
  const { quietModeSettingsFormData, quietModeSettingsData } =
    useContext(QuietModeContext);
  const { t } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.userSettings.quietMode.quietModeSchedule",
  });

  const mainTitleText = t("mainTitle");
  const descriptionText = t("description");

  return {
    mainTitleText,
    descriptionText,
    quietModeSettingsFormData,
    quietModeSettingsData,
  };
}
