import React from "react";
import { ArrowRight } from "untitledui-js-base";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { IBaseDifferencesObject } from "../../../types/commonTypes";

export default function useDifferencesHelpers() {
  const theme = useTheme();
  const { t: unitsT } = useTranslation("cloud_ui", { keyPrefix: "units" });
  const buildBaseDifferenceValue = (
    value: IBaseDifferencesObject | undefined
  ) => {
    if (!value) {
      return null;
    }
    return (
      <>
        {value.defaultValue?.value !== undefined &&
          `${value.defaultValue?.value} ${unitsT(
            value.defaultValue?.unit || ""
          )}`}
        {value.defaultValue?.value !== undefined && (
          <ArrowRight
            size="14"
            style={{
              color: theme.colors.colorsTextTextPrimary,
            }}
            strokeWidth={2}
          />
        )}
        {`${value.changedValue.value} ${unitsT(value.changedValue.unit || "")}`}
      </>
    );
  };
  return { buildBaseDifferenceValue };
}
