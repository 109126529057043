import {
  Button as MUIButton,
  Box,
  Container,
  Grid,
  SxProps,
  TableCell,
  Typography,
} from "@mui/material";
import React, {
  JSX,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useTheme from "../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import EmptyInstallationPage from "./EmptyPage";
import {
  AlertOctagon,
  AlertTriangle,
  Building01,
  Edit01,
  Phone01,
  Plus,
  SearchLG,
  XClose,
} from "untitledui-js-base";
import { FleetManagementAPI } from "../../api/FleetManagementAPI";
import { useLocation, useNavigate } from "react-router";
import SortableTable, { Column } from "../../components/Grid/SortableTable";
import InstallationsListItemResponse, {
  AlertType,
  IInstallationAlert,
  Status,
} from "../../api/responses/fleetManagementResponses/FleetManagementResponse";
import { formatDate } from "../../util/DateUtil";
import { Button, ButtonType } from "../../components/Button/Button";
import queryString from "query-string";
import SearchBarComponent from "../../components/SearchBar/SearchBarComponent";
import Badge from "../../components/Badge";
import AssignInstallationDialog, {
  AssignInstallationDialogRef,
} from "./AssingInstallation/AssignInstallationDialog";
import { UsersAPI } from "../../api/UsersAPI";
import { jwtDecode } from "jwt-decode";
import { AddResidentialInstallationModal } from "./AddResidentialInstallationModal/AddResidentialInstallationModal";
import AuthenticationAPI from "../../api/AuthenticationAPI";
import FilterButton from "../../components/Button/FilterButton";

export enum Filters {
  WITH_ALARMS = "WITH_ALARMS",
  WITH_WARNINGS = "WITH_WARNINGS",
  WITH_INFO = "WITH_INFO",
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  WITH_USER_APP = "WITH_USER_APP",
  TYPE_DOMESTIC = "TYPE_DOMESTIC",
  TYPE_COMMERCIAL = "TYPE_COMMERCIAL",
  UNASSIGNED = "UNASSIGNED",
}

export enum FiledsForSorting {
  connectivity_id = "connectivity_id",
  name = "name",
  installation_date = "installation_date",
  address = "address",
  installer = "installer",
  online_status = "online_status",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export default function ResidentialInstallationsPage({
  isSuperAdminLevel = false,
}: {
  isSuperAdminLevel?: boolean;
}) {
  const theme = useTheme();

  const { typography, spacing, colors } = theme;
  const labelBaseStyles = useMemo(
    () => ({
      ...typography[".text-xs-medium"],
      height: "22px",
      padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingSm}`,
      borderRadius: "10px",
      border: `1px solid ${colors.componentColorsUtilityGrayUtilityGray600}`,
      color: colors.componentColorsUtilityGrayUtilityGray600,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
    }),
    [typography, spacing, colors],
  );

  const dotStyles: SxProps = useMemo(
    () => ({
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      backgroundColor: colors.componentColorsUtilityBrandUtilityBrand600,
      mr: spacing.spacingSm,
    }),
    [colors.componentColorsUtilityBrandUtilityBrand600, spacing.spacingSm],
  );

  const alertsBaseStyles: SxProps = useMemo(
    () => ({
      ...typography[".text-sm-medium"],
      height: "24px",
      maxWidth: "fit-content",
      display: "flex",
      alignItems: "center",
      padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingMd}`,
      border: `1px solid ${colors.componentColorsUtilityErrorUtilityError200}`,
      borderRadius: "12px",
      backgroundColor: colors.componentColorsUtilityErrorUtilityError50,
      color: colors.componentColorsUtilityErrorUtilityError700,
    }),
    [
      typography,
      spacing.spacingXxs,
      spacing.spacingMd,
      colors.componentColorsUtilityErrorUtilityError200,
      colors.componentColorsUtilityErrorUtilityError50,
      colors.componentColorsUtilityErrorUtilityError700,
    ],
  );

  const { t } = useTranslation("cloud_ui");
  const navigate = useNavigate();
  const location = useLocation();
  const api = new FleetManagementAPI(navigate);
  const usersApi = new UsersAPI(navigate);
  const authApi = new AuthenticationAPI(navigate);

  const initialQuery = queryString.parse(location.search);
  const getMyOrgUuid = () => {
    const token = usersApi.getAuthTokenForUsersInfo();
    const decodedToken: any = jwtDecode(token as string);
    return decodedToken.current_membership_uuid;
  };

  const myOrgUuid = getMyOrgUuid();

  const parseArrayFromQuery = (queryParam: any): string[] => {
    if (Array.isArray(queryParam)) return queryParam;
    if (typeof queryParam === "string") return queryParam.split(",");
    return [];
  };

  const [installations, setInstallations] = useState<Array<InstallationsListItemResponse>>([]);
  const [filterData, setFilterData] = useState<Array<string>>(
    parseArrayFromQuery(initialQuery.filters).length
      ? parseArrayFromQuery(initialQuery.filters)
      : [Filters.TYPE_DOMESTIC],
  );
  const [sortingData, setSortingData] = useState<{
    fieldName: FiledsForSorting;
    order: SortOrder;
  }>({
    fieldName: (initialQuery.sortField as FiledsForSorting) || FiledsForSorting.name,
    order: (initialQuery.sortOrder as SortOrder) || SortOrder.asc,
  });
  const [page, setPage] = useState<number>(parseInt(initialQuery.page as string) || 0);
  const [totalPages, setTotalPages] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>();
  const [searchValue, setSearchValue] = useState<string>(
    (initialQuery.searchValue as string) || "",
  );
  const [stringForSearch, setStringForSearch] = useState<string>(
    (initialQuery.stringForSearch as string) || "",
  );

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [hasUserInstallationShareAccess, setUserInstallationShareAccess] = useState<boolean>(false);

  const [isAddInstallationModalOpen, setIsAddInstallationModalOpen] = useState<boolean>(false);

  const assignInstallationDialogRef = useRef<AssignInstallationDialogRef>(null);

  useEffect(() => {
    authApi
      .hasRoles(["ADMIN", "INSTALLATION_SHARE"])
      .then((response) => {
        setUserInstallationShareAccess(response.data.value);
      })
      .catch((error) => {
        //TODO Stan: Show message?
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    getInstallations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterData, sortingData, stringForSearch]);

  // useLayoutEffect(() => {
  //   setPage(0);
  // }, [filterData, sortingData, stringForSearch]);

  useEffect(() => {
    const params = {
      page,
      filters: filterData.join(","),
      sortField: sortingData.fieldName,
      sortOrder: sortingData.order,
      searchValue,
      stringForSearch,
    };

    const queryStringParams = queryString.stringify(params);
    navigate(`?${queryStringParams}`, { replace: true });
  }, [page, filterData, sortingData, searchValue, stringForSearch, navigate]);

  const getInstallations = (isShowLoader: boolean = true) => {
    if (isShowLoader) setIsDataLoading(true);
    api
      .getInstallations(
        {
          pageNum: page === 0 ? 0 : page - 1,
          pageSize: 10,
          search: stringForSearch,
        },
        {
          filter: filterData?.join(","),
          sortBy: `${sortingData.fieldName}:${sortingData.order}`,
        },
        {
          accept: isSuperAdminLevel ? "application/admin-installations+json" : "application/json",
        },
      )
      .then((value) => {
        setPage(value.data.pagination.currentPage + 1);
        setTotalPages(value.data.pagination.totalPages);
        setTotalCount(value.data.pagination.totalCount);
        // renderTableRows(value.data.result);
        setInstallations(value.data.result);
        if (isShowLoader) setIsDataLoading(false);
      })
      .catch((err) => {
        if (isShowLoader) setIsDataLoading(false);
      })
      .finally(() => {
        if (isShowLoader) setIsDataLoading(false);
      });
  };

  const tableColumns: Column[] = [
    { id: "id", label: "", hidden: true },
    {
      id: FiledsForSorting.connectivity_id,
      label: t("pages.residentialInstallation.table.lables.connectivityId"),
      stopPropagateRowClick: true,
    },
    {
      id: FiledsForSorting.name,
      label: t("pages.residentialInstallation.table.lables.name"),
    },
    {
      id: FiledsForSorting.installation_date,
      label: t("pages.residentialInstallation.table.lables.registrationDate"),
    },
    {
      id: FiledsForSorting.address,
      label: t("pages.residentialInstallation.table.lables.address"),
    },
    {
      id: FiledsForSorting.installer,
      label: t("pages.residentialInstallation.table.lables.installer"),
      disableSorting: true,
    },
    {
      id: FiledsForSorting.online_status,
      label: t("pages.residentialInstallation.table.lables.status"),
      width: 70,
      align: "center",
    },
    {
      id: "alerts",
      label: t("pages.residentialInstallation.table.lables.alerts"),
      disableSorting: true,
      width: 90,
      align: "center",
    },
    {
      id: "userApp",
      label: t("pages.residentialInstallation.table.lables.userApp"),
      disableSorting: true,
      width: 90,
    },
  ];

  const filters = [
    {
      label: t("pages.residentialInstallation.table.filters.viewAll"),
      value: "clear",
    },
    // {
    //   label: t("pages.residentialInstallation.table.filters.unassigned"),
    //   value: Filters.UNASSIGNED,
    // },
    {
      label: t("pages.residentialInstallation.table.filters.alarms"),
      value: Filters.WITH_ALARMS,
      icon: (
        <AlertOctagon
          size="20"
          style={{
            color: "inherit",
          }}
          strokeWidth={2}
        />
      ),
    },
    {
      label: t("pages.residentialInstallation.table.filters.warnings"),
      value: Filters.WITH_WARNINGS,
      icon: (
        <AlertTriangle
          size="20"
          style={{
            color: "inherit",
          }}
          strokeWidth={2}
        />
      ),
    },
  ];

  const renderOnlineStatus = useCallback(
    (status: Status) => {
      if (status === Status.ONLINE) {
        return (
          <Box
            sx={{
              ...labelBaseStyles,
              color: colors.componentColorsUtilityBrandUtilityBrand600,
              borderColor: colors.componentColorsUtilityBrandUtilityBrand600,
            }}
          >
            <Box
              sx={{
                ...dotStyles,
              }}
            ></Box>
            {t("online_statuses.online")}
          </Box>
        );
      } else {
        return <Box sx={{ ...labelBaseStyles }}>{t("online_statuses.offline")}</Box>;
      }
    },
    [labelBaseStyles, dotStyles, colors.componentColorsUtilityBrandUtilityBrand600, t],
  );

  const renderAssignButton = useCallback(
    (uuid: string) => (
      <Badge
        text={
          myOrgUuid ? t("pages.assignInstaller.selectBtn") : t("pages.assignInstaller.assignBtn")
        }
        type={myOrgUuid ? "success" : "warning"}
        Icon={!myOrgUuid ? Edit01 : undefined}
        onClick={(e) => {
          e.stopPropagation();
          assignInstallationDialogRef.current?.open({ installationUuid: uuid });
        }}
      />
    ),
    [myOrgUuid, t, assignInstallationDialogRef],
  );

  const renderAlerts = useCallback(
    (alerts: Array<IInstallationAlert>, id: string) => {
      const errorsCount = alerts.filter((alert) => alert.type === AlertType.ERROR).length;
      const warningsCount = alerts.filter((alert) => alert.type === AlertType.WARNING).length;

      return (
        <Box
          sx={{ display: "flex" }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            navigate(`./${id}/alerts`);
          }}
        >
          {errorsCount > 0 && (
            <Box sx={{ ...alertsBaseStyles, mr: spacing.spacingXs }}>
              <AlertOctagon
                size="12"
                style={{
                  marginRight: theme.spacing.spacingXs,
                  color: colors.componentColorsUtilityErrorUtilityError500,
                }}
                strokeWidth={2}
              />
              {errorsCount}
            </Box>
          )}
          {warningsCount > 0 && (
            <Box
              sx={{
                ...alertsBaseStyles,
                borderColor: colors.componentColorsUtilityWarningUtilityWarning200,
                backgroundColor: colors.componentColorsUtilityWarningUtilityWarning50,
                color: colors.componentColorsUtilityWarningUtilityWarning700,
              }}
            >
              <AlertTriangle
                size="12"
                style={{
                  marginRight: theme.spacing.spacingXs,
                  color: colors.componentColorsUtilityWarningUtilityWarning500,
                }}
                strokeWidth={2}
              />
              {warningsCount}
            </Box>
          )}
        </Box>
      );
    },
    [
      alertsBaseStyles,
      navigate,
      colors.componentColorsUtilityErrorUtilityError500,
      colors.componentColorsUtilityWarningUtilityWarning200,
      colors.componentColorsUtilityWarningUtilityWarning50,
      colors.componentColorsUtilityWarningUtilityWarning500,
      colors.componentColorsUtilityWarningUtilityWarning700,
      spacing.spacingXs,
      theme.spacing.spacingXs,
    ],
  );

  const getTableRows = useMemo(() => {
    const data = installations.map((installation: InstallationsListItemResponse) => {
      const hasErrors = installation.alerts.some((alert) => alert.type === AlertType.ERROR);
      const hasWarnings = installation.alerts.some((alert) => alert.type === AlertType.WARNING);
      return {
        id: installation.uuid,
        connectivity_id: (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              minHeight: "52px",
              display: "flex",
              alignItems: "center",
              color: theme.colors.colorsTextTextTertiary,
            }}
          >
            {installation.connectivitySettings?.connectivityId}
          </Typography>
        ),
        name: (
          <Typography
            sx={{
              ...theme.typography[".text-sm-medium"],
              color: theme.colors.colorsTextTextPrimary,
            }}
          >
            {installation.name}
          </Typography>
        ),
        installation_date: (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextQuaternary,
            }}
          >
            {installation.createdAt ? formatDate(installation.createdAt) : "-"}
          </Typography>
        ),
        address: (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextSecondary,
            }}
          >
            {installation.location}
          </Typography>
        ),
        installer: installation.responsibleOrgName ? (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextQuaternary,
            }}
          >
            {installation.responsibleOrgName}
          </Typography>
        ) : (
          (isSuperAdminLevel || hasUserInstallationShareAccess) &&
          renderAssignButton(installation.uuid)
        ),
        online_status: renderOnlineStatus(installation.onlineStatus),
        alerts: renderAlerts(installation.alerts, installation.uuid),
        userApp: installation.privateClientConnected && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Phone01
              size="20"
              strokeWidth={2}
              style={{ color: colors.colorsForegroundFgBrandPrimary }}
            />
          </Box>
        ),
        isRowHighlited: installation.alerts.length > 0,
        highlightColor: hasErrors
          ? colors.colorsForegroundFgErrorPrimary
          : hasWarnings
          ? colors.colorsForegroundFgWarningSecondary
          : "initial",
      };
    });
    return data;
  }, [
    colors.colorsForegroundFgBrandPrimary,
    colors.colorsForegroundFgErrorPrimary,
    colors.colorsForegroundFgWarningSecondary,
    hasUserInstallationShareAccess,
    installations,
    isSuperAdminLevel,
    renderAlerts,
    renderAssignButton,
    renderOnlineStatus,
    theme.colors.colorsTextTextPrimary,
    theme.colors.colorsTextTextQuaternary,
    theme.colors.colorsTextTextSecondary,
    theme.colors.colorsTextTextTertiary,
    theme.typography,
  ]);

  const renderFilterItem = (
    filter: { label: string; value: string; icon?: JSX.Element },
    index: number,
  ) => (
    <FilterButton
      key={`res_install_filter_item_${index}`}
      label={filter.label}
      isSelected={filterData.includes(filter.value)}
      isFirst={index === 0}
      isLast={index === filters.length - 1}
      icon={filter.icon}
      onClick={() => onFilterChange(filter.value as Filters)}
    />
  );

  const renderUnassignedFilter = () => {
    const isSelected = filterData.includes(Filters.UNASSIGNED);
    return (
      <Box
        component={"button"}
        sx={{
          ...typography[".text-sm-semibold"],
          minHeight: "40px",
          display: "flex",
          alignItems: "center",
          border: `1px solid ${colors.colorsBorderBorderPrimary}`,
          padding: `${spacing.spacingMd} ${spacing.spacingXl}`,
          borderRadius: "8px",
          cursor: "pointer",
          backgroundColor: isSelected ? colors.colorsBackgroundBgActive : "initial",
          mr: spacing.spacingMd,
          "&:hover": {
            backgroundColor: colors.colorsBackgroundBgActive,
          },
        }}
        onClick={() => onFilterChange(Filters.UNASSIGNED as Filters)}
      >
        <Typography
          sx={{
            ...typography[".text-sm-semibold"],
            color: isSelected ? colors.colorsTextTextBrandTertiary : colors.colorsTextTextSecondary,
          }}
        >
          {t("pages.residentialInstallation.table.filters.unassigned")}
        </Typography>
        {isSelected && (
          <XClose
            size="20"
            style={{
              color: colors.colorsBorderBorderPrimary,
              marginLeft: spacing.spacingMd,
            }}
            strokeWidth={2}
          />
        )}
      </Box>
    );
  };

  const renderInfoTableHeadContent = () => {
    return (
      <TableCell colSpan={8} sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            height: "1px",
            left: "0",
            top: "59px",
            width: "100%",
            backgroundColor: colors.colorsBorderBorderSecondary,
          }}
        ></Box>
        <Grid container alignItems={"center"}>
          <Grid item xs={2} sx={{ mb: spacing.spacingLg }}>
            <Typography
              color={"primary"}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#101828",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
              }}
            >
              {t("pages.commercialInstallation.table.mainTitle")}{" "}
              <Typography
                sx={{
                  m: "0 10px",
                  fontSize: "20px",
                  color: "#667085",
                  fontWeight: 500,
                  lineHeight: "30px",
                }}
              >
                {totalCount}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              top: "-7px",
            }}
          >
            <MUIButton
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "initial",
                backgroundColor: "#779A19",
              }}
              type="submit"
              startIcon={<Plus size="16" color="#FFF" strokeWidth={2} />}
              onClick={() => setIsAddInstallationModalOpen(true)}
            >
              {t("pages.commercialInstallation.addInstallationButtonText")}
            </MUIButton>
          </Grid>
        </Grid>
        <Grid container alignItems={"center"} sx={{ mt: spacing.spacingLg, mb: "-6px" }}>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-start" }}>
            {isSuperAdminLevel && renderUnassignedFilter()}
            <Box
              sx={{
                display: "flex",
              }}
            >
              {filters.map(renderFilterItem)}
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBarComponent
              searchValue={searchValue}
              onSearchChange={onSearchChange}
              onClear={() => {
                setSearchValue("");
                setStringForSearch("");
                setPage(0);
              }}
              onSearchButtonClick={onSearchButtonClick}
            />
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const renderNoResultsBlockForTable = () => {
    return (
      <Box sx={{ width: "100%", height: "268px", display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: "0 auto",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "48px",
              width: "48px",
              borderRadius: theme.radius.radiusLg,
              color: theme.colors.colorsForegroundFgSecondary,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacingXl,
              border: `1px solid ${theme.colors.componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder}`,
            }}
          >
            <SearchLG
              size="20"
              style={{
                color: "inherit",
              }}
              strokeWidth={2}
            />
          </Box>
          <Typography
            sx={{
              ...theme.typography[".text-md-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              mb: theme.spacing.spacingMd,
            }}
          >
            {t("pages.residentialInstallation.table.emptyPage.noResultsTitle")}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextTertiary,
              mb: theme.spacing.spacingXl,
            }}
          >
            {t("pages.residentialInstallation.table.emptyPage.noResultsDescription")}
          </Typography>
          <Button
            onClick={() => {
              setFilterData([Filters.TYPE_DOMESTIC]);
              setSearchValue("");
              setStringForSearch("");
              setPage(0);
            }}
            buttonType={ButtonType.SecondaryGray}
            label={`${t("pages.residentialInstallation.table.emptyPage.clearFiltersButton")}`}
          />
        </Box>
      </Box>
    );
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const onSortClick = (columnId: string, direction: string) => {
    // setPage(0);
    setSortingData({
      fieldName: columnId as FiledsForSorting,
      order: direction as SortOrder,
    });
  };

  const onFilterChange = (filterValue: Filters | string) => {
    setPage(0);
    switch (filterValue) {
      case "clear":
        setFilterData([Filters.TYPE_DOMESTIC]);
        break;
      case Filters.WITH_ALARMS:
        if (filterData.includes(Filters.WITH_ALARMS)) {
          setFilterData((prev) => prev.filter((val) => val !== Filters.WITH_ALARMS));
        } else {
          setFilterData((prev) => [...prev, Filters.WITH_ALARMS]);
        }
        break;
      case Filters.WITH_WARNINGS:
        if (filterData.includes(Filters.WITH_WARNINGS)) {
          setFilterData((prev) => prev.filter((val) => val !== Filters.WITH_WARNINGS));
        } else {
          setFilterData((prev) => [...prev, Filters.WITH_WARNINGS]);
        }
        break;
      case Filters.UNASSIGNED:
        if (filterData.includes(Filters.UNASSIGNED)) {
          setFilterData((prev) => prev.filter((val) => val !== Filters.UNASSIGNED));
        } else {
          setFilterData((prev) => [...prev, Filters.UNASSIGNED]);
        }
        break;
      default:
        break;
    }
  };

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onSearchButtonClick = () => {
    setStringForSearch(searchValue?.trim());
    setPage(0);
  };

  const onRowClick = (row: any) => {
    navigate(`./${row.id}/real-time-data`);
  };

  return (
    <Container style={{ marginTop: "2em", paddingBottom: theme.spacing.spacing5xl }}>
      <div style={{ marginBottom: "3em" }}>
        <Grid container alignItems={"center"}>
          <Grid item xs={6}>
            <Typography color={"primary.dark"} style={{ fontWeight: "500" }} variant={"h4"}>
              {t("pages.residentialInstallation.title")}
            </Typography>
          </Grid>
        </Grid>
      </div>

      {!getTableRows.length && filterData.length === 1 && !stringForSearch && !isDataLoading ? (
        <EmptyInstallationPage
          icon={
            <Building01
              size="24"
              style={{
                color:
                  theme.colors
                    .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
                position: "relative",
                left: "3px",
                top: "3px",
              }}
              strokeWidth={2}
            />
          }
          title={`${t("pages.residentialInstallation.emptyStateTitle")}`}
          description={`${t("pages.residentialInstallation.emptyStateDescription")}`}
          withButton
          buttonText={t("pages.commercialInstallation.addInstallationButtonText")}
          onButtonClick={() => setIsAddInstallationModalOpen(true)}
          buttonSx={{
            bgcolor: theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
            color: theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            border: `1px solid ${theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder}`,
            svg: {
              color: theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            },
            "&:hover": {
              bgcolor: theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBgHover,
            },
          }}
        />
      ) : (
        <SortableTable
          columns={tableColumns}
          rows={getTableRows}
          infoTableHeadContent={renderInfoTableHeadContent()}
          defaultSorting={(initialQuery?.sortField as any) || "name"}
          defaultOrderDirection={(initialQuery?.sortOrder as "asc" | "desc") || "asc"}
          onRowClick={onRowClick}
          onSortClick={onSortClick}
          noResultsShown={!!(!getTableRows.length && (stringForSearch || filterData.length > 1))}
          noResultsBlock={renderNoResultsBlockForTable()}
          paginationData={{
            page: page,
            totalCount: totalPages!,
            onPageChange: handleChangePage,
          }}
          isLoadingInPropgress={isDataLoading}
        />
      )}
      {isAddInstallationModalOpen && (
        <AddResidentialInstallationModal
          isOpen={isAddInstallationModalOpen}
          onCancel={() => setIsAddInstallationModalOpen(false)}
        />
      )}
      {(isSuperAdminLevel || hasUserInstallationShareAccess) && (
        <AssignInstallationDialog
          ref={assignInstallationDialogRef}
          isSuperAdmin={isSuperAdminLevel}
          onSuccess={() => getInstallations(false)}
          myOrganizationUuid={myOrgUuid}
        />
      )}
    </Container>
  );
}
