import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
  Button as MUIButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useTheme from "../../../theme/hooks/useTheme";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { IUpdateUserRequest, UsersAPI } from "../../../api/UsersAPI";
import { Button, ButtonType } from "../../Button/Button";
import ChangePasswordDialog from "../ChangePasswordModal/ChangePasswordDialog";

export default function EditProfileDialog({
  dialogOpen,
  userId,
  userDetails,
  setDialogOpen,
  onAddCallback,
}: AddUserDialogProperties) {
  const theme = useTheme();
  const showSnackbar = useSnackbar();
  const { t } = useTranslation("cloud_ui");
  const navigate = useNavigate();
  const usersApi = new UsersAPI(navigate);

  const [editAPICallInProgress, setEditAPICallInProgress] =
    useState<boolean>(false);

  //Edit profile fields state
  const [name, setName] = useState<string>();
  const [surname, setSurname] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>("");

  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setName(userDetails.firstName);
    setSurname(userDetails.lastName);
    setEmail(userDetails.email);
    setPhone(userDetails.phoneNumber || "");
  }, [userDetails]);

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleSurnameChange = (value: string) => {
    setSurname(value);
  };

  const handlePhoneChange = (value: string) => {
    setPhone(value);
  };

  const handleAddDialogClose = () => {
    setDialogOpen(false);
  };

  const onChangePassword = () => {
    setIsChangePasswordDialogOpen(true);
  };

  const editUserProfile = () => {
    setEditAPICallInProgress(true);

    const requestBody: IUpdateUserRequest = {
      firstName: name as string,
      lastName: surname as string,
      phoneNumber: (phone as string) || null,
    };

    usersApi
      .updateUser(requestBody)
      .then(() => {
        showSnackbar(t("pages.editProfile.successfullyEdited"), {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
          severity: "success",
        });
        setEditAPICallInProgress(false);
        onAddCallback();
      })
      .catch(() => {
        setEditAPICallInProgress(false);
        showSnackbar(t("pages.editProfile.editError"), {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
          severity: "error",
        });
      });
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        fullWidth={true}
        sx={{
          "& .MuiPaper-root": { maxWidth: "640px" },
          opacity: isChangePasswordDialogOpen ? 0 : 1,
        }}
      >
        <DialogTitle sx={{ padding: `${theme.spacing.spacing3xl}` }}>
          <Typography
            color={theme.colors.colorsTextTextPrimary}
            fontSize={18}
            variant={"h5"}
          >
            {t("pages.editProfile.title")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: `0 ${theme.spacing.spacing3xl}`,
            mb: theme.spacing.spacing4xl,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: theme.spacing.spacingXl,
            }}
          >
            <Box sx={{ width: `calc(50% - (${theme.spacing.spacingXl} / 2))` }}>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("pages.editProfile.nameFieldLabel")}
              </InputLabel>
              <TextField
                sx={{
                  margin: 0,
                  "& .MuiInputBase-root": {
                    height: 40,
                    padding: `0 ${theme.spacing.spacingLg}`,
                    input: {
                      padding: 0,
                      ":placeholder-shown": {
                        color: theme.colors.colorsTextTextPlaceholder,
                      },
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    ml: 0,
                  },
                }}
                value={name}
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                onChange={(e) => handleNameChange(e.target.value.trim())}
              />
            </Box>
            <Box sx={{ width: `calc(50% - (${theme.spacing.spacingXl} / 2))` }}>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("pages.editProfile.surnameFieldLabel")}
              </InputLabel>
              <TextField
                sx={{
                  margin: 0,
                  "& .MuiInputBase-root": {
                    height: 40,
                    padding: `0 ${theme.spacing.spacingLg}`,
                    input: {
                      padding: 0,
                      ":placeholder-shown": {
                        color: theme.colors.colorsTextTextPlaceholder,
                      },
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    ml: 0,
                  },
                }}
                value={surname}
                margin="dense"
                id="surname"
                type="text"
                fullWidth
                onChange={(e) => handleSurnameChange(e.target.value.trim())}
              />
            </Box>
          </Box>
          <InputLabel
            shrink
            sx={{
              color: theme.colors.colorsTextTextSecondary,
              fontWeight: 500,
            }}
          >
            {t("pages.editProfile.emailFieldLabel")}
          </InputLabel>
          <TextField
            sx={{
              margin: 0,
              "& .MuiInputBase-root": {
                backgroundColor: theme.colors.colorsBackgroundBgDisabledSubtle,
                height: 40,
                padding: `0 ${theme.spacing.spacingLg}`,
                input: {
                  padding: 0,
                  ":placeholder-shown": {
                    color: theme.colors.colorsTextTextPlaceholder,
                  },
                },
              },
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
            value={email}
            disabled
            margin="dense"
            id="email"
            type="text"
            fullWidth
            onChange={(e) => {}}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: theme.spacing.spacingXl,
              mt: theme.spacing.spacingXl,
            }}
          >
            <Box
              sx={{
                width: `calc(50% - (${theme.spacing.spacingXl} / 2))`,
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <MUIButton
                variant="text"
                sx={{
                  ...theme.typography[".text-sm-semibold"],
                  maxWidth: "fit-content",
                  marginTop: 0,
                  display: { xs: "none", md: "flex" },
                  color: theme.colors.colorsForegroundFgBrandSecondary,
                  textTransform: "initial",
                  height: "40px",
                }}
                aria-label="open drawer"
                onClick={onChangePassword}
              >
                {t("pages.editProfile.changePasswordButtonText")}
              </MUIButton>
            </Box>
          </Box>
          <InputLabel
            shrink
            sx={{
              color: theme.colors.colorsTextTextSecondary,
              fontWeight: 500,
            }}
          >
            {t("pages.editProfile.phoneFieldLabel")}
          </InputLabel>
          <TextField
            sx={{
              margin: 0,
              "& .MuiInputBase-root": {
                backgroundColor: theme.colors.colorsBackgroundBgDisabledSubtle,
                height: 40,
                padding: `0 ${theme.spacing.spacingLg}`,
                input: {
                  padding: 0,
                  ":placeholder-shown": {
                    color: theme.colors.colorsTextTextPlaceholder,
                  },
                },
              },
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
            value={phone}
            margin="dense"
            id="phone"
            type="text"
            fullWidth
            onChange={(e) => handlePhoneChange(e.target.value.trim())}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
          }}
        >
          <Button
            label={`${t("actions.cancel")}`}
            onClick={handleAddDialogClose}
            buttonType={ButtonType.SecondaryGray}
            sx={{
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
          <Button
            label={`${t("actions.save")}`}
            disabled={!name || !surname}
            onClick={editAPICallInProgress ? () => {} : editUserProfile}
            loadingInProgress={editAPICallInProgress}
            sx={{
              pointerEvents: editAPICallInProgress ? "none" : "initial",
              ml: "0 !important",
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </Dialog>
      {isChangePasswordDialogOpen && (
        <ChangePasswordDialog
          dialogOpen={isChangePasswordDialogOpen}
          onAddCallback={() => {
            setIsChangePasswordDialogOpen(false);
          }}
          setDialogOpen={setIsChangePasswordDialogOpen}
        />
      )}
    </>
  );
}

export type AddUserDialogProperties = {
  dialogOpen: boolean;
  userId: string;
  userDetails: any;
  setDialogOpen: (open: boolean) => void;
  onAddCallback: () => void;
};
