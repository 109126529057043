import React from "react";
import { IInstallationZonesProps } from "./types";
import useInstallationZones from "./hooks/useInstallationZones";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ZonesTabs from "../../components/ZonesTabs/ZonesTabs";
import { Navigate, Route, Routes } from "react-router";
import PrivateRoute from "../../../../../../components/PrivateRoute/PrivateRoute";
import InstallationZone from "./components/InstallationZone/InstallationZone";
import useInstallationZonesStyles from "./styles/useInstallationZonesStyles";

export default function InstallationZones({
  baseUrl,
  isInstallationOffline = false,
  isInstallerZones = false,
}: IInstallationZonesProps) {
  //Hooks
  const {
    installationSettingsResponse,
    installationStateResponse,
    zonesNavItems,
    zonesCount,
    isLoadingSettings,
    isLoadingState,
    setIsPoolingIntervalEnabled,
  } = useInstallationZones({ baseUrl, isInstallerZones });

  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });
  //Hooks end

  //Styles
  const { styles } = useInstallationZonesStyles(zonesCount, isInstallerZones);
  //Styles end

  return (
    <Box sx={styles.mainWrapper}>
      <Typography sx={styles.mainTitle}>{t("mainTitle")}</Typography>
      {zonesNavItems && (
        <Box sx={styles.zonesNavigationWrapper}>
          <ZonesTabs tabs={zonesNavItems} />
        </Box>
      )}

      <Box sx={styles.zoneContentWrapper}>
        <Routes>
          <Route index element={<Navigate to={`${baseUrl}/zone1`} replace />} />
          <Route
            path="/:zoneNumber"
            element={
              <PrivateRoute
                element={
                  <InstallationZone
                    isInstallerZones={isInstallerZones}
                    installationSettingsResponse={installationSettingsResponse}
                    installationStateResponse={installationStateResponse}
                    isInstallationOffline={isInstallationOffline}
                    setIsPoolingIntervalEnabled={setIsPoolingIntervalEnabled}
                    isLoadingSettings={isLoadingSettings}
                    isLoadingState={isLoadingState}
                  />
                }
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
}
