import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useResize from "../../../hooks/useResize";

export default function useDropdownScrollableWithCheckboxesAndSectionsPosition(
  isDropdownOpen: boolean
) {
  const { width, height } = useResize();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [buttonCoords, setButtonCoords] = useState<DOMRect | null>(null);
  const [dropdownCoords, setDropdownCoords] = useState<DOMRect | null>(null);
  const [distanceToBottom, setDistanceToBottom] = useState<string | null>(null);

  const updateButtonCoords = useCallback(() => {
    if (buttonRef.current) {
      setButtonCoords(buttonRef.current.getBoundingClientRect());
    }
  }, []);

  const updateDropdownCoords = useCallback(
    (height: number) => {
      if (dropdownRef.current && isDropdownOpen) {
        const distanceToBottom =
          height - dropdownRef.current.getBoundingClientRect().y;
        setDropdownCoords(dropdownRef.current.getBoundingClientRect());
        let heightValue: string | null;
        switch (true) {
          case distanceToBottom < 400:
            heightValue = `${distanceToBottom - 5}px`;
            break;
          case distanceToBottom >= 400:
            heightValue = null;
            break;
          default:
            heightValue = null;
            break;
        }
        setDistanceToBottom(heightValue);
      }
    },
    [isDropdownOpen]
  );

  useEffect(() => {
    updateButtonCoords();
  }, [width, isDropdownOpen, updateButtonCoords]);

  useLayoutEffect(() => {
    updateDropdownCoords(height);
  }, [height, isDropdownOpen, updateDropdownCoords]);

  return {
    buttonRef,
    dropdownRef,
    buttonCoords,
    dropdownCoords,
    distanceToBottom,
  };
}
