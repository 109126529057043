import { SxProps } from "@mui/material";
import { useMemo } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export default function useRadioButtonWithLabelStyles(isDisabled: boolean) {
  const theme = useTheme();
  const baseRadioIconStyles: SxProps = useMemo(
    () => ({
      width: "16px",
      minWidth: "16px",
      height: "16px",
      border: `1px solid ${
        isDisabled
          ? theme.colors.colorsBorderBorderDisabledSubtle
          : theme.colors.colorsBorderBorderPrimary
      }`,
      backgroundColor: isDisabled
        ? theme.colors.colorsBackgroundBgDisabledSubtle
        : "initial",
      borderRadius: "50%",
      cursor: isDisabled ? "initial" : "pointer",
    }),
    [theme, isDisabled]
  );

  const selectedRadioIconStyles: SxProps = useMemo(
    () => ({
      ...baseRadioIconStyles,
      border: `5px solid ${
        isDisabled
          ? theme.colors.colorsBackgroundBgDisabledSubtle
          : theme.colors.colorsBackgroundBgBrandSolid
      }`,
      backgroundColor: isDisabled
        ? theme.colors.colorsForegroundFgDisabledSubtle
        : "initial",
    }),
    [theme, isDisabled]
  );

  const styles: Record<string, SxProps> = useMemo(
    () => ({
      wrapper: {
        mb: theme.spacing.spacingXl,
        display: "flex",
        alignItems: "center",
        border: "none",
        backgroundColor: "initial",
        cursor: isDisabled ? "initial" : "pointer",
      },
      label: {
        ...theme.typography[".text-md-medium"],
        color: theme.colors.colorsTextTextSecondary,
        ml: theme.spacing.spacingLg,
      },
    }),
    [theme, isDisabled]
  );
  return { baseRadioIconStyles, selectedRadioIconStyles, styles };
}
