import {
  ActiveOrInactiveEnum,
  IInstallationBaseValueWithMeasurement,
  OnOrOffEnum,
  UserSettingsVacationModeStateEnum,
  ZoneOperationsKey,
} from "./installationsCommon";

export enum InstallationZoneOperationModeEnum {
  NONE = "NONE",
  HEATING = "HEATING",
  COOLING = "COOLING",
  BOTH = "BOTH",
}

export enum InstallationIndoorSensorStateEnum {
  NONE = "NONE",
  WIRED = "WIRED",
  WIRELESS_1 = "WIRELESS_1",
  WIRELESS_2 = "WIRELESS_2",
  UNDEFINED = "UNDEFINED",
}

export enum InstallationZoneOperationControllerEnum {
  CURVE = "CURVE",
  FIXED = "FIXED",
}

export enum InstallationOperationSpaceModeEnum {
  AUTO = "AUTO",
  HEATING = "HEATING",
  COOLING = "COOLING",
  OFF = "OFF",
}

export enum InstallationOperationHPStateEnum {
  ON = "ON",
  OFF = "OFF",
  UNDEFINED = "UNDEFINED",
}

export enum InstallationComissioningZoneCircuitTypeEnum {
  MIXING = "MIXING",
  DIRECT = "DIRECT",
}

export enum StageStateEnum {
  OFF = "OFF",
  ON_COMPRESSOR_OFF = "ON_COMPRESSOR_OFF",
  AVAILABLE = "AVAILABLE",
}

export enum AdditionalSourcePriorityForDhwEnum {
  INLINE = "INLINE",
  DHW_TANK = "DHW_TANK",
}

export enum AdditionalSourcePriorityForHeatingEnum {
  INLINE = "INLINE",
  BUFFER_TANK = "BUFFER_TANK",
}

export enum SeasonSwitchEnum {
  OUTDOOR_SENSOR = "SEASON",
  DIGITAL_SIGNAL = "DIGITAL_SIGNAL",
}

export interface IPointValues {
  outdoorTemp: IInstallationBaseValueWithMeasurement;
  flowTemp: IInstallationBaseValueWithMeasurement;
}

export interface ISetPointValues {
  outdoorTemp: number;
  flowTemp: number;
}

export type PointKey = "point1" | "point2" | "point3" | "point4" | "point5";

export interface IInstallationZoneOperation {
  heatingController: InstallationZoneOperationControllerEnum;
  coolingController: InstallationZoneOperationControllerEnum;
  preferredRoomTemp: IInstallationBaseValueWithMeasurement;
  preferredFixedFlowHeatingTemp: IInstallationBaseValueWithMeasurement;
  preferredFixedFlowCoolingTemp: IInstallationBaseValueWithMeasurement;
  heatingCurve: {
    [key in PointKey]?: IPointValues;
  };
  coolingCurve: {
    [key in PointKey]?: IPointValues;
  };
}

export interface IInstallationDHWAntoLegionella {
  state: ActiveOrInactiveEnum;
  temp: IInstallationBaseValueWithMeasurement;
  duration: IInstallationBaseValueWithMeasurement;
  startHour: IInstallationBaseValueWithMeasurement;
  endHour: IInstallationBaseValueWithMeasurement;
  interval: IInstallationBaseValueWithMeasurement;
  timeout: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationDHWSettings {
  preferredTemp: IInstallationBaseValueWithMeasurement;
  antiLegionella: IInstallationDHWAntoLegionella;
}

export interface IInstallationsOperationSettings {
  spaceMode: InstallationOperationSpaceModeEnum;
  hpState: InstallationOperationHPStateEnum;
  dhwState: OnOrOffEnum;
}

export interface IInstallationUserSettingsVacationMode {
  state: UserSettingsVacationModeStateEnum;
  startDate: string;
  endDate: string;
  reduceHeatingDeltaTemp: IInstallationBaseValueWithMeasurement;
  reduceDHWDeltaTemp: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationUserSettingsQuietMode {
  startMonday: IInstallationBaseValueWithMeasurement;
  lengthMonday: IInstallationBaseValueWithMeasurement;
  startTuesday: IInstallationBaseValueWithMeasurement;
  lengthTuesday: IInstallationBaseValueWithMeasurement;
  startWednesday: IInstallationBaseValueWithMeasurement;
  lengthWednesday: IInstallationBaseValueWithMeasurement;
  startThursday: IInstallationBaseValueWithMeasurement;
  lengthThursday: IInstallationBaseValueWithMeasurement;
  startFriday: IInstallationBaseValueWithMeasurement;
  lengthFriday: IInstallationBaseValueWithMeasurement;
  startSaturday: IInstallationBaseValueWithMeasurement;
  lengthSaturday: IInstallationBaseValueWithMeasurement;
  startSunday: IInstallationBaseValueWithMeasurement;
  lengthSunday: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationUserSettings {
  vacationMode: IInstallationUserSettingsVacationMode;
  quietMode: IInstallationUserSettingsQuietMode;
  heatingSeasonStartTemp: IInstallationBaseValueWithMeasurement;
  coolingSeasonStartTemp: IInstallationBaseValueWithMeasurement;
}

export interface IZoneComissioningValues {
  mode: InstallationZoneOperationModeEnum;
  indoorSensor: InstallationIndoorSensorStateEnum;
  minFlowHeatTemp: IInstallationBaseValueWithMeasurement;
  maxFlowHeatTemp: IInstallationBaseValueWithMeasurement;
  minFlowCoolTemp: IInstallationBaseValueWithMeasurement;
  maxFlowCoolTemp: IInstallationBaseValueWithMeasurement;
  mixingValveTurningTime: IInstallationBaseValueWithMeasurement;
}

export interface IAdditionalDhwHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValueWithMeasurement;
}

export interface IAdditionalInlineHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValueWithMeasurement;
  stage1?: StageStateEnum;
  stage2?: StageStateEnum;
  stage3?: StageStateEnum;
  outdoorThresholdTemp: IInstallationBaseValueWithMeasurement;
  heatBalanceForHeating: IInstallationBaseValueWithMeasurement;
}

export interface IBufferTank {
  state: ActiveOrInactiveEnum;
  inlineHeaterState: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValueWithMeasurement;
  additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
}

export interface IPriorities {
  heating: IInstallationBaseValueWithMeasurement;
  cooling: IInstallationBaseValueWithMeasurement;
  dhw: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationInstallerSettingsComissioningDHW {
  active: ActiveOrInactiveEnum;
  deltaDeviationTemp: IInstallationBaseValueWithMeasurement;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
  compressorSpeedForDhw: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationInstallerSettingsComissioning {
  zonesCount: IInstallationBaseValueWithMeasurement;
  zoneOperations: {
    [key in ZoneOperationsKey]?: IZoneComissioningValues;
  };
  additionalDhwHeatingSource: IAdditionalDhwHeatingSource;
  additionalInlineHeatingSource: IAdditionalInlineHeatingSource;
  bufferTank: IBufferTank;
  dhw: IInstallationInstallerSettingsComissioningDHW;
  shiftingPriorities: IPriorities;
}

export interface IWaterPumpP0Speed {
  heating: IInstallationBaseValueWithMeasurement;
  cooling: IInstallationBaseValueWithMeasurement;
  dhw: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationInstallerSettingsOthers {
  seasonSwitch: SeasonSwitchEnum;
  waterPumpP0Speed: IWaterPumpP0Speed;
}

export interface IInstallationInstallerSettings {
  commissioning: IInstallationInstallerSettingsComissioning;
  others: IInstallationInstallerSettingsOthers;
}

//SET HELPERS MODELS
export interface ISetOperationZonesParams {
  heatingController: InstallationZoneOperationControllerEnum;
  coolingController: InstallationZoneOperationControllerEnum;
  preferredRoomTemp: number;
  preferredFixedFlowHeatingTemp: number;
  preferredFixedFlowCoolingTemp: number;
  heatingCurve: {
    [key in PointKey]?: ISetPointValues;
  };
  coolingCurve: {
    [key in PointKey]?: ISetPointValues;
  };
}

export interface ISetAntilegionellaSettings {
  state: ActiveOrInactiveEnum;
  temp: number;
  duration: number;
  startHour: number;
  endHour: number;
  interval: number;
}

export interface ISetDhwSettings {
  preferredTemp: number;
  antiLegionella: ISetAntilegionellaSettings;
}

export interface ISetOperationSettings {
  spaceMode: InstallationOperationSpaceModeEnum;
  hpState: OnOrOffEnum;
  dhwState: OnOrOffEnum;
}

export interface ISetVacationMode {
  startDate: string; //"2024-08-04" Schedule start date in user timezone. Timezone will be picked up from device datetime.
  endDate: string; //"2024-08-04" Schedule end date in user timezone. Timezone will be picked up from device datetime.
  reduceHeatingDeltaTemp: number;
  reduceDHWDeltaTemp: number;
}

export interface ISetQuietMode {
  startMonday: number;
  lengthMonday: number;
  startTuesday: number;
  lengthTuesday: number;
  startWednesday: number;
  lengthWednesday: number;
  startThursday: number;
  lengthThursday: number;
  startFriday: number;
  lengthFriday: number;
  startSaturday: number;
  lengthSaturday: number;
  startSunday: number;
  lengthSunday: number;
}

export interface ISetUserSettings {
  vacationMode?: ISetVacationMode;
  quietMode?: ISetQuietMode;
  heatingSeasonStartTemp?: number;
  coolingSeasonStartTemp?: number;
}

export interface ISetInstallerSettignsComissioningZoneOperation {
  mode: InstallationZoneOperationModeEnum;
  indoorSensor: InstallationIndoorSensorStateEnum;
  minFlowHeatTemp: number;
  maxFlowHeatTemp: number;
  minFlowCoolTemp: number;
  maxFlowCoolTemp: number;
  mixingValveTurningTime: number;
}

export interface ISetInstallerSettignsComissioningAdditionalDHWHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: number;
}

export interface ISetInstallerSettignsComissioningAdditionalInlineHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: number;
  stage1?: StageStateEnum;
  stage2?: StageStateEnum;
  stage3?: StageStateEnum;
  outdoorThresholdTemp: number;
  heatBalanceThresholdForHeating: number;
}

export interface ISetInstallerSettignsComissioningBufferTank {
  state: ActiveOrInactiveEnum;
  inlineHeaterState: ActiveOrInactiveEnum;
  inputPower: number;
  additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
}

export interface ISetInstallerSettignsComissioningPriorities {
  heating: number;
  cooling: number;
  dhw: number;
}

export interface ISetInstallerSettingsComissioningDHW {
  active: ActiveOrInactiveEnum;
  deltaDeviationTemp: number;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
  compressorSpeedForDhw: number;
}

export interface ISetComissioningIstallerSettings {
  zonesCount?: number;
  zoneOperations?: {
    [key in ZoneOperationsKey]?: ISetInstallerSettignsComissioningZoneOperation;
  };
  additionalDhwHeatingSource?: ISetInstallerSettignsComissioningAdditionalDHWHeatingSource;
  additionalInlineHeatingSource?: ISetInstallerSettignsComissioningAdditionalInlineHeatingSource;
  bufferTank?: ISetInstallerSettignsComissioningBufferTank;
  dhw?: ISetInstallerSettingsComissioningDHW;
  shiftingPriorities?: ISetInstallerSettignsComissioningPriorities;
}

export interface ISetOtherInstallerSettingsWaterPumpP0Speed {
  heating: number;
  cooling: number;
  dhw: number;
}

export interface ISetOthersInstallerSettings {
  seasonSwitch: SeasonSwitchEnum;
  waterPumpP0Speed: ISetOtherInstallerSettingsWaterPumpP0Speed;
}

export interface ISetInstallerSettings {
  commissioning?: ISetComissioningIstallerSettings;
  others?: ISetOthersInstallerSettings;
}

//main
//GET
export interface IInstallationSettingsByIdResponse {
  lastUpdatedAt: string;
  zoneOperations: {
    [key in ZoneOperationsKey]?: IInstallationZoneOperation;
  };
  dhw: IInstallationDHWSettings;
  operation: IInstallationsOperationSettings;
  userSettings: IInstallationUserSettings;
  installerSettings: IInstallationInstallerSettings;
}

//SET
export interface ISetInstallationSettings {
  zoneOperations?: {
    [key in ZoneOperationsKey]?: ISetOperationZonesParams;
  };
  dhw?: ISetDhwSettings;
  operation?: ISetOperationSettings;
  userSettings?: ISetUserSettings;
  installerSettings?: ISetInstallerSettings;
}
