import React from "react";
import DifferencesRow from "../../../../../components/Differences/components/DifferencesRow/DifferencesRow";
import SettingsDivider from "../../../../../components/SettingsDivider/SettingsDivider";
import DifferencesSecondaryTitle from "../../../../../components/Differences/components/DifferencesSecondaryTitle/DifferencesSecondaryTitle";
import PointsDifferences from "../PointsDifferences/PointsDifferences";

import { IDefaultZoneDifferencesProps } from "../../../types";

import useDefaultZoneDifferencesConditionsAndValuesForDisplay from "../../../hooks/zoneDifferences/useDefaultZoneDifferencesConditionsAndValuesForDisplay";
import useDifferencesHelpers from "../../../../../components/Differences/hooks/useDifferencesHelpers";

export default function DefaultZoneDifferences({
  differences,
}: IDefaultZoneDifferencesProps) {
  const {
    flowTempHeatingTitle,
    flowTempCoolingTitle,

    desiredRoomTempLabel,
    fixedFlowTempLabel,
    flowTempLabel,

    showMainChangesSection,
    showFlowTempHeatingSection,
    showFlowTempCoolingSection,

    buildSpecificValueForFlowTempController,
  } = useDefaultZoneDifferencesConditionsAndValuesForDisplay(differences);

  const { buildBaseDifferenceValue } = useDifferencesHelpers();
  return (
    <>
      {showMainChangesSection && (
        <DifferencesRow
          label={desiredRoomTempLabel}
          value={buildBaseDifferenceValue(differences?.preferredRoomTemp)}
        />
      )}
      {showMainChangesSection &&
        (showFlowTempHeatingSection || showFlowTempCoolingSection) && (
          <SettingsDivider />
        )}

      {showFlowTempHeatingSection && (
        <>
          <DifferencesSecondaryTitle title={flowTempHeatingTitle} />
          <DifferencesRow
            label={flowTempLabel}
            value={buildSpecificValueForFlowTempController(
              differences?.heatingController
            )}
          />
          <PointsDifferences
            curveDifferences={differences?.heatingCurve}
            buildBaseDifferenceValue={buildBaseDifferenceValue}
          />
          <DifferencesRow
            label={fixedFlowTempLabel}
            value={buildBaseDifferenceValue(
              differences?.preferredFixedFlowHeatingTemp
            )}
          />
        </>
      )}
      {showFlowTempHeatingSection && showFlowTempCoolingSection && (
        <SettingsDivider />
      )}

      {showFlowTempCoolingSection && (
        <>
          <DifferencesSecondaryTitle title={flowTempCoolingTitle} />
          <DifferencesRow
            label={flowTempLabel}
            value={buildSpecificValueForFlowTempController(
              differences?.coolingController
            )}
          />
          <PointsDifferences
            curveDifferences={differences?.coolingCurve}
            buildBaseDifferenceValue={buildBaseDifferenceValue}
          />
          <DifferencesRow
            label={fixedFlowTempLabel}
            value={buildBaseDifferenceValue(
              differences?.preferredFixedFlowCoolingTemp
            )}
          />
        </>
      )}
    </>
  );
}
