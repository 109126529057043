import { useContext } from "react";
import InstallationZonesContext from "../../context/InstallationZonesContext";
import { useTranslation } from "react-i18next";

export default function useZoneDifferences() {
  const { zoneKeys } = useContext(InstallationZonesContext);

  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });

  const mainTitle: string = `${t("zoneTitle")} ${zoneKeys?.zoneNumber}`;

  return { mainTitle };
}
