import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../theme/hooks/useTheme";
import { LifeBuoy01 } from "untitledui-js-base";

export default function SupportFormOpener({
  onClick,
}: ISupportFormOpenerProps) {
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.support_form" });
  const theme = useTheme();

  const baseColor =
    theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder;
  const styles: Record<string, SxProps> = {
    wrapper: {
      display: "flex",
      border: `1px solid ${baseColor}`,
      borderRadius: theme.radius.radiusMd,
      height: "fit-content",
      padding: ".625rem .875rem",
      cursor: "pointer",
      boxShadow: "0px 1px 2px 0px #1018280D",
      transition: "background-color .4s linear, box-shadow 0.1s linear",
      bgcolor: "initial",
      "&:hover": {
        bgcolor: theme.colors.colorsBackgroundBgSecondaryHover,
      },
      "&:active": {
        boxShadow:
          "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(149, 193, 31, 0.24)",
      },
      "&:focus-visible": {
        outline: "none",
        boxShadow:
          "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(149, 193, 31, 0.24)",
      },
    },
    text: {
      ...theme.typography[".text-sm-semibold"],
      color: theme.colors.colorsTextTextSecondary,
      ml: theme.spacing.spacingSm,
    },
  };

  return (
    <Box component={"button"} sx={styles.wrapper} onClick={onClick}>
      <LifeBuoy01
        size="20"
        strokeWidth={2}
        color={theme.colors.colorsForegroundFgSecondary}
      />
      <Typography sx={styles.text}>{t("opener_text")}</Typography>
    </Box>
  );
}
