import React from "react";
import useTheme from "../../theme/hooks/useTheme";
import { SxProps } from "@mui/material";

export default function useDropdownWithExtendedOptionStyles(
  isDropdownOpened: boolean
) {
  const { colors, radius, spacing, typography } = useTheme();

  const styles: Record<string, SxProps> = {
    mainLabel: {
      ...typography[".text-sm-medium"],
      color: colors.colorsTextTextSecondary,
    },
    wrapper: {
      ".MuiInputBase-root": {
        m: `${spacing.spacingSm} 0`,
        p: 0,
        border: `1px solid ${
          isDropdownOpened
            ? colors.colorsBorderBorderBrand
            : colors.colorsBorderBorderPrimary
        }`,
        borderRadius: radius.radiusMd,
        boxShadow: isDropdownOpened
          ? "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(149, 193, 31, 0.24)"
          : "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        "&:hover": {
          borderColor: colors.colorsBorderBorderBrand,
        },
      },
      ".MuiSelect-select": {
        p: 0,
      },
      ".MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
    selectValueWrapper: {
      p: ".625rem .875rem",
    },
    selectValue: {
      ...typography[".text-sm-regular"],
      color: colors.colorsTextTextPrimary,
    },
    arrow: {},
    optionWrapper: {
      p: `0 ${spacing.spacingSm}`,
      borderRadius: radius.radiusSm,
    },
    optionContent: {
      position: "relative",
      width: "100%",
      p: ".625rem",
    },
    checkIconWrapper: {
      display: "flex",
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
    },
    selectOptionLabel: {
      ...typography[".text-sm-medium"],
      color: colors.colorsTextTextPrimary,
      mb: spacing.spacingXs,
    },
    selectOptionDescription: {
      ...typography[".text-xs-regular"],
      color: colors.colorsTextTextTertiary,
    },
  };
  return { styles };
}
