import React from "react";
import { IZoneDifferencesProps } from "../../types";

import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import SettingsDivider from "../../../../components/SettingsDivider/SettingsDivider";

import useZoneDifferences from "../../hooks/zoneDifferences/useZoneDifferences";
import DefaultZoneDifferences from "./DefaultZoneDifferences/DefaultZoneDifferences";
import InstallerZoneDifferences from "./InstallerZoneDifferences/InstallerZoneDifferences";

export default function ZoneDifferences({
  differences,
  zoneCountDifferences,
  installerDifferences,
}: IZoneDifferencesProps) {
  const { mainTitle } = useZoneDifferences();

  return (
    <>
      <SecondaryTitle title={mainTitle} variant="md" />
      {(installerDifferences || zoneCountDifferences) && (
        <InstallerZoneDifferences
          installerDifferences={installerDifferences}
          zoneCountDifferences={zoneCountDifferences}
        />
      )}

      {differences && (zoneCountDifferences || installerDifferences) && (
        <SettingsDivider sx={{ mb: "10px" }} />
      )}

      {differences && <DefaultZoneDifferences differences={differences} />}
    </>
  );
}
