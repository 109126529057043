import React from "react";
import SecondaryTitle from "../../../../../../components/SecondaryTitle/SecondaryTitle";
import { useTranslation } from "react-i18next";
import { ISeasonSettingsDifferences } from "../../types/types";
import DifferencesRow from "../../../../../../components/Differences/components/DifferencesRow/DifferencesRow";
import useDifferencesHelpers from "../../../../../../components/Differences/hooks/useDifferencesHelpers";
import SettingsDivider from "../../../../../../components/SettingsDivider/SettingsDivider";

export interface ISeasonDifferencesProps {
  differences: ISeasonSettingsDifferences | null;
}

export default function SeasonDifferences({
  differences,
}: ISeasonDifferencesProps) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.userSettings.season",
  });

  const mainTitle = t("mainTitle");

  const { buildBaseDifferenceValue } = useDifferencesHelpers();

  return (
    <>
      <SecondaryTitle title={mainTitle} variant="md" />
      {differences?.heatingSeasonStartTemp && (
        <DifferencesRow
          label={t("fieldsLabels.heatingSeasonStartStop")}
          value={buildBaseDifferenceValue(differences?.heatingSeasonStartTemp)}
        />
      )}
      {differences?.heatingSeasonStartTemp &&
        differences?.coolingSeasonStartTemp && (
          <SettingsDivider sx={{ m: "15px 0" }} />
        )}
      {differences?.coolingSeasonStartTemp && (
        <DifferencesRow
          label={t("fieldsLabels.coolingSeasonStartStop")}
          value={buildBaseDifferenceValue(differences?.coolingSeasonStartTemp)}
        />
      )}
    </>
  );
}
