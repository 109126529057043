import React from "react";
import SecondaryTitle from "../../../SecondaryTitle/SecondaryTitle";
import useTheme from "../../../../../../../../theme/hooks/useTheme";

interface IDifferencesSecondaryTitle {
  title: string;
}

export default function DifferencesSecondaryTitle({
  title,
}: IDifferencesSecondaryTitle) {
  const theme = useTheme();
  return (
    <SecondaryTitle
      title={title}
      variant="sm"
      color={theme.colors.colorsTextTextTertiary}
    />
  );
}
