import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { getStyles } from "./SubMenuStyles";

export interface ISubNavItem {
  label: string;
  href: string;
}

export interface ISubMenuProps {
  menuItems: Array<ISubNavItem>;
}

export default function SubMenu({ menuItems }: ISubMenuProps) {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;

  const styles = getStyles(theme);

  return (
    <Box sx={styles.subMenu}>
      <List sx={{ padding: `0 ${theme.spacing.spacingMd}` }}>
        {menuItems.map((menuItem, index) => (
          <ListItem
            button
            key={`${index}_menuItem_installer-settings`}
            component={RouterLink}
            to={menuItem.href}
            sx={{
              ...styles.listItem,
              ".MuiTypography-root": {
                color: currentPath.includes(menuItem.href.split("?")[0])
                  ? theme.colors.colorsTextTextBrandTertiaryAlt
                  : "inherit",
              },
            }}
          >
            <ListItemText
              sx={styles.listItemText}
              primary={menuItem.label}
              primaryTypographyProps={{
                style: {
                  fontWeight: 600,
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
