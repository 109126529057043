import { Divider, SxProps } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export default function SettingsDivider({ sx }: { sx?: SxProps }) {
  const theme = useTheme();
  return (
    <Divider
      sx={{
        ...sx,
        width: "100%",
        borderColor: theme.colors.colorsBorderBorderSecondary,
      }}
    />
  );
}
