import { useEffect, useMemo, useRef, useState } from "react";
import {
  IBaseItem,
  IUseDropdownScrollableWithCheckboxesAndSectionsParams,
} from "../types";
import useClickOutside from "../../../hooks/useClickOutside";

export default function useDropdownScrollableWithCheckboxesAndSectionsState({
  isDropdownOpen,
  setIsDropdownOpen,
  items,
  applyOnClose,
  selectedValues,
  dropdownRef,
  setSelectedValues,
}: IUseDropdownScrollableWithCheckboxesAndSectionsParams) {
  const [selectedValuesCurrentState, setSelectedValuesCurrentState] =
    useState<Array<string> | null>(applyOnClose ? selectedValues : null);

  const selectedValuesRef = useRef<Array<string | null>>(
    selectedValuesCurrentState
  );

  const allItemsCount = useMemo(
    () => items?.reduce((acc, next) => acc + next.itemsList.length, 0),
    [items]
  );

  const selectedValuesDependsOnMode = useMemo(
    () => (applyOnClose ? selectedValuesCurrentState : selectedValues),
    [applyOnClose, selectedValuesCurrentState, selectedValues]
  );

  useEffect(() => {
    if (applyOnClose) selectedValuesRef.current = selectedValuesCurrentState;
  }, [selectedValuesCurrentState, applyOnClose]);

  useEffect(() => {
    applyOnClose && setSelectedValuesCurrentState(selectedValues);
  }, [selectedValues, applyOnClose]);

  useClickOutside(
    dropdownRef,
    () => {
      applyOnClose && setSelectedValues(selectedValuesRef.current as string[]);
      setIsDropdownOpen(false);
    },
    ["dd_button"]
  );

  const handleSelectAllCheckboxChange = () => {
    const getAllValuesArray = () =>
      items!
        .reduce((acc, next) => acc.concat(next.itemsList as any), [])
        .map((item) => (item as any).value);

    if (applyOnClose) {
      setSelectedValuesCurrentState(
        allItemsCount === selectedValuesCurrentState?.length
          ? []
          : getAllValuesArray()
      );
    } else {
      setSelectedValues(
        allItemsCount === selectedValues.length ? [] : getAllValuesArray()
      );
    }
  };

  const handleParameterCheckboxChange = (item: IBaseItem) => {
    let updatedSelectedValues: Array<string> = [
      ...((applyOnClose
        ? selectedValuesCurrentState
        : selectedValues) as string[]),
    ];
    if (updatedSelectedValues.includes(item.value)) {
      updatedSelectedValues = updatedSelectedValues.filter((val) => {
        return val !== item.value;
      });
    } else {
      updatedSelectedValues.push(item.value);
    }
    applyOnClose && setSelectedValuesCurrentState(updatedSelectedValues);
    !applyOnClose && setSelectedValues(updatedSelectedValues);
  };

  const onDropdownOpenerClick = () => {
    if (isDropdownOpen && applyOnClose) {
      setSelectedValues(selectedValuesCurrentState as string[]);
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onClearSelectionClick = () => {
    applyOnClose && setSelectedValuesCurrentState([]);
    setSelectedValues([]);
  };
  return {
    allItemsCount,
    selectedValuesDependsOnMode,
    onDropdownOpenerClick,
    onClearSelectionClick,
    handleSelectAllCheckboxChange,
    handleParameterCheckboxChange,
  };
}
