import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { InstallationPartsTypes } from "../../../../../../api/FleetManagementAPI";

export interface IAboutSectionProps {
  title: string;
  type?: InstallationPartsTypes;
  modelNumber?: string;
  model?: string | number;
  serialNumber?: string;
  softwareVersion?: string;
  eeprom?: string;
  hardwareVersion?: string;
}

export interface IAboutColumnItem {
  title: string;
  value?: any;
}

export default function AboutSection({
  title,
  type,
  modelNumber,
  model,
  serialNumber,
  softwareVersion,
  eeprom,
  hardwareVersion,
}: IAboutSectionProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.infoDetailsPage.about",
  });

  const availableOutdoorUnitModels: Array<number> = [
    120702, 120703, 120704, 120707,
  ];

  const getModelTranslation = () => {
    switch (type) {
      case InstallationPartsTypes.INDOOR_UNIT:
        return t(`indorUnitModels.${model}`);
      case InstallationPartsTypes.OUTDOOR_UNIT:
        return model
          ? availableOutdoorUnitModels.includes(+model)
            ? t(`outdoorUnitModels.${model}`)
            : model
          : model;
      default:
        return model;
    }
  };

  const [aboutColumItems] = useState<Array<IAboutColumnItem>>([
    {
      title: t("labels.serialNumber"),
      value: serialNumber,
    },
    {
      title: t("labels.model"),
      value: getModelTranslation(),
    },
    {
      title: t("labels.modelNumber"),
      value: type === InstallationPartsTypes.OUTDOOR_UNIT ? null : modelNumber,
    },
    {
      title: t("labels.softwareVersion"),
      value: softwareVersion,
    },
    {
      title: t("labels.hardwareVersion"),
      value: hardwareVersion,
    },
    {
      title: t("labels.eeprom"),
      value: eeprom,
    },
  ]);

  return (
    <Box sx={{ width: "100%", mb: theme.spacing.spacing3xl }}>
      <Typography
        sx={{
          ...theme.typography[".text-lg-semibold"],
          color: theme.colors.colorsTextTextPrimary,
          mb: theme.spacing.spacingXl,
        }}
      >
        {title}
      </Typography>
      {aboutColumItems.map(
        (item, index) =>
          item.value && (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: theme.spacing.spacingXl,
              }}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-md-medium"],
                  color: theme.colors.colorsTextTextTertiary,
                  minWidth: "50%",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  wordBreak: "break-word",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
}
