import {
  Box,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useTheme from "../../theme/hooks/useTheme";
import { IDropdownWithExtendedOptionsProps } from "./types";
import { Check, ChevronDown, ChevronUp } from "untitledui-js-base";
import useDropdownWithExtendedOptionStyles from "./useDropdownWithExtendedOptionStyles";

export default function DropdownWithExtendedOptions({
  items,
  defaultValue,
  onChange,
  label,
}: IDropdownWithExtendedOptionsProps) {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  const { colors } = useTheme();
  const { styles } = useDropdownWithExtendedOptionStyles(isDropdownOpened);

  const renderSelectedValue = (selected: any) => {
    const selectedItem = items.find((el) => el.value === selected);
    return (
      <Box sx={styles.selectValueWrapper}>
        <Typography sx={styles.selectValue}>{selectedItem?.label}</Typography>
      </Box>
    );
  };

  const renderIconComponent = () => {
    const iconProps = {
      size: "20",
      strokeWidth: 2,
      style: {
        position: "absolute" as const,
        top: "50%",
        right: "10px",
        transform: "translateY(-50%)",
        color: colors.colorsForegroundFgQuinary,
        pointerEvents: "none" as const,
      },
    };
    return isDropdownOpened ? (
      <ChevronUp {...iconProps} />
    ) : (
      <ChevronDown {...iconProps} />
    );
  };

  return (
    <>
      <InputLabel sx={styles.mainLabel}>{label}</InputLabel>
      <TextField
        select
        sx={styles.wrapper}
        fullWidth
        value={defaultValue}
        onChange={(e) => onChange(e.target.value)}
        SelectProps={{
          renderValue: renderSelectedValue,
          IconComponent: renderIconComponent,
          onOpen: () => setIsDropdownOpened(true),
          onClose: () => setIsDropdownOpened(false),
          MenuProps: {
            PaperProps: {
              sx: {
                mt: 0.5,
                p: 0.5,
              },
            },
          },
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={styles.optionWrapper}
          >
            <Box sx={styles.optionContent}>
              <Typography sx={styles.selectOptionLabel}>
                {item.label}
              </Typography>
              <Typography sx={styles.selectOptionDescription}>
                {item.description}
              </Typography>
              {defaultValue === item.value && (
                <Box sx={styles.checkIconWrapper}>
                  <Check
                    size="20"
                    color={colors.colorsForegroundFgBrandPrimary}
                  />
                </Box>
              )}
            </Box>
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
