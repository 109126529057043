import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import FilterButton from "../../../../../../components/Button/FilterButton";

export interface IFilter {
  label: string;
  value: any;
  icon?: any;
  selected: boolean;
  canNotBeCleared?: boolean;
}

export interface IFiltersProps {
  filters: Array<IFilter>;
  onFilterClick: (value: any) => void;
}

export default function Filters({ filters, onFilterClick }: IFiltersProps) {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {filters.map((filter, index) => (
        <FilterButton
          label={filter.label}
          isSelected={filter.selected}
          isFirst={index === 0}
          isLast={index === filters.length - 1}
          icon={filter.icon}
          onClick={() => onFilterClick(filter.value)}
        />
      ))}
    </Box>
  );
}
