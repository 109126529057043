import { api } from "./api";
import { SensorData } from "./models/analytics/analytics";
import {
  ICreateSupportRequestForInstallationRequestParams,
  IInstallationDetails,
  InstallationHistoryAlertsCodesRequestParams,
  InstallationHistoryAlertsCodesResponse,
  InstallationHistoryAlertsRequestParams,
  InstallationHistoryAlertsResponse,
  ShareInstallationRequestParams,
  ShareInstallationRequestSuperAdminParams,
} from "./models/installations/installationsCommon";
import {
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
} from "./models/installations/installationSettings";
import { IInstallationStateByIdResponse } from "./models/installations/installationState";

type GetInstallationStateParams = {
  uuid: string;
  isRealData?: boolean;
};

export type GetInstallationSettingsParams = {
  uuid: string;
  isRealData?: boolean;
};

export type GetInstallationRuntimeHistoryParams = {
  installationUuid: string;
  startDateTime: string;
  endDateTime?: string;
  param: Array<string>;
};

export interface ISetInstallationDetails {
  name: string;
  location: string;
}

export const installationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInstallationState: build.query<
      IInstallationStateByIdResponse,
      GetInstallationStateParams
    >({
      query: ({ uuid, isRealData }) => ({
        url: isRealData
          ? `installations/${uuid}/state?dummyData=false`
          : `installations/${uuid}/state`,
      }),
      providesTags: ["InstallationState"],
    }),
    getInstallationSettings: build.query<
      IInstallationSettingsByIdResponse,
      GetInstallationSettingsParams
    >({
      query: ({ uuid, isRealData }) => ({
        url: isRealData
          ? `installations/${uuid}/settings?dummyData=false`
          : `installations/${uuid}/settings`,
      }),
      providesTags: ["InstallationSettings"],
    }),
    getInstallationSettingsForRealTimeData: build.query<
      IInstallationSettingsByIdResponse,
      GetInstallationSettingsParams
    >({
      query: ({ uuid, isRealData }) => ({
        url: isRealData
          ? `installations/${uuid}/settings?dummyData=false`
          : `installations/${uuid}/settings`,
      }),
      providesTags: ["InstallationSettings"],
    }),
    setInstallationSettings: build.mutation<
      IInstallationSettingsByIdResponse,
      Partial<ISetInstallationSettings> & { uuid: string }
    >({
      query: ({ uuid, ...settings }) => ({
        url: `installations/${uuid}/settings`,
        method: "PATCH",
        headers: {
          // "content-type": "application/settings+json", //TODO!!! Condition (roles) for changing content-type???
          "content-type": "application/configuration-settings+json",
        },
        body: JSON.stringify(settings),
      }),
      // invalidatesTags: ['InstallationSettings', 'InstallationState'],
    }),

    getInstallationRuntimeHistory: build.query<
      Blob,
      GetInstallationRuntimeHistoryParams
    >({
      query: ({ installationUuid, startDateTime, endDateTime, param }) => ({
        url: `installations/${installationUuid}/history/runtime?startDateTime=${startDateTime}&endDateTime=${endDateTime}&param=${param.join(
          "&param="
        )}`,
        responseHandler: (response) => response.blob(),
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }),
      // providesTags: ["InstallationSettings"],
    }),

    getInstallationRuntimeHistoryForGraph: build.query<
      { history: Array<SensorData> },
      GetInstallationRuntimeHistoryParams
    >({
      query: ({ installationUuid, startDateTime, endDateTime, param }) => ({
        url: `installations/${installationUuid}/history/runtime?startDateTime=${startDateTime}&endDateTime=${endDateTime}&param=${param.join(
          "&param="
        )}`,
        headers: {
          Accept: "application/json",
        },
      }),
      // providesTags: ["InstallationSettings"],
    }),

    getInstallationDetails: build.query<
      IInstallationDetails,
      { installationUuid: string; organizationUuid?: string }
    >({
      query: ({ installationUuid }) => ({
        url: `installations/${installationUuid}`,
      }),
      // providesTags: ["InstallationDetails"],
      providesTags: (_result, _error, arg) => [
        { type: "InstallationDetails", id: arg.organizationUuid },
      ],
    }),

    setInstallationDetails: build.mutation<
      IInstallationSettingsByIdResponse,
      ISetInstallationDetails & { uuid: string }
    >({
      query: ({ uuid, ...details }) => ({
        url: `installations/${uuid}`,
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(details),
      }),
    }),
    getInstallationAlertsHistory: build.query<
      InstallationHistoryAlertsResponse,
      InstallationHistoryAlertsRequestParams
    >({
      query: ({
        installationUuid,
        pageNum,
        pageSize,
        activeOnly,
        type,
        alertCode,
        startTimestamp,
        endTimestamp,
      }) => ({
        url: `installations/${installationUuid}/history/alerts?pageNum=${pageNum}&pageSize=${pageSize}&alertCode=${alertCode.join(
          "&alertCode="
        )}&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}&activeOnly=${activeOnly}&type=${type.join(
          "&type="
        )}`,
      }),
    }),
    getInstallationAlertsHistoryCodes: build.query<
      InstallationHistoryAlertsCodesResponse,
      InstallationHistoryAlertsCodesRequestParams
    >({
      query: ({ installationUuid, startTimestamp, endTimestamp }) => ({
        url: `installations/${installationUuid}/history/alerts/codes`,
      }),
    }),
    unpairInstallation: build.mutation<void, { installationUuid: string }>({
      query: ({ installationUuid }) => ({
        url: `installations/${installationUuid}/sharing`,
        method: "DELETE",
      }),
    }),
    shareInstallation: build.mutation<
      void,
      ShareInstallationRequestParams | ShareInstallationRequestSuperAdminParams
    >({
      query: (params) => {
        const { installationUuid, isSuperAdmin } = params;

        if (isSuperAdmin) {
          // Handle ShareInstallationRequestSuperAdminParams
          const superAdminParams =
            params as ShareInstallationRequestSuperAdminParams;
          return {
            url: `installations/${installationUuid}/sharing`,
            method: "POST",
            headers: {
              "content-type": "application/admin-installation-sharing+json",
            },
            body: JSON.stringify({
              orgUuid: superAdminParams.orgUuid,
              installationName: superAdminParams.installationName,
            }),
          };
        } else {
          // Handle ShareInstallationRequestParams
          const regularParams = params as ShareInstallationRequestParams;
          return {
            url: `installations/${installationUuid}/sharing`,
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              parentOrgUuid: regularParams.parentOrgUuid,
              childOrgUuid: regularParams.childOrgUuid,
            }),
          };
        }
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: "InstallationDetails", id: arg.installationUuid },
      ],
    }),
    createSupportRequestForInstallation: build.mutation<
      void,
      ICreateSupportRequestForInstallationRequestParams
    >({
      query: ({ installationId, request, files }) => {
        const formData = new FormData();
        const jsonBlob = new Blob([JSON.stringify(request)], {
          type: "application/json",
        });
        formData.append("request", jsonBlob);
        files.forEach((file) => {
          formData.append("files", file);
        });
        return {
          url: `installations/${installationId}/support/requests`,
          method: "POST",
          body: formData,
        };
      },
    }),

    restoreInstallationSettings: build.mutation<
      void,
      { installationUuid: string; restoreDate: string }
    >({
      query: ({ installationUuid, restoreDate }) => ({
        url: `installations/${installationUuid}/settings/restore`,
        method: "POST",
        body: JSON.stringify({ restoreDate }),
      }),
    }),
  }),
});

export const {
  useGetInstallationStateQuery,
  useGetInstallationSettingsQuery,
  useGetInstallationSettingsForRealTimeDataQuery,
  useSetInstallationSettingsMutation,
  useGetInstallationRuntimeHistoryQuery,
  useGetInstallationDetailsQuery,
  useLazyGetInstallationRuntimeHistoryQuery,
  useLazyGetInstallationRuntimeHistoryForGraphQuery,
  useSetInstallationDetailsMutation,
  useLazyGetInstallationAlertsHistoryQuery,
  useLazyGetInstallationAlertsHistoryCodesQuery,
  useUnpairInstallationMutation,
  useShareInstallationMutation,
  useCreateSupportRequestForInstallationMutation,
  useRestoreInstallationSettingsMutation,
  endpoints: {
    getInstallationState,
    getInstallationSettings,
    setInstallationSettings,
    getInstallationRuntimeHistory,
    getInstallationRuntimeHistoryForGraph,
    getInstallationDetails,
    setInstallationDetails,
    getInstallationAlertsHistory,
    getInstallationAlertsHistoryCodes,
    unpairInstallation,
    shareInstallation,
    createSupportRequestForInstallation,
  },
} = installationApi;
