import { Box, SxProps, Typography } from "@mui/material";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";
import EChartsReact from "echarts-for-react";
import { SensorData } from "../../../../../../store/services/models/analytics/analytics";
import useTheme, { ESThemeType } from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import GraphSkeleton from "./GraphSkeleton";
import html2canvas from "html2canvas";
import { AlertCircle } from "untitledui-js-base";
import { getParameterColor, getParameterUnit } from "../../GraphReport/ParametersUnits";
import { AvailableParametersEnum } from "../../types/types";
import LegendsList from "./LegendsList";
import { useUnactivate } from "react-activation";

type GraphProps = {
  data: Array<SensorData>;
  isLoading?: boolean;
  isError?: boolean;
  height?: number;
};

type ChartsData = {
  seriesData: Array<EChartsOption["series"]>;
  timeLabels: Array<string>;
  palette: Array<string>;
};

export type GraphRef = {
  getImageUrl: () => Promise<string | undefined>;
};

const defaultPalette = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
];

const transformDataForCharts = (data: SensorData[]): ChartsData => {
  const allKeys =
    data?.length > 0 ? Object.keys(data[0]).filter((key) => key !== "date" && key !== "time") : [];
  const timeLabels = data?.length > 0 ? data.map((item) => `${item.date} ${item.time}`) : [];
  const seriesData: EChartsOption["series"] =
    allKeys.map((key) => {
      const dataValues = data.map((item) => item[key]);
      const uniqueValues = new Set(dataValues);
      const useMarkPoints = uniqueValues.size !== 1;
      return {
        name: key,
        type: "line",
        data: dataValues,
        smooth: false,
        // sampling: "lttb",
        // emphasis: {
        //   focus: "series",
        //   // blurScope: "series",
        // },
        markPoint: useMarkPoints
          ? {
              symbolSize: 40,
              label: {
                color: "#fff",
                fontSize: 8,
                fontFamily: "Inter",
                fontWeight: 600,
                position: "inside",
              },
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
              zlevel: 0,
              z: -1,
            }
          : undefined,
      };
    }) || [];

  const palette =
    allKeys
      .map((key) => getParameterColor(key as AvailableParametersEnum))
      .filter((color): color is string => color !== undefined) || defaultPalette;
  return {
    timeLabels,
    seriesData,
    palette,
  };
};

const Graph = forwardRef<GraphRef, GraphProps>(
  ({ data, isLoading = false, isError = false, height = 440 }, ref) => {
    const { t } = useTranslation("cloud_ui");
    const keyPrefix = "pages.analyticsPage.availableParametersDropdown.parameters";
    const { t: tParameters } = useTranslation("cloud_ui", { keyPrefix: keyPrefix });

    const theme = useTheme();
    const styles = getStyles(theme);
    const chartRef = useRef<EChartsReact>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const hasTranslation = useCallback(
      (key: string) => Boolean(tParameters(key, { returnNull: false }) !== `${keyPrefix}.${key}`),
      [tParameters, keyPrefix],
    );

    const { timeLabels, seriesData, palette } = useMemo(() => transformDataForCharts(data), [data]);
    const [isRendering, setIsRendering] = useState(false);

    const renderTooltip = useCallback(
      (params: any) => {
        if (!params || params.length === 0) return "";

        const timestamp = params[0]?.axisValue;

        return `
      <div>
        <div style="font-weight: 600; font-size: 12px; color: #1A1A1A; margin-bottom: 6px;">
          ${timestamp}
        </div>
        ${params
          .map((param: any) => {
            const unit = getParameterUnit(param.seriesName as AvailableParametersEnum);
            const paramName = hasTranslation(param.seriesName)
              ? tParameters(`${param.seriesName}`)
              : `${param.seriesName} ${unit ? "(" + unit + ")" : ""}`;
            return `
                <div style="display: flex; align-items: center; gap: 6px; margin-bottom: 4px;">
                  <span style="width: 8px; height: 8px; border-radius: 50%; background: ${param.color}; display: inline-block;"></span>
                  <span style="font-size: 12px; color: #1A1A1A;">${paramName}</span>
                  <span style="font-weight: 600; margin-left: auto;">${param.value}</span>
                </div>
              `;
          })
          .join("")}
      </div>
    `;
      },
      [hasTranslation, tParameters],
    );

    const option: EChartsOption = useMemo(
      () => ({
        tooltip: {
          trigger: "axis",
          renderMode: "html",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          extraCssText: "backdrop-filter: blur(12px);",
          padding: 10,
          formatter: renderTooltip,
        },
        legend: {
          show: false,
          selectedMode: "multiple",
          // type: "scroll",
          // align: "left",
          // icon: "circle",
          // height: "auto",
          // left: 10,
          // itemWidth: 12,
          // itemHeight: 12,
          // itemGap: 20,
          // textStyle: {
          //   fontSize: "14px",
          //   fontFamily: "Inter",
          //   fontWeight: 500,
          //   color: theme.colors.colorsTextTextPrimary,
          // },
          // selectedMode: false,
        },
        grid: {
          top: 40,
          left: 8,
          right: 30,
          height: height - 100,
          containLabel: true,
        },
        dataZoom: [
          { type: "inside", start: 0, end: 100 },
          { type: "slider", start: 0, end: 100 },
        ],
        xAxis: {
          type: "category",
          data: timeLabels.filter((label): label is string => label !== undefined),
          axisLabel: {
            interval: "auto",
            showMaxLabel: true,
            showMinLabel: true,
          },
        },
        yAxis: {
          type: "value",
        },
        series: seriesData.filter(
          (series): series is EChartsOption["series"] => series !== undefined,
        ) as EChartsOption["series"],
        color: palette,
      }),
      [height, palette, renderTooltip, seriesData, timeLabels],
    );

    const [chartOption, setChartOption] = useState<EChartsOption>();

    const saveOptions = () => {
      if (chartRef.current) {
        const instance = chartRef.current.getEchartsInstance();
        const newOption = instance.getOption();
        setChartOption(newOption as EChartsOption);
      }
    };

    const getImageGraph = async () => {
      if (containerRef.current) {
        const canvas = await html2canvas(containerRef.current, {
          backgroundColor: "#fff",
          scale: 2,
        });
        let imgUrl = canvas.toDataURL("image/jpeg");
        return imgUrl;
      } else {
        const chart = chartRef.current?.getEchartsInstance();
        let imgUrl = chart?.getDataURL({
          type: "jpeg",
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        return imgUrl;
      }
    };

    const clearChart = useCallback(() => {
      if (isLoading) {
        const chart = chartRef.current?.getEchartsInstance();
        chart?.clear();
        setIsRendering(true);
      }
    }, [isLoading]);

    const checkIfChartRendered = () => {
      const chart = chartRef.current?.getEchartsInstance();
      if (chart) {
        const option = chart.getOption();
        if (option && option.series && (option.series as Array<any>)?.length > 0) {
          setIsRendering(false);
          return;
        }
      }
      requestAnimationFrame(checkIfChartRendered);
    };

    const handleChartFinished = () => {
      requestAnimationFrame(checkIfChartRendered);
    };

    useImperativeHandle(ref, () => ({
      getImageUrl: getImageGraph,
    }));

    useEffect(() => {
      clearChart();
    }, [clearChart]);

    useEffect(() => {
      setChartOption(option);
    }, [option]);

    useEffect(() => {
      const chartResize = () => {
        const chart = chartRef.current?.getEchartsInstance();
        chart?.resize();
      };

      window.addEventListener("resize", chartResize);

      return () => window.removeEventListener("resize", chartResize);
    }, []);

    useUnactivate(() => {
      saveOptions();
    });

    if (!isLoading && isError) {
      return (
        <Box sx={styles.errorContainer}>
          <Box sx={styles.errorIconContainer}>
            <AlertCircle
              size="20"
              color={
                theme.colors.componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray
              }
            />
          </Box>
          <Typography sx={styles.errorTitle}>{t("errors.loadingErrorTitle")}</Typography>
          <Typography sx={styles.errorText}>{t("errors.loadingErrorMessage")}</Typography>
        </Box>
      );
    }

    return (
      <Box ref={containerRef} sx={{ position: "relative" }}>
        {(isLoading || isRendering) && (
          <Box sx={styles.loaderContainer}>
            <GraphSkeleton loadingText={t("common_texts.loading")} height={height} />
          </Box>
        )}
        {Array.isArray(seriesData) && seriesData.length > 0 && !isRendering && (
          <LegendsList seriesData={seriesData} chartRef={chartRef} />
        )}
        <ReactECharts
          ref={chartRef}
          option={chartOption || option}
          opts={{ width: "auto", height: height, devicePixelRatio: 2 }}
          style={{
            height: `${height}px`,
            opacity: isLoading || isRendering || data.length === 0 ? 0 : 1,
          }}
          onEvents={{
            finished: handleChartFinished,
          }}
        />
      </Box>
    );
  },
);

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    errorContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    } as SxProps,
    errorIconContainer: {
      display: "flex",
      width: "48px",
      height: "48px",
      borderRadius: radius.radiusFull,
      backgroundColor: colors.componentColorsUtilityGrayUtilityGray50,
      justifyContent: "center",
      alignItems: "center",
      mb: spacing.spacingXl,
    } as SxProps,
    errorTitle: {
      ...typography[".text-md-semibold"],
      color: colors.colorsTextTextPrimary,
    } as SxProps,
    errorText: {
      ...typography[".text-sm-regular"],
      color: colors.colorsTextTextTertiary,
    } as SxProps,
    loaderContainer: {
      background: colors.colorsBackgroundBgPrimary,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 10,
    } as SxProps,
  };
};

export default memo(Graph);
