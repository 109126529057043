import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { TableContainerWithCustomScroll } from "../EnergySavingCalculatorPage";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { defineSourceAndUnit } from "../helpers/defineSourceAndUnit";

export interface IEnergyCalculationsTableProps {
  showDHW: boolean;
  showEnergyPricing: boolean;
  currency: string | null | undefined;
  heatPumpInputEnergyTotal: string;
  heatPumpOutputEnergyHeating: string;
  heatPumpOutputEnergyDHW: string;
  heatPumpOutputEnergyTotal: string;
  heatPumpRunningCosts: string;
  additivePoverSource: string | undefined;
  additivePoverOutputEnergyHeating: string;
  additivePoverOutputEnergyDHW: string;
  additivePoverOutputEnergyTotal: string;
  additiveRunningCosts: string;
  currentEnergyEfficiency: string;
  additiveEnergyEfficiency: string;
  getTotalEfficiency: (val: string) => void;
  getTotalInputEnergy: (val: string) => void;
  getTotalCosts: (val: string) => void;
}

export const EnergyCalculationsTable = forwardRef(
  (
    {
      showDHW,
      showEnergyPricing,
      currency,
      heatPumpInputEnergyTotal,
      heatPumpOutputEnergyHeating,
      heatPumpOutputEnergyDHW,
      heatPumpOutputEnergyTotal,
      heatPumpRunningCosts,
      additivePoverSource,
      additivePoverOutputEnergyHeating,
      additivePoverOutputEnergyDHW,
      additivePoverOutputEnergyTotal,
      additiveRunningCosts,
      currentEnergyEfficiency,
      additiveEnergyEfficiency,
      getTotalEfficiency,
      getTotalInputEnergy,
      getTotalCosts,
    }: IEnergyCalculationsTableProps,
    ref
  ) => {
    const SavingTableRow = styled(TableRow)(({ theme }) => ({
      [`th, td`]: {
        padding: `6px 16px`,
        fontSize: "1rem",
      },
      "&:last-child td, &:last-child th": { border: 0 },
    }));

    const { t } = useTranslation("cloud_ui");

    const [heatPumpEfficiency, setHeatPumpEfficiency] = useState<string>();
    const [additivePowerEfficiency, setAdditivePowerEfficiency] =
      useState<string>(additiveEnergyEfficiency);

    const [additivePowerSourceHint, setAdditivePowerSourceHint] =
      useState<string>("");

    const [additivePowerInputEnergy, setAdditivePowerInputEnergy] =
      useState<string>();

    const [inputEnergyTotal, setInputEnergyTotal] = useState<string>();
    const [outputEnergyHeatingTotal, setOutputEnergyHeatingTotal] =
      useState<string>();
    const [outputEnergyDHWTotal, setOutputEnergyDHWTotal] =
      useState<string>("0 kWh");
    const [efficiencyTotal, setEfficiencyTotal] = useState<string>();
    const [totalCosts, setTotalCosts] = useState<string>("");

    useImperativeHandle(ref, () => ({
      getData: () => {
        let data = {
          hpEfficiency: "",
          additiveInputEnergy: "",
          additiveEfficiency: "",
          totalInputEnergy: "",
          totalOutputHeating: "",
          totalOutputDHW: "",
          totalEfficiency: "",
          totalRunningCost: "",
          additiveInputEnergyInOriginalUOM: "",
        };

        if (heatPumpEfficiency) {
          data.hpEfficiency = heatPumpEfficiency.replace(/[^\d.]/g, "");
        }

        if (additivePowerInputEnergy) {
          data.additiveInputEnergy = additivePowerInputEnergy.replace(
            /[^\d.]/g,
            ""
          );
        }

        if (additivePowerEfficiency) {
          data.additiveEfficiency = additivePowerEfficiency.replace(
            /[^\d.]/g,
            ""
          );
        }

        if (inputEnergyTotal) {
          data.totalInputEnergy = inputEnergyTotal.replace(/[^\d.]/g, "");
        }

        if (outputEnergyHeatingTotal) {
          data.totalOutputHeating = outputEnergyHeatingTotal.replace(
            /[^\d.]/g,
            ""
          );
        }

        if (outputEnergyDHWTotal) {
          data.totalOutputDHW = outputEnergyDHWTotal.replace(/[^\d.]/g, "");
        }

        if (efficiencyTotal) {
          data.totalEfficiency = efficiencyTotal.replace(/[^\d.]/g, "");
        }

        if (totalCosts) {
          data.totalRunningCost = totalCosts.replace(/[^\d.]/g, "");
        }

        if (additivePowerSourceHint) {
          data.additiveInputEnergyInOriginalUOM =
            additivePowerSourceHint.replace(/[^\d.]/g, "");
        }

        return data;
      },
    }));

    useEffect(() => {
      const inputNumber = Number(
        heatPumpInputEnergyTotal.replace(/[^\d]/g, "")
      );
      const outputNumber = Number(
        heatPumpOutputEnergyTotal.replace(/[^\d]/g, "")
      );
      const efficiency = `${parseInt(
        ((outputNumber / inputNumber) * 100).toString()
      )} %`;
      setHeatPumpEfficiency(efficiency);
    }, [heatPumpInputEnergyTotal, heatPumpOutputEnergyTotal]);

    useEffect(() => {
      if (additivePoverSource) {
        const { source, unit, approximatelyEquals, coefficient } =
          defineSourceAndUnit(additivePoverSource);
        const inputEnergyNumber = Number(
          additivePowerInputEnergy?.replace(/[^\d]/g, "")
        );
        const approximatelyEqualsNumber = Number(
          (approximatelyEquals || "1").replace(/[^\d]/g, "")
        );
        const value = inputEnergyNumber / approximatelyEqualsNumber;
        const rounded = Math.ceil(value * 10) / 10;
        const calculatedValue =
          rounded % 1 === 0 ? Math.floor(rounded) : rounded;
        const formatted = calculatedValue.toLocaleString("sv-SE");
        setAdditivePowerSourceHint(
          `(${t(source as any)}: ${formatted} ${unit})`
        );

        setAdditivePowerEfficiency(`${additiveEnergyEfficiency} %`);
      }
    }, [
      additivePoverSource,
      additivePowerInputEnergy,
      currentEnergyEfficiency,
      additiveEnergyEfficiency,
    ]);

    useEffect(() => {
      const { coefficient } = defineSourceAndUnit(additivePoverSource);
      const outputNumber = Number(
        additivePoverOutputEnergyTotal.replace(/[^\d]/g, "")
      );

      const resultNumber = Math.round(
        (outputNumber / (coefficient ? +coefficient : 100)) * 100
      );
      const formattedNumber = resultNumber.toLocaleString("sv-SE");
      if (coefficient) {
        setAdditivePowerInputEnergy(`${formattedNumber} kWh`);
      } else {
        setAdditivePowerInputEnergy("-");
      }
    }, [additivePoverOutputEnergyTotal, currentEnergyEfficiency]);

    useEffect(() => {
      const HP = Number(heatPumpInputEnergyTotal.replace(/[^\d]/g, ""));
      const additive = Number(additivePowerInputEnergy?.replace(/[^\d]/g, ""));
      const totalEnergy = HP + additive;
      const formattedTotal = totalEnergy.toLocaleString("sv-SE");

      const total = `${formattedTotal} kWh`;
      setInputEnergyTotal(total);
      getTotalInputEnergy(total);
    }, [heatPumpInputEnergyTotal, additivePowerInputEnergy]);

    useEffect(() => {
      const HP = Number(heatPumpOutputEnergyHeating.replace(/[^\d]/g, ""));
      const additive = Number(
        additivePoverOutputEnergyHeating.replace(/[^\d]/g, "")
      );
      const totalEnergy = HP + additive;
      const formattedTotal = totalEnergy.toLocaleString("sv-SE");

      const total = `${formattedTotal} kWh`;
      setOutputEnergyHeatingTotal(total);
    }, [heatPumpOutputEnergyHeating, additivePoverOutputEnergyHeating]);

    useEffect(() => {
      if (showDHW) {
        const HP = Number(heatPumpOutputEnergyDHW.replace(/[^\d]/g, ""));
        const additive = Number(
          additivePoverOutputEnergyDHW.replace(/[^\d]/g, "")
        );
        const totalEnergy = HP + additive;
        const formattedTotal = totalEnergy.toLocaleString("sv-SE");

        const total = `${formattedTotal} kWh`;
        setOutputEnergyDHWTotal(total);
      }
    }, [heatPumpOutputEnergyDHW, additivePoverOutputEnergyDHW]);

    useEffect(() => {
      const outputHeatingNumber = Number(
        outputEnergyHeatingTotal?.replace(/[^\d]/g, "")
      );
      const outputDHWNumber = Number(
        outputEnergyDHWTotal.replace(/[^\d]/g, "")
      );
      const inputNumber = Number(inputEnergyTotal?.replace(/[^\d]/g, ""));
      const efficiencyTotal = `${parseInt(
        (
          ((outputHeatingNumber + outputDHWNumber) / inputNumber) *
          100
        ).toString()
      )} %`;
      getTotalEfficiency(efficiencyTotal);
      setEfficiencyTotal(efficiencyTotal);
    }, [outputEnergyHeatingTotal, outputEnergyDHWTotal, inputEnergyTotal]);

    useEffect(() => {
      const HPCosts = Number(heatPumpRunningCosts.replace(/[^\d]/g, ""));
      const additiveCosts = Number(additiveRunningCosts.replace(/[^\d]/g, ""));
      const sumCosts = additiveCosts + HPCosts;
      const formattedTotalCosts = sumCosts.toLocaleString("sv-SE");

      const totalCosts = `${formattedTotalCosts} ${currency}`;
      setTotalCosts(totalCosts);
      getTotalCosts(totalCosts);
    }, [heatPumpRunningCosts, additiveRunningCosts]);

    const memoizedTable = useMemo(() => {
      return (
        <Box mb={"35px"}>
          <TableContainerWithCustomScroll>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
              <TableHead sx={{ borderBottom: "2px solid #95C11F" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={500} fontSize={14}>
                      {t(
                        "pages.savingCalculator.energyCalculationsTable.inputEnergyColTitle"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={500} fontSize={14}>
                      {t(
                        "pages.savingCalculator.energyCalculationsTable.outputEnergyHeatingColTitle"
                      )}
                    </Typography>
                  </TableCell>
                  {showDHW && (
                    <TableCell align="right">
                      <Typography fontWeight={500} fontSize={14}>
                        {t(
                          "pages.savingCalculator.energyCalculationsTable.outputEnergyDHWColTitle"
                        )}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Typography fontWeight={500} fontSize={14}>
                      {t(
                        "pages.savingCalculator.energyCalculationsTable.efficiencyColTitle"
                      )}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontWeight={500} fontSize={14}>
                        {t(
                          "pages.savingCalculator.energyCalculationsTable.runningCostsColTitle"
                        )}{" "}
                        ({currency})
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <SavingTableRow sx={{ borderBottom: "1px solid #95C11F" }}>
                  <TableCell component="th" scope="row">
                    <Typography fontWeight={700} fontSize={14}>
                      {t(
                        "pages.savingCalculator.energyCalculationsTable.heatPumpEnergyRowTitle"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {heatPumpInputEnergyTotal}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {heatPumpOutputEnergyHeating}
                    </Typography>
                  </TableCell>
                  {showDHW && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {heatPumpOutputEnergyDHW}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Typography fontSize={14}>{heatPumpEfficiency}</Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {heatPumpRunningCosts} {` ⁽²`}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow sx={{ borderBottom: "2px solid #95C11F" }}>
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.energyCalculationsTable.additivePoverRowTitle"
                      )}
                      <Typography fontSize={14} ml={"5px"} fontStyle={"italic"}>
                        {additivePowerSourceHint}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {additivePowerInputEnergy} {` ⁽¹`}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {additivePoverOutputEnergyHeating}
                    </Typography>
                  </TableCell>
                  {showDHW && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {additivePoverOutputEnergyDHW}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {additivePowerEfficiency}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {additiveRunningCosts} {` ⁽³`}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow>
                  <TableCell component="th" scope="row">
                    <Typography fontWeight={700} fontSize={14}>
                      {t(
                        "pages.savingCalculator.energyCalculationsTable.totalRowTitle"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={700} fontSize={14}>
                      {inputEnergyTotal}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={700} fontSize={14}>
                      {outputEnergyHeatingTotal}
                    </Typography>
                  </TableCell>
                  {showDHW && (
                    <TableCell align="right">
                      <Typography fontWeight={700} fontSize={14}>
                        {outputEnergyDHWTotal}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Typography fontWeight={700} fontSize={14}>
                      {efficiencyTotal}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontWeight={700} fontSize={14}>
                        {totalCosts}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
              </TableBody>
            </Table>
          </TableContainerWithCustomScroll>
          <Box
            mt={"16px"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <Typography fontSize={12} fontStyle={"italic"}>
              {t(
                "pages.savingCalculator.energyCalculationsTable.tableHintCalculatedOnAYearBasis"
              )}
            </Typography>
            <Typography fontSize={12} fontStyle={"italic"}>
              {t("pages.savingCalculator.energyCalculationsTable.tableHint1")}
            </Typography>
            {showEnergyPricing && (
              <>
                <Typography fontSize={12} fontStyle={"italic"}>
                  {t(
                    "pages.savingCalculator.energyCalculationsTable.tableHint2"
                  )}
                </Typography>
                <Typography fontSize={12} fontStyle={"italic"}>
                  {t(
                    "pages.savingCalculator.energyCalculationsTable.tableHint3"
                  )}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      );
    }, [
      showDHW,
      showEnergyPricing,
      currency,
      heatPumpInputEnergyTotal,
      heatPumpOutputEnergyHeating,
      heatPumpOutputEnergyDHW,
      heatPumpOutputEnergyTotal,
      heatPumpRunningCosts,
      additivePoverSource,
      additivePoverOutputEnergyHeating,
      additivePoverOutputEnergyDHW,
      additivePoverOutputEnergyTotal,
      additiveRunningCosts,
      currentEnergyEfficiency,
      additiveEnergyEfficiency,
      getTotalEfficiency,
      getTotalInputEnergy,
      getTotalCosts,
    ]);

    return memoizedTable;
  }
);
