import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import { ToastContentProps } from "react-toastify";
import { XClose } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";
import StatusIcon from "./StatusIcon/StatusIcon";
import { ToastStatuses } from "./StatusIcon/Types";

export interface IToasterData {
  title: string;
  text?: string;
  status: ToastStatuses;
}

export interface IToasterProps extends ToastContentProps<IToasterData> {}

export default function Toaster({ closeToast, data }: IToasterProps) {
  const theme = useTheme();
  const styles: Record<string, SxProps> = {
    root: {
      display: "flex",
      width: "100%",
      "& .toast-progress": {
        backgroundColor:
          data.status === ToastStatuses.Success
            ? theme.colors.colorsForegroundFgBrandPrimary
            : theme.colors.colorsForegroundFgErrorPrimary,
      },
    },
    content: {
      margin: `${theme.spacing.spacingMd} 0`,
    },
    title: {
      ...theme.typography[".text-sm-semibold"],
      color: theme.colors.colorsForegroundFgPrimary,
    },
    text: {
      ...theme.typography[".text-sm-regular"],
      color: theme.colors.colorsForegroundFgSecondary,
    },
    closeButton: {
      border: "none",
      backgroundColor: "initial",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ml: "auto",
      width: "36px",
      height: "36px",
    },
  };
  return (
    <Box sx={styles.root}>
      <StatusIcon status={data.status} />
      <Box sx={styles.content}>
        <Typography sx={styles.title}>{data.title}</Typography>
        {data.text && <Typography sx={styles.text}>{data.text}</Typography>}
      </Box>
      <Box component="button" sx={styles.closeButton} onClick={closeToast}>
        <XClose
          size="20"
          strokeWidth={2}
          style={{ color: theme.colors.colorsForegroundFgQuaternary }}
        />
      </Box>
    </Box>
  );
}
