export enum Priorities {
  URGENT = "URGENT",
  HIGH = "HIGH",
  NORMAL = "NORMAL",
}

interface ISupportFormData {
  priority: Priorities;
  description: string;
  images: IPreview[];
}

interface ISupportFormErrors {
  description: string;
}

export type { ISupportFormData, ISupportFormErrors };
