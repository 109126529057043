import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import {
  useGetInstallationSettingsQuery,
  useGetInstallationStateQuery,
} from "../../../../../../../store/services/installation";
import { ITabData } from "../../../../../../../components/TabsNavigation/TabsNavigation";
import { IUseInstallationZonesParameters } from "../types";

export default function useInstallationZones({
  baseUrl,
  isInstallerZones,
}: IUseInstallationZonesParameters) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });
  const { id } = useParams();
  const location = useLocation();

  //States
  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] =
    useState<boolean>(true);

  const poolingInterval = isPoolingIntervalEnabled ? 60000 : 0;

  //States end

  //Queries
  const { data: installationStateResponse, isLoading: isLoadingState } =
    useGetInstallationStateQuery(
      { uuid: id! },
      {
        pollingInterval: poolingInterval,
      }
    );

  const { data: installationSettingsResponse, isLoading: isLoadingSettings } =
    useGetInstallationSettingsQuery(
      { uuid: id! },
      {
        pollingInterval: poolingInterval,
      }
    );
  //Queries end

  const zonesCount =
    installationSettingsResponse?.installerSettings.commissioning.zonesCount
      .value || 0;

  const zonesNavItems: ITabData[] | null =
    zonesCount > 1 || isInstallerZones
      ? Array.from(
          { length: isInstallerZones ? 2 : zonesCount },
          (_, i) => i + 1
        ).map((zone) => ({
          href: `${baseUrl}/zone${zone}${location.search}`,
          label: `${t("zoneTitle")} ${zone}`,
        }))
      : null;

  return {
    installationStateResponse,
    installationSettingsResponse,
    zonesNavItems,
    zonesCount,
    isLoadingSettings,
    isLoadingState,
    setIsPoolingIntervalEnabled,
  };
}
