import { Box, SxProps, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Minus, Plus } from "untitledui-js-base";
import { InstallationValueUnit } from "../../../../../../store/services/models/installations/installationsCommon";
import { useTranslation } from "react-i18next";
import { getTextWidth } from "../../../../../../util/TextWidth";

interface NumberInputProps {
  min?: number;
  max?: number;
  step?: number;
  initialValue?: number;
  decimalPlaces?: 0 | 1 | 2;
  label?: string;
  labelColor?: string;
  hint?: string;
  changeValue: (val: number) => void;
  unit?: string;
  inputWidth?: string;
  textAlign?: string;
  hideUnit?: boolean;
  hideControls?: boolean;
  disabled?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({
  min,
  max,
  step = 1,
  initialValue = 0,
  decimalPlaces = 0,
  label,
  labelColor,
  hint,
  changeValue,
  unit,
  inputWidth = "95px",
  textAlign = "center",
  hideUnit = false,
  hideControls = false,
  disabled,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  const [formattedUnit, setFormattedUnit] = useState<string>("");

  const [isDisabled, setIsDisabled] = useState<boolean>(!!disabled);

  useEffect(() => {
    setIsDisabled(!!disabled);
  }, [disabled]);

  const buttonStyles: SxProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor:
      theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
    width: "36px",
    height: "36px",
    cursor: isDisabled ? "initial" : "pointer",
    outline: "none",
    borderRadius: "50%",
    border: `1px solid ${
      isDisabled
        ? theme.colors.colorsBorderBorderDisabledSubtle
        : theme.colors
            .componentColorsComponentsButtonsSecondaryButtonSecondaryBorder
    }`,
    "&:hover": {
      backgroundColor: isDisabled
        ? "initial"
        : theme.colors.colorsBackgroundBgSecondaryHover,
    },
  };

  useEffect(() => {
    if (unit || unit === "") {
      if (
        unit === InstallationValueUnit.AMPERE ||
        unit === InstallationValueUnit.BAR ||
        unit === InstallationValueUnit.CELSIUS ||
        unit === InstallationValueUnit.COUNT ||
        unit === InstallationValueUnit.DAYS ||
        unit === InstallationValueUnit.HERTZ ||
        unit === InstallationValueUnit.HOURS ||
        unit === InstallationValueUnit.KILOWATT ||
        unit === InstallationValueUnit.WATT ||
        unit === InstallationValueUnit.LITRE_PER_MINUTE ||
        unit === InstallationValueUnit.MINUTES ||
        unit === InstallationValueUnit.PERCENT ||
        unit === InstallationValueUnit.REVOLUTION_PER_MINUTE ||
        unit === InstallationValueUnit.STEP ||
        unit === InstallationValueUnit.VOLT
      ) {
        setFormattedUnit(`${t(`units.${unit}`)}`);
      } else {
        setFormattedUnit(unit || "");
      }
    } else if (!hideUnit) {
      setFormattedUnit(`${t(`units.${InstallationValueUnit.CELSIUS}`)}`);
    }
  }, [unit, hideUnit]);

  const handleIncrement = () => {
    if (isDisabled) {
      return;
    }

    if (max === undefined || (initialValue as number) + step <= max) {
      const newValue = Math.ceil(initialValue / step) * step;
      const adjustedValue =
        newValue === initialValue ? newValue + step : newValue;

      changeValue(+adjustedValue.toFixed(decimalPlaces));
    }

    if (max === undefined || (initialValue as number) + step > max) {
      // changeValue(+(max as number).toFixed(decimalPlaces));
      return;
    }

    if (min === undefined || (initialValue as number) + step < min) {
      changeValue(+(min as number).toFixed(decimalPlaces));
    }
  };

  const handleDecrement = () => {
    if (isDisabled) {
      return;
    }

    if (min === undefined || (initialValue as number) - step >= min) {
      const newValue = Math.floor(initialValue / step) * step;
      const adjustedValue =
        newValue === initialValue ? newValue - step : newValue;

      changeValue(+adjustedValue.toFixed(decimalPlaces));
    }

    if (min === undefined || (initialValue as number) - step < min) {
      // changeValue(+(min as number).toFixed(decimalPlaces));
      return;
    }

    if (max === undefined || (initialValue as number) - step > max) {
      changeValue(+(max as number).toFixed(decimalPlaces));
    }
  };

  const [innerValue, setInnerValue] = useState<string>(String(initialValue));
  const [isValueSwitchedToInner, setIsValueSwitchedToInner] =
    useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) {
      return;
    }
    setIsValueSwitchedToInner(true);
    const newValue = e.target.value;
    setInnerValue(newValue);
  };

  const onInputBlur = () => {
    setIsValueSwitchedToInner(false);
    if (min !== undefined && (+innerValue as number) < min) {
      changeValue(+min.toFixed(decimalPlaces));
    } else if (max !== undefined && (+innerValue as number) > max) {
      changeValue(+max.toFixed(decimalPlaces));
    } else {
      changeValue(+(Number(innerValue) || 0).toFixed(decimalPlaces));
    }
  };

  return (
    <Box sx={{ mb: theme.spacing.spacingXl }}>
      <Typography
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: labelColor || theme.colors.colorsTextTextSecondary,
          mb: theme.spacing.spacingSm,
        }}
      >
        {label}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!hideControls && (
          <Box
            component={"button"}
            disabled={isDisabled}
            sx={{ ...buttonStyles, mr: theme.spacing.spacingXs }}
            onClick={handleDecrement}
          >
            <Minus
              size="24"
              strokeWidth={2}
              style={{
                color: isDisabled
                  ? theme.colors.colorsForegroundFgDisabled
                  : theme.colors.colorsBorderBorderBrandSolid,
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: `${Math.max(
              95,
              getTextWidth(
                (isValueSwitchedToInner ? innerValue : `${initialValue}`) +
                  formattedUnit,
                "16px Inter"
              ) +
                12 * 2 +
                10
            )}px`,
            maxWidth: "200px",
            height: "40px",
            border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
            backgroundColor: isDisabled
              ? theme.colors.colorsBackgroundBgDisabledSubtle
              : "initial",
            borderRadius: "8px",
            padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingLg}`,
            input: {
              ...theme.typography[".text-md-regular"],
              color: isDisabled
                ? theme.colors.colorsTextTextDisabled
                : theme.colors.colorsTextTextPrimary,
              border: "none",
              width: "100%",
              outline: "none",
              textAlign: textAlign,
              backgroundColor: isDisabled
                ? theme.colors.colorsBackgroundBgDisabledSubtle
                : "initial",
            },
          }}
        >
          <input
            type="number"
            disabled={isDisabled}
            value={isValueSwitchedToInner ? innerValue : initialValue}
            onChange={handleChange}
            min={min}
            max={max}
            step={step}
            onBlur={onInputBlur}
            pattern="[0-9]*"
          />
          {!hideUnit && (
            <Box
              sx={{
                ...theme.typography[".text-md-regular"],
                color: theme.colors.colorsTextTextPrimary,
              }}
            >
              {formattedUnit}
            </Box>
          )}
        </Box>
        {!hideControls && (
          <Box
            component={"button"}
            disabled={isDisabled}
            sx={{ ...buttonStyles, ml: theme.spacing.spacingXs }}
            onClick={handleIncrement}
          >
            <Plus
              size="24"
              strokeWidth={2}
              style={{
                color: isDisabled
                  ? theme.colors.colorsForegroundFgDisabled
                  : theme.colors.colorsBorderBorderBrandSolid,
              }}
            />
          </Box>
        )}
      </Box>
      {hint && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
            mt: theme.spacing.spacingSm,
          }}
        >
          {hint}
        </Typography>
      )}
    </Box>
  );
};

export default NumberInput;
