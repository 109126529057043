import React from "react";
import useTheme from "../../theme/hooks/useTheme";
import { getScrollStyles } from "../../styles/stylesHelpers/scrollStyles";
import { SxProps } from "@mui/material";

export default function useTextAreaStyles(
  isTextAreaFocused: boolean,
  error: boolean
) {
  const theme = useTheme();
  const { colors, radius, spacing, typography } = theme;
  const styles: Record<string, SxProps> = {
    mainLabel: {
      ...typography[".text-sm-medium"],
      color: colors.colorsTextTextSecondary,
      display: "flex",
    },
    requiredStar: {
      ...typography[".text-sm-medium"],
      color: colors.colorsTextTextErrorPrimary,
      mr: spacing.spacingSm,
    },
    textAreaWrapper: {
      ".MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
      "&.MuiFormControl-root": {
        position: "relative",
        m: `${spacing.spacingSm} 0`,
        p: 0,
        border: `1px solid ${
          isTextAreaFocused
            ? colors.colorsBorderBorderBrand
            : error
            ? colors.colorsBorderBorderError
            : colors.colorsBorderBorderPrimary
        }`,
        borderRadius: radius.radiusMd,
        boxShadow: isTextAreaFocused
          ? "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(149, 193, 31, 0.24)"
          : "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        ".MuiFormHelperText-root": {
          position: "absolute",
          bottom: "-1px",
        },
        "&:hover": {
          borderColor: colors.colorsBorderBorderBrand,
        },
      },
      ".MuiInputBase-root": {
        p: theme.spacing.spacingLg,
      },
      textarea: {
        ...getScrollStyles({ theme }),
        ...typography[".text-sm-regular"],
        color: colors.colorsTextTextPrimary,
        ":placeholder-shown": {
          ...typography[".text-sm-regular"],
          color: colors.colorsTextTextPlaceholder,
          opacity: 1,
        },
      },
    },
  };
  return { styles };
}
