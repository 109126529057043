export const isEmpty = (obj: object | undefined) => {
  if (obj) {
    return Object.keys(obj).length === 0;
  } else {
    return true;
  }
};

export const cleanValuesForRequest = (obj: any) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (obj.hasOwnProperty("value")) {
    return obj.value.newValue;
  }

  if (obj.hasOwnProperty("newValue") && obj.hasOwnProperty("previousValue")) {
    return obj.newValue;
  }

  const newObj: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = cleanValuesForRequest(obj[key]);
    }
  }

  return newObj;
};

export const baseFindDifferences = (initial: any, current: any) => {
  const zoneDifferences: any = {};

  const compareFields = (
    initialField: any,
    currentField: any,
    fieldName: string
  ) => {
    if (
      typeof initialField === "object" &&
      initialField !== null &&
      !Array.isArray(initialField)
    ) {
      Object.keys(initialField).forEach((subField) => {
        compareFields(
          initialField[subField],
          currentField[subField],
          `${fieldName}.${subField}`
        );
      });
    } else if (JSON.stringify(initialField) !== JSON.stringify(currentField)) {
      const fieldPath = fieldName.split(".");
      const fieldKey = fieldPath.pop();
      let nestedDifferences = zoneDifferences;
      fieldPath.forEach((key) => {
        if (!nestedDifferences[key]) {
          nestedDifferences[key] = {};
        }
        nestedDifferences = nestedDifferences[key];
      });

      nestedDifferences[fieldKey!] = {
        previousValue: initialField,
        newValue: currentField,
      };
    }
  };

  Object.keys(current).forEach((key) => {
    compareFields((initial as any)[key], (current as any)[key], key);
  });

  return zoneDifferences;
};
