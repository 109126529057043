import { api } from "./api";
import {
  AcceptExistOrganizationInvitationRequestParams,
  AcceptOrganizationInvitationRequestParams,
  Country,
  FindOrganizationRequestParams,
  FindOrganizationResponse,
  GetOrganizationRequestParams,
  GetOrganizationsRequestParams,
  IOrganizationCancelCooperationInvitationRequestParams,
  IUpdateOrganizationRequestParams,
  OrganizationInvitationInfoRequestParams,
  OrganizationInvitationInfoResponse,
  OrganizationItemResponse,
  OrganizationsMePartnershipsResponse,
  RelatedCompanyResponse,
  SendOrganizationInvitationRequestParams,
} from "./models/organizations/organizations";

export const organizationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPartnerShips: build.query<
      OrganizationsMePartnershipsResponse,
      GetOrganizationRequestParams
    >({
      query: () => ({
        url: "organizations/me/partnerships",
      }),
      providesTags: ["Partnerships"],
    }),
    getOrganizations: build.query<
      Array<OrganizationItemResponse>,
      GetOrganizationsRequestParams
    >({
      query: ({ country, parentOrgUuid, types }) => {
        const urlParams = new URLSearchParams();
        if (country && country !== "all") {
          urlParams.append("country", country);
        }
        if (parentOrgUuid) {
          urlParams.append("parentOrgUuid", parentOrgUuid);
        }
        if (types && types.length) {
          types.forEach((type) => {
            urlParams.append("type", type);
          });
        }
        return {
          url: `organizations${urlParams ? "?" : ""}${urlParams.toString()}`,
        };
      },
    }),
    getOrganization: build.query<
      RelatedCompanyResponse,
      GetOrganizationRequestParams
    >({
      query: ({ uuid }) => ({
        url: `organizations/${uuid}`,
      }),
      providesTags: ["OrganizationData"],
    }),
    findOrganization: build.mutation<
      FindOrganizationResponse,
      FindOrganizationRequestParams
    >({
      query: ({ uuid, email }) => ({
        url: `organizations/${uuid}/child?email=${email}`,
        method: "GET",
      }),
    }),
    sendOrganizationInvitation: build.mutation<
      void,
      SendOrganizationInvitationRequestParams
    >({
      query: ({ uuid, ...props }) => ({
        url: `organizations/${uuid}/child/invitations`,
        method: "POST",
        body: props,
      }),
    }),
    acceptOrganizationInvitation: build.mutation<
      void,
      | AcceptOrganizationInvitationRequestParams
      | AcceptExistOrganizationInvitationRequestParams
    >({
      query: (props) => ({
        url: `organizations/child`,
        method: "POST",
        body: props,
      }),
    }),
    getOrganizationInvitationInfo: build.query<
      OrganizationInvitationInfoResponse,
      OrganizationInvitationInfoRequestParams
    >({
      query: ({ token }) => ({
        url: `organizations/child/invitations/${token}`,
      }),
    }),
    getCountries: build.query<Array<Country>, void>({
      query: () => ({
        url: `configuration/countries`,
      }),
    }),
    deleteOrganization: build.mutation<void, { orgUuid: string }>({
      query: ({ orgUuid }) => ({
        url: `organizations/${orgUuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Partnerships"],
    }),
    cancelCooperationInvitation: build.mutation<
      void,
      IOrganizationCancelCooperationInvitationRequestParams
    >({
      query: ({ parentOrgUuid, email }) => ({
        url: `organizations/${parentOrgUuid}/child/invitations?email=${email}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Partnerships"],
    }),
    editOrganization: build.mutation<void, IUpdateOrganizationRequestParams>({
      query: ({ orgUuid, requestBody }) => ({
        url: `organizations/${orgUuid}`,
        method: "PUT",
        body: requestBody,
      }),
      invalidatesTags: ["OrganizationData"],
    }),
  }),
});

export const {
  useGetPartnerShipsQuery,
  useGetOrganizationQuery,
  useFindOrganizationMutation,
  useSendOrganizationInvitationMutation,
  useAcceptOrganizationInvitationMutation,
  useGetOrganizationInvitationInfoQuery,
  useGetOrganizationsQuery,
  useGetCountriesQuery,
  useDeleteOrganizationMutation,
  useCancelCooperationInvitationMutation,
  useEditOrganizationMutation,
  endpoints: {
    getPartnerShips,
    getOrganization,
    findOrganization,
    sendOrganizationInvitation,
    acceptOrganizationInvitation,
    getOrganizationInvitationInfo,
    getOrganizations,
    getCountries,
    deleteOrganization,
    cancelCooperationInvitation,
    editOrganization,
  },
} = organizationsApi;
