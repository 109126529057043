import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { IUseInstallationZoneConditionsAndValuesFromEnumsParams } from "../../types";
import { InstallationZoneOperationModeEnum } from "../../../../../../../../store/services/models/installations/installationSettings";

export default function useInstallationZoneConditionsAndValuesFromEnums({
  isInstallerZones,
  staticValues,
  isLoadingSettings,
  isLoadingState,
  installerSettingsFormData,
  zoneFormData,
  zoneKeys,
}: IUseInstallationZoneConditionsAndValuesFromEnumsParams) {
  const { t: baseT } = useTranslation("cloud_ui");

  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });

  const { t: tabNameT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.tabsNames",
  });

  const tabNameLabel: string = useMemo(() => tabNameT("zones"), [tabNameT]);

  const commonErrorForRequest: string = baseT("errors.somethingWentWrong");

  const zoneSwitcherLabel: string = `${t("zoneTitle")} - ${
    zoneKeys.zoneNumber
  }`;

  const modeForZoneIsNotNone: boolean =
    (isInstallerZones ? installerSettingsFormData?.mode : staticValues.mode) !==
    InstallationZoneOperationModeEnum.NONE;

  const modeForZoneIsCooling: boolean =
    (isInstallerZones ? installerSettingsFormData?.mode : staticValues.mode) ===
    InstallationZoneOperationModeEnum.COOLING;

  const modeForZoneIsHeating: boolean =
    (isInstallerZones ? installerSettingsFormData?.mode : staticValues.mode) ===
    InstallationZoneOperationModeEnum.HEATING;

  const modeForZoneIsCoolingAndHeating: boolean =
    (isInstallerZones ? installerSettingsFormData?.mode : staticValues.mode) ===
    InstallationZoneOperationModeEnum.BOTH;

  const showEmptyBlock: boolean =
    !zoneFormData && !isLoadingSettings && !isLoadingState;

  return {
    modeForZoneIsNotNone,
    modeForZoneIsCooling,
    modeForZoneIsHeating,
    modeForZoneIsCoolingAndHeating,
    tabNameLabel,
    zoneSwitcherLabel,
    showEmptyBlock,
    commonErrorForRequest,
  };
}
