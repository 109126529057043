import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  Controllers,
  IInstallationZonesDifferences,
  IInstallationZoneStaticValues,
  IUseInstallationZoneParameters,
  IZoneKeys,
  ZoneCurveKeys,
  ZoneCurveTempKeys,
} from "../../types";
import { IStateZoneOperation } from "../../../../../../../../store/services/models/installations/installationState";
import { ZoneOperationsKey } from "../../../../../../../../store/services/models/installations/installationsCommon";
import {
  IInstallationZoneOperation,
  InstallationZoneOperationControllerEnum,
  IZoneComissioningValues,
  PointKey,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import { adjustCurveValues } from "../../helpers/zoneSettingsHelpers";
import { findDifferences } from "../../../../helpers/settingsHelpers";
import { deepClone } from "../../../../helpers/settingsHelpers";

export default function useInstallationZone({
  installationSettingsResponse,
  installationStateResponse,
  isInstallationOffline,
}: IUseInstallationZoneParameters) {
  const { zoneNumber } = useParams();

  const [zoneFormData, setZoneFormData] =
    useState<IInstallationZoneOperation | null>(null);

  const zoneKeys: IZoneKeys = {
    zoneOperationKey: `zone${zoneNumber?.slice(
      -1
    )}Operations` as ZoneOperationsKey,
    zoneNumber: zoneNumber?.slice(-1),
  };

  //State data for current zoneOperations
  const zoneOperationStateData: IStateZoneOperation | undefined =
    installationStateResponse?.zoneOperations[zoneKeys.zoneOperationKey];

  //Settings data for current zoneOperations
  const zoneOperationSettingsData: IInstallationZoneOperation | undefined =
    installationSettingsResponse?.zoneOperations[zoneKeys.zoneOperationKey];

  //Installer settings data for current zoneOperations
  const zoneOperationInstallerSettingsData:
    | IZoneComissioningValues
    | undefined =
    installationSettingsResponse?.installerSettings?.commissioning
      ?.zoneOperations[zoneKeys.zoneOperationKey];

  useEffect(() => {
    if (zoneOperationSettingsData) {
      setZoneFormData(deepClone(zoneOperationSettingsData));
    }
  }, [zoneOperationSettingsData]);

  const staticValues: IInstallationZoneStaticValues = {
    mode: zoneOperationInstallerSettingsData?.mode,
    indoorSensor: zoneOperationInstallerSettingsData?.indoorSensor,
    currentRoomTemp: zoneOperationStateData?.currentRoomTemp,
    circuitType: zoneOperationStateData?.circuitType,
  };

  const zoneFormValueDifferencesWithInitialValue: IInstallationZonesDifferences | null =
    useMemo(() => {
      if (!zoneFormData || !zoneOperationSettingsData) return null;
      return findDifferences({
        current: zoneFormData,
        prev: zoneOperationSettingsData,
      });
    }, [zoneFormData, zoneOperationSettingsData]);

  const updateZoneFormData = (newValue: number, propertyName: string) => {
    setZoneFormData(
      (prev) =>
        ({
          ...prev,
          [propertyName]: {
            ...(prev as any)[propertyName],
            value: newValue,
          },
        } as any)
    );
  };

  const updateZoneController = (
    newValue: InstallationZoneOperationControllerEnum | undefined,
    propertyName: Controllers
  ) => {
    setZoneFormData(
      (prev) =>
        ({
          ...prev,
          [propertyName]: newValue,
        } as any)
    );
  };

  const updateTempInCurve = (
    newValue: number,
    curveType: ZoneCurveKeys,
    pointKey: PointKey,
    tempKey: ZoneCurveTempKeys
  ) => {
    if (tempKey === "outdoorTemp") {
      setZoneFormData((prev) => {
        if (!prev) return prev;

        const updatedCurve = adjustCurveValues(
          prev[curveType] as any,
          pointKey,
          newValue
        );

        return {
          ...prev,
          [curveType]: updatedCurve,
        };
      });
    } else {
      setZoneFormData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          [curveType]: {
            ...prev[curveType],
            [pointKey]: {
              ...prev[curveType]?.[pointKey],
              [tempKey]: {
                ...prev[curveType]?.[pointKey]?.[tempKey],
                value: newValue,
              },
            },
          },
        };
      });
    }
  };

  const discardFormChanges = () => {
    setZoneFormData(deepClone(zoneOperationSettingsData));
  };

  return {
    installationSettingsResponse,
    installationStateResponse,
    zoneOperationInstallerSettingsData,
    isInstallationOffline,
    zoneKeys,
    staticValues,
    zoneFormData,
    zoneFormValueDifferencesWithInitialValue,
    updateZoneController,
    updateZoneFormData,
    updateTempInCurve,
    discardFormChanges,
  };
}
