import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useTypedSelector } from "../../../../../../../../../store";
import { useState } from "react";
import { selectUserOrganizationInfo } from "../../../../../../../../../store/reducers/organizationSlice";
import {
  useGetInstallationDetailsQuery,
  useUnpairInstallationMutation,
} from "../../../../../../../../../store/services/installation";

export default function useUserAppPairingTab() {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.userSettings.userAppPairing",
  });

  const { id: installationUuid } = useParams();
  const [unpairInstallation] = useUnpairInstallationMutation();

  const currentUserOrganizationInfo = useTypedSelector(
    selectUserOrganizationInfo
  );
  const { data: installationDetails, refetch: refetchDetails } =
    useGetInstallationDetailsQuery(
      {
        installationUuid: installationUuid!,
        organizationUuid: currentUserOrganizationInfo?.uuid,
      },
      {
        pollingInterval: 60000,
      }
    );

  const [isUnpairModalOpen, setIsUnpairModalOpen] = useState<boolean>(false);

  const mainTitleText = t("mainTitle");
  const userAppTitleText = t("userAppTitle");
  const unpairButtonText = t("unpairButtonText");
  const pairedText = t("paired");
  const unpairedText = t("unpaired");

  const isPaired = installationDetails?.sharingInfo?.clientOrgUuid;

  const onUnpairInstallation = () => {
    unpairInstallation({
      installationUuid: installationUuid!,
    })
      .unwrap()
      .then(() => {
        setIsUnpairModalOpen(false);
        refetchDetails();
      })
      .catch((error) => {});
  };

  return {
    isUnpairModalOpen,
    mainTitleText,
    userAppTitleText,
    unpairButtonText,
    pairedText,
    unpairedText,
    isPaired,
    setIsUnpairModalOpen,
    onUnpairInstallation,
  };
}
