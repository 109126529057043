import { useParams } from "react-router";
import {
  useGetInstallationSettingsForRealTimeDataQuery,
  useGetInstallationStateQuery,
} from "../../../../../store/services/installation";
import { ActiveOrInactiveEnum } from "../../../../../store/services/models/installations/installationsCommon";

export default function useRealTimeDataConditions() {
  const { id } = useParams();

  //get state query
  const { data: installationState } = useGetInstallationStateQuery(
    {
      uuid: id!,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000,
    }
  );

  //get settings query
  const { data: installationSettings } =
    useGetInstallationSettingsForRealTimeDataQuery(
      {
        uuid: id!,
      },
      {
        refetchOnMountOrArgChange: true,
        pollingInterval: 60000,
      }
    );

  //DIFFERENT CONDITIONS
  //isDHWActive (from installer settings/ comissioning / dhw)
  const isInstallerSettingsComissioningDHWActive =
    installationSettings?.installerSettings?.commissioning?.dhw?.active ===
    ActiveOrInactiveEnum.ACTIVE;

  //isAntiLegionellaActive (from state / dhw)
  const isAntiLegionellaActiveState =
    installationState?.dhw.antiLegionella.state === ActiveOrInactiveEnum.ACTIVE;

  //isAdditionalHeatingSourceStagesActive (from state / additionalHeatingSourcesParameters)
  const isAdditionalHeatingSourceStagesActive =
    installationSettings?.installerSettings?.commissioning
      ?.additionalInlineHeatingSource?.state === ActiveOrInactiveEnum.ACTIVE;

  //is additional dhw heater configured (from settings / installer / comissioning /additionalDhwHeatingSource)
  const isAdditionalDhwHeatingSourceSetted =
    installationSettings?.installerSettings?.commissioning
      ?.additionalDhwHeatingSource?.state === ActiveOrInactiveEnum.ACTIVE;

  //is buffer tank configured (from settings/ installer / comissioning / bufferTank)
  const isBufferTankAsInlineHeaterSetted =
    installationSettings?.installerSettings?.commissioning?.bufferTank
      ?.inlineHeaterState === ActiveOrInactiveEnum.ACTIVE;

  //is sgReady configured
  const isSGReadyActive = installationState?.sgReady?.status;

  return {
    installationState,
    installationSettings,
    isAdditionalDhwHeatingSourceSetted,
    isAdditionalHeatingSourceStagesActive,
    isAntiLegionellaActiveState,
    isBufferTankAsInlineHeaterSetted,
    isInstallerSettingsComissioningDHWActive,
    isSGReadyActive,
  };
}
