import React from "react";
import TabWrapper from "../../../../TabWrapper";
import useQuietModeTab from "./hooks/useQuietModeTab";
import useQuietModeTabConditionsAndValuesToDisplay from "./hooks/useQuietModeTabConditionsAndValuesToDisplay";
import useTheme, {
  ESThemeType,
} from "../../../../../../../../theme/hooks/useTheme";
import { Typography } from "@mui/material";
import SettingsDivider from "../../../../components/SettingsDivider/SettingsDivider";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import QuietModeSchedule from "./components/QuietModeSchedule/QuietModeSchedule";
import QuietModeContext from "./context/QuietModeContext";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import useTabsIcons from "../../../../hooks/useTabsIcons";
import QuietModeDifferences from "./components/QuietModeDifferences/QuietModeDifferences";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import NoParametersBlock from "../../../../NoParametersBlock";

export interface IQuietModeTabProps {
  isInstallationOffline?: boolean;
}

export default function QuietModeTab({
  isInstallationOffline = false,
}: IQuietModeTabProps) {
  const theme = useTheme();
  const {
    quietModeSettingsData,
    quietModeSettingsFormData,
    quietModeSettingsHelperData,
    quietModeSettingsFormValueDifferencesWithInitialValue,
    isSomethingChanged,
    isQuietModeEnabledInitialy,
    isQuietModeEnabled,
    wasEverEnabled,
    hoursDropdownOptions,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    isLoadingSettings,
    setIsConfirmChangesModalOpen,
    setIsNotificationModalOpen,
    confirmFormChanges,
    onQuietModeEnabledChange,
    onQuietModeScheduleChange,
    onQuietModeHelperDataChange,
    onApplyForAllDays,
    onDiscardChanges,
    onSaveChanges,
  } = useQuietModeTab();

  const {
    mainTitleText,
    mainDescriptionText,
    stateSwitcherLabelText,
    tabNameLabelText,
    commonErrorForRequestText,
    subTabNameLabelText,
    isShowQuietModeSchedule,
    isShowEmptyBlock,
  } = useQuietModeTabConditionsAndValuesToDisplay({
    quietModeSettingsFormData,
    isQuietModeEnabledInitialy,
    wasEverEnabled,
    isLoadingSettings,
  });

  const { userSettingsTabIcon } = useTabsIcons();

  const styles = getStyles(theme);

  if (isShowEmptyBlock) {
    return <NoParametersBlock />;
  }

  return (
    <TabWrapper
      onSaveChangesPress={onSaveChanges}
      onDiscardPress={onDiscardChanges}
      isSaveDisabled={!isSomethingChanged}
      isDiscardDisabled={!isSomethingChanged}
    >
      <Typography sx={styles.mainTitle}>{mainTitleText}</Typography>
      <Typography sx={styles.mainDescription}>{mainDescriptionText}</Typography>
      <SettingsDivider sx={styles.divider} />

      <SecondaryTitle title={stateSwitcherLabelText} variant="md" />
      <SettingsSwitcher
        state={isQuietModeEnabled}
        changeState={() => {
          onQuietModeEnabledChange(!isQuietModeEnabled);
        }}
        disabled={isInstallationOffline}
      />

      {isShowQuietModeSchedule && (
        <QuietModeContext.Provider
          value={{
            isInstallationOffline,
            quietModeSettingsData,
            quietModeSettingsFormData,
            quietModeSettingsHelperData,
            hoursDropdownOptions,
            onQuietModeScheduleChange,
            onQuietModeHelperDataChange,
            onApplyForAllDays,
          }}
        >
          <SettingsDivider sx={styles.divider} />
          <QuietModeSchedule />
        </QuietModeContext.Provider>
      )}

      <UnsavedChangesModal
        tabName={tabNameLabelText}
        condition={isSomethingChanged}
        discardChanges={onDiscardChanges}
      />
      <ConfirmChangesModal
        isOpen={isConfirmChangesModalOpen}
        onCancel={() => setIsConfirmChangesModalOpen(false)}
        onOk={() => confirmFormChanges(commonErrorForRequestText)}
        page={subTabNameLabelText}
        block={tabNameLabelText}
        icon={userSettingsTabIcon}
      >
        <QuietModeDifferences
          differences={quietModeSettingsFormValueDifferencesWithInitialValue}
          initialValue={quietModeSettingsData!}
          formData={quietModeSettingsFormData!}
        />
      </ConfirmChangesModal>
      <NotificationModal
        isOpen={isNotificationModalOpen}
        onOkPress={() => setIsNotificationModalOpen(false)}
        icon={userSettingsTabIcon}
      />
    </TabWrapper>
  );
}

const getStyles = ({ colors, spacing, typography }: ESThemeType) => ({
  mainTitle: {
    ...typography[".text-lg-semibold"],
    color: colors.colorsTextTextPrimary,
  },
  mainDescription: {
    ...typography[".text-sm-regular"],
    color: colors.colorsTextTextTertiary,
    maxWidth: "600px",
  },
  divider: {
    margin: `${spacing.spacingXl} 0`,
  },
});
