import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../theme/hooks/useTheme";
import { Box, SxProps, Typography } from "@mui/material";
import { Settings02 } from "untitledui-js-base";

export default function NoParametersBlock() {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.noParametersBlock",
  });
  const theme = useTheme();
  const styles: Record<string, SxProps> = useMemo(
    () => ({
      wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: `${theme.spacing.spacingSm} ${theme.spacing.spacing2xl}`,
      },
      containerWithBorder: {
        border: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
        borderRadius: theme.radius.radiusMd,
        padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacingMd}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      },
      iconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.radius.radiusFull,
        backgroundColor: theme.colors.componentColorsUtilityGrayUtilityGray50,
        height: "50px",
        width: "50px",
        mb: theme.spacing.spacingLg,
        svg: {
          color:
            theme.colors
              .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
        },
      },
      title: {
        ...theme.typography[".text-md-semibold"],
        color: theme.colors.colorsTextTextPrimary,
        mb: theme.spacing.spacingMd,
      },
      description: {
        ...theme.typography[".text-sm-regular"],
        color: theme.colors.colorsTextTextTertiary,
        mb: theme.spacing.spacingXl,
      },
    }),
    [theme]
  );

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.containerWithBorder}>
        <Box sx={styles.iconContainer}>
          <Settings02 strokeWidth={2} size="20" />
        </Box>
        <Typography sx={styles.title}>{t("title")}</Typography>
        <Typography sx={styles.description}>{t("description")}</Typography>
      </Box>
    </Box>
  );
}
