import React from "react";
import { ISmallDialogWrapperProps } from "./Types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Typography,
} from "@mui/material";
import useTheme from "../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "../Button/Button";

export default function SmallDialogWrapper({
  dialogOpen,
  onClose,
  title,
  description,
  onSave,
  isSaveInProgress = false,
  isSaveButtonDisabled = false,
  children,
}: ISmallDialogWrapperProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const styles: Record<string, SxProps> = {
    dialog: {
      "& .MuiPaper-root": { maxWidth: "400px" },
    },
    dialogTitleWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing.spacing3xl,
    },
    dialogTitle: {
      ...theme.typography[".text-lg-semibold"],
      color: theme.colors.colorsTextTextPrimary,
    },
    dialogDescription: {
      ...theme.typography[".text-sm-regular"],
      color: theme.colors.colorsTextTextTertiary,
    },
    dialogContentWrapper: {},
    dialogActionsWrapper: {
      padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
      justifyContent: "space-between",
    },
    cancelButton: {
      width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
    },
    saveButton: {
      ml: "0 !important",
      width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
    },
  };

  const handleDialogClose = () => {
    onClose();
  };

  const handleSaveClick = () => {
    onSave();
  };

  return (
    <Dialog open={dialogOpen} fullWidth={true} sx={styles.dialog}>
      <DialogTitle sx={styles.dialogTitleWrapper}>
        <Typography sx={styles.dialogTitle}>{title}</Typography>
        {description && (
          <Typography sx={styles.dialogDescription}>{description}</Typography>
        )}
      </DialogTitle>
      <DialogContent sx={styles.dialogContentWrapper}>{children}</DialogContent>
      <DialogActions sx={styles.dialogActionsWrapper}>
        <Button
          label={`${t("actions.cancel")}`}
          onClick={handleDialogClose}
          buttonType={ButtonType.SecondaryGray}
          sx={styles.cancelButton}
        />
        <Button
          label={`${t("actions.save")}`}
          onClick={() => {
            handleSaveClick();
          }}
          loadingInProgress={isSaveInProgress}
          disabled={isSaveButtonDisabled}
          sx={styles.saveButton}
        />
      </DialogActions>
    </Dialog>
  );
}
