import { Box, Grid, SxProps, Typography } from "@mui/material";
import { EChartsOption } from "echarts";
import React, { useEffect, useState } from "react";
import useTheme, { ESThemeType } from "../../../../../../theme/hooks/useTheme";
import ReactECharts from "echarts-for-react";
import { AvailableParametersEnum } from "../../types/types";
import { getParameterColor } from "../../GraphReport/ParametersUnits";
import { useTranslation } from "react-i18next";

type LegendsListProps = {
  seriesData: Array<EChartsOption["series"]>;
  chartRef: React.RefObject<ReactECharts | null>;
};

export default function LegendsList({ seriesData, chartRef }: LegendsListProps) {
  const theme = useTheme();
  const styles = getStyles(theme);
  const keyPrefix = "pages.analyticsPage.availableParametersDropdown.parameters";
  const { t } = useTranslation("cloud_ui", { keyPrefix: keyPrefix });
  const hasTranslation = (key: string) => Boolean(t(key, { returnNull: false }) !== `${keyPrefix}.${key}`);
  const [selectedLegends, setSelectedLegends] = useState<string[]>([]);

  const legendToggleSelect = (name: string) => {
    const chart = chartRef.current?.getEchartsInstance();
    if (chart) {
      const indexLegend = selectedLegends.indexOf(name);
      const upSelectedLegends = [...selectedLegends];
      chart.dispatchAction({
        type: indexLegend > -1 ? "legendUnSelect" : "legendSelect",
        name: name,
      });
      if (indexLegend > -1) {
        upSelectedLegends.splice(indexLegend, 1);
      } else {
        upSelectedLegends.push(name);
      }
      setSelectedLegends(upSelectedLegends);
    }
  };

  const highlightToggleSeries = (name: string, select: boolean) => {
    const chart = chartRef.current?.getEchartsInstance();
    if (select) {
      chart?.dispatchAction({
        type: "highlight",
        seriesName: name,
      });
    } else {
      chart?.dispatchAction({
        type: "downplay",
        seriesName: name,
      });
    }
  };

  useEffect(() => {
    setSelectedLegends(seriesData.map((s: any) => s.name as string));
  }, [seriesData]);

  return (
    <Grid container flexDirection={"row"} columnGap={2} rowGap={1} pl={theme.spacing.spacingMd}>
      {seriesData.map((series: any) => (
        <Grid
          key={series.name}
          item
          sx={styles.legendItemContainer}
          onClick={() => legendToggleSelect(series.name)}
          onMouseEnter={() => highlightToggleSeries(series.name, true)}
          onMouseLeave={() => highlightToggleSeries(series.name, false)}
        >
          <Box
            sx={{
              ...styles.legendItemIcon,
              backgroundColor: getParameterColor(series.name as AvailableParametersEnum),
            }}
          />
          <Typography
            sx={{
              ...styles.legendItemName,
              opacity: selectedLegends.includes(series.name) ? 1 : 0.6,
              textDecoration: selectedLegends.includes(series.name) ? "inherit" : "line-through",
            }}
          >
            {hasTranslation(series.name) ? t(`${series.name}`) : series.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    legendItemContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
    } as SxProps,
    legendItemIcon: {
      width: 12,
      height: 12,
      borderRadius: 90,
      mr: spacing.spacingMd,
    } as SxProps,
    legendItemName: {
      ...typography[".text-sm-medium"],
      color: colors.colorsTextTextPrimary,
    } as SxProps,
  };
};
