import { PointKey } from "../../../../../../../store/services/models/installations/installationSettings";
import { ILimitValuesForOutdoorTemp } from "../types";

const calculateMinAndMaxOutdoorTempValues = (
  index: number,
  baseLimits: ILimitValuesForOutdoorTemp,
  step: number,
  isCooling: boolean
) => {
  const lastIndex = isCooling ? 2 : 4;
  return {
    min: baseLimits.min + index * step,
    max: baseLimits.max - (lastIndex - index) * step,
  };
};

const adjustCurveValues = (
  curveData: Record<PointKey, any>,
  updatedKey: PointKey,
  newValue: number
) => {
  const keys = Object.keys(curveData) as PointKey[];
  const updatedIndex = keys.indexOf(updatedKey);
  if (updatedIndex === -1) return curveData;

  const newCurveData = { ...curveData };

  newCurveData[updatedKey] = {
    ...newCurveData[updatedKey],
    outdoorTemp: {
      ...newCurveData[updatedKey].outdoorTemp,
      value: newValue,
    },
  };

  for (let i = updatedIndex + 1; i < keys.length; i++) {
    if (
      newCurveData[keys[i]].outdoorTemp.value <=
      newCurveData[keys[i - 1]].outdoorTemp.value
    ) {
      newCurveData[keys[i]] = {
        ...newCurveData[keys[i]],
        outdoorTemp: {
          ...newCurveData[keys[i]].outdoorTemp,
          value: newCurveData[keys[i - 1]].outdoorTemp.value + 0.5,
        },
      };
    } else {
      break;
    }
  }

  for (let i = updatedIndex - 1; i >= 0; i--) {
    if (
      newCurveData[keys[i]].outdoorTemp.value >=
      newCurveData[keys[i + 1]].outdoorTemp.value
    ) {
      newCurveData[keys[i]] = {
        ...newCurveData[keys[i]],
        outdoorTemp: {
          ...newCurveData[keys[i]].outdoorTemp,
          value: newCurveData[keys[i + 1]].outdoorTemp.value - 0.5,
        },
      };
    } else {
      break;
    }
  }

  return newCurveData;
};

export { calculateMinAndMaxOutdoorTempValues, adjustCurveValues };
