export enum AvailableParametersEnum {
  additionalBufferTankHeatingSourceStatus = "additionalBufferTankHeatingSourceStatus",
  additionalDhwHeatingSourceStatus = "additionalDhwHeatingSourceStatus",
  additionalInlineHeatingSource = "additionalInlineHeatingSource",
  additionalInlineHeatingSourceStage1 = "additionalInlineHeatingSourceStage1",
  additionalInlineHeatingSourceStage2 = "additionalInlineHeatingSourceStage2",
  additionalInlineHeatingSourceStage3 = "additionalInlineHeatingSourceStage3",
  compressorDischargeTemperatureTD = "compressorDischargeTemperatureTD",
  compressorSpeed = "compressorSpeed",
  compressorSuctionTemperatureTS = "compressorSuctionTemperatureTS",
  condenserInletWaterTemperatureTUI = "condenserInletWaterTemperatureTUI",
  condenserOutletWaterTemperatureTUO = "condenserOutletWaterTemperatureTUO",
  condensingTemperatureTUP = "condensingTemperatureTUP",
  currentRoomTemperatureZ1 = "currentRoomTemperatureZ1",
  currentRoomTemperatureZ2 = "currentRoomTemperatureZ2",
  currentSeason = "currentSeason",
  defrostMode = "defrostMode",
  dhwAntiLegionellaState = "dhwAntiLegionellaState",
  dhwPreferredTemp = "dhwPreferredTemp",
  dhwTankTemperatureTWD = "dhwTankTemperatureTWD",
  dhwValvePosition = "dhwValvePosition",
  eevOpeningP = "eevOpeningP",
  electricGridProtectionStatus = "electricGridProtectionStatus",
  evaporatingTemperatureTP = "evaporatingTemperatureTP",
  fanSpeed1 = "fanSpeed1",
  fanSpeed2 = "fanSpeed2",
  heatBalance = "heatBalance",
  highPressurePd = "highPressurePd",
  hpState = "hpState",
  lowPressurePs = "lowPressurePs",
  operationStatus = "operationStatus",
  outdoorTemperature = "outdoorTemperature",
  outdoorUnitCurrent = "outdoorUnitCurrent",
  outdoorUnitVoltage = "outdoorUnitVoltage",
  preferredRoomTempZ1 = "preferredRoomTempZ1",
  preferredRoomTempZ2 = "preferredRoomTempZ2",
  quietMode = "quietMode",
  reducedModeState = "reducedModeState",
  requestedCompressorSpeed = "requestedCompressorSpeed",
  setPointTHC = "setPointTHC",
  sgReadyStatus = "sgReadyStatus",
  tv1SetPointTemp = "tv1SetPointTemp",
  tv1Temperature = "tv1Temperature",
  tv2SetPointTemp = "tv2SetPointTemp",
  tv2Temperature = "tv2Temperature",
  vacationModeState = "vacationModeState",
  waterFlowRate = "waterFlowRate",
  waterPumpP0Status = "waterPumpP0Status",
  waterPumpP1Status = "waterPumpP1Status",
  waterPumpP2Status = "waterPumpP2Status",
  waterTempTHC = "waterTempTHC",
}
