import React from "react";
import DifferencesRow from "../../../../../components/Differences/components/DifferencesRow/DifferencesRow";
import DifferencesSecondaryTitle from "../../../../../components/Differences/components/DifferencesSecondaryTitle/DifferencesSecondaryTitle";
import SettingsDivider from "../../../../../components/SettingsDivider/SettingsDivider";

import useInstallerZoneDifferencesConditionsAndValuesForDisplay from "../../../hooks/zoneDifferences/useInstallerZoneDifferencesConditionsAndValuesForDisplay";
import useDifferencesHelpers from "../../../../../components/Differences/hooks/useDifferencesHelpers";

import { IInstallerZoneDifferencesProps } from "../../../types";

export default function InstallerZoneDifferences({
  installerDifferences,
  zoneCountDifferences,
}: IInstallerZoneDifferencesProps) {
  const {
    flowTempHeatingLimitsTitle,
    flowTempCoolingLimitsTitle,

    showZoneStateChangeSection,
    showMixingValveTurningTimeChange,
    showZoneModeChange,
    showFlowTempHeatingLimitsSection,
    showFlowTempCoolingLimitsSection,
    showIndoorSensorChange,

    zoneStateLabel,
    mixingValveTurningTimeLabel,
    zoneModeLabel,
    heatingMinLimitLabel,
    heatingMaxLimitLabel,
    coolingMinLimitLabel,
    coolingMaxLimitLabel,
    indoorSensorLabel,

    buildSpecificValueForZoneState,
    buildSpecificValueForZoneMode,
    buildSpecificValueForIndoorSensor,
  } = useInstallerZoneDifferencesConditionsAndValuesForDisplay(
    zoneCountDifferences,
    installerDifferences
  );

  const { buildBaseDifferenceValue } = useDifferencesHelpers();

  return (
    <>
      {showZoneStateChangeSection && (
        <DifferencesRow
          label={zoneStateLabel}
          value={buildSpecificValueForZoneState(zoneCountDifferences)}
        />
      )}
      {showMixingValveTurningTimeChange && (
        <DifferencesRow
          label={mixingValveTurningTimeLabel}
          value={buildBaseDifferenceValue(
            installerDifferences?.mixingValveTurningTime
          )}
        />
      )}
      {showZoneModeChange && (
        <DifferencesRow
          label={zoneModeLabel}
          value={buildSpecificValueForZoneMode(installerDifferences?.mode)}
        />
      )}
      {showFlowTempHeatingLimitsSection && (
        <>
          <DifferencesSecondaryTitle title={flowTempHeatingLimitsTitle} />
          <DifferencesRow
            label={heatingMinLimitLabel}
            value={buildBaseDifferenceValue(
              installerDifferences?.minFlowHeatTemp
            )}
          />
          <DifferencesRow
            label={heatingMaxLimitLabel}
            value={buildBaseDifferenceValue(
              installerDifferences?.maxFlowHeatTemp
            )}
          />
        </>
      )}
      {showFlowTempHeatingLimitsSection && showFlowTempCoolingLimitsSection && (
        <SettingsDivider />
      )}
      {showFlowTempCoolingLimitsSection && (
        <>
          <DifferencesSecondaryTitle title={flowTempCoolingLimitsTitle} />
          <DifferencesRow
            label={coolingMinLimitLabel}
            value={buildBaseDifferenceValue(
              installerDifferences?.minFlowCoolTemp
            )}
          />
          <DifferencesRow
            label={coolingMaxLimitLabel}
            value={buildBaseDifferenceValue(
              installerDifferences?.maxFlowCoolTemp
            )}
          />
        </>
      )}
      {showIndoorSensorChange && (
        <DifferencesRow
          label={indoorSensorLabel}
          value={buildSpecificValueForIndoorSensor(
            installerDifferences?.indoorSensor
          )}
        />
      )}
    </>
  );
}
