import { List, SxProps, Typography } from "@mui/material";
import React, { useContext } from "react";
import useTheme from "../../../../theme/hooks/useTheme";
import SupportFormContext from "../../context/SupportFormContext";

export default function DescriptionTextAreaHintsList() {
  const { colors, spacing, typography } = useTheme();

  const { hints } = useContext(SupportFormContext);

  const styles: Record<string, SxProps> = {
    hintsList: {
      color: colors.colorsTextTextTertiary,
      ml: spacing.spacing3xl,
      p: 0,
      mb: spacing.spacing2xl,
    },
    hintsListItem: {
      ...typography[".text-sm-regular"],
      listStyle: "numeric",
    },
  };
  return (
    <List component={"ol"} sx={styles.hintsList}>
      {hints.map((hint, index) => (
        <Typography key={index} component="li" sx={styles.hintsListItem}>
          {hint}
        </Typography>
      ))}
    </List>
  );
}
