import { useParams } from "react-router";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../../store/services/installation";
import { useState, useEffect, useMemo } from "react";
import {
  IInstallationSettingsByIdResponse,
  IInstallationUserSettingsQuietMode,
  ISetInstallationSettings,
  ISetQuietMode,
} from "../../../../../../../../../store/services/models/installations/installationSettings";
import {
  deepClone,
  findDifferences,
  transformDifferencesForRequest,
} from "../../../../../helpers/settingsHelpers";
import { IQuietModeDifferences } from "../types/types";
import { useAppDispatch } from "../../../../../../../../../store";
import { useToast } from "../../../../../../../../../hooks/useToast";
import { ToastStatuses } from "../../../../../../../../../components/Toaster/StatusIcon/Types";
import {
  IInstallationBaseValueWithMeasurement,
  InstallationValueUnit,
} from "../../../../../../../../../store/services/models/installations/installationsCommon";

export default function useQuietModeTab() {
  const { id: installationUuid } = useParams();
  const dispatch = useAppDispatch();

  const { showToast } = useToast();

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] =
    useState<boolean>(true);

  //form data states
  const [quietModeSettingsFormData, setQuietModeSettingsFormData] =
    useState<IInstallationUserSettingsQuietMode | null>(null);
  const [quietModeSettingsHelperData, setQuietModeSettingsHelperData] =
    useState<IInstallationUserSettingsQuietMode | null>(null);

  const [
    initialQuietModeSettingsFormData,
    setInitialQuietModeSettingsFormData,
  ] = useState<IInstallationUserSettingsQuietMode | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false);

  //quiet mode enabled/disabled states
  const [isQuietModeEnabledInitialy, setIsQuietModeEnabledInitialy] =
    useState<boolean>(false);
  const [isQuietModeEnabled, setIsQuietModeEnabled] = useState<boolean>(false);
  const [wasEverEnabled, setWasEverEnabled] = useState(false);

  //modals states
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const poolingInterval = isPoolingIntervalEnabled ? 60000 : 0;
  const { data: installationSettings, isLoading: isLoadingSettings } =
    useGetInstallationSettingsQuery(
      { uuid: installationUuid! },
      { pollingInterval: poolingInterval }
    );

  const quietModeSettingsData = installationSettings?.userSettings.quietMode;

  const hoursDropdownOptions = Array.from({ length: 24 }, (_, index) => {
    const hour = index;
    const formattedHour = hour.toString().padStart(2, "0");
    return {
      label: `${formattedHour}:00`,
      value: hour,
    };
  });

  const calculateIsHasNonZeroLength = (
    quietModeSettingsFormData: IInstallationUserSettingsQuietMode
  ) => {
    return Object.keys(quietModeSettingsFormData).some((key) => {
      return (
        key.startsWith("length") &&
        quietModeSettingsFormData[
          key as keyof IInstallationUserSettingsQuietMode
        ].value !== 0
      );
    });
  };

  useEffect(() => {
    if (quietModeSettingsFormData) {
      const hasNonZeroLength = calculateIsHasNonZeroLength(
        quietModeSettingsFormData
      );
      setIsQuietModeEnabled(hasNonZeroLength);
    }
  }, [quietModeSettingsFormData]);

  useEffect(() => {
    if (quietModeSettingsData) {
      setQuietModeSettingsFormData(deepClone(quietModeSettingsData));
      const hasNonZeroLength = calculateIsHasNonZeroLength(
        quietModeSettingsData
      );

      setIsQuietModeEnabledInitialy(hasNonZeroLength);
      setIsQuietModeEnabled(hasNonZeroLength);
    }
  }, [quietModeSettingsData]);

  const generateDefaultQuietModeSettingsData = (
    quietModeSettingsData: IInstallationUserSettingsQuietMode
  ): IInstallationUserSettingsQuietMode => {
    const baseValueObjectForStart: IInstallationBaseValueWithMeasurement = {
      value: 0,
      unit: InstallationValueUnit.HOURS,
      min: 0,
      max: 23,
    };
    const baseValueObjectForLength: IInstallationBaseValueWithMeasurement = {
      value: 6,
      unit: InstallationValueUnit.HOURS,
      min: 0,
      max: 24,
    };
    return {
      startMonday: {
        ...baseValueObjectForStart,
        value:
          quietModeSettingsData?.startMonday?.value !== undefined
            ? quietModeSettingsData?.startMonday?.value
            : 0,
      },
      lengthMonday: {
        ...baseValueObjectForLength,
        value:
          quietModeSettingsData?.lengthMonday?.value !== undefined
            ? quietModeSettingsData?.lengthMonday?.value
            : 0,
      },
      startTuesday: {
        ...baseValueObjectForStart,
        value:
          quietModeSettingsData?.startTuesday?.value !== undefined
            ? quietModeSettingsData?.startTuesday?.value
            : 0,
      },
      lengthTuesday: {
        ...baseValueObjectForLength,
        value:
          quietModeSettingsData?.lengthTuesday?.value !== undefined
            ? quietModeSettingsData?.lengthTuesday?.value
            : 0,
      },
      startWednesday: {
        ...baseValueObjectForStart,
        value:
          quietModeSettingsData?.startWednesday?.value !== undefined
            ? quietModeSettingsData?.startWednesday?.value
            : 0,
      },
      lengthWednesday: {
        ...baseValueObjectForLength,
        value:
          quietModeSettingsData?.lengthWednesday?.value !== undefined
            ? quietModeSettingsData?.lengthWednesday?.value
            : 0,
      },
      startThursday: {
        ...baseValueObjectForStart,
        value:
          quietModeSettingsData?.startThursday?.value !== undefined
            ? quietModeSettingsData?.startThursday?.value
            : 0,
      },
      lengthThursday: {
        ...baseValueObjectForLength,
        value:
          quietModeSettingsData?.lengthThursday?.value !== undefined
            ? quietModeSettingsData?.lengthThursday?.value
            : 0,
      },
      startFriday: {
        ...baseValueObjectForStart,
        value:
          quietModeSettingsData?.startFriday?.value !== undefined
            ? quietModeSettingsData?.startFriday?.value
            : 0,
      },
      lengthFriday: {
        ...baseValueObjectForLength,
        value:
          quietModeSettingsData?.lengthFriday?.value !== undefined
            ? quietModeSettingsData?.lengthFriday?.value
            : 0,
      },
      startSaturday: {
        ...baseValueObjectForStart,
        value:
          quietModeSettingsData?.startSaturday?.value !== undefined
            ? quietModeSettingsData?.startSaturday?.value
            : 0,
      },
      lengthSaturday: {
        ...baseValueObjectForLength,
        value:
          quietModeSettingsData?.lengthSaturday?.value !== undefined
            ? quietModeSettingsData?.lengthSaturday?.value
            : 0,
      },
      startSunday: {
        ...baseValueObjectForStart,
        value:
          quietModeSettingsData?.startSunday?.value !== undefined
            ? quietModeSettingsData?.startSunday?.value
            : 0,
      },
      lengthSunday: {
        ...baseValueObjectForLength,
        value:
          quietModeSettingsData?.lengthSunday?.value !== undefined
            ? quietModeSettingsData?.lengthSunday?.value
            : 0,
      },
    };
  };

  useEffect(() => {
    if (quietModeSettingsData) {
      setQuietModeSettingsFormData(
        generateDefaultQuietModeSettingsData(quietModeSettingsData)
      );
      setQuietModeSettingsHelperData(
        generateDefaultQuietModeSettingsData(quietModeSettingsData)
      );
      setInitialQuietModeSettingsFormData(
        generateDefaultQuietModeSettingsData(quietModeSettingsData)
      );
    }
  }, [quietModeSettingsData]);

  const quietModeSettingsFormValueDifferencesWithInitialValue: IQuietModeDifferences | null =
    useMemo(() => {
      if (!quietModeSettingsFormData || !initialQuietModeSettingsFormData)
        return null;
      return findDifferences({
        current: quietModeSettingsFormData,
        prev: initialQuietModeSettingsFormData,
      });
    }, [quietModeSettingsFormData, initialQuietModeSettingsFormData]);

  useEffect(() => {
    const hasChanges = Boolean(
      quietModeSettingsFormValueDifferencesWithInitialValue
    );
    setIsSomethingChanged(hasChanges);
    setIsPoolingIntervalEnabled(!hasChanges);
  }, [quietModeSettingsFormValueDifferencesWithInitialValue]);

  useEffect(() => {
    if (isQuietModeEnabled && !wasEverEnabled) {
      setWasEverEnabled(true);
    }
  }, [isQuietModeEnabled, wasEverEnabled]);

  const onQuietModeEnabledChange = (value: boolean) => {
    const updatedFormData = {
      ...quietModeSettingsFormData,
      ...Object.keys(quietModeSettingsFormData || {}).reduce((acc, key) => {
        if (!quietModeSettingsFormData) return acc;

        return {
          ...acc,
          [key]: {
            ...quietModeSettingsFormData[
              key as keyof IInstallationUserSettingsQuietMode
            ],
            value: key.startsWith("length")
              ? value
                ? quietModeSettingsHelperData![
                    key as keyof IInstallationUserSettingsQuietMode
                  ].value || 6
                : 0
              : value
              ? quietModeSettingsHelperData![
                  key as keyof IInstallationUserSettingsQuietMode
                ].value || 0
              : 0,
          },
        };
      }, {}),
    };
    setQuietModeSettingsFormData(
      updatedFormData as unknown as IInstallationUserSettingsQuietMode
    );
    setIsQuietModeEnabled(value);
  };

  const onQuietModeHelperDataChange = (value: number, key: string) => {
    setQuietModeSettingsHelperData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [key]: {
          ...prev[key as keyof IInstallationUserSettingsQuietMode],
          value,
        },
      };
    });
  };

  const onQuietModeScheduleChange = (value: number, key: string) => {
    setQuietModeSettingsFormData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [key]: {
          ...prev[key as keyof IInstallationUserSettingsQuietMode],
          value,
        },
      };
    });
  };

  const onApplyForAllDays = (start: number, length: number) => {
    const updatedFormData = {
      ...quietModeSettingsFormData,
      ...Object.keys(quietModeSettingsFormData || {}).reduce((acc, key) => {
        if (!quietModeSettingsFormData) return acc;

        return {
          ...acc,
          [key]: {
            ...quietModeSettingsFormData[
              key as keyof IInstallationUserSettingsQuietMode
            ],
            value: key.startsWith("length") ? length : start,
          },
        };
      }, {}),
    };
    setQuietModeSettingsFormData(
      updatedFormData as unknown as IInstallationUserSettingsQuietMode
    );
    setQuietModeSettingsHelperData(
      updatedFormData as unknown as IInstallationUserSettingsQuietMode
    );
  };

  const onDiscardChanges = () => {
    setQuietModeSettingsFormData(deepClone(initialQuietModeSettingsFormData));
    setQuietModeSettingsHelperData(deepClone(initialQuietModeSettingsFormData));
  };

  const onSaveChanges = () => {
    setIsConfirmChangesModalOpen(true);
  };

  const confirmFormChanges = (commonErrorForRequestText: string) => {
    setIsConfirmChangesModalOpen(false);
    const quietModeFormattedChanges: ISetQuietMode =
      transformDifferencesForRequest(
        quietModeSettingsFormValueDifferencesWithInitialValue
      );
    let requestBody: Partial<ISetInstallationSettings> = {};
    let cacheData: IInstallationSettingsByIdResponse;
    if (quietModeFormattedChanges) {
      requestBody = {
        userSettings: {
          quietMode: quietModeFormattedChanges,
        },
      };
    }
    cacheData = {
      ...(installationSettings as IInstallationSettingsByIdResponse),
      userSettings: {
        ...installationSettings!.userSettings,
        quietMode: quietModeSettingsFormData!,
      },
    };

    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: installationUuid!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: installationUuid! },
            (draftSettings) => {
              Object.assign(draftSettings, cacheData);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((_error) => {
        showToast({
          title: commonErrorForRequestText,
          status: ToastStatuses.Error,
        });
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  return {
    quietModeSettingsData,
    quietModeSettingsFormData,
    quietModeSettingsHelperData,
    quietModeSettingsFormValueDifferencesWithInitialValue,
    isSomethingChanged,
    isPoolingIntervalEnabled,
    isQuietModeEnabledInitialy,
    isQuietModeEnabled,
    wasEverEnabled,
    hoursDropdownOptions,
    isConfirmChangesModalOpen,
    isNotificationModalOpen,
    isLoadingSettings,
    setIsPoolingIntervalEnabled,
    onQuietModeEnabledChange,
    onQuietModeScheduleChange,
    onQuietModeHelperDataChange,
    onApplyForAllDays,
    onDiscardChanges,
    onSaveChanges,
    setIsConfirmChangesModalOpen,
    setIsNotificationModalOpen,
    confirmFormChanges,
  };
}
