import { Box } from "@mui/material";
import React from "react";
import useQuietModeSchedule from "./hooks/useQuietModeSchedule";
import { Typography } from "@mui/material";
import SecondaryTitle from "../../../../../../components/SecondaryTitle/SecondaryTitle";
import useTheme, {
  ESThemeType,
} from "../../../../../../../../../../theme/hooks/useTheme";
import QuietModeScheduleRow from "./components/QuietModeScheduleRow/QuietModeScheduleRow";
import { useTranslation } from "react-i18next";

export default function QuietModeSchedule() {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "daysOfWeekMultiple",
  });
  const theme = useTheme();
  const {
    mainTitleText,
    descriptionText,
    quietModeSettingsFormData,
  } = useQuietModeSchedule();
  const styles = getStyles(theme);
  return (
    <Box>
      <SecondaryTitle title={mainTitleText} variant="md" />
      <Typography sx={styles.description}>{descriptionText}</Typography>

      <QuietModeScheduleRow
        isShowApplyForAllDays
        startKey="startMonday"
        lengthKey="lengthMonday"
        label={t("mondays")}
        startHourFormData={quietModeSettingsFormData?.startMonday?.value}
        timeLengthFormData={quietModeSettingsFormData?.lengthMonday?.value}
      />
      <QuietModeScheduleRow
        startKey="startTuesday"
        lengthKey="lengthTuesday"
        label={t("tuesdays")}
        startHourFormData={quietModeSettingsFormData?.startTuesday?.value}
        timeLengthFormData={quietModeSettingsFormData?.lengthTuesday?.value}
      />
      <QuietModeScheduleRow
        startKey="startWednesday"
        lengthKey="lengthWednesday"
        label={t("wednesdays")}
        startHourFormData={quietModeSettingsFormData?.startWednesday?.value}
        timeLengthFormData={quietModeSettingsFormData?.lengthWednesday?.value}
      />
      <QuietModeScheduleRow
        startKey="startThursday"
        lengthKey="lengthThursday"
        label={t("thursdays")}
        startHourFormData={quietModeSettingsFormData?.startThursday?.value}
        timeLengthFormData={quietModeSettingsFormData?.lengthThursday?.value}
      />
      <QuietModeScheduleRow
        startKey="startFriday"
        lengthKey="lengthFriday"
        label={t("fridays")}
        startHourFormData={quietModeSettingsFormData?.startFriday?.value}
        timeLengthFormData={quietModeSettingsFormData?.lengthFriday?.value}
      />
      <QuietModeScheduleRow
        startKey="startSaturday"
        lengthKey="lengthSaturday"
        label={t("saturdays")}
        startHourFormData={quietModeSettingsFormData?.startSaturday?.value}
        timeLengthFormData={quietModeSettingsFormData?.lengthSaturday?.value}
      />
      <QuietModeScheduleRow
        startKey="startSunday"
        lengthKey="lengthSunday"
        label={t("sundays")}
        startHourFormData={quietModeSettingsFormData?.startSunday?.value}
        timeLengthFormData={quietModeSettingsFormData?.lengthSunday?.value}
      />
    </Box>
  );
}

const getStyles = ({ colors, typography }: ESThemeType) => ({
  description: {
    ...typography[".text-sm-regular"],
    color: colors.colorsTextTextTertiary,
    maxWidth: "600px",
    mt: "-15px",
  },
});
