import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useTheme from "../../theme/hooks/useTheme";
import { IInstallationAlertsDataForCounter } from "../../pages/fleetManagement/ResidentialInstallationDetailsPage/ResidentialInstallationDetailsPage";

export interface ITabData {
  href: string;
  label: string;
}

export interface ITabsNavigationProps {
  tabs: Array<ITabData> | undefined;
  alertsData?: IInstallationAlertsDataForCounter | undefined;
}

export default function TabsNavigation({
  tabs,
  alertsData,
}: ITabsNavigationProps) {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <List
        sx={{
          display: "flex",
          width: "100%",
          height: "44px",
          backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
          border: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
          borderRadius: "10px",
          padding: theme.spacing.spacingXs,
        }}
      >
        {tabs?.map((tab, index) => (
          <ListItem
            key={`tab-item-residential-installation-detail-${index}`}
            button
            component={RouterLink}
            to={tab.href}
            sx={{
              position: "relative",
              borderRadius: "10px",
              backgroundColor: currentPath.includes(tab.href.split("?")[0])
                ? theme.colors.colorsBackgroundBgPrimary
                : "initial",
              "&:hover": {
                backgroundColor: currentPath.includes(tab.href.split("?")[0])
                  ? theme.colors.colorsBackgroundBgPrimary
                  : theme.colors.colorsBackgroundBgSecondaryHover,
              },
              boxShadow: currentPath.includes(tab.href.split("?")[0])
                ? "0 1px 3px rgba(16, 24, 40, 0.06)"
                : "none",
            }}
          >
            <ListItemText
              sx={{
                display: "flex",
                justifyContent: "center",
                span: {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <Typography
                sx={{
                  ...(theme.typography[".text-sm-semibold"] as any),
                  color: currentPath.includes(tab.href)
                    ? theme.colors.colorsTextTextSecondary
                    : theme.colors.colorsTextTextQuaternary,
                }}
              >
                {tab.label}
              </Typography>
              {tab.href.indexOf("/alerts") > -1 &&
                (alertsData?.totalCount || 0) > 0 && (
                  <Box
                    sx={{
                      ...theme.typography[".text-xs-medium"],
                      color: alertsData?.color
                        ? alertsData?.color
                        : theme.colors.componentColorsUtilityGrayUtilityGray700,
                      backgroundColor: alertsData?.backgroundColor
                        ? alertsData?.backgroundColor
                        : theme.colors.componentColorsUtilityGrayUtilityGray50,
                      width: "24px",
                      height: "24px",
                      border: `1px solid ${
                        alertsData?.borderColor
                          ? alertsData?.borderColor
                          : theme.colors
                              .componentColorsUtilityGrayUtilityGray200
                      }`,
                      borderRadius: theme.radius.radiusFull,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ml: theme.spacing.spacingLg,
                    }}
                  >
                    {alertsData?.totalCount}
                  </Box>
                )}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
