import React from "react";
import { ESThemeType } from "../theme/hooks/useTheme";
import { Box, Typography, SxProps } from "@mui/material";
import useTheme from "../theme/hooks/useTheme";
import { AlertCircle } from "untitledui-js-base";

type SystemMessageBoxProps = {
  type: "error" | "success" | "warning" | "info";
  title?: string;
  message?: string;
  useBackground?: boolean;
};

export default function SystemMessageBox({ type, message, title, useBackground = true }: SystemMessageBoxProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  const getContainerStyle = () => {
    switch (type) {
      case "error":
        return {
          container: styles.errorStyle,
          circle: styles.errorIconCircleStyle,
          iconColor: theme.colors.colorsForegroundFgErrorPrimary,
        };
      case "warning":
        return {
          container: styles.warningStyle,
          circle: styles.warningIconCircleStyle,
          iconColor: theme.colors.colorsForegroundFgWarningPrimary,
        };
      case "info":
        return {
          container: styles.infoStyle,
          circle: styles.infoIconCircleStyle,
          iconColor: theme.colors.colorsForegroundFgTertiary,
        };
      case "success":
      default:
        return {
          container: styles.successStyle,
          circle: styles.successIconCircleStyle,
          iconColor: theme.colors.colorsForegroundFgSuccessPrimary,
        };
    }
  };

  const { container, circle, iconColor } = getContainerStyle();
  return (
    <Box sx={{ ...styles.container, ...(useBackground ? container : styles.noBackgroundStyle) } as SxProps}>
      <Box sx={styles.iconContainer}>
        <Box sx={{ ...styles.iconCircle1, ...circle } as SxProps} />
        <Box sx={{ ...styles.iconCircle2, ...circle } as SxProps} />
        <AlertCircle size="16" color={iconColor} />
      </Box>
      <Box sx={styles.messageContainer}>
        {title && <Typography sx={styles.title}>{title}</Typography>}
        {message && <Typography sx={styles.messageStyle}>{message}</Typography>}
      </Box>
    </Box>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    container: {
      display: "flex",
      padding: spacing.spacingXl,
      borderRadius: radius.radiusXl,
      border: `1px solid`,
      flexDirection: "row",
    } as SxProps,
    noBackgroundStyle: {
      backgroundColor: "transparent",
      border: "none",
    } as SxProps,
    errorStyle: {
      backgroundColor: colors.componentColorsUtilityErrorUtilityError50,
      borderColor: colors.componentColorsUtilityErrorUtilityError300,
    } as SxProps,
    warningStyle: {
      backgroundColor: colors.componentColorsUtilityWarningUtilityWarning50,
      borderColor: colors.componentColorsUtilityWarningUtilityWarning300,
    } as SxProps,
    successStyle: {
      backgroundColor: colors.componentColorsUtilitySuccessUtilitySuccess50,
      borderColor: colors.componentColorsUtilitySuccessUtilitySuccess300,
    } as SxProps,
    infoStyle: {
      backgroundColor: colors.componentColorsUtilityGrayUtilityGray50,
      borderColor: colors.componentColorsUtilityGrayUtilityGray300,
    } as SxProps,
    iconContainer: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      width: 16,
      height: 16,
    } as SxProps,
    iconCircle1: {
      display: "flex",
      position: "absolute",
      width: 34,
      height: 34,
      border: `2px solid`,
      borderRadius: radius.radiusFull,
      opacity: 0.1,
    } as SxProps,
    iconCircle2: {
      display: "flex",
      position: "absolute",
      width: 24,
      height: 24,
      border: `2px solid`,
      borderRadius: radius.radiusFull,
      opacity: 0.3,
    } as SxProps,
    errorIconCircleStyle: { borderColor: colors.colorsForegroundFgErrorPrimary },
    warningIconCircleStyle: { borderColor: colors.colorsForegroundFgWarningPrimary },
    infoIconCircleStyle: { borderColor: colors.colorsForegroundFgTertiary },
    successIconCircleStyle: {
      borderColor: colors.colorsForegroundFgSuccessPrimary,
    } as SxProps,
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: spacing.spacingXl,
      flex: 1,
    } as SxProps,
    title: {
      ...typography[".text-sm-semibold"],
      color: colors.colorsTextTextSecondary,
    } as SxProps,
    messageStyle: {
      ...typography[".text-sm-regular"],
      color: colors.colorsTextTextTertiary,
    } as SxProps,
  };
};
