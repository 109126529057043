import { Box } from "@mui/material";
import React from "react";
import MainTitle from "../MainTitle/MainTitle";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import AboutSection from "../AboutSection/AboutSection";
import { AboutSectionPartsTypes } from "../../../../../../store/services/models/installations/installationsCommon";

export default function AboutColumn({ parts }: { parts: Array<any> }) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.infoDetailsPage.about",
  });
  const setTitle = (type: AboutSectionPartsTypes) => {
    switch (type) {
      case AboutSectionPartsTypes.INDOOR_UNIT:
        return t("indorUnitTitle");
      case AboutSectionPartsTypes.CCV_CONTROL:
        return t("indorUnitControlModuleTitle");
      case AboutSectionPartsTypes.DISPLAY_CONTROL:
        return t("indorUnitUICModuleTitle");
      case AboutSectionPartsTypes.OUTDOOR_UNIT:
        return t("outdoorUnitTitle");
      default:
        return "";
    }
  };
  return (
    <Box
      sx={{
        height: "100%",
        maxWidth: "610px",
        width: "50%",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: "rgba(0,0,0,0.2)",
          minHeight: "24px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: theme.colors.colorsTextTextQuaternary,
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "415px",
          pl: theme.spacing.spacing4xl,
        }}
      >
        <MainTitle
          text={t("mainTitle")}
          sx={{ mb: theme.spacing.spacing2xl }}
        />
        {parts.map((part, index) => {
          return (
            <AboutSection
              key={index}
              title={setTitle(part.type)}
              type={part.type}
              model={part.model}
              modelNumber={part.modelNumber}
              serialNumber={part.serialNumber}
              hardwareVersion={part.hardwareVersion}
              softwareVersion={part.softwareVersion}
              eeprom={part.eeprom}
            />
          );
        })}
      </Box>
    </Box>
  );
}
