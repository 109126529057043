import { useEffect, useState } from "react";
import useTheme from "../../theme/hooks/useTheme";
import { SxProps } from "@mui/material";

export default function useSimpleButtonStyles(type: "Primary" | "Secondary") {
  const { colors, radius, spacing, typography } = useTheme();
  const [buttonColors, setButtonColors] = useState<IButtonColors>({
    backgroundColor: "",
    borderColor: "",
    textColor: "",
    hoverColor: "",
    boxShadowActive: "",
  });

  useEffect(() => {
    if (type) {
      switch (type) {
        case "Primary":
          setButtonColors({
            backgroundColor:
              colors.componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
            borderColor:
              colors.componentColorsComponentsButtonsPrimaryButtonPrimaryBorder,
            textColor:
              colors.componentColorsComponentsButtonsPrimaryButtonPrimaryFg,
            hoverColor:
              colors.componentColorsComponentsButtonsPrimaryButtonPrimaryBgHover,
            boxShadowActive:
              "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(149, 193, 31, 0.24)",
          });
          break;
        case "Secondary":
          setButtonColors({
            backgroundColor:
              colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
            borderColor:
              colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder,
            textColor:
              colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            hoverColor:
              colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBgHover,
            boxShadowActive:
              "0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px rgba(184, 191, 166, 0.24)",
          });
          break;
        default:
          break;
      }
    }
  }, [type]);

  const styles: Record<string, SxProps> = {
    wrapper: {
      border: `1px solid ${buttonColors.borderColor}`,
      display: "flex",
      justifyContent: "center",
      borderRadius: radius.radiusMd,
      p: `.625rem ${spacing.spacingXl}`,
      width: "100%",
      cursor: "pointer",
      bgcolor: buttonColors.backgroundColor,
      transition: "background-color .3s ease, box-shadow 0.1s linear",
      "&:hover": {
        bgcolor: buttonColors.hoverColor,
      },
      "&:active": {
        boxShadow: buttonColors.boxShadowActive,
      },
      "&:focus-visible": {
        outline: "none",
        boxShadow: buttonColors.boxShadowActive,
      },
      "&:disabled": {
        pointerEvents: "none",
      },
    },
    text: {
      ...typography[".text-md-semibold"],
      color: buttonColors.textColor,
    },
  };
  return { styles };
}
