import { InputLabel, SxProps, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import AddressAutocompleteComponent, {
  AddressFormats,
} from "../../components/AddressAutocompleteComponent/AddressAutocompleteComponent";
import { OrganizationItemResponse } from "../../store/services/models/organizations/organizations";

export interface IUpdatedAddressDataParameters {
  address: string;
  isValidAddress: boolean | undefined;
}

export interface IEditMyOrganizationFormProps {
  organizationToEdit: OrganizationItemResponse;
  setMyOrganizationUpdatedAddressData: ({
    address,
    isValidAddress,
  }: IUpdatedAddressDataParameters) => void;
  setMyOrganizationUpdatedPhoneData: (newPhone: string) => void;
}
export default function EditMyOrganizationForm({
  organizationToEdit,
  setMyOrganizationUpdatedAddressData,
  setMyOrganizationUpdatedPhoneData,
}: IEditMyOrganizationFormProps) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.company_organizations",
  });
  const theme = useTheme();

  const styles: Record<string, SxProps> = {
    inputLabel: {
      color: theme.colors.colorsTextTextSecondary,
      fontWeight: 500,
      mt: theme.spacing.spacingLg,
    },
    textField: {
      margin: 0,
      "& .MuiInputBase-root": {
        height: 44,
        padding: "0 15px",
        input: {
          padding: 0,
          ":placeholder-shown": {
            color: theme.colors.colorsTextTextPlaceholder,
          },
        },
      },
      "& .MuiFormHelperText-root": {
        ml: 0,
      },
    },
  };

  const [location, setLocation] = useState<string | null>(
    organizationToEdit.address
  );
  const [phone, setPhone] = useState<string | null>();

  useEffect(() => {
    setLocation(organizationToEdit.address);
    setMyOrganizationUpdatedAddressData({
      address: organizationToEdit.address,
      isValidAddress: false,
    });
  }, [organizationToEdit.address]);

  useEffect(() => {
    setPhone(organizationToEdit.phone);
    setMyOrganizationUpdatedPhoneData(organizationToEdit.phone);
  }, [organizationToEdit.phone]);

  return (
    <>
      <InputLabel shrink sx={styles.inputLabel}>
        {t("edit_modal.address_label")}
      </InputLabel>
      <AddressAutocompleteComponent
        onAddressSelect={(address, _country, isValidAddress) => {
          setLocation(address);
          setMyOrganizationUpdatedAddressData({ address, isValidAddress });
        }}
        address={location}
        placeholder={t("edit_modal.address_placeholder")}
        addressFormat={AddressFormats.FullAddress}
      />

      <InputLabel shrink sx={styles.inputLabel}>
        {t("edit_modal.phone_label")}
      </InputLabel>
      <TextField
        sx={styles.textField}
        defaultValue={phone}
        autoFocus
        margin="dense"
        id="myOrganizationPhone"
        placeholder={t("edit_modal.phone_placeholder")}
        error={false}
        helperText={""}
        type="phone"
        fullWidth
        onChange={(e) => {
          setPhone(e.target.value);
          setMyOrganizationUpdatedPhoneData(e.target.value);
        }}
      />
    </>
  );
}
