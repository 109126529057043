import { IUseInstallationInstallerZoneConditionsAndValuesFromEnumsParameters } from "../../types";

export default function useInstallationInstallerZoneConditionsAndValuesFromEnums({
  zoneKeys,
  isInstallerZones = false,
  zonesCountData,
}: IUseInstallationInstallerZoneConditionsAndValuesFromEnumsParameters) {
  const showZoneActiveSwitcher: boolean = Boolean(
    isInstallerZones && zoneKeys.zoneNumber !== "1"
  );
  const showZoneContent: boolean = Boolean(
    zoneKeys.zoneNumber === "1" ||
      (zoneKeys.zoneNumber !== "1" && !isInstallerZones) ||
      (isInstallerZones && zonesCountData > 1 && zoneKeys.zoneNumber !== "1")
  );
  return { showZoneActiveSwitcher, showZoneContent };
}
