import React from "react";
import useTheme from "../../theme/hooks/useTheme";
import { Box, List } from "@mui/material";
import SimpleSideMenuItem from "./SimpleSideMenuItem";

export interface ISideMenuItem {
  label: string;
  href: string;
  icon?: any;
  hide?: boolean;
}

export interface ISideMenuProps {
  menuItems: Array<ISideMenuItem>;
}

export default function SimpleSideMenu({ menuItems }: ISideMenuProps) {
  const theme = useTheme();

  return (
    <Box>
      <List sx={{ padding: `0 ${theme.spacing.spacingMd}` }}>
        {menuItems.map(
          (menuItem, index) =>
            !menuItem.hide && (
              <SimpleSideMenuItem
                key={`${index}_menuItem`}
                menuItem={menuItem}
              />
            )
        )}
      </List>
    </Box>
  );
}
