import { useTranslation } from "react-i18next";
import {
  IControllerDifferencesObject,
  IInstallationZonesDifferences,
} from "../../types";
import ArrowIcon from "../../../../components/Differences/components/ArrowIcon/ArrowIcon";

export default function useDefaultZoneDifferencesConditionsAndValuesForDisplay(
  differences: IInstallationZonesDifferences | null
) {
  const { t: diffT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones.zoneDifferences",
  });

  const showMainChangesSection: boolean = !!differences?.preferredRoomTemp;

  const showFlowTempHeatingSection: boolean = !!(
    differences?.heatingCurve ||
    differences?.heatingController ||
    differences?.preferredFixedFlowHeatingTemp
  );

  const showFlowTempCoolingSection: boolean = !!(
    differences?.coolingCurve ||
    differences?.coolingController ||
    differences?.preferredFixedFlowCoolingTemp
  );

  //titles

  const flowTempHeatingTitle: string = `${diffT(
    "titles.flowTempSetPoint"
  )} - ${diffT("titles.heating")}`;

  const flowTempCoolingTitle: string = `${diffT(
    "titles.flowTempSetPoint"
  )} - ${diffT("titles.cooling")}`;

  //labels

  const desiredRoomTempLabel: string = diffT("labels.desiredRoomTemp");

  const flowTempLabel: string = diffT("labels.flowTemperature");

  const outdoorTempLabel: string = diffT("labels.outdoorTemperature");

  const fixedFlowTempLabel: string = diffT("labels.fixedFlowTemperature");

  //values
  const buildSpecificValueForFlowTempController = (
    controllerChanges: IControllerDifferencesObject | undefined
  ) => {
    if (!controllerChanges) {
      return null;
    }
    return (
      <>
        {diffT(`specificValues.${controllerChanges.defaultValue}`)}
        <ArrowIcon />
        {diffT(`specificValues.${controllerChanges.changedValue}`)}
      </>
    );
  };

  return {
    flowTempHeatingTitle,
    flowTempCoolingTitle,

    desiredRoomTempLabel,
    flowTempLabel,
    outdoorTempLabel,
    fixedFlowTempLabel,

    showMainChangesSection,
    showFlowTempHeatingSection,
    showFlowTempCoolingSection,

    buildSpecificValueForFlowTempController,
  };
}
