import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AlertCircle } from "untitledui-js-base";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Button, ButtonType } from "../../../../../../components/Button/Button";

export interface IUnpairModalProps {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export default function UnpairModal({
  isOpen,
  onOk,
  onCancel,
}: IUnpairModalProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginBottom: theme.spacing.spacing4xl,
          padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
        }}
      >
        <Box
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor: theme.colors.colorsBackgroundBgErrorPrimary,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: theme.spacing.spacingXl,
          }}
        >
          <AlertCircle
            size="24"
            style={{
              color: theme.colors.colorsBackgroundBgErrorSolid,
            }}
            strokeWidth={2}
          />
        </Box>
        <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
          {t("pages.installationSettings.unpairModal.title")}
        </Typography>
        <Typography fontSize={14} color={theme.colors.colorsTextTextTertiary}>
          {t("pages.installationSettings.unpairModal.description")}
        </Typography>
      </DialogTitle>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t("actions.cancel")}`}
          onClick={onCancel}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={`${t("pages.installationSettings.unpairModal.confirmButtonText")}`}
          onClick={onOk}
          buttonType={ButtonType.ErrorPrimary}
          sx={{
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
