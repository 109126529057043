import React from "react";
import useUserSettings from "./hooks/useUserSettings";
import { Box } from "@mui/material";
import SubMenu from "../../components/SubMenu/SubMenu";
import { Navigate, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import QuietModeTab from "./SubTabs/QuietModeTab/QuietModeTab";
import SeasonTab from "./SubTabs/SeasonTab/SeasonTab";
import UserAppPairingTab from "./SubTabs/UserAppPairingTab/UserAppPairingTab";

interface IUserSettingsProps {
  baseUrl: string;
  isInstallationOffline?: boolean;
}

export default function UserSettings({
  baseUrl,
  isInstallationOffline,
}: IUserSettingsProps) {
  const { getSubMenuItems } = useUserSettings({ baseUrl });
  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <SubMenu menuItems={getSubMenuItems()} />
      <Box sx={{ width: "100%" }}>
        <Routes>
          <Route
            index
            element={<Navigate to={`${baseUrl}/quiet-mode`} replace />}
          />
          <Route
            path="quiet-mode"
            element={
              <QuietModeTab isInstallationOffline={isInstallationOffline} />
            }
          />
          <Route
            path="season"
            element={
              <SeasonTab isInstallationOffline={isInstallationOffline} />
            }
          />
          <Route
            path="user-app-pairing"
            element={
              <UserAppPairingTab
                isInstallationOffline={isInstallationOffline}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
}
