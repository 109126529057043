import { HomeSmile, Tool02, UserEdit } from "untitledui-js-base";
import useTheme from "../../../../../theme/hooks/useTheme";

export default function useTabsIcons() {
  const { colors } = useTheme();
  const zonesTabIcon: React.JSX.Element = (
    <HomeSmile
      size="24"
      style={{
        color: colors.colorsForegroundFgWarningPrimary,
      }}
      strokeWidth={2}
    />
  );

  const userSettingsTabIcon: React.JSX.Element = (
    <UserEdit
      size="24"
      style={{
        color: colors.colorsForegroundFgWarningPrimary,
      }}
      strokeWidth={2}
    />
  );

  const additionalHeatingSourceThresholdsIcon: React.JSX.Element = (
    <Tool02
      size="24"
      style={{
        color: colors.colorsForegroundFgWarningPrimary,
      }}
      strokeWidth={2}
    />
  );
  return {
    zonesTabIcon,
    userSettingsTabIcon,
    additionalHeatingSourceThresholdsIcon,
  };
}
