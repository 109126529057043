import { useTranslation } from "react-i18next";
import {
  IIndoorSensorDifferencesObject,
  IInstallationInstallerZonesDifferences,
  IModeDifferencesObject,
} from "../../types";
import ArrowIcon from "../../../../components/Differences/components/ArrowIcon/ArrowIcon";
import { IBaseDifferencesObject } from "../../../../types/commonTypes";

export default function useInstallerZoneDifferencesConditionsAndValuesForDisplay(
  zoneCountDifferences: IBaseDifferencesObject | null,
  installerDifferences: IInstallationInstallerZonesDifferences | null
) {
  const { t: diffT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones.zoneDifferences",
  });

  const showZoneStateChangeSection: boolean = Boolean(zoneCountDifferences);

  const showMixingValveTurningTimeChange: boolean = Boolean(
    installerDifferences?.mixingValveTurningTime
  );

  const showZoneModeChange: boolean = Boolean(installerDifferences?.mode);

  const showFlowTempHeatingLimitsSection: boolean = Boolean(
    installerDifferences?.minFlowHeatTemp ||
      installerDifferences?.maxFlowHeatTemp
  );

  const showFlowTempCoolingLimitsSection: boolean = Boolean(
    installerDifferences?.minFlowCoolTemp ||
      installerDifferences?.maxFlowCoolTemp
  );

  const showIndoorSensorChange: boolean = Boolean(
    installerDifferences?.indoorSensor
  );

  //titles

  const flowTempHeatingLimitsTitle: string = `${diffT(
    "titles.flowTempLimits"
  )} - ${diffT("titles.heating")}`;

  const flowTempCoolingLimitsTitle: string = `${diffT(
    "titles.flowTempLimits"
  )} - ${diffT("titles.cooling")}`;

  //installer labels
  const zoneStateLabel: string = diffT("labels.zoneState");

  const mixingValveTurningTimeLabel: string = diffT(
    "labels.mixingValveTurningTime"
  );

  const zoneModeLabel: string = diffT("labels.zoneHeatingCooling");

  const heatingMinLimitLabel: string = `${diffT("labels.heating")} - ${diffT(
    "labels.minLimit"
  )} `;

  const heatingMaxLimitLabel: string = `${diffT("labels.heating")} - ${diffT(
    "labels.maxLimit"
  )} `;

  const coolingMinLimitLabel: string = `${diffT("labels.cooling")} - ${diffT(
    "labels.minLimit"
  )} `;

  const coolingMaxLimitLabel: string = `${diffT("labels.cooling")} - ${diffT(
    "labels.maxLimit"
  )} `;

  const indoorSensorLabel: string = diffT("labels.indoorSensor");

  //values

  const buildSpecificValueForZoneState = (
    zonesCountChanges: IBaseDifferencesObject | null
  ) => {
    if (!zonesCountChanges) {
      return null;
    }
    const checkValue = (value: number) => {
      switch (value) {
        case 1:
          return diffT("specificValues.deactivate");
        case 2:
          return diffT("specificValues.activate");
        default:
          diffT("specificValues.deactivate");
          break;
      }
    };
    return (
      <>
        {checkValue(zonesCountChanges.defaultValue!.value)}
        <ArrowIcon />
        {checkValue(zonesCountChanges.changedValue.value)}
      </>
    );
  };

  const buildSpecificValueForZoneMode = (
    zoneModeChanges: IModeDifferencesObject | undefined
  ) => {
    if (!zoneModeChanges) {
      return null;
    }
    return (
      <>
        {diffT(`specificValues.${zoneModeChanges.defaultValue}`)}
        <ArrowIcon />
        {diffT(`specificValues.${zoneModeChanges.changedValue}`)}
      </>
    );
  };

  const buildSpecificValueForIndoorSensor = (
    indoorSensorChanges: IIndoorSensorDifferencesObject | undefined
  ) => {
    if (!indoorSensorChanges) {
      return null;
    }
    return (
      <>
        {diffT(`specificValues.${indoorSensorChanges.defaultValue}`)}
        <ArrowIcon />
        {diffT(`specificValues.${indoorSensorChanges.changedValue}`)}
      </>
    );
  };

  return {
    flowTempHeatingLimitsTitle,
    flowTempCoolingLimitsTitle,

    zoneStateLabel,
    mixingValveTurningTimeLabel,
    zoneModeLabel,
    heatingMinLimitLabel,
    heatingMaxLimitLabel,
    coolingMinLimitLabel,
    coolingMaxLimitLabel,
    indoorSensorLabel,

    showZoneStateChangeSection,
    showMixingValveTurningTimeChange,
    showZoneModeChange,
    showFlowTempHeatingLimitsSection,
    showFlowTempCoolingLimitsSection,
    showIndoorSensorChange,

    buildSpecificValueForZoneState,
    buildSpecificValueForZoneMode,
    buildSpecificValueForIndoorSensor,
  };
}
