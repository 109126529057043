import { Box, SxProps, Typography } from "@mui/material";
import React, { useMemo } from "react";
import useTheme from "../../../../../../../../../theme/hooks/useTheme";

import NumberInput from "../../../../../components/NumberInput/NumberInput";

import { ICurveRowProps } from "../../../types";

export default function CurveRow({
  title,
  flowTempInputLabel,
  outdorTempInputLabel,
  isInstallationOffline = false,
  pointValue,
  limitValuesForOutdoorTemp,
  onFlowTempChange,
}: ICurveRowProps) {
  const theme = useTheme();
  const { colors, typography } = theme;

  const styles: Record<string, SxProps> = useMemo(
    () => ({
      wrapper: {
        display: "flex",
        alignItems: "center",
        width: "408px",
        justifyContent: "space-between",
      },
      pointTitle: {
        ...typography[".text-sm-medium"],
        color: colors.colorsTextTextTertiary,
      },
    }),
    [typography, colors]
  );
  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.pointTitle}>{title}</Typography>
      <NumberInput
        initialValue={pointValue.outdoorTemp.value}
        min={limitValuesForOutdoorTemp.min}
        max={limitValuesForOutdoorTemp.max}
        changeValue={(newVal) => onFlowTempChange(newVal, "outdoorTemp")}
        decimalPlaces={1}
        step={0.5}
        labelColor={theme.colors.colorsTextTextQuaternary}
        label={outdorTempInputLabel}
        disabled={isInstallationOffline}
      />
      <NumberInput
        initialValue={pointValue.flowTemp.value}
        min={pointValue.flowTemp.min}
        max={pointValue.flowTemp.max}
        changeValue={(newVal) => onFlowTempChange(newVal, "flowTemp")}
        decimalPlaces={1}
        step={0.5}
        labelColor={theme.colors.colorsTextTextQuaternary}
        label={flowTempInputLabel}
        disabled={isInstallationOffline}
      />
    </Box>
  );
}
