import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ISectionData,
  ISectionItem,
  InfoSectionComponent,
} from "./InfoSectionComponent";
import { defineSourceAndUnit } from "../helpers/defineSourceAndUnit";

export interface IGeneralSection {
  location?: string;
  dimensionWinterOutdoorTemperature?: string;
  avgOutdoorTemperature?: string;
  heatLimitOutdoorTemperature?: string;
  powerNeedsAtDWOT?: string;
  systemInletTempAtDWOT?: string;
}

export interface IHeatingSection {
  heatingNumberOfHeatPumps: string;
  fixedCondensation?: string | undefined;
}

export interface IDHWSection {
  numberOfHeatPumpsForDHW?: string;
  energyDemandPerDay?: string;
  waterTemperature?: string;
}

export interface IEnergyPricingSection {
  currentEnergySource?: string;
  currentEnergyPrice?: string;
  heatPumpElectricityPrice?: string;
  additiveEnergySource?: string;
  additiveEnergyPrice?: string;
}

export interface IConfigurationBlockProps {
  heatPumpModel: string;
  isDHWSectionShown: boolean;
  isCostsSectionShown: boolean;
  currency: string;
  generalSection?: IGeneralSection;
  heatingSection?: IHeatingSection;
  domesticHotWaterSection?: IDHWSection;
  energyPricingSection?: IEnergyPricingSection;
}

const ConfigurationBlock = ({
  heatPumpModel,
  isDHWSectionShown,
  isCostsSectionShown,
  generalSection,
  heatingSection,
  domesticHotWaterSection,
  energyPricingSection,
}: IConfigurationBlockProps) => {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const [configurationTitle, setConfigurationTitle] = useState<string>("");

  //sections data
  const [generalSectionData, setGeneralSectionData] = useState<ISectionData>();
  const [heatingSectionData, setHeatingSectionData] = useState<ISectionData>();
  const [DHWSectionData, setDHWSectionData] = useState<ISectionData>();
  const [enetgyPricingSectionData, setEnergyPricingSectionData] =
    useState<ISectionData>();

  useEffect(() => {
    if (isDHWSectionShown) {
      setConfigurationTitle(
        `${t("pages.savingCalculator.configurationBlock.heating")} + ${t(
          "pages.savingCalculator.configurationBlock.domesticHotWater"
        )}`
      );
    } else {
      setConfigurationTitle(
        `${t("pages.savingCalculator.configurationBlock.heating")}`
      );
    }
  }, [isDHWSectionShown]);

  useEffect(() => {
    if (generalSection) {
      setGeneralSectionData(
        mapDataForSection(
          generalSection,
          t("pages.savingCalculator.configurationBlock.general")
        )
      );
    }
  }, [generalSection]);

  useEffect(() => {
    if (heatingSection) {
      setHeatingSectionData(
        mapDataForSection(
          heatingSection,
          t("pages.savingCalculator.configurationBlock.heating")
        )
      );
    }
  }, [heatingSection]);

  useEffect(() => {
    if (domesticHotWaterSection) {
      setDHWSectionData(
        mapDataForSection(
          domesticHotWaterSection,
          t("pages.savingCalculator.configurationBlock.domesticHotWater")
        )
      );
    }
  }, [domesticHotWaterSection]);

  useEffect(() => {
    if (energyPricingSection) {
      let energyPricingItems: Array<ISectionItem> = [];
      const currSource = defineSourceAndUnit(
        energyPricingSection.currentEnergySource
      ).source;
      const currUnit = defineSourceAndUnit(
        energyPricingSection.currentEnergySource
      ).unit;
      const additiveSource = defineSourceAndUnit(
        energyPricingSection.additiveEnergySource
      ).source;
      const additiveUnit = defineSourceAndUnit(
        energyPricingSection.additiveEnergySource
      ).unit;
      const approximatelyEquals = defineSourceAndUnit(
        energyPricingSection.additiveEnergySource
      ).approximatelyEquals;

      if (energyPricingSection.currentEnergySource) {
        energyPricingItems = [
          ...energyPricingItems,
          {
            title: t(
              "pages.savingCalculator.configurationBlock.currentEnergySource"
            ),
            value: t(currSource),
          },
          {
            title: t(
              "pages.savingCalculator.configurationBlock.currentEnergyPrice",
              { unit: currUnit }
            ),
            value: energyPricingSection.currentEnergyPrice as string,
          },
        ];
      }

      if (energyPricingSection.heatPumpElectricityPrice) {
        energyPricingItems.push({
          title: t(
            "pages.savingCalculator.configurationBlock.heatPumpElectricityPrice"
          ),
          value: energyPricingSection.heatPumpElectricityPrice,
        });
      }

      if (energyPricingSection.additiveEnergySource) {
        energyPricingItems.push(
          {
            title: t(
              "pages.savingCalculator.configurationBlock.additiveEnergySource"
            ),
            value: t(additiveSource),
          },
          {
            title: t(
              "pages.savingCalculator.configurationBlock.additiveEnergyPrice",
              { unit: additiveUnit }
            ),
            value: energyPricingSection.additiveEnergyPrice as string,
          }
        );
      }

      if (approximatelyEquals) {
        energyPricingItems.push({
          title: `1 ${additiveUnit} ≈`,
          value: approximatelyEquals,
        });
      }

      setEnergyPricingSectionData({
        title: t(
          "pages.savingCalculator.configurationBlock.energyPricing"
        ) as string,
        items: energyPricingItems,
      });
    }
  }, [energyPricingSection]);

  const mapDataForSection = (
    sectionValues:
      | IGeneralSection
      | IHeatingSection
      | IDHWSection
      | IEnergyPricingSection,
    title: string
  ) => {
    const mappedItemsForSection: Array<ISectionItem> = [];
    Object.entries(sectionValues).forEach(([key, value]) => {
      mappedItemsForSection.push({
        title: t(`pages.savingCalculator.configurationBlock.${key}` as any),
        value,
      });
    });

    const data: ISectionData = {
      title,
      items: mappedItemsForSection,
    };
    return data;
  };

  return (
    <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
      <Paper className="paper-data">
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            color: "#212121",
            mb: "50px",
            flexWrap: "wrap",
          }}
        >
          <Typography
            fontSize={48}
            fontWeight={"bold"}
            lineHeight={"56px"}
            mr={2}
          >
            {t("pages.savingCalculator.configurationBlock.heatPump")}
          </Typography>
          <Typography fontSize={48} lineHeight={"56px"} mr={2}>
            {heatPumpModel}
          </Typography>
          <Typography
            fontSize={40}
            lineHeight={"56px"}
            fontWeight={"light"}
            color={"#95C11F"}
          >
            {t("pages.savingCalculator.configurationBlock.energyCalculation")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", color: "#212121" }} mb={"20px"}>
          <Typography mr={"5px"} fontSize={24}>
            {t("pages.savingCalculator.configurationBlock.configuration")}
          </Typography>
          <Typography fontSize={24} fontWeight={"bold"}>
            {configurationTitle}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={isMobileOrTablet ? 12 : isCostsSectionShown ? 4 : 6}>
            <InfoSectionComponent
              title={generalSectionData?.title}
              items={generalSectionData?.items}
            />
          </Grid>
          <Grid item xs={isMobileOrTablet ? 12 : isCostsSectionShown ? 4 : 6}>
            <InfoSectionComponent
              title={heatingSectionData?.title}
              items={heatingSectionData?.items}
            />
            {isDHWSectionShown && (
              <InfoSectionComponent
                title={DHWSectionData?.title}
                items={DHWSectionData?.items}
              />
            )}
          </Grid>
          {isCostsSectionShown && (
            <Grid item xs={isMobileOrTablet ? 12 : 4}>
              <InfoSectionComponent
                title={enetgyPricingSectionData?.title}
                items={enetgyPricingSectionData?.items}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ConfigurationBlock;
