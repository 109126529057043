import { useTranslation } from "react-i18next";

export default function useCurveSectionConditionsAndValuesForDisplay(
  isCooling: boolean
) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.zones",
  });

  const mainTitle = t(
    `fieldsLabels.${isCooling ? "coolingCurve" : "heatingCurve"}`
  );

  const dependenciesDescriptionText = t("dependenciesMessage");

  const pointsTitle = t("fieldsLabels.point");

  const flowTempInputLabel = t("fieldsLabels.flowTemperature");

  const outdoorTempInputLabel = t("fieldsLabels.outdoorTempereature");

  return {
    mainTitle,
    dependenciesDescriptionText,
    pointsTitle,
    flowTempInputLabel,
    outdoorTempInputLabel,
  };
}
