import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { UsersAPI } from "../../api/UsersAPI";
import { ChevronDown, Mail01, Send01 } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";
import { UserRolesEnum } from "../../api/responses/UserListResponse";
import { Button, ButtonType } from "../../components/Button/Button";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";
import { OrganizationsAPI } from "../../api/OrganizationsAPI";

export default function AddUserDialog({
  dialogOpen,
  setDialogOpen,
  onAddCallback,
  organizationName,
  isSuccessView,
}: AddUserDialogProperties) {
  const theme = useTheme();
  const showSnackbar = useSnackbar();
  const { t } = useTranslation("cloud_ui");
  const navigate = useNavigate();
  const usersApi = new UsersAPI(navigate);
  const organizationsApi = new OrganizationsAPI(navigate);

  const roles = [
    {
      value: UserRolesEnum.ORG_ADMIN,
      label: t("pages.users.roles.admin"),
    },
    { value: UserRolesEnum.ORG_USER, label: t("pages.users.roles.user") },
  ];

  const [inviteAPICallInProgress, setInviteAPICallInProgress] =
    useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [role, setRole] = useState<UserRolesEnum>(UserRolesEnum.ORG_USER);
  const [successView, setSuccessView] = useState<boolean>(isSuccessView);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);

  useEffect(() => {
    setSuccessView(isSuccessView);
  }, [isSuccessView]);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (value: string) => {
    setUserEmail(value);
    if (validateEmail(value)) {
      setEmailError("");
    }
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
    if (!validateEmail(userEmail) && userEmail) {
      setEmailError(`${t("pages.users.inviteUserEmailInputError")}`);
    } else {
      setEmailError("");
    }
  };

  const handleRoleChange = (event: any) => {
    setRole(event.target?.value as UserRolesEnum);
  };

  const handleAddDialogClose = () => {
    setDialogOpen(false);
    cleanInput();
  };

  const cleanInput = () => {
    setSuccessView(false);
    setUserEmail("");
    setEmailError("");
    setEmailTouched(false);
    setRole(UserRolesEnum.ORG_USER);
  };

  const onError = (errorText: string) => {
    setInviteAPICallInProgress(false);
    showSnackbar(errorText, {
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      autoHideDuration: 3000,
      severity: "error",
    });
  };

  const sendUserInvite = () => {
    setInviteAPICallInProgress(true);

    const location = window.location;
    let userValidationUrl = `${location.protocol}//${location.host}/users/verify`;
    usersApi
      .addUser(userEmail, role, userValidationUrl)
      .then(() => {
        setSuccessView(true);
        setInviteAPICallInProgress(false);
        onAddCallback();
      })
      .catch((err) => {
        const beError = err?.response?.data?.message;
        if (beError?.includes("User already belongs to organization")) {
          const index = beError.indexOf(":");
          const id = beError.substring(index + 1).trim();
          organizationsApi
            .getOrganization(id)
            .then((res: any) => {
              onError(
                t("pages.users.existingUserError", {
                  organizationName: res.data.name,
                })
              );
            })
            .catch((err) => {
              onError(t("pages.users.inviteError"));
            });
        } else {
          onError(t("pages.users.inviteError"));
        }
      });
  };

  if (successView) {
    return (
      <Dialog
        open={dialogOpen}
        fullWidth={true}
        sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            marginBottom: theme.spacing.spacing4xl,
            padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              backgroundColor:
                theme.colors.componentColorsUtilitySuccessUtilitySuccess100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacingXl,
            }}
          >
            <Send01
              size="24"
              style={{
                color:
                  theme.colors.componentColorsUtilitySuccessUtilitySuccess600,
              }}
              strokeWidth={2}
            />
          </Box>
          <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
            {t("pages.users.invitationSent")}
          </Typography>
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
          }}
        >
          <Button
            label={`${t("pages.users.inviteUserInviteMoreButtonText")}`}
            onClick={cleanInput}
            buttonType={ButtonType.SecondaryGray}
            sx={{
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
          <Button
            label={`${t("actions.done")}`}
            onClick={() => {
              handleAddDialogClose();
            }}
            sx={{
              ml: "0 !important",
              width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={dialogOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle sx={{ padding: `${theme.spacing.spacing3xl}` }}>
        <Typography
          color={theme.colors.colorsTextTextPrimary}
          fontSize={18}
          variant={"h5"}
        >{`${t("pages.users.inviteUser")} to ${organizationName}`}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: `0 ${theme.spacing.spacing3xl}`,
          mb: theme.spacing.spacing4xl,
        }}
      >
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
          }}
        >
          {t("pages.users.inviteUserEmailInputLabel")}
        </InputLabel>
        <Box
          sx={{
            position: "relative",
            mb: theme.spacing.spacingLg,
          }}
        >
          <Mail01
            size="16"
            style={{
              marginRight: "5px",
              color: theme.colors.colorsTextTextPlaceholder,
              opacity: ".4",
              position: "absolute",
              top: "15px",
              left: "10px",
            }}
            strokeWidth={2}
          />
          <TextField
            sx={{
              margin: 0,
              "& .MuiInputBase-root": {
                height: 44,
                padding: "0 35px",
                input: {
                  padding: 0,
                  ":placeholder-shown": {
                    color: theme.colors.colorsTextTextPlaceholder,
                  },
                },
              },
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
            value={userEmail}
            autoFocus
            margin="dense"
            id="name"
            placeholder={`${t("pages.users.inviteUserEmailInputPlaceholder")}`}
            error={Boolean(emailError)}
            helperText={emailError}
            type="email"
            fullWidth
            onChange={(e) => handleEmailChange(e.target.value.trim())}
            onBlur={handleEmailBlur}
          />
        </Box>
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
          }}
        >
          {t("pages.users.inviteUserRoleSelectLabel")}
        </InputLabel>
        <FormControl fullWidth variant="outlined">
          <Select
            displayEmpty
            value={role}
            onChange={handleRoleChange}
            IconComponent={ChevronDown}
            sx={{
              "& .MuiSelect-select": { padding: "0 10px" },
              "& .MuiSelect-icon": { top: "11px" },
              height: 44,
              color: theme.colors.colorsTextTextPlaceholder,
              fontSize: "16px",
            }}
            renderValue={(selected) => {
              return roles.find((option) => option.value === selected)?.label;
            }}
          >
            {roles.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontSize: "16px",
                  color: theme.colors.colorsTextTextPlaceholder,
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t("pages.users.inviteUserCancelButtonText")}`}
          onClick={handleAddDialogClose}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={`${t("pages.users.inviteUserSendInviteButtonText")}`}
          disabled={!userEmail || Boolean(emailError)}
          onClick={inviteAPICallInProgress ? () => {} : sendUserInvite}
          loadingInProgress={inviteAPICallInProgress}
          sx={{
            pointerEvents: inviteAPICallInProgress ? "none" : "initial",
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export type AddUserDialogProperties = {
  dialogOpen: boolean;
  organizationName?: string;
  setDialogOpen: (open: boolean) => void;
  onAddCallback: () => void;
  isSuccessView: boolean;
};
