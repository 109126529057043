import { useContext, useMemo } from "react";
import InstallationZonesContext from "../../context/InstallationZonesContext";
import {
  IPointValues,
  PointKey,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import { ILimitValuesForOutdoorTemp } from "../../types";

export default function useCurveSection(isCooling: boolean) {
  const { zoneFormData, isInstallationOffline, updateTempInCurve } = useContext(
    InstallationZonesContext
  );
  const curve: { [key in PointKey]?: IPointValues } | undefined = isCooling
    ? zoneFormData?.coolingCurve
    : zoneFormData?.heatingCurve;

  const limitValuesForOtdoorTemp: ILimitValuesForOutdoorTemp = useMemo(() => {
    if (!curve) return { min: Infinity, max: -Infinity };

    const outdoorTemps = Object.values(curve)
      .map((point) => point?.outdoorTemp)
      .filter(Boolean);

    return {
      min: Math.min(...outdoorTemps.map((t) => t?.min ?? Infinity)),
      max: Math.max(...outdoorTemps.map((t) => t?.max ?? -Infinity)),
    };
  }, [curve]);

  return {
    zoneFormData,
    curve,
    isInstallationOffline,
    limitValuesForOtdoorTemp,
    updateTempInCurve,
  };
}
