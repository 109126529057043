import React from "react";
import useTheme, {
  ESThemeType,
} from "../../../../../../../../../../../../theme/hooks/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckboxWithLabel from "../../../../../../../../../../../../components/CheckboxWithLabel/CheckboxWithLabel";
import SimpleDropdown from "../../../../../../../../components/SimpleDropdown/SimpleDropdown";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonType,
} from "../../../../../../../../../../../../components/Button/Button";
import useQuietModeScheduleRow from "./hooks/useQuietModeScheduleRow";

export interface IQuietModeScheduleRowProps {
  startHourFormData: number | undefined;
  timeLengthFormData: number | undefined;
  startKey: string;
  lengthKey: string;
  label: string;
  isShowApplyForAllDays?: boolean;
}

export default function QuietModeScheduleRow({
  startHourFormData = 0,
  timeLengthFormData = 0,
  label,
  startKey,
  lengthKey,
  isShowApplyForAllDays,
}: IQuietModeScheduleRowProps) {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.userSettings.quietMode.quietModeSchedule",
  });
  const theme = useTheme();

  const {
    isDayActive,
    calculatedEndHour,
    hoursDropdownOptions,
    isInstallationOffline,
    handleCheckboxChange,
    handleStartHourChange,
    handleStopHourChange,
    onApplyForAllDays,
  } = useQuietModeScheduleRow({
    timeLengthFormData,
    startHourFormData,
    startKey,
    lengthKey,
  });

  const styles = getStyles(theme, isDayActive);

  return (
    <Box sx={styles.wrapper}>
      <CheckboxWithLabel
        checkedCondition={isDayActive}
        sx={styles.checkbox}
        onChange={handleCheckboxChange}
      />
      <Typography sx={styles.label}>{label}</Typography>
      <Box sx={styles.dropdownWrapper}>
        <SimpleDropdown
          sx={styles.dropdown}
          disabled={!isDayActive || isInstallationOffline}
          label={t("start")}
          currentValue={startHourFormData}
          dropdownOptions={hoursDropdownOptions}
          onChange={handleStartHourChange}
        />
        <SimpleDropdown
          sx={styles.dropdown}
          disabled={!isDayActive || isInstallationOffline}
          label={t("stop")}
          currentValue={calculatedEndHour}
          dropdownOptions={hoursDropdownOptions}
          onChange={handleStopHourChange}
        />
        {isShowApplyForAllDays && (
          <Button
            disabled={!isDayActive || isInstallationOffline}
            buttonType={ButtonType.LinkColor}
            label={t("applyForAllDays")}
            onClick={() => {
              onApplyForAllDays(startHourFormData, timeLengthFormData);
            }}
          />
        )}
      </Box>
    </Box>
  );
}

const getStyles = (
  { colors, typography, spacing }: ESThemeType,
  isDayActive: boolean
) => ({
  wrapper: {
    maxWidth: "592px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: spacing.spacingXl,
    borderRadius: spacing.spacingXl,
    mt: spacing.spacingXl,
    border: `2px solid ${
      isDayActive
        ? colors.colorsBorderBorderBrandSolid
        : colors.colorsBorderBorderSecondary
    }`,
  },
  label: {
    ...typography[".text-sm-semibold"],
    color: colors.colorsTextTextPrimary,
  },
  checkbox: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  dropdownWrapper: {
    mt: spacing.spacingXl,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dropdown: {
    mr: spacing.spacingXl,
    "& .MuiInputBase-root": {
      minWidth: "143px",
    },
  },
  applyForAllDays: {
    ...typography[".text-sm-regular"],
    color: colors.colorsTextTextTertiary,
  },
});
