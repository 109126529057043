import { Box, SxProps, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import useTheme from "../../../../../../../theme/hooks/useTheme";

export interface ISpaceRadioGroupItem {
  title?: string;
  description?: string;
  value: any;
  icon?: ReactElement;
  disabled?: boolean;
}

export interface ISpaceRadioGroupProps {
  radioOptions: Array<ISpaceRadioGroupItem>;
  selectedValue: any;
  onChange: (value: any) => void;
}

export default function SpaceRadioGroup({
  radioOptions,
  selectedValue,
  onChange,
}: ISpaceRadioGroupProps) {
  const theme = useTheme();
  const baseRadioOptionStyles: SxProps = {
    display: "flex",
    padding: theme.spacing.spacingXl,
    border: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
    borderRadius: "12px",
    backgroundColor: "initial",
    mb: theme.spacing.spacingXl,
    maxWidth: "590px",
    cursor: "pointer",
    position: "relative",
    boxSizing: "border-box",
  };

  const selectedRadioOptionStyles: SxProps = {
    ...baseRadioOptionStyles,
    border: `2px solid ${theme.colors.colorsBorderBorderBrandSolid}`,
  };

  const iconWrapperStyles: SxProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: theme.colors.componentColorsUtilityGrayUtilityGray50,
    mr: theme.spacing.spacingLg,
  };

  const baseRadioIconStyles: SxProps = {
    width: "16px",
    minWidth: "16px",
    height: "16px",
    border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
    borderRadius: "50%",
    ml: "auto",
  };

  const disabledMaskStyles: SxProps = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.colors.colorsBackgroundBgDisabled,
    opacity: 0.3,
    borderRadius: "12px",
    border: "1px solid gray",
  };

  const selectedRadioIconStyles: SxProps = {
    ...baseRadioIconStyles,
    border: `5px solid ${theme.colors.colorsBackgroundBgBrandSolid}`,
  };

  const [selected, setSelected] = useState<any>(selectedValue);

  useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {radioOptions.map((option, index) => {
        return (
          <Box
            key={`radio-item-${index}`}
            sx={
              selected === option.value
                ? {
                    ...selectedRadioOptionStyles,
                    cursor: option.disabled ? "not-allowed" : "pointer",
                  }
                : {
                    ...baseRadioOptionStyles,
                    cursor: option.disabled ? "not-allowed" : "pointer",
                  }
            }
            component={"button"}
            disabled={option.disabled}
            onClick={() => onChange(option.value)}
          >
            {option.disabled && (
              <Box
                sx={{
                  ...disabledMaskStyles,
                }}
              />
            )}
            {option.icon && (
              <Box sx={{ ...iconWrapperStyles }}>{option.icon}</Box>
            )}
            {(option.title || option.description) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {option.title && (
                  <Typography
                    sx={{
                      ...theme.typography[".text-sm-medium"],
                      color: theme.colors.colorsTextTextSecondary,
                      textAlign: "start",
                    }}
                  >
                    {option.title}
                  </Typography>
                )}
                {option.description && (
                  <Typography
                    sx={{
                      ...theme.typography[".text-sm-regular"],
                      color: theme.colors.colorsTextTextTertiary,
                      textAlign: "start",
                    }}
                  >
                    {option.description}
                  </Typography>
                )}
              </Box>
            )}
            <Box
              sx={
                selected === option.value
                  ? { ...selectedRadioIconStyles }
                  : {
                      ...baseRadioIconStyles,
                    }
              }
            />
          </Box>
        );
      })}
    </Box>
  );
}
