import { InputLabel, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import useTextAreaStyles from "./useTextAreaStyles";

export default function TextArea({
  defaultValue,
  label,
  onChange,
  isRequired,
  error,
}: ITextAreaProps) {
  const [isTextAreaFocused, setIsTextAreaFocused] = useState<boolean>(false);

  const { styles } = useTextAreaStyles(isTextAreaFocused, !!error);

  return (
    <>
      <InputLabel sx={styles.mainLabel}>
        {isRequired && <Typography sx={styles.requiredStar}>*</Typography>}
        {label}
      </InputLabel>
      <TextField
        fullWidth
        multiline
        rows={4}
        defaultValue={defaultValue}
        sx={styles.textAreaWrapper}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => setIsTextAreaFocused(true)}
        onBlur={() => setIsTextAreaFocused(false)}
        placeholder="Message"
        error={!!error}
        helperText={error}
      ></TextField>
    </>
  );
}
