import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IItemWithSection } from "../../../../../components/DropdownScrollableWithCheckboxesAndSections/types";
import { AvailableParametersEnum } from "../types/types";
import { IStateZoneOperation } from "../../../../../store/services/models/installations/installationState";
import { ZoneOperationsKey } from "../../../../../store/services/models/installations/installationsCommon";
import {
  InstallationComissioningZoneCircuitTypeEnum,
  InstallationIndoorSensorStateEnum,
  IZoneComissioningValues,
} from "../../../../../store/services/models/installations/installationSettings";
import useRealTimeDataConditions from "../../RealTimeData/hooks/useRealTimeDataConditions";

export default function useParametersData(): {
  availableParametersForDropdown: IItemWithSection[];
} {
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.analyticsPage.availableParametersDropdown",
  });

  const {
    installationSettings,
    installationState,
    isAdditionalDhwHeatingSourceSetted,
    isAdditionalHeatingSourceStagesActive,
    isAntiLegionellaActiveState,
    isBufferTankAsInlineHeaterSetted,
    isInstallerSettingsComissioningDHWActive,
    isSGReadyActive,
  } = useRealTimeDataConditions();

  //zone operations generator (if will be more than 2)
  const generateZonesOperations = (
    stateZoneOperations:
      | {
          [key in ZoneOperationsKey]?: IStateZoneOperation;
        }
      | undefined,
    installerSettingsComissioningZoneOperations:
      | {
          [key in ZoneOperationsKey]?: IZoneComissioningValues;
        }
      | undefined,
    zonesCount: number | undefined
  ) => {
    //func for generation zoneOperation (if zones more than 2)

    const extractZoneNumber = (zoneKey: string): number | null => {
      //func to extract zoneOperation number
      const match = zoneKey.match(/zone(\d+)Operations/);
      return match ? parseInt(match[1], 10) : null;
    };

    const buildValue = (value: string) => {
      //func to build dynamic value from available params depending on zone operation number
      return AvailableParametersEnum[
        value as keyof typeof AvailableParametersEnum
      ];
    };

    //hide zoneOperations more then 1 if zoneCount > 1
    const zoneOperationForMapping:
      | {
          [key in ZoneOperationsKey]?: IStateZoneOperation;
        }
      | undefined =
      (zonesCount || 0) > 1
        ? stateZoneOperations
        : { zone1Operations: stateZoneOperations?.zone1Operations };

    if (
      zoneOperationForMapping &&
      installerSettingsComissioningZoneOperations
    ) {
      return Object.keys(zoneOperationForMapping).map((zone) => {
        const zoneNumber = extractZoneNumber(zone);

        const isMixingCircuitType =
          zoneOperationForMapping[zone as ZoneOperationsKey]?.circuitType ===
          InstallationComissioningZoneCircuitTypeEnum.MIXING;

        const isIndoorSensorSetted =
          installerSettingsComissioningZoneOperations[zone as ZoneOperationsKey]
            ?.indoorSensor !== InstallationIndoorSensorStateEnum.NONE &&
          installerSettingsComissioningZoneOperations[zone as ZoneOperationsKey]
            ?.indoorSensor !== InstallationIndoorSensorStateEnum.UNDEFINED;

        return {
          sectionTitle: t("titles.zoneOperations", {
            zoneNumber: zoneNumber,
          }),
          itemsList: [
            {
              value: buildValue(`preferredRoomTempZ${zoneNumber!}`),
              label: t("parameters.preferredRoomTemp", {
                zoneNumber: zoneNumber,
              }),
              hidden: !isIndoorSensorSetted,
            },
            {
              value: buildValue(`currentRoomTemperatureZ${zoneNumber!}`),
              label: t("parameters.currentRoomTemperature", {
                zoneNumber: zoneNumber,
              }),
              hidden: !isIndoorSensorSetted,
            },
            {
              value: buildValue(`tv${zoneNumber!}Temperature`),
              label: t("parameters.tvTemperature", {
                zoneNumber: zoneNumber,
              }),
              hidden: !isMixingCircuitType,
            },
            {
              value: buildValue(`tv${zoneNumber!}SetPointTemp`),
              label: t("parameters.tvSetPointTemp", {
                zoneNumber: zoneNumber,
              }),
              hidden: !isMixingCircuitType,
            },
            {
              value: buildValue(`waterPumpP${zoneNumber!}Status`),
              label: t("parameters.waterPumpStatus", {
                zoneNumber: zoneNumber,
              }),
            },
          ],
        };
      });
    } else {
      return [];
    }
  };

  //available params for dropdown
  const availableParametersForDropdown: IItemWithSection[] = useMemo(
    () => [
      //systemOperation section
      {
        sectionTitle: t("titles.systemOperation"),
        itemsList: [
          {
            value: AvailableParametersEnum.hpState,
            label: t("parameters.hpState"),
          },
          {
            value: AvailableParametersEnum.operationStatus,
            label: t("parameters.operationStatus"),
          },
          {
            value: AvailableParametersEnum.outdoorTemperature,
            label: t("parameters.outdoorTemperature"),
          },
        ],
      },
      //systemOperation section end
      //season section
      {
        sectionTitle: t("titles.season"),
        itemsList: [
          {
            value: AvailableParametersEnum.currentSeason,
            label: t("parameters.currentSeason"),
          },
        ],
      },
      //season section end
      //zoneOperations sections
      ...generateZonesOperations(
        installationState?.zoneOperations,
        installationSettings?.installerSettings?.commissioning?.zoneOperations,
        installationSettings?.installerSettings?.commissioning?.zonesCount
          ?.value
      ),
      //zoneOperations sections end
      //DHW section
      {
        sectionTitle: t("titles.DHW"),
        sectionHidden: !isInstallerSettingsComissioningDHWActive,
        itemsList: [
          {
            value: AvailableParametersEnum.dhwPreferredTemp,
            label: t("parameters.dhwPreferredTemp"),
          },
          {
            value: AvailableParametersEnum.dhwTankTemperatureTWD,
            label: t("parameters.dhwTankTemperatureTWD"),
          },
          {
            value: AvailableParametersEnum.dhwAntiLegionellaState,
            hidden: !isAntiLegionellaActiveState,
            label: t("parameters.dhwAntiLegionellaState"),
          },
        ],
      },
      //DHW section end
      //Modes section
      {
        sectionTitle: t("titles.modes"),
        itemsList: [
          {
            value: AvailableParametersEnum.quietMode,
            label: t("parameters.quietMode"),
          },
          {
            value: AvailableParametersEnum.defrostMode,
            label: t("parameters.defrostMode"),
          },
          {
            value: AvailableParametersEnum.vacationModeState,
            label: t("parameters.vacationModeState"),
          },
          {
            value: AvailableParametersEnum.reducedModeState,
            label: t("parameters.reducedModeState"),
          },
        ],
      },
      //Modes section end
      //Hydraulic parameters section
      {
        sectionTitle: t("titles.hydraulicParameters"),
        itemsList: [
          {
            value: AvailableParametersEnum.heatBalance,
            label: t("parameters.heatBalance"),
          },
          {
            value: AvailableParametersEnum.waterTempTHC,
            label: t("parameters.waterTempTHC"),
          },
          {
            value: AvailableParametersEnum.setPointTHC,
            label: t("parameters.setPointTHC"),
          },
          {
            value: AvailableParametersEnum.condenserOutletWaterTemperatureTUO,
            label: t("parameters.condenserOutletWaterTemperatureTUO"),
          },
          {
            value: AvailableParametersEnum.condenserInletWaterTemperatureTUI,
            label: t("parameters.condenserInletWaterTemperatureTUI"),
          },
          {
            value: AvailableParametersEnum.waterPumpP0Status,
            label: t("parameters.waterPumpP0Status"),
          },
          {
            value: AvailableParametersEnum.waterFlowRate,
            label: t("parameters.waterFlowRate"),
          },
        ],
      },
      //Hydraulic parameters section end
      //Refrigerant parameters section
      {
        sectionTitle: t("titles.refrigerantParameters"),
        itemsList: [
          {
            value: AvailableParametersEnum.compressorSpeed,
            label: t("parameters.compressorSpeed"),
          },
          {
            value: AvailableParametersEnum.requestedCompressorSpeed,
            label: t("parameters.requestedCompressorSpeed"),
          },
          {
            value: AvailableParametersEnum.highPressurePd,
            label: t("parameters.highPressurePd"),
          },
          {
            value: AvailableParametersEnum.compressorDischargeTemperatureTD,
            label: t("parameters.compressorDischargeTemperatureTD"),
          },
          {
            value: AvailableParametersEnum.lowPressurePs,
            label: t("parameters.lowPressurePs"),
          },
          {
            value: AvailableParametersEnum.compressorSuctionTemperatureTS,
            label: t("parameters.compressorSuctionTemperatureTS"),
          },
          {
            value: AvailableParametersEnum.condensingTemperatureTUP,
            label: t("parameters.condensingTemperatureTUP"),
          },
          {
            value: AvailableParametersEnum.evaporatingTemperatureTP,
            label: t("parameters.evaporatingTemperatureTP"),
          },
          {
            value: AvailableParametersEnum.eevOpeningP,
            label: t("parameters.eevOpeningP"),
          },
          {
            value: AvailableParametersEnum.fanSpeed1,
            label: t("parameters.fanSpeed1"),
          },
          {
            value: AvailableParametersEnum.fanSpeed2,
            label: t("parameters.fanSpeed2"),
          },
        ],
      },
      //Refrigerant parameters section end
      //Electric grid protection section
      {
        sectionTitle: t("titles.electricGridProtection"),
        itemsList: [
          {
            value: AvailableParametersEnum.electricGridProtectionStatus,
            label: t("parameters.electricGridProtectionStatus"),
          },
        ],
      },
      //Electric grid protection section end
      //SGReady section
      {
        sectionTitle: t("titles.sgReady"),
        sectionHidden: !isSGReadyActive,
        itemsList: [
          {
            value: AvailableParametersEnum.sgReadyStatus,
            label: t("parameters.sgReadyStatus"),
          },
        ],
      },
      //SGReady section end
      //Additional Heating Sources section
      {
        sectionTitle: t("titles.additionalHeatingSources"),
        sectionHidden:
          !isAdditionalHeatingSourceStagesActive &&
          !isAdditionalDhwHeatingSourceSetted &&
          !isBufferTankAsInlineHeaterSetted,
        itemsList: [
          {
            value: AvailableParametersEnum.additionalInlineHeatingSource,
            hidden: !isAdditionalHeatingSourceStagesActive,
            label: t("parameters.additionalInlineHeatingSource"),
          },
          {
            value: AvailableParametersEnum.additionalDhwHeatingSourceStatus,
            hidden: !isAdditionalDhwHeatingSourceSetted,
            label: t("parameters.additionalDhwHeatingSourceStatus"),
          },
          {
            value:
              AvailableParametersEnum.additionalBufferTankHeatingSourceStatus,
            hidden: !isBufferTankAsInlineHeaterSetted,
            label: t("parameters.additionalBufferTankHeatingSourceStatus"),
          },
        ],
      },
      //Additional Heating Sources section end
      //Other section
      {
        sectionTitle: t("titles.other"),
        itemsList: [
          {
            value: AvailableParametersEnum.outdoorUnitCurrent,
            label: t("parameters.outdoorUnitCurrent"),
          },
          {
            value: AvailableParametersEnum.outdoorUnitVoltage,
            label: t("parameters.outdoorUnitVoltage"),
          },
          {
            value: AvailableParametersEnum.dhwValvePosition,
            label: t("parameters.dhwValvePosition"),
          },
        ],
      },
      //Other section end
    ],
    [
      t,
      installationState?.zoneOperations,
      installationSettings?.installerSettings?.commissioning,
      isAdditionalDhwHeatingSourceSetted,
      isAdditionalHeatingSourceStagesActive,
      isAntiLegionellaActiveState,
      isBufferTankAsInlineHeaterSetted,
      isInstallerSettingsComissioningDHWActive,
    ]
  );

  return { availableParametersForDropdown };
}
