import { useLocation } from "react-router-dom";
import { ISubNavItem } from "../../InstallerSettingsTab/SubMenu/InstallerSettingsSubMenu";
import { useTranslation } from "react-i18next";

export interface IUseUserSettingsParameters {
  baseUrl: string;
}

export default function useUserSettings({
  baseUrl,
}: IUseUserSettingsParameters) {
  const { t: subMenuT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.userSettings.subMenuItems",
  });
  const location = useLocation();
  const getSubMenuItems = () => {
    const hrefBuilder = (href: string) => `${baseUrl}${href}${location.search}`;
    let subMenuItems: Array<ISubNavItem> = [];
    subMenuItems = subMenuItems.concat([
      {
        label: subMenuT("quietMode"),
        href: hrefBuilder("/quiet-mode"),
      },
      {
        label: subMenuT("season"),
        href: hrefBuilder("/season"),
      },
      {
        label: subMenuT("userAppPairing"),
        href: hrefBuilder("/user-app-pairing"),
      },
    ]);
    return subMenuItems;
  };
  return { getSubMenuItems };
}
